import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Modal,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import LocationModal from "../Location/LocationModal"
import MigrationModal from "../../Migration"
import SweetAlert from "react-bootstrap-sweetalert"

const SettingMenu = props => {
  const [modal, setModal] = useState(false)
  const [modal2, setmodal2] = useState(false)
  const [migration_modal, setmigration_modal] = useState(false)
  const showMessage = () => {
    setModal(true)
  }
  const [dashboardData, setDashboardData] = useState(null)

  useEffect(() => {
    const storedData = localStorage.getItem("DashboardData")
    if (storedData) {
      setDashboardData(JSON.parse(storedData))
    }
  }, [])

  return (
    <>
      <Modal isOpen={modal} centered={true}>
        <div className="modal-body text-center">
          <h6>Upgrade your package to use this feature.</h6>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setModal(false)}
            aria-hidden="true"
          >
            Close
          </button>
        </div>
      </Modal>

      {modal2 ? <LocationModal modal2={modal2} setmodal2={setmodal2} /> : ""}
      {migration_modal ? (
        <MigrationModal
          migration_modal={migration_modal}
          setmigration_modal={setmigration_modal}
        />
      ) : (
        ""
      )}
      <Row className="mt-4">
        <Col md={9}>
          <Card className="h-100">
            <CardBody>
              <Row>
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/staff`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Staff</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/role`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Permissions</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/scheme`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Staff schemes</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/global-ip`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Global IP</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/global-security`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Day & Time</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                {localStorage.getItem("company_name") === "maxnova" ? (
                  <Col xl={3} md={4} sm={6}>
                    <Link to={`/reminder`}>
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="text-center">
                          <h4 className="fw-medium fs-4">Reminder</h4>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  ""
                )}
                {dashboardData?.fetch_enquries?.fetch_enquries === 1 ? (
                  <>
                    {/* <Col xl={3} md={4} sm={6}>
                                 <Link to={`/fetch-from-email`}>
                                    <Card className="mini-stat bg-primary text-white">
                                       <CardBody className="text-center">
                                          <h4 className="fw-medium fs-4">Fetch From Email</h4>
                                       </CardBody>
                                    </Card>
                                 </Link>
                              </Col> */}
                    <Col xl={3} md={4} sm={6}>
                      <Link to={`/fetch-enquiries`}>
                        <Card className="mini-stat bg-primary text-white">
                          <CardBody className="text-center">
                            <h4 className="fw-medium fs-4">Fetch Enquiries</h4>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/login-logs`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Login Logs</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/klik-drive`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Klik Drive</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/messages`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Create Messages</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/campaigns`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Campaigns</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                {getCurrentUser().crmpronew_permission === 1 ? (
                  <Col xl={3} md={4} sm={6}>
                    <Link to={`/workflows`}>
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="text-center">
                          <h4 className="fw-medium fs-4">Workflows</h4>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  ""
                )}
                {getCurrentUser()?.crmpro_permission === 1 ||
                getCurrentUser()?.crmpronew_permission === 1 ? (
                  <>
                    <Col xl={3} md={4} sm={6}>
                      <Link to={`/connect-email`}>
                        <Card className="mini-stat bg-primary text-white">
                          <CardBody className="text-center">
                            <h4 className="fw-medium fs-4">Connect Gmail</h4>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xl={3} md={4} sm={6}>
                      <Card
                        className="mini-stat bg-primary text-white"
                        onClick={showMessage}
                      >
                        <CardBody className="text-center">
                          <h4 className="fw-medium fs-4">Connect Gmail</h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                )}

                {dashboardData?.limit_setting === 1 ? (
                  <>
                    <Col xl={3} md={4} sm={6}>
                      <Link to={`/connect-meta`}>
                        <Card className="mini-stat bg-primary text-white">
                          <CardBody className="text-center">
                            <h4 className="fw-medium fs-4">Connect Facebook</h4>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/connect-whatsapp`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Connect Whatsapp</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                {getCurrentUser()?.crmpro_permission === 1 ||
                getCurrentUser()?.crmpronew_permission === 1 ? (
                  <>
                    <Col xl={3} md={4} sm={6}>
                      <Link to={`/webhook-keys`}>
                        <Card className="mini-stat bg-primary text-white disabled">
                          <CardBody className="text-center">
                            <h4 className="fw-medium fs-4">
                              Connect Website/App
                            </h4>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xl={3} md={4} sm={6}>
                      <Card
                        className="mini-stat bg-primary text-white"
                        onClick={showMessage}
                      >
                        <CardBody className="text-center">
                          <h4 className="fw-medium fs-4">
                            Connect Website/App
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                )}
                {getCurrentUser()?.crmpro_permission === 1 ||
                getCurrentUser()?.crmpronew_permission === 1 ? (
                  <Col xl={3} md={4} sm={6}>
                    <Link to={`/proforma-settings`}>
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="text-center">
                          <h4 className="fw-medium fs-4">Proforma settings</h4>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  ""
                )}
                <Col xl={3} md={4} sm={6}>
                  <Link to={`/host-domain`}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody className="text-center">
                        <h4 className="fw-medium fs-4">Host on your domain</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="">
            <CardBody>
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingOne"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Lead Settings
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div className="accordion-body bg-primary text-light">
                      {getCurrentUser() &&
                      getCurrentUser().migrate_data == 1 ? (
                        <div
                          className="mb-3"
                          style={{
                            borderBottom: "1px solid rgb(247 247 247 / 38%)",
                          }}
                        >
                          <span
                            onClick={() => setmigration_modal(true)}
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            Migration Form
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {getCurrentUser() && getCurrentUser().location == 1 ? (
                        <div
                          className="mb-3"
                          style={{
                            borderBottom: "1px solid rgb(247 247 247 / 38%)",
                          }}
                        >
                          <span
                            onClick={() => setmodal2(true)}
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            Add New Location
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="mb-3"
                        style={{
                          borderBottom: "1px solid rgb(247 247 247 / 38%)",
                        }}
                      >
                        <Link to={`/dead`} className="text-light my-2">
                          Dead Reasons
                        </Link>
                      </div>
                      <div
                        className="mb-3"
                        style={{
                          borderBottom: "1px solid rgb(247 247 247 / 38%)",
                        }}
                      >
                        <Link
                          to={`/followup-status`}
                          className="text-light my-2"
                        >
                          Followup Stages
                        </Link>
                      </div>
                      <div
                        className="mb-3"
                        style={{
                          borderBottom: "1px solid rgb(247 247 247 / 38%)",
                        }}
                      >
                        <Link to={`/mature-status`} className="text-light my-2">
                          Mature Stages
                        </Link>
                      </div>
                      {getCurrentUser() && getCurrentUser().tag == 1 ? (
                        <div
                          className="mb-3"
                          style={{
                            borderBottom: "1px solid rgb(247 247 247 / 38%)",
                          }}
                        >
                          <Link to={`/lead-tag`} className="text-light my-2">
                            Lead Tag
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="mb-3"
                        style={{
                          borderBottom: "1px solid rgb(247 247 247 / 38%)",
                        }}
                      >
                        <Link to={`/lead-fields`} className="text-light my-2">
                          Custom Fields
                        </Link>
                      </div>
                      <div
                        className="mb-3"
                        style={{
                          borderBottom: "1px solid rgb(247 247 247 / 38%)",
                        }}
                      >
                        <Link to={`/lead-source`} className="text-light my-2">
                          Lead Source
                        </Link>
                      </div>
                      <div
                        className="mb-3"
                        style={{
                          borderBottom: "1px solid rgb(247 247 247 / 38%)",
                        }}
                      >
                        <Link to={`/lead-label`} className="text-light my-2">
                          Lead Label
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default SettingMenu
