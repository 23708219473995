import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import TodayReport from "../../components/DailyReport/Today-Report"

const ReportToday = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Today Report | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <TodayReport />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportToday
