import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import axios from "axios"
import { getCurrentUser } from "../../../helpers/Utils"
import { withTranslation } from "react-i18next"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [error, setError] = useState("")
  const [count, setCount] = useState(
    localStorage.getItem("Noti-Count") ? localStorage.getItem("Noti-Count") : ""
  )
  const [notificationData, setNotificationData] = useState([])

  const getNotifications = () => {
    setCount("")
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/get-notifications`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setError(res.data.message);
          setNotificationData(res.data.data.data)
          setCount(res.data.data.total)
        } else {
          setError(res.data.message)
        }
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  useEffect(() => {
    // getNotifications()
  }, [])

  const handleNotification = useCallback((type, message) => {
    if (type === "header-notification") {
      // setError('')
      setNotificationData(prevState => [message, ...prevState])
    }
  }, [])

  //   useEffect(() => {
  //     if (getCurrentUser()) {
  //       const socket = io("https://sockets.klikcrm.com/")
  //       socket.on(`${localStorage.getItem("company_name")}-${getCurrentUser() && getCurrentUser().id}`, handleNotification)
  //       return () => {
  //         socket.off(getCurrentUser() && getCurrentUser().id, handleNotification)
  //       }
  //     }
  //   }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
          onClick={() => getNotifications()}
        >
          <i className="mdi mdi-bell-outline"></i>
          <span className="badge bg-danger" style={{ borderRadius: "100%" }}>
            {count}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16 noti-font">
                  {" "}
                  {props.t("Notifications")}
                </h6>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: "230px" }}>
            {notificationData &&
              notificationData.map((member, i) => (
                <Link
                  to={
                    member.data?.type === "campaign_complete"
                      ? `/campaigns?id=${member.campaign_id}`
                      : member.data?.type === "transfer_request"
                      ? `/transfer-request?id=${member.lead_id}`
                      : `/leads?id=${member.lead_id}`
                  }
                  className="text-reset notification-item"
                  key={i}
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span
                        className="avatar-title rounded-circle font-size-16"
                        style={{ backgroundColor: "#bdbcbc" }}
                      >
                        <img className="user-image" src={member.profile_pic} />
                      </span>
                    </div>
                    <div className="flex-1">
                      {member.data !== null ? (
                        <p
                          className="user-name d-inline"
                          dangerouslySetInnerHTML={{
                            __html: member.message,
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{member.created_time_ago}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
