import React, { useState, useEffect } from "react"
import {
   AvForm,
   AvField,
   AvRadio,
   AvRadioGroup,
   AvCheckboxGroup,
   AvCheckbox,
} from "availity-reactstrap-validation"
import { Card, CardBody, Row, Col, Modal } from "reactstrap"
import { getCurrentUser } from "../../../helpers/Utils"
import { addDays } from "date-fns"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import DatePicker from "react-datepicker"
import axios from "axios"
import moment from "moment"
import Loader from "../../Loader/loader.js"
import LeadModal3 from "../../Lead/leadmodal3"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import TimePicker from "react-times"
// use material theme
import "react-times/css/material/default.css"
// or you can use classic theme
import "react-times/css/classic/default.css"
import Select from "react-select"
import ProductCart from "./productCart"
import ServiceCart from "./serviceCart"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { MentionsInput, Mention } from "react-mentions"
import _escapeRegExp from "lodash/escapeRegExp"

let total
let subTotal
let productNames = []
let otherGst = 0

const CupdateTable = props => {
   const [type, setType] = useState("")
   const [time, setTime] = useState(
      props.lead.best_time !== null ? props.lead.best_time : "08:00"
   )
   const [loading, setLoading] = useState(false)
   const [loader, setLoader] = useState(<Loader />)
   const [loader2, setLoader2] = useState("")
   const [loader3, setLoader3] = useState("")
   const [loader4, setLoader4] = useState("")
   const [modal_standard3, setmodal_standard3] = useState(false)
   const [lead, setLead] = useState([])
   const [first, setFirst] = useState(false)
   const [followupSec, setFollowupSec] = useState(false)
   const [matureSec, setMatureSec] = useState(false)
   const [deadSec, setDeadSec] = useState(false)
   const [dead_reqSec, setDead_reqSec] = useState(false)
   const [orderSec, setOrderSec] = useState(false)
   const [showOrderValue, setShowOrderValue] = useState(false)
   const [reorder, setReorder] = useState(false)
   const [payment, setPayment] = useState(false)
   const [allMature, setAllMature] = useState([])
   const [allFollowup, setAllFollowup] = useState([])
   const [allDead, setAllDead] = useState([])
   const [allLinks, setAllLinks] = useState([])
   const [orderDetails, setOrderDetails] = useState([])
   const [followupMessage, setFollowupMessage] = useState("")
   const [orderType, setOrderType] = useState("")
   const [startDate, setStartDate] = useState(addDays(new Date(), 1))
   const [product, setProduct] = useState([])
   const [productDetail, setProductDetail] = useState([])
   const [err, setErr] = useState(false)
   const [err2, setErr2] = useState("")
   const [gstErr, setGstErr] = useState(false)
   const [quanErr, setQuanErr] = useState(false)
   const [min, setMin] = useState("")
   const [rate, setRate] = useState("")
   const [gst, setGST] = useState("")
   const [quan, setQuan] = useState(1)
   const [productID, setProductID] = useState("")
   const [productName, setProductName] = useState("")
   const [stockLeft, setStockLeft] = useState("")
   const [showCart, setShowCart] = useState(false)
   const [showCart2, setShowCart2] = useState(false)
   const [nextPopup, setNextPopup] = useState(false)
   const [clientReminder, setClientReminder] = useState(false)
   const [clientMessage, setClientMessage] = useState("")
   const [clientMessages, setClientMessages] = useState([])
   const [cart, setCart] = useState({ products: [] })
   const [serviceFields, setServiceFields] = useState({ values: [] })
   const [reorderDate, setReorderDate] = useState(addDays(new Date(), 1))
   const [paymentDate, setPaymentDate] = useState(addDays(new Date(), 1))
   const [reminderDate, setReminderDate] = useState(addDays(new Date(), 1))
   const [tandC, setTandC] = useState("")
   const [deliverTo, setDeliverTo] = useState("")
   const [billTo, setBillTo] = useState("")
   const [otherCharges, setOtherCharges] = useState({ fields: [] })
   const [afterDiscount, setAfterDiscount] = useState("")
   const [outstanding, setOutstanding] = useState("")
   const [proformaDate, setProformaDate] = useState(addDays(new Date(), 1))
   const [endTime, setEndTime] = useState(setHours(setMinutes(new Date(), 0), 8))
   const [state, setState] = useState({
      name: "React",
      value: props.data?.message ? props.data?.message : "",
      singleLineValue: props.data?.message ? props.data?.message : "",
      mentionData: null,
      variables: [
         {
            _id: "{company_name}",
            name: "{company_name}",
         },
         {
            _id: "{user_name}",
            name: "{user_name}",
         },
         {
            _id: "{user_number}",
            name: "{user_number}",
         },
         {
            _id: "{order_id}",
            name: "{order_id}",
         },
         {
            _id: "{proforma_id}",
            name: "{proforma_id}",
         },
         {
            _id: "{lead_name}",
            name: "{lead_name}",
         },
         {
            _id: "{customer_care_number}",
            name: "{customer_care_number}",
         },
         {
            _id: "{brand_name}",
            name: "{brand_name}",
         },
         {
            _id: "{order_balance}",
            name: "{order_balance}",
         },
         {
            _id: "{due_date}",
            name: "{due_date}",
         },
      ],
   })

   function swapTags(text) {
      let displayText = text
      const tagsWithBraces = text.match(/@\[\{[^\]]+\}\]\([^\)]+\)/gi) || []
      const tagsWithoutBraces = text.match(/@\[[^\]]+\]\([^\)]+\)/gi) || []

      // Handle tags with curly braces
      tagsWithBraces.forEach(tag => {
         const tagData = tag.slice(3, -1) // Extract data between [{...}]({...})
         const tagDisplayValue = tagData.split("](")[0] // Get the content before ']('
         const tagReplacement = "{" + tagDisplayValue // Ensure { and } are retained
         displayText = displayText.replace(
            new RegExp(_escapeRegExp(tag), "gi"),
            tagReplacement
         )
      })

      // Handle tags without curly braces
      tagsWithoutBraces.forEach(tag => {
         const tagData = tag.slice(2, -1) // Extract data between [...](...)
         const tagDisplayValue = tagData.split("](")[0] // Get the content before ']('
         const tagReplacement = tagDisplayValue + ": " + tagData.split("](")[1] // No need to add curly braces
         displayText = displayText.replace(
            new RegExp(_escapeRegExp(tag), "gi"),
            tagReplacement
         )
      })

      return displayText
   }

   const handleChangeSingle = (e, newValue) => {
      setState({
         ...state,
         singleLineValue: newValue,
      })
   }

   const getAllDrive = () => {
      setAllLinks([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/klikdrive?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllLinks(res.data.data.data)
            } else {
            }
         })
   }

   const variableData1 = state.variables.map(variable => ({
      id: variable._id,
      display: `${variable.name}`,
   }))

   const variableData2 = allLinks?.map(link => ({
      id: link?.link,
      display: `${link?.title}`,
   }))

   const variableData = [...variableData1, ...variableData2]

   const handleTimeChange = time => {
      let hours
      if (time.meridiem === "AM") {
         hours = time.hour
      } else {
         hours = parseInt(time.hour) + 12
      }
      let timeString = `${hours}:${time.minute}`
      setTime(timeString)
   }

   const formatDate = date => {
      let d = new Date(date)
      let month = (d.getMonth() + 1).toString()
      let day = d.getDate().toString()
      let year = d.getFullYear()
      if (month.length < 2) {
         month = "0" + month
      }
      if (day.length < 2) {
         day = "0" + day
      }
      return [year, month, day].join("-")
   }

   const getFollowupCount = e => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/get-followup-count`,
            {
               date: e !== undefined ? formatDate(e) : formatDate(startDate),
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            setFollowupMessage(res.data.data)
         })
   }

   const getTandC = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition?type=product_list`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setTandC(res.data.data[0]?.term_condition)
            }
         })
   }

   const getDeliverTo = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead/${props.lead?.id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setDeliverTo(res.data.data.deliver_to)
               setBillTo(res.data.data.proforma_address)
            }
         })
   }

   useEffect(() => {
      if (nextPopup) {
         getTandC()
         getDeliverTo()
      }
   }, [nextPopup])

   const addOtherFields = () => {
      setOtherCharges({
         fields: [
            ...otherCharges.fields,
            { name: "", action: "", value: 0, gst: 0, gstAmount: 0 },
         ],
      })
   }

   const addtionalCharge = e => {
      setOtherCharges({
         fields: [
            {
               name: "Extra charges",
               action: "1",
               value: e.target.value,
               gst: 0,
               gstAmount: 0,
            },
         ],
      })
      total = cart.products.reduce(
         (total, currentValue) => (total = total + currentValue.total),
         0
      )
      setAfterDiscount((total + parseFloat(e.target.value)).toFixed(2))
   }

   const removeOtherFields = i => {
      setLoader4(<Loader />)
      let fields // declare fields variable here
      if (otherCharges) {
         fields = otherCharges.fields
         setOtherCharges({ fields: [] })
      }
      fields.splice(i, 1)
      setTimeout(() => {
         setOtherCharges({ fields })
         setLoader4("")
         let total = cart.products.reduce(
            (total, currentValue) => (total = total + currentValue.total),
            0
         )
         let totalValue = total
         let addAmount = 0
         let addPercent = 0
         let addPercentValue = 0
         let subtractAmount = 0
         let subtractPercent = 0
         let subPercentValue = 0
         let gstValue = 0
         otherCharges.fields.map(item => {
            if (item.action === "1") {
               if (item.value !== "") {
                  addAmount = addAmount + parseFloat(item.value)
                  let amount = parseFloat(item.value)
                  let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
                  gstValue = gstAmnt - amount
               } else {
                  addAmount = addAmount + 0
               }
            } else if (item.action === "2") {
               if (item.value !== "") {
                  subtractAmount = subtractAmount + parseFloat(item.value)
                  let amount = parseFloat(item.value)
                  let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
                  gstValue = gstAmnt - amount
               } else {
                  subtractAmount = subtractAmount + 0
               }
            } else if (item.action === "3") {
               if (item.value !== "") {
                  addPercent = addPercent + parseInt(item.value)
               } else {
                  addPercent = addPercent + 0
               }
               addPercentValue = (totalValue * addPercent) / 100
               let amount = (totalValue * parseFloat(item.value)) / 100
               let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
               gstValue = gstAmnt - amount
            } else if (item.action === "4") {
               if (item.value !== "") {
                  subtractPercent = subtractPercent + parseInt(item.value)
               } else {
                  subtractPercent = subtractPercent + 0
               }
               subPercentValue = (totalValue * subtractPercent) / 100
               let amount = (totalValue * parseFloat(item.value)) / 100
               let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
               gstValue = gstAmnt - amount
            }
         })
         // fields[i]["gstAmount"] = gstValue
         setOtherCharges({ fields })
         setAfterDiscount(
            (
               totalValue -
               subtractAmount +
               addAmount +
               addPercentValue -
               subPercentValue
            ).toFixed(2)
         )
      }, 500)
   }

   const handleOtherFields = (index, e) => {
      let fields = otherCharges.fields
      fields[index][e.target.name] = e.target.value
      setOtherCharges({ fields })
      total = cart.products.reduce(
         (total, currentValue) => (total = total + currentValue.total),
         0
      )
      let totalValue = total
      if (e.target.name == "name") {
      } else {
         let addAmount = 0
         let addPercent = 0
         let addPercentValue = 0
         let subtractAmount = 0
         let subtractPercent = 0
         let subPercentValue = 0
         let gstValue = 0
         otherCharges.fields.map(item => {
            if (item.action === "1") {
               if (item.value !== "") {
                  addAmount = addAmount + parseFloat(item.value)
                  let amount = parseFloat(item.value)
                  let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
                  gstValue = gstAmnt - amount
               } else {
                  addAmount = addAmount + 0
               }
            } else if (item.action === "2") {
               if (item.value !== "") {
                  subtractAmount = subtractAmount + parseFloat(item.value)
                  let amount = parseFloat(item.value)
                  let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
                  gstValue = gstAmnt - amount
               } else {
                  subtractAmount = subtractAmount + 0
               }
            } else if (item.action === "3") {
               if (item.value !== "") {
                  addPercent = addPercent + parseInt(item.value)
               } else {
                  addPercent = addPercent + 0
               }
               addPercentValue = (totalValue * addPercent) / 100
               let amount = (totalValue * parseFloat(item.value)) / 100
               let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
               gstValue = gstAmnt - amount
            } else if (item.action === "4") {
               if (item.value !== "") {
                  subtractPercent = subtractPercent + parseInt(item.value)
               } else {
                  subtractPercent = subtractPercent + 0
               }
               subPercentValue = (totalValue * subtractPercent) / 100
               let amount = (totalValue * parseFloat(item.value)) / 100
               let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
               gstValue = gstAmnt - amount
            }
         })
         let fields = otherCharges.fields
         fields[index]["gstAmount"] = gstValue
         setOtherCharges({ fields })
         setAfterDiscount(
            (
               totalValue -
               subtractAmount +
               addAmount +
               addPercentValue -
               subPercentValue
            ).toFixed(2)
         )
      }
   }

   const getAllFollowup = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-status?per_page=200`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            setLoader("")
            setAllFollowup(res.data.data.data)
         })
   }

   const getMatureStatus = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/mature-status?per_page=200`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setAllMature(res.data.data.data)
            } else {
               setLoader("")
            }
         })
   }

   const getAllDead = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/dead-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllDead(res.data.data.data)
               setLoader("")
            } else {
               setLoader("")
            }
         })
   }

   const getData = e => {
      setLoader2(<Loader />)
      let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
         "company_name"
      )}/product?per_page=5000&permission=enable`
      if (e === "Service") {
         url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
         )}/services?per_page=5000`
      }
      axios
         .get(url, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                  }`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               if (e === "Service") {
                  setProduct(res.data.data.data)
               } else {
                  setProduct(res.data.data.values.data)
               }
               setLoader2("")
            } else {
               setLoader2("")
            }
         })
      setCart({ products: [] })
   }

   const productDetails = e => {
      setRate("0")
      setGST("0")
      setMin("")
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/proforma-product/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               res.data.data.map(item => {
                  if (item.key == "Selling Price") {
                     setRate(item.value)
                  }
                  if (item.key == "Minimum Price") {
                     setMin(item.value)
                  }
                  if (item.key == "GST") {
                     setGST(item.value)
                  }
                  if (item.key == "Id") {
                     setProductID(item.value)
                  }
                  if (item.key == "Name") {
                     setProductName(item.value)
                  }
                  if (item.key == "Stock Left") {
                     setStockLeft(item.value)
                  }
               })
               setQuan(1)
            }
         })
   }

   const options = []

   if (product !== undefined) {
      if (orderType === "Product") {
         product.map(item => {
            item.stock_left !== "0"
               ? options.push({
                  value: item.id,
                  label: `${item.name} (${item.expiry ? item.expiry : ""})`,
               })
               : ""
         })
      } else {
         product.map(item => {
            options.push({
               value: item.id,
               label: item.name,
            })
         })
      }
   }

   const getNetRate = e => {
      if (parseInt(e) > parseInt(stockLeft)) {
         setQuanErr(true)
      } else {
         setQuanErr(false)
         setQuan(e)
      }
   }

   const getRate = e => {
      if (parseInt(e) > parseInt(min)) {
         setRate(e)
         setErr(false)
      } else {
         setErr(true)
      }
   }

   const addToCart = () => {
      if (gst !== "") {
         setGstErr(false)
         if (productID == "") {
            setErr2("Please select a Product before adding to cart.")
         } else {
            setErr2("")
            setLoader3(<Loader />)
            let products = cart.products
            let index = products.findIndex(id => id.product_id === productID)
            if (index !== -1) {
               let subtotal
               let netRate
               let productPrice = rate
               let minPrice = min
               let qn = quan
               let tax = gst
               netRate = productPrice * qn
               subtotal = netRate + (netRate * tax) / 100
               setLoader3(<Loader />)
               products[index] = {
                  product_id: productID,
                  name: productName,
                  details: "",
                  product_price: productPrice,
                  quantity: qn,
                  min_price: minPrice,
                  stock_left: stockLeft,
                  subtotal: netRate,
                  tax: gst,
                  freeitem: "",
                  total: subtotal,
               }
               setTimeout(() => {
                  setLoader3("")
                  setCart({ products })
                  updateTotal()
               }, 500)
            } else {
               setShowCart(true)
               let subtotal
               let netRate
               let productPrice = rate
               let minPrice = min
               let qn = quan
               let tax = gst
               netRate = productPrice * qn
               subtotal = netRate + (netRate * tax) / 100
               setCart({
                  products: [
                     ...cart.products,
                     {
                        product_id: productID,
                        name: productName,
                        details: "",
                        product_price: productPrice,
                        quantity: qn,
                        min_price: minPrice,
                        stock_left: stockLeft,
                        subtotal: netRate,
                        tax: gst,
                        freeitem: "",
                        total: subtotal,
                     },
                  ],
               })
               setTimeout(() => {
                  updateTotal()
               }, 500)
            }
            setTimeout(() => {
               setLoader3("")
            }, 500)
         }
      } else {
         setGstErr(true)
      }
   }

   total = cart.products.reduce(
      (total, currentValue) => (total = total + currentValue.total),
      0
   )

   subTotal = cart.products.reduce(
      (subTotal, currentValue) => (subTotal = subTotal + currentValue.subtotal),
      0
   )

   otherGst = otherCharges.fields.reduce(
      (otherGst, currentValue) =>
         (otherGst = otherGst + parseFloat(currentValue.gstAmount)),
      0
   )

   const updateTotal = () => {
      total = cart.products.reduce(
         (total, currentValue) => (total = total + currentValue.total),
         0
      )
      subTotal = cart.products.reduce(
         (subTotal, currentValue) => (subTotal = subTotal + currentValue.subtotal),
         0
      )
   }

   const removeFormFields = i => {
      let products = cart.products
      setLoader3(<Loader />)
      setCart({ products: [] })
      products.splice(i, 1)
      setTimeout(() => {
         setLoader3("")
         setCart({ products })
      }, 500)
   }

   const serviceDetails = e => {
      setProductDetail([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/services/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setProductDetail(res.data.data)
               setProductID(res.data.data.id)
               setProductName(res.data.data.name)
               setServiceFields({
                  values: res.data.data.service_fields?.map(item =>
                     item.type == "text"
                        ? { field_name: item.field_name, value: item.value }
                        : { field_name: item.field_name, value: "" }
                  ),
               })
               setGST("0")
               setQuan(1)
               setRate("")
            } else {
            }
         })
   }

   const handleDetails = (e, i) => {
      let values = serviceFields.values
      values[i]["value"] = e.target.value
      setServiceFields({ values })
   }

   const handleChange = (i, e) => {
      let products = cart.products
      products[i][e.target.name] = e.target.value
      setCart({ products })
      setTimeout(() => {
         updateTotal()
      }, 500)
   }

   const addToCart2 = () => {
      if (productID == "") {
         setErr2("Please select a Product before adding to cart.")
      } else {
         setErr2("")
         setLoader3(<Loader />)
         let products = cart.products
         let index = products.findIndex(id => id.product_id === productID)
         // if (index !== -1) {
         //    let subtotal = serviceValue
         //    let netRate = nRate
         //    let qn = quan
         //    let tax = gst
         //    let totalValue = subtotal + (subtotal * tax) / 100
         //    setLoader(<Loader />)
         //    products[index] = {
         //       product_id: productID,
         //       name: productName,
         //       product_price: netRate,
         //       quantity: qn,
         //       subtotal: subtotal,
         //       tax: tax,
         //       total: totalValue,
         //       value: serviceFields.values,
         //    }
         //    setTimeout(() => {
         //       setLoader("")
         //       setCart({ products })
         //       updateTotalAfterDisc()
         //    }, 500)
         // } else {
         setShowCart(true)
         let netRate = rate
         let qn = quan
         let subtotal = netRate * qn
         let tax = gst
         let totalValue = subtotal + (subtotal * tax) / 100
         setCart({
            products: [
               ...cart.products,
               {
                  product_id: productID,
                  name: productName,
                  details: "",
                  hsn_code: "",
                  product_price: netRate,
                  quantity: qn,
                  subtotal: subtotal,
                  tax: tax,
                  total: totalValue,
                  value: serviceFields.values,
               },
            ],
         })
         setTimeout(() => {
            setLoader3("")
         }, 500)
         // }
      }
   }

   const handleDynamicValues = (i, index, e) => {
      let products = cart.products
      products[i].value[index]["value"] = e.target.value
      setCart({ products })
      setTimeout(() => {
         updateTotal()
      }, 500)
   }

   const getLastOrderDetails = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-detail?id=${props.lead && props.lead.id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setOrderDetails(res.data.data)
               setLoader("")
            } else {
               setLoader("")
            }
         })
   }

   const getOutstanding = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order-counts?customer_id=${props.lead && props.lead.id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            setOutstanding(res.data.data)
         })
   }

   const getMatureValue = e => {
      if (e.target.options[e.target.selectedIndex].text == "Order Received") {
         if (
            getCurrentUser()?.crm_permission == "1" ||
            getCurrentUser()?.crmpro_permission == "1"
         ) {
            setShowOrderValue(true)
         }
      } else {
         setShowOrderValue(false)
      }
   }

   const getClientReminder = e => {
      setClientReminder(e)
      if (e && clientMessages?.length === 0) {
         axios
            .get(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/message-templates?events=followup_reminder&per_page=20`,
               {
                  headers: {
                     "content-type": "application/json",
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setClientMessages(res.data.data.data)
                  setLoader("")
               } else {
                  setLoader("")
               }
            })
         getAllDrive()
      }
   }

   const handleMessageChange = event => {
      const newSelectedId = parseInt(event.target.value)
      const selectedObject = clientMessages?.find(
         item => item.id === newSelectedId
      )
      const message = selectedObject ? selectedObject.message : ""
      // setClientMessage(message)
      setState({
         ...state,
         singleLineValue: message,
      })
   }

   const updateOption = (e, f) => {
      setType(e)
      if (e === "followup") {
         getAllFollowup()
         setDeadSec(false)
         setMatureSec(false)
         setDead_reqSec(false)
         setFollowupSec(true)
         setFirst(false)
         setOrderSec(false)
      } else if (e === "matured") {
         getMatureStatus()
         getLastOrderDetails()
         setFollowupSec(false)
         setDeadSec(false)
         setMatureSec(true)
         setDead_reqSec(false)
         setOrderSec(false)
         if (f === "firstTime") {
            setFirst(true)
         }
      } else if (e === "dead") {
         getAllDead()
         setFollowupSec(false)
         setMatureSec(false)
         setDead_reqSec(false)
         setDeadSec(true)
         setFirst(false)
         setOrderSec(false)
      } else if (e === "dead_request") {
         getAllDead()
         setFollowupSec(false)
         setMatureSec(false)
         setDead_reqSec(false)
         setDeadSec(true)
         setFirst(false)
         setOrderSec(false)
      } else if (e === "matured_dead") {
         getAllDead()
         setDeadSec(true)
         setFollowupSec(false)
         setMatureSec(false)
         setDead_reqSec(true)
         setFirst(false)
         setOrderSec(false)
      } else if (e === "matured_dead_request") {
         getAllDead()
         setDeadSec(true)
         setDead_reqSec(true)
         setFollowupSec(false)
         setMatureSec(false)
         setFirst(false)
         setOrderSec(false)
      } else if (e === "proforma" || e === "order") {
         setOrderSec(true)
         setDeadSec(false)
         setDead_reqSec(false)
         setFollowupSec(false)
         setMatureSec(false)
         setFirst(false)
         setCart({ products: [] })
         if (
            getCurrentUser()?.crmpro_products_enabled === 1 &&
            getCurrentUser()?.crmpro_services_enabled === 0
         ) {
            getData("Product")
            setOrderType("Product")
         } else if (
            getCurrentUser()?.crmpro_products_enabled === 0 &&
            getCurrentUser()?.crmpro_services_enabled === 1
         ) {
            getData("Service")
            setOrderType("Service")
         } else {
            setOrderType("")
         }
      }
   }

   const submitFollowup = (e, value) => {
      setLoading(true)
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)

      let exe = "0"
      if (value.senior && value.senior.includes("executive")) {
         exe = "1"
      } else {
         exe = "0"
      }
      let teamL = "0"
      if (value.senior && value.senior.includes("team_leader")) {
         teamL = "1"
      } else {
         teamL = "0"
      }
      let admi = "0"
      if (value.senior && value.senior.includes("administrator")) {
         admi = "1"
      } else {
         admi = "0"
      }
      let Priority = "0"
      if (value.priority) {
         Priority = "1"
      } else {
         Priority = "0"
      }
      let Reminder = "0"
      if (value.send_followup_reminder) {
         Reminder = "1"
      } else {
         Reminder = "0"
      }
      let Email = 0
      if (value.send_email) {
         Email = 1
      } else {
         Email = 0
      }
      let Whatsapp = 0
      if (value.whatsapp) {
         Whatsapp = 1
      } else {
         Whatsapp = 0
      }
      let reminderClient = 0
      if (value.client_reminder) {
         reminderClient = 1
      } else {
         reminderClient = 0
      }
      if (value.type === "matured_dead") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  action: value.action,
                  dead_reason_id: value.dead_reason_id,
                  message: value.message,
                  admin: admi,
                  team_leader: teamL,
                  priority: Priority,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "matured_dead_request") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  action: value.action,
                  priority: Priority,
                  dead_reason_id: value.dead_reason_id,
                  message: value.message,
                  admin: admi,
                  priority: Priority,
                  team_leader: teamL,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "matured") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  order_value: showOrderValue ? e.target.order_value.value : "",
                  payment_received: showOrderValue
                     ? e.target.payment_received.value
                     : "",
                  mature_status_id: showOrderValue ? 0 : value.mature_status_id,
                  next_followup_date: e.target.next_followup_date.value,
                  next_followup_time: time,
                  action: value.action,
                  message: value.message,
                  admin: admi,
                  priority: Priority,
                  team_leader: teamL,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
                  client_reminder: reminderClient,
                  client_message: swapTags(state.singleLineValue),
                  reminder_date_time: clientReminder ? reminderDate : null,
                  reorder_date_time: reorder ? reorderDate : null,
                  payment_reminder_date_time: payment ? paymentDate : null,
                  // mature_status_options: value.mature_status_options,
                  send_followup_reminder:
                     followupMessage?.follow_event_added === 1 ? Reminder : "0",
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLead(res.data.data?.lead)
                  if (first) {
                     setmodal_standard3(true)
                  } else {
                     if (props.local !== undefined) {
                        props.getAllLeads("local", props.lead.id)
                     } else {
                        props.getAllLeads()
                     }
                     props.setmodal_xl(false)
                  }
                  setLoading(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "followup") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  followup_status_id: value.followup_status_id,
                  next_followup_date: e.target.next_followup_date.value,
                  next_followup_time: time,
                  message: value.message,
                  priority: Priority,
                  action: value.action,
                  admin: admi,
                  team_leader: teamL,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
                  client_reminder: reminderClient,
                  client_message: swapTags(state.singleLineValue),
                  reminder_date_time: clientReminder ? reminderDate : null,
                  send_followup_reminder:
                     followupMessage?.follow_event_added === 1 ? Reminder : "0",
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "dead") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  action: value.action,
                  dead_reason_id: value.dead_reason_id,
                  message: value.message,
                  admin: admi,
                  priority: Priority,
                  team_leader: teamL,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "dead_request") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  action: value.action,
                  dead_reason_id: value.dead_reason_id,
                  message: value.message,
                  admin: admi,
                  team_leader: teamL,
                  priority: Priority,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "proforma") {
         if (cart.products !== "") {
            productNames = cart.products
         }

         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/order`,
               {
                  lead_id: props.lead && props.lead.id,
                  payment_type: e.target.payment_type.value,
                  amount_after_charges: e.target.amount_after_charges.value,
                  amount: e.target.order_value.value,
                  advance_amount: e.target.advance_amount.value,
                  type: orderType === "Product" ? "proforma" : "proforma_service",
                  ...(orderType === "Product"
                     ? { products: productNames }
                     : { services: productNames }),
                  total_gst: value.total_gst,
                  grand_total: value.grand_total
                     ? value.grand_total
                     : e.target.order_value.value,
                  preferred_transport: value.preferred_transport
                     ? value.preferred_transport
                     : "",
                  term_condition: tandC,
                  deliver_to: deliverTo,
                  bill_to: billTo,
                  remarks: value.remarks ? value.remarks : "",
                  other_charges: otherCharges.fields,
                  next_followup_date: e.target.next_followup_date.value
                     ? e.target.next_followup_date.value
                     : "",
                  next_followup_time: e.target.next_followup_time.value
                     ? e.target.next_followup_time.value
                     : "",
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "order") {
         if (cart.products !== "") {
            productNames = cart.products
         }
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/order`,
               {
                  lead_id: props.lead && props.lead.id,
                  payment_type: e.target.payment_type.value,
                  amount_after_charges: e.target.order_value.value,
                  amount: e.target.order_value.value,
                  paymentamount: e.target.paymentamount.value,
                  type: orderType === "Product" ? "order" : "order_service",
                  ...(orderType === "Product"
                     ? { products: productNames }
                     : { services: productNames }),
                  preferred_transport: value.preferred_transport
                     ? value.preferred_transport
                     : "",
                  term_condition: tandC,
                  deliver_to: deliverTo,
                  bill_to: billTo,
                  remarks: value.remarks ? value.remarks : "",
                  other_charges: otherCharges.fields,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      if (props.lead && props.lead.type.includes("matured")) {
         setMatureSec(true)
         getMatureStatus()
         getLastOrderDetails()
         getOutstanding()
         if (localStorage.getItem("company_name") == "krishlar") {
            updateOption("matured")
         }
      } else {
         if (localStorage.getItem("company_name") == "krishlar") {
            updateOption("followup")
         }
         updateOption("followup")
      }
      getFollowupCount()
   }, [])

   return (
      <>
         {modal_standard3 ? (
            <LeadModal3
               modal_standard3={modal_standard3}
               setmodal_standard3={setmodal_standard3}
               lead={lead}
               setLead={setLead}
               setIsOpen={props.setmodal_xl}
               getAllLeads={props.getAllLeads}
               local={props.local !== undefined ? true : false}
            />
         ) : (
            ""
         )}
         <div className="card" style={{ position: "relative" }}>
            <div className="card-body">
               <AvForm
                  className="modal-title mt-0"
                  id="updateModalId"
                  onValidSubmit={(e, v) => {
                     submitFollowup(e, v)
                  }}
               >
                  {props.typeMature ? (
                     <div>
                        <div className="mb-3">
                           <AvRadioGroup
                              inline
                              name="type"
                              // label="Lead Status"
                              onChange={e => updateOption(e.target.value)}
                              required
                              value={
                                 localStorage.getItem("company_name") == "krishlar"
                                    ? "matured"
                                    : "matured"
                              }
                           >
                              {getCurrentUser() &&
                                 getCurrentUser().role_name === "Executive" ? (
                                 <>
                                    <AvRadio label="Matured Followup" value="matured" />
                                    <AvRadio
                                       label="Matured Dead Request"
                                       value="matured_dead_request"
                                    />
                                 </>
                              ) : (
                                 <>
                                    <AvRadio label="Matured Followup" value="matured" />
                                    <AvRadio label="Matured Dead" value="matured_dead" />
                                 </>
                              )}
                              {getCurrentUser().crmpro_permission == 1 ? (
                                 <>
                                    <AvRadio label="Create Proforma" value="proforma" />
                                    <AvRadio label="Create Order" value="order" />
                                 </>
                              ) : getCurrentUser().crmpronew_permission == 1 ? (
                                 <>
                                    <AvRadio label="Create Order" value="order" />
                                 </>
                              ) : (
                                 ""
                              )}
                           </AvRadioGroup>
                        </div>
                        {orderSec ? (
                           getCurrentUser()?.crmpro_products_enabled === 0 &&
                              getCurrentUser()?.crmpro_services_enabled === 0 ? (
                              <div className="text-danger text-center mb-3">
                                 You don't have a product or service permission.
                              </div>
                           ) : (
                              <>
                                 {outstanding ? (
                                    <div
                                       className="position-absolute fs-6 fw-bold text-end"
                                       style={{ right: "15px", top: "20px" }}
                                    >
                                       Outstanding: {outstanding?.outstanding}
                                    </div>
                                 ) : (
                                    ""
                                 )}
                                 <div className="d-flex justify-content-between">
                                    {getCurrentUser()?.crmpro_products_enabled === 1 &&
                                       getCurrentUser()?.crmpro_services_enabled === 1 ? (
                                       <div className="mb-3 w-50">
                                          <AvField
                                             className="select-arrow"
                                             // label="Select type"
                                             type="select"
                                             required
                                             name="order_type"
                                             onChange={e => {
                                                setOrderType(e.target.value),
                                                   getData(e.target.value)
                                             }}
                                             value={orderType}
                                          >
                                             <option value="" hidden>
                                                Select Type
                                             </option>
                                             <option value="Product">Product</option>
                                             <option value="Service">Service</option>
                                          </AvField>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                    <div className="mb-3">
                                       {/* <label>Type</label> */}
                                       <AvRadioGroup inline name="payment_type" required>
                                          <AvRadio label="Cash" value="cash" />
                                          <AvRadio label="Self" value="self" />
                                          <AvRadio label="Sample" value="sample" />
                                          <AvRadio label="Credit" value="credit" />
                                       </AvRadioGroup>
                                    </div>
                                 </div>
                                 {loader2 ? (
                                    loader2
                                 ) : (
                                    <>
                                       {err2 ? (
                                          <span className="text-danger text-align-center">
                                             {err2}
                                          </span>
                                       ) : (
                                          ""
                                       )}
                                       {orderType === "Product" ? (
                                          <div className="table-responsive">
                                             <Row>
                                                <Col
                                                   md={
                                                      getCurrentUser().crmpro_permission !== 1
                                                         ? 4
                                                         : 6
                                                   }
                                                >
                                                   <div className="mb-3">
                                                      <label>Order Value</label>
                                                      <AvField
                                                         type="number"
                                                         onFocus={e =>
                                                            e.target.addEventListener(
                                                               "wheel",
                                                               function (e) {
                                                                  e.preventDefault()
                                                               },
                                                               { passive: false }
                                                            )
                                                         }
                                                         // label="Order Value"
                                                         name="order_value"
                                                         disabled
                                                         value={parseFloat(total).toFixed(2)}
                                                      />
                                                   </div>
                                                </Col>
                                                {getCurrentUser().crmpro_permission !== 1 ? (
                                                   <Col md={4}>
                                                      <div className="mb-3">
                                                         <label>Additional charges</label>
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            // label="Order Value"
                                                            name="additional_charges"
                                                            placeholder="Other charges"
                                                            onChange={e => addtionalCharge(e)}
                                                         />
                                                      </div>
                                                   </Col>
                                                ) : (
                                                   ""
                                                )}
                                                <Col
                                                   md={
                                                      getCurrentUser().crmpro_permission !== 1
                                                         ? 4
                                                         : 6
                                                   }
                                                >
                                                   {type === "proforma" ? (
                                                      <div className="mb-3">
                                                         <label>Advance to be paid in(%)</label>
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            max={100}
                                                            name="advance_amount"
                                                            placeholder="Enter number between 0-100"
                                                         />
                                                      </div>
                                                   ) : (
                                                      <div className="mb-3">
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            label="Amount Received"
                                                            name="paymentamount"
                                                            placeholder="Enter amount"
                                                            required
                                                         />
                                                      </div>
                                                   )}
                                                </Col>
                                             </Row>
                                             <div
                                                className="lead_table"
                                                style={{ minHeight: "100px" }}
                                             >
                                                <table style={{ width: "100%" }}>
                                                   <tbody>
                                                      <tr>
                                                         <td>
                                                            <div style={{ minWidth: "200px" }}>
                                                               <label>Select Product</label>
                                                               <Select
                                                                  id="product"
                                                                  name="product"
                                                                  options={options}
                                                                  onChange={e =>
                                                                     productDetails(e.value)
                                                                  }
                                                               />
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <label>Quantity</label>
                                                            <span className="fw-lighter ms-5">
                                                               Stock: {stockLeft}
                                                            </span>
                                                            <AvField
                                                               type="number"
                                                               onFocus={e =>
                                                                  e.target.addEventListener(
                                                                     "wheel",
                                                                     function (e) {
                                                                        e.preventDefault()
                                                                     },
                                                                     { passive: false }
                                                                  )
                                                               }
                                                               min="1"
                                                               style={{ minWidth: "150px" }}
                                                               name="quantity"
                                                               value={quan}
                                                               onChange={e =>
                                                                  getNetRate(e.target.value)
                                                               }
                                                            />
                                                            {quanErr ? (
                                                               <span className="text-danger">
                                                                  Quantity can not be more than stock
                                                                  left.
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <label>Rate</label>
                                                            <span className="fw-lighter ms-5">
                                                               Min price: {min}
                                                            </span>
                                                            <AvField
                                                               type="number"
                                                               onFocus={e =>
                                                                  e.target.addEventListener(
                                                                     "wheel",
                                                                     function (e) {
                                                                        e.preventDefault()
                                                                     },
                                                                     { passive: false }
                                                                  )
                                                               }
                                                               min="0"
                                                               style={{ minWidth: "150px" }}
                                                               name="selling_price"
                                                               value={rate}
                                                               onChange={e =>
                                                                  getRate(e.target.value)
                                                               }
                                                            />
                                                            {err ? (
                                                               <span className="text-danger">
                                                                  Rate should be more than Min Price
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               className="select-arrow"
                                                               type="select"
                                                               name="gst"
                                                               label="GST"
                                                               style={{ minWidth: "120px" }}
                                                               onChange={e => {
                                                                  setGST(e.target.value)
                                                               }}
                                                               value={gst}
                                                            // disabled={disGst !==null ? true : false}
                                                            >
                                                               <option value="0">0%</option>
                                                               <option value="0.1">0.1%</option>
                                                               <option value="0.25">0.25%</option>
                                                               <option value="5">5%</option>
                                                               <option value="12">12%</option>
                                                               <option value="18">18%</option>
                                                               <option value="28">28%</option>
                                                            </AvField>
                                                            {gstErr ? (
                                                               <span className="text-danger">
                                                                  Select GST before adding to Cart*
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <button
                                                               type="button"
                                                               className="btn bg-success text-light float-end"
                                                               onClick={() => addToCart()}
                                                               disabled={
                                                                  err || quanErr ? true : false
                                                               }
                                                            >
                                                               Add to Cart
                                                            </button>
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                             {showCart ? (
                                                <div
                                                   className="lead_table mb-3"
                                                   style={{
                                                      minHeight: "50px",
                                                      maxHeight: "400px",
                                                      overflow: "auto",
                                                   }}
                                                >
                                                   <span
                                                      className=""
                                                      style={{ fontSize: "24px" }}
                                                   >
                                                      Cart
                                                   </span>
                                                   {loader3 !== "" ? (
                                                      loader3
                                                   ) : (
                                                      <table style={{ width: "100%" }}>
                                                         <thead>
                                                            <tr>
                                                               <th>Name</th>
                                                               <th>Quantity</th>
                                                               <th>Rate</th>
                                                               <th>Subtotal</th>
                                                               <th>GST</th>
                                                               <th>Total</th>
                                                               <th>Action</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            {cart.products &&
                                                               cart.products
                                                                  .reverse()
                                                                  .map((item, i) => (
                                                                     <ProductCart
                                                                        key={i}
                                                                        data={item}
                                                                        i={i}
                                                                        removeFormFields={
                                                                           removeFormFields
                                                                        }
                                                                        setCart={setCart}
                                                                        cart={cart}
                                                                        updateTotal={updateTotal}
                                                                     />
                                                                  ))}
                                                         </tbody>
                                                      </table>
                                                   )}
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                       ) : orderType === "Service" ? (
                                          <div className="table-responsive">
                                             <Row>
                                                <Col
                                                   md={
                                                      getCurrentUser().crmpro_permission !== 1
                                                         ? 4
                                                         : 6
                                                   }
                                                >
                                                   <div className="mb-3">
                                                      <AvField
                                                         type="number"
                                                         onFocus={e =>
                                                            e.target.addEventListener(
                                                               "wheel",
                                                               function (e) {
                                                                  e.preventDefault()
                                                               },
                                                               { passive: false }
                                                            )
                                                         }
                                                         label="Order Value"
                                                         name="order_value"
                                                         disabled
                                                         value={parseFloat(total).toFixed(2)}
                                                      />
                                                   </div>
                                                </Col>
                                                {getCurrentUser().crmpro_permission !== 1 ? (
                                                   <Col md={4}>
                                                      <div className="mb-3">
                                                         <label>Additional charges</label>
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            name="additional_charges"
                                                            placeholder="Other charges"
                                                            onChange={e => addtionalCharge(e)}
                                                         />
                                                      </div>
                                                   </Col>
                                                ) : (
                                                   ""
                                                )}
                                                <Col
                                                   md={
                                                      getCurrentUser().crmpro_permission !== 1
                                                         ? 4
                                                         : 6
                                                   }
                                                >
                                                   {type === "proforma" ? (
                                                      <div className="mb-3">
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            max={100}
                                                            label="Advance to be paid in(%)"
                                                            name="advance_amount"
                                                            placeholder="Enter number between 0-100"
                                                         />
                                                      </div>
                                                   ) : (
                                                      <div className="mb-3">
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            label="Amount Received"
                                                            name="paymentamount"
                                                            placeholder="Enter amount"
                                                            required
                                                         />
                                                      </div>
                                                   )}
                                                </Col>
                                             </Row>
                                             <div
                                                className="lead_table"
                                                style={{ minHeight: "100px" }}
                                             >
                                                <table style={{ width: "100%" }}>
                                                   <tbody>
                                                      <tr>
                                                         <td>
                                                            <div style={{ width: "120px" }}>
                                                               <label>Select Service</label>
                                                               <Select
                                                                  id="service"
                                                                  name="service"
                                                                  options={options}
                                                                  onChange={e =>
                                                                     serviceDetails(e.value)
                                                                  }
                                                               />
                                                            </div>
                                                         </td>
                                                         {productDetail.service_fields?.map(
                                                            (item, i) => (
                                                               <td key={i}>
                                                                  {item.type == "text" ? (
                                                                     <AvField
                                                                        style={{ width: "120px" }}
                                                                        label={item.field_name}
                                                                        type="text"
                                                                        name={item.field_name}
                                                                        // value={item.value}
                                                                        onChange={e =>
                                                                           handleDetails(e, i)
                                                                        }
                                                                     />
                                                                  ) : (
                                                                     <AvField
                                                                        style={{ width: "120px" }}
                                                                        className="select-arrow"
                                                                        type="select"
                                                                        label={item.field_name}
                                                                        name={item.field_name}
                                                                        onChange={e =>
                                                                           handleDetails(e, i)
                                                                        }
                                                                     >
                                                                        <option value="" hidden>
                                                                           Select
                                                                        </option>
                                                                        {item.value
                                                                           .split(",")
                                                                           .map(data => (
                                                                              <option
                                                                                 key={data}
                                                                                 value={data}
                                                                              >
                                                                                 {data}
                                                                              </option>
                                                                           ))}
                                                                     </AvField>
                                                                  )}
                                                               </td>
                                                            )
                                                         )}
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <AvField
                                                               type="number"
                                                               onFocus={e =>
                                                                  e.target.addEventListener(
                                                                     "wheel",
                                                                     function (e) {
                                                                        e.preventDefault()
                                                                     },
                                                                     { passive: false }
                                                                  )
                                                               }
                                                               min="1"
                                                               style={{ width: "120px" }}
                                                               label="Quantity"
                                                               name="quantity"
                                                               value={quan}
                                                               onChange={e =>
                                                                  setQuan(e.target.value)
                                                               }
                                                            />
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               type="number"
                                                               onFocus={e =>
                                                                  e.target.addEventListener(
                                                                     "wheel",
                                                                     function (e) {
                                                                        e.preventDefault()
                                                                     },
                                                                     { passive: false }
                                                                  )
                                                               }
                                                               min="0"
                                                               style={{ width: "120px" }}
                                                               label="Rate"
                                                               name="rate"
                                                               value={rate}
                                                               onChange={e =>
                                                                  setRate(e.target.value)
                                                               }
                                                            />
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               className="select-arrow"
                                                               label="GST"
                                                               type="select"
                                                               name="gst"
                                                               style={{ width: "120px" }}
                                                               onChange={e => setGST(e.target.value)}
                                                               value={gst}
                                                            >
                                                               <option value="0">0%</option>
                                                               <option value="0.1">0.1%</option>
                                                               <option value="0.25">0.25%</option>
                                                               <option value="5">5%</option>
                                                               <option value="12">12%</option>
                                                               <option value="18">18%</option>
                                                               <option value="28">28%</option>
                                                            </AvField>
                                                         </td>
                                                         <td>
                                                            <button
                                                               type="button"
                                                               className="btn bg-success text-light float-end"
                                                               onClick={() => addToCart2()}
                                                            >
                                                               Add to Cart
                                                            </button>
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>

                                             {showCart ? (
                                                <div
                                                   className="lead_table mb-3"
                                                   style={{
                                                      minHeight: "50px",
                                                      maxHeight: "400px",
                                                      overflow: "auto",
                                                   }}
                                                >
                                                   <span
                                                      className=""
                                                      style={{ fontSize: "24px" }}
                                                   >
                                                      Cart
                                                   </span>
                                                   {loader3 !== "" ? (
                                                      loader3
                                                   ) : (
                                                      <table style={{ width: "100%" }}>
                                                         <thead>
                                                            <tr>
                                                               <th>Service</th>
                                                               <th>Quantity</th>
                                                               <th>Rate</th>
                                                               <th>Subtotal</th>
                                                               <th>GST</th>
                                                               <th>Total</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            {cart.products &&
                                                               cart.products
                                                                  .reverse()
                                                                  .map((item, i) => (
                                                                     <ServiceCart
                                                                        key={i}
                                                                        data={item}
                                                                        i={i}
                                                                        removeFormFields={
                                                                           removeFormFields
                                                                        }
                                                                        productDetail={productDetail}
                                                                        setCart={setCart}
                                                                        cart={cart}
                                                                        updateTotal={updateTotal}
                                                                        handleChange={handleChange}
                                                                        handleDynamicValues={
                                                                           handleDynamicValues
                                                                        }
                                                                     />
                                                                  ))}
                                                         </tbody>
                                                      </table>
                                                   )}
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                    </>
                                 )}
                              </>
                           )
                        ) : (
                           <>
                              {followupSec === true ? (
                                 <>
                                    <div className="mb-3 required">
                                       <AvField
                                          className="select-arrow"
                                          label="Followup Stages"
                                          type="select"
                                          name="followup_status_id"
                                          style={{
                                             maxHeight: "200px !important",
                                             overflowY: "auto !important",
                                          }}
                                          value={props.lead?.followup_status_id}
                                       >
                                          <option value="" hidden>
                                             Select
                                          </option>
                                          {allFollowup &&
                                             allFollowup.map((item, i) => (
                                                <option value={item.id} key={i}>
                                                   {item.name}
                                                </option>
                                             ))}
                                       </AvField>
                                    </div>
                                 </>
                              ) : (
                                 ""
                              )}
                              {matureSec ? (
                                 <>
                                    <div className="d-flex mt-2">
                                       <div className="mb-3 pe-2">
                                          <AvField
                                             label="First Order Amount"
                                             type="text"
                                             name="first_order"
                                             value={orderDetails.first_order_amount}
                                             disabled
                                          />
                                          {orderDetails.matured_date !== null ? (
                                             <span>
                                                {orderDetails.matured_date},{" "}
                                                {orderDetails.first_order_by}
                                             </span>
                                          ) : (
                                             " "
                                          )}
                                       </div>
                                       {orderDetails.total_orders <= 1 ? (
                                          ""
                                       ) : (
                                          <div className="mb-3">
                                             <AvField
                                                label="Last Order Amount"
                                                type="text"
                                                name="last_order"
                                                value={orderDetails.last_order_amount}
                                                disabled
                                             />
                                             {orderDetails.last_order_date !== null ? (
                                                <span>
                                                   {orderDetails.last_order_date},{" "}
                                                   {orderDetails.last_order_by}
                                                </span>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                       )}
                                    </div>
                                    {orderDetails.lead_is_three_months_old === 1 ? (
                                       <div className="d-flex mt-2">
                                          <div className="mb-3 pe-2">
                                             <AvField
                                                label="Last 3 months billing/total billing"
                                                type="text"
                                                name="total_billing"
                                                value={`${orderDetails.last_three_month_avg}/${orderDetails.last_three_months_total}`}
                                                disabled
                                             />
                                          </div>
                                          <div className="mb-3">
                                             <AvField
                                                label="Last 3 months/total orders"
                                                type="text"
                                                name="total_orders"
                                                value={`${orderDetails.last_three_month_orders}/${orderDetails.total_orders}`}
                                                disabled
                                             />
                                          </div>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                    <div className="mb-3">
                                       <AvField
                                          label="I have received an order"
                                          type="checkbox"
                                          name="order"
                                          onClick={e => setShowOrderValue(e.target.checked)}
                                       />
                                    </div>
                                    {showOrderValue ? (
                                       <>
                                          <div className="row">
                                             <div className="mb-3 required col-md-6">
                                                <AvField
                                                   label="Order Value"
                                                   type="number"
                                                   onFocus={e =>
                                                      e.target.addEventListener(
                                                         "wheel",
                                                         function (e) {
                                                            e.preventDefault()
                                                         },
                                                         { passive: false }
                                                      )
                                                   }
                                                   name="order_value"
                                                   placeholder="Amount"
                                                   required
                                                />
                                                <i>
                                                   *If you have already recieved a new order.
                                                </i>
                                             </div>
                                             <div className="mb-3 col-md-6">
                                                <AvField
                                                   label="Payment Recieved"
                                                   type="number"
                                                   onFocus={e =>
                                                      e.target.addEventListener(
                                                         "wheel",
                                                         function (e) {
                                                            e.preventDefault()
                                                         },
                                                         { passive: false }
                                                      )
                                                   }
                                                   name="payment_received"
                                                   placeholder="Payment Amount"
                                                />
                                                <i>*Payment received against this order.</i>
                                             </div>
                                             {getCurrentUser()?.crmpro_permission === 1 ||
                                                getCurrentUser()?.crmpronew_permission === 1 ? (
                                                <>
                                                   <div
                                                      className={`mb-3 ${reorder ? "col-md-6" : "col-md-12"
                                                         }`}
                                                   >
                                                      <AvField
                                                         label="Re-order"
                                                         type="checkbox"
                                                         name="reorder"
                                                         onClick={e =>
                                                            setReorder(e.target.checked)
                                                         }
                                                      />
                                                   </div>
                                                   {reorder ? (
                                                      <div className="mb-3 col-md-6">
                                                         <label className="form-group">
                                                            Re-order Date
                                                         </label>
                                                         <DatePicker
                                                            className="w-100 form-control"
                                                            dateFormat="yyyy-MM-dd h:mm aa"
                                                            name="reorder_date"
                                                            selected={reorderDate}
                                                            minDate={moment().toDate()}
                                                            onChange={date => setReorderDate(date)}
                                                            showTimeSelect
                                                         />
                                                      </div>
                                                   ) : (
                                                      ""
                                                   )}
                                                   <div
                                                      className={`mb-3 ${payment ? "col-md-6" : "col-md-12"
                                                         }`}
                                                   >
                                                      <AvField
                                                         label="Payment Reminder"
                                                         type="checkbox"
                                                         name="payment_reminder"
                                                         onClick={e =>
                                                            setPayment(e.target.checked)
                                                         }
                                                      />
                                                   </div>
                                                   {payment ? (
                                                      <div className="mb-3 col-md-6">
                                                         <label className="form-group">
                                                            Payment reminder Date
                                                         </label>
                                                         <DatePicker
                                                            className="w-100 form-control"
                                                            dateFormat="yyyy-MM-dd h:mm aa"
                                                            name="payment_date"
                                                            selected={paymentDate}
                                                            minDate={moment().toDate()}
                                                            onChange={date => setPaymentDate(date)}
                                                            showTimeSelect
                                                         />
                                                      </div>
                                                   ) : (
                                                      ""
                                                   )}
                                                </>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                       </>
                                    ) : (
                                       <div className="mb-3 required">
                                          <AvField
                                             className="select-arrow"
                                             label="Matured Stages"
                                             type="select"
                                             name="mature_status_id"
                                             // onChange={e => getMatureValue(e)}
                                             value={props.lead?.mature_status_id}
                                          >
                                             <option value="" hidden>
                                                Select
                                             </option>
                                             {allMature &&
                                                allMature.map((item, i) =>
                                                   item.name !== "Order Received" ? (
                                                      <option value={item.id} key={i}>
                                                         {item.name}
                                                      </option>
                                                   ) : (
                                                      ""
                                                   )
                                                )}
                                          </AvField>
                                       </div>
                                    )}
                                 </>
                              ) : (
                                 ""
                              )}
                              {deadSec ? (
                                 <div className="mb-3 required">
                                    <AvField
                                       className="select-arrow"
                                       label="Dead Reason"
                                       type="select"
                                       name="dead_reason_id"
                                    >
                                       <option value="" hidden>
                                          Select
                                       </option>
                                       {allDead &&
                                          allDead.map((item, i) => (
                                             <option value={item.id} key={i}>
                                                {item.reason}
                                             </option>
                                          ))}
                                    </AvField>
                                 </div>
                              ) : (
                                 <div className="mt-2 row">
                                    <div className="mb-3 col-md-6 required">
                                       <label className="form-group">
                                          Next Followup Date
                                       </label>
                                       <DatePicker
                                          className="w-100 form-control"
                                          dateFormat="yyyy-MM-dd"
                                          name="next_followup_date"
                                          selected={startDate}
                                          minDate={moment().toDate()}
                                          onChange={date => {
                                             setStartDate(date), getFollowupCount(date)
                                          }}
                                       />
                                       <span className="fst-italic">
                                          {followupMessage?.count !== 0 ? (
                                             <>
                                                <a
                                                   href={`/leads?type=followup,matured&nextFollowupDate=${formatDate(
                                                      startDate
                                                   )}`}
                                                   target="_blank"
                                                   className="text-info"
                                                >
                                                   {followupMessage.count} Followup(s){" "}
                                                </a>
                                                are scheduled for the day.
                                             </>
                                          ) : (
                                             "No Followup is scheduled for the day."
                                          )}
                                       </span>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                       <label className="form-group">
                                          Next Followup Time
                                       </label>
                                       <TimePicker
                                          name="next_followup_time"
                                          // colorPalette="dark"
                                          time={time}
                                          theme="material"
                                          timeMode="12"
                                          onTimeChange={e => handleTimeChange(e)}
                                       />
                                    </div>
                                 </div>
                              )}
                              <div className="mb-3 required">
                                 <AvField
                                    label="Message"
                                    type="textarea"
                                    name="message"
                                    placeholder="Message"
                                 />
                              </div>
                              {followupSec || dead_reqSec ? (
                                 <div className="mb-3">
                                    <AvField
                                       label="Priority"
                                       type="checkbox"
                                       name="priority"
                                    />
                                 </div>
                              ) : (
                                 <>
                                    {matureSec ? (
                                       <>
                                          <div className="d-flex mt-2">
                                             {getCurrentUser()?.crm_permission == "1" ||
                                                getCurrentUser()?.crmpro_permission == "1" ? (
                                                <div className="mb-3 me-4">
                                                   <AvField
                                                      label="Priority"
                                                      type="checkbox"
                                                      name="priority"
                                                   />
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                             {followupMessage?.follow_event_added === 1 ? (
                                                <div className="mb-3">
                                                   <AvField
                                                      label="Receive system notification"
                                                      type="checkbox"
                                                      name="send_followup_reminder"
                                                   />
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                             {getCurrentUser().crmpronew_permission == 1 ? (
                                                <div className="mb-3 ms-4">
                                                   <AvField
                                                      label="Send Reminder to client"
                                                      type="checkbox"
                                                      name="client_reminder"
                                                      onChange={e =>
                                                         getClientReminder(e.target.checked)
                                                      }
                                                      style={{
                                                         background: `${getCurrentUser()?.whatsapp_enabled === 1
                                                               ? ""
                                                               : "lightgray"
                                                            }`,
                                                      }}
                                                      disabled={
                                                         getCurrentUser()?.whatsapp_enabled === 1
                                                            ? false
                                                            : true
                                                      }
                                                   />
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                             {clientReminder && clientMessages.length ? (
                                                <div className="mb-3 ms-4">
                                                   <AvField
                                                      className="select-arrow"
                                                      type="select"
                                                      name="message_title"
                                                      onChange={e => handleMessageChange(e)}
                                                   >
                                                      <option value="">Select Message I </option>
                                                      {clientMessages?.map((item, i) => (
                                                         <option value={item.id} key={i}>
                                                            {item.name}
                                                         </option>
                                                      ))}
                                                   </AvField>
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                          {clientReminder ? (
                                             <div className="d-flex">
                                                <div className="mb-3">
                                                   <label className="form-group">
                                                      Select date/time
                                                   </label>
                                                   <DatePicker
                                                      className="w-100 form-control"
                                                      dateFormat="yyyy-MM-dd h:mm aa"
                                                      name="reminder_date"
                                                      selected={reminderDate}
                                                      minDate={moment().toDate()}
                                                      onChange={date => setReminderDate(date)}
                                                      showTimeSelect
                                                   />
                                                </div>
                                                {getCurrentUser().crmpro_permission === 1 ||
                                                   getCurrentUser().crmpronew_permission === 1 ? (
                                                   <div className="mb-3 required ms-3 w-100">
                                                      <label>Whatsapp Message</label>
                                                      <MentionsInput
                                                         value={state.singleLineValue}
                                                         onChange={handleChangeSingle}
                                                         markup="@{{__type__||__id__||__display__}}"
                                                         placeholder="Type anything, use the @ symbol to select variables."
                                                         className="mentions"
                                                         required
                                                      >
                                                         <Mention
                                                            type="user"
                                                            trigger="@"
                                                            data={variableData}
                                                            className="mentions__mention"
                                                         />
                                                      </MentionsInput>
                                                      <small className="fst-italic">{`Use @ to select variables to create message.`}</small>
                                                      {/* <AvField
                                                      label="Message for client"
                                                      type="textarea"
                                                      name="message_client"
                                                      placeholder="Message"
                                                      value={clientMessage}
                                                      onChange={(e) => setClientMessage(e.target.value)}
                                                   /> */}
                                                   </div>
                                                ) : (
                                                   ""
                                                )}
                                             </div>
                                          ) : (
                                             ""
                                          )}
                                       </>
                                    ) : (
                                       ""
                                    )}
                                 </>
                              )}
                              <div className="mb-3">
                                 <label>Select Action</label>
                                 <AvRadioGroup
                                    inline
                                    name="action"
                                    defaultValue="call"
                                    required
                                 >
                                    <AvRadio label="Call" value="call" />
                                    <AvRadio label="Meeting" value="meeting" />
                                    <AvRadio label="Update" value="update" />
                                    <AvRadio label="Whatsapp" value="whatsapp" />
                                 </AvRadioGroup>
                              </div>
                              <div className="d-flex mt-2">
                                 {(props.lead && props.lead.leadholder_role == null) ||
                                    (props.lead &&
                                       props.lead.leadholder_role == "Administrator") ? (
                                    ""
                                 ) : (
                                    <>
                                       {getCurrentUser() &&
                                          getCurrentUser().role_name == "Administrator" ? (
                                          <div className="mb-3 me-2">
                                             <label>Send Notification to:</label>
                                             <AvCheckboxGroup inline name="senior">
                                                <AvCheckbox
                                                   label="Team Leader"
                                                   value="team_leader"
                                                ></AvCheckbox>{" "}
                                                {props.lead.leadholder_role == "Team Leader" ? (
                                                   ""
                                                ) : (
                                                   <AvCheckbox
                                                      label="Executive"
                                                      value="executive"
                                                   ></AvCheckbox>
                                                )}
                                             </AvCheckboxGroup>
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                       {getCurrentUser() &&
                                          getCurrentUser().role_name == "Team Leader" ? (
                                          <div className="mb-3 me-2">
                                             <label>Send Notification to:</label>
                                             <AvCheckboxGroup inline name="senior">
                                                <AvCheckbox
                                                   label="Administrator"
                                                   value="administrator"
                                                ></AvCheckbox>{" "}
                                                {props.lead.leadholder_role == "Team Leader" ? (
                                                   ""
                                                ) : (
                                                   <AvCheckbox
                                                      label="Executive"
                                                      value="executive"
                                                   ></AvCheckbox>
                                                )}
                                             </AvCheckboxGroup>
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                       {getCurrentUser() &&
                                          getCurrentUser().role_name == "Executive" ? (
                                          <div className="mb-3 me-2">
                                             <label>Send Notification to:</label>
                                             <AvCheckboxGroup inline name="senior">
                                                <AvCheckbox
                                                   label="Team Leader"
                                                   value="team_leader"
                                                ></AvCheckbox>{" "}
                                                <AvCheckbox
                                                   label="Administrator"
                                                   value="administrator"
                                                ></AvCheckbox>
                                             </AvCheckboxGroup>
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                    </>
                                 )}
                                 <div
                                    className={
                                       (props.lead && props.lead.leadholder_role == null) ||
                                          (props.lead &&
                                             props.lead.leadholder_role == "Administrator")
                                          ? "mb-3"
                                          : "mb-3 ms-5"
                                    }
                                 >
                                    <label>Send</label>
                                    <div className="d-flex">
                                       <div className="mb-3 me-2">
                                          <AvField
                                             label="Email"
                                             type="checkbox"
                                             name="send_email"
                                             style={{
                                                background: `${getCurrentUser()?.email_enabled === 1
                                                      ? ""
                                                      : "lightgray"
                                                   }`,
                                             }}
                                             disabled={
                                                getCurrentUser()?.email_enabled === 1
                                                   ? false
                                                   : true
                                             }
                                          />
                                       </div>
                                       <div className="mb-3 ms-4">
                                          <AvField
                                             label="Whatsapp"
                                             type="checkbox"
                                             name="whatsapp"
                                             style={{
                                                background: `${getCurrentUser()?.whatsapp_enabled === 1
                                                      ? ""
                                                      : "lightgray"
                                                   }`,
                                             }}
                                             disabled={
                                                getCurrentUser()?.whatsapp_enabled === 1
                                                   ? false
                                                   : true
                                             }
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </>
                        )}
                     </div>
                  ) : (
                     <>
                        <div className="mb-3">
                           <AvRadioGroup
                              inline
                              name="type"
                              // label="Lead Status"
                              onChange={e => updateOption(e.target.value)}
                              required
                              value={
                                 localStorage.getItem("company_name") == "krishlar"
                                    ? "followup"
                                    : "followup"
                              }
                           >
                              {getCurrentUser() &&
                                 getCurrentUser().role_name === "Executive" ? (
                                 <>
                                    <AvRadio label="Followup" value="followup" />
                                    <AvRadio label="Matured" value="matured" />
                                    <AvRadio label="Dead Request" value="dead_request" />
                                 </>
                              ) : (
                                 <>
                                    <AvRadio label="Followup" value="followup" />
                                    <AvRadio label="Matured" value="matured" />
                                    <AvRadio label="Dead" value="dead" />
                                 </>
                              )}
                              {getCurrentUser().crmpro_permission == 1 ? (
                                 <>
                                    <AvRadio label="Create Proforma" value="proforma" />
                                    <AvRadio label="Create Order" value="order" />
                                 </>
                              ) : getCurrentUser().crmpronew_permission == 1 ? (
                                 <>
                                    <AvRadio label="Create Order" value="order" />
                                 </>
                              ) : (
                                 ""
                              )}
                           </AvRadioGroup>
                        </div>
                        {orderSec ? (
                           getCurrentUser()?.crmpro_products_enabled === 0 &&
                              getCurrentUser()?.crmpro_services_enabled === 0 ? (
                              <div className="text-danger text-center mb-3">
                                 You don't have a product or service permission.
                              </div>
                           ) : (
                              <>
                                 <div className="d-flex justify-content-between">
                                    {getCurrentUser()?.crmpro_products_enabled === 1 &&
                                       getCurrentUser()?.crmpro_services_enabled === 1 ? (
                                       <div className="mb-3 w-50">
                                          <AvField
                                             className="select-arrow"
                                             // label="Select type"
                                             type="select"
                                             required
                                             name="order_type"
                                             onChange={e => {
                                                setOrderType(e.target.value),
                                                   getData(e.target.value)
                                             }}
                                             value={orderType}
                                          >
                                             <option value="" hidden>
                                                Select Type
                                             </option>
                                             <option value="Product">Product</option>
                                             <option value="Service">Service</option>
                                          </AvField>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                    <div className="mb-3">
                                       {/* <label>Type</label> */}
                                       <AvRadioGroup inline name="payment_type" required>
                                          <AvRadio label="Cash" value="cash" />
                                          <AvRadio label="Self" value="self" />
                                          <AvRadio label="Sample" value="sample" />
                                          <AvRadio label="Credit" value="credit" />
                                       </AvRadioGroup>
                                    </div>
                                 </div>
                                 {loader2 ? (
                                    loader2
                                 ) : (
                                    <>
                                       {err2 ? (
                                          <span className="text-danger text-align-center">
                                             {err2}
                                          </span>
                                       ) : (
                                          ""
                                       )}
                                       {orderType === "Product" ? (
                                          <div className="table-responsive">
                                             <Row>
                                                <Col
                                                   md={
                                                      getCurrentUser().crmpro_permission !== 1
                                                         ? 4
                                                         : 6
                                                   }
                                                >
                                                   <div className="mb-3">
                                                      <label>Order Value</label>
                                                      <AvField
                                                         type="number"
                                                         onFocus={e =>
                                                            e.target.addEventListener(
                                                               "wheel",
                                                               function (e) {
                                                                  e.preventDefault()
                                                               },
                                                               { passive: false }
                                                            )
                                                         }
                                                         // label="Order Value"
                                                         name="order_value"
                                                         disabled
                                                         value={parseFloat(total).toFixed(2)}
                                                      />
                                                   </div>
                                                </Col>
                                                {getCurrentUser().crmpro_permission !== 1 ? (
                                                   <Col md={4}>
                                                      <div className="mb-3">
                                                         <label>Additional charges</label>
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            // label="Order Value"
                                                            name="additional_charges"
                                                            placeholder="Other charges"
                                                            onChange={e => addtionalCharge(e)}
                                                         />
                                                      </div>
                                                   </Col>
                                                ) : (
                                                   ""
                                                )}
                                                <Col
                                                   md={
                                                      getCurrentUser().crmpro_permission !== 1
                                                         ? 4
                                                         : 6
                                                   }
                                                >
                                                   {type === "proforma" ? (
                                                      <div className="mb-3">
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            max={100}
                                                            label="Advance to be paid in(%)"
                                                            name="advance_amount"
                                                            placeholder="Enter number between 0-100"
                                                         />
                                                      </div>
                                                   ) : (
                                                      <div className="mb-3">
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            label="Amount Received"
                                                            name="paymentamount"
                                                            placeholder="Enter amount"
                                                            required
                                                         />
                                                      </div>
                                                   )}
                                                </Col>
                                             </Row>
                                             <div
                                                className="lead_table"
                                                style={{ minHeight: "100px" }}
                                             >
                                                <table style={{ width: "100%" }}>
                                                   <tbody>
                                                      <tr>
                                                         <td>
                                                            <div style={{ minWidth: "200px" }}>
                                                               <label>Select Product</label>
                                                               <Select
                                                                  id="product"
                                                                  name="product"
                                                                  options={options}
                                                                  onChange={e =>
                                                                     productDetails(e.value)
                                                                  }
                                                               />
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <label>Quantity</label>
                                                            <span className="fw-lighter ms-5">
                                                               Stock: {stockLeft}
                                                            </span>
                                                            <AvField
                                                               type="number"
                                                               onFocus={e =>
                                                                  e.target.addEventListener(
                                                                     "wheel",
                                                                     function (e) {
                                                                        e.preventDefault()
                                                                     },
                                                                     { passive: false }
                                                                  )
                                                               }
                                                               min="1"
                                                               style={{ minWidth: "150px" }}
                                                               name="quantity"
                                                               value={quan}
                                                               onChange={e =>
                                                                  getNetRate(e.target.value)
                                                               }
                                                            />
                                                            {quanErr ? (
                                                               <span className="text-danger">
                                                                  Quantity can not be more than stock
                                                                  left.
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <label>Rate</label>
                                                            <span className="fw-lighter ms-5">
                                                               Min price: {min}
                                                            </span>
                                                            <AvField
                                                               type="number"
                                                               onFocus={e =>
                                                                  e.target.addEventListener(
                                                                     "wheel",
                                                                     function (e) {
                                                                        e.preventDefault()
                                                                     },
                                                                     { passive: false }
                                                                  )
                                                               }
                                                               min="0"
                                                               style={{ minWidth: "150px" }}
                                                               name="selling_price"
                                                               value={rate}
                                                               onChange={e =>
                                                                  getRate(e.target.value)
                                                               }
                                                            />
                                                            {err ? (
                                                               <span className="text-danger">
                                                                  Rate should be more than Min Price
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               className="select-arrow"
                                                               type="select"
                                                               name="gst"
                                                               label="GST"
                                                               style={{ minWidth: "120px" }}
                                                               onChange={e => {
                                                                  setGST(e.target.value)
                                                               }}
                                                               value={gst}
                                                            // disabled={disGst !==null ? true : false}
                                                            >
                                                               <option value="0">0%</option>
                                                               <option value="0.1">0.1%</option>
                                                               <option value="0.25">0.25%</option>
                                                               <option value="5">5%</option>
                                                               <option value="12">12%</option>
                                                               <option value="18">18%</option>
                                                               <option value="28">28%</option>
                                                            </AvField>
                                                            {gstErr ? (
                                                               <span className="text-danger">
                                                                  Select GST before adding to Cart*
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <button
                                                               type="button"
                                                               className="btn bg-success text-light float-end"
                                                               onClick={() => addToCart()}
                                                               disabled={
                                                                  err || quanErr ? true : false
                                                               }
                                                            >
                                                               Add to Cart
                                                            </button>
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                             {showCart ? (
                                                <div
                                                   className="lead_table mb-3"
                                                   style={{
                                                      minHeight: "50px",
                                                      maxHeight: "400px",
                                                      overflow: "auto",
                                                   }}
                                                >
                                                   <span
                                                      className=""
                                                      style={{ fontSize: "24px" }}
                                                   >
                                                      Cart
                                                   </span>
                                                   {loader3 !== "" ? (
                                                      loader3
                                                   ) : (
                                                      <table style={{ width: "100%" }}>
                                                         <thead>
                                                            <tr>
                                                               <th>Name</th>
                                                               <th>Quantity</th>
                                                               <th>Rate</th>
                                                               <th>Subtotal</th>
                                                               <th>GST</th>
                                                               <th>Total</th>
                                                               <th>Action</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            {cart.products &&
                                                               cart.products
                                                                  .reverse()
                                                                  .map((item, i) => (
                                                                     <ProductCart
                                                                        key={i}
                                                                        data={item}
                                                                        i={i}
                                                                        removeFormFields={
                                                                           removeFormFields
                                                                        }
                                                                        setCart={setCart}
                                                                        cart={cart}
                                                                        updateTotal={updateTotal}
                                                                     />
                                                                  ))}
                                                         </tbody>
                                                      </table>
                                                   )}
                                                </div>
                                             ) : (
                                                <div style={{ height: "50px" }}></div>
                                             )}
                                          </div>
                                       ) : orderType === "Service" ? (
                                          <div className="table-responsive">
                                             <Row>
                                                <Col
                                                   md={
                                                      getCurrentUser().crmpro_permission !== 1
                                                         ? 4
                                                         : 6
                                                   }
                                                >
                                                   <div className="mb-3">
                                                      <label>Order Value</label>
                                                      <AvField
                                                         type="number"
                                                         onFocus={e =>
                                                            e.target.addEventListener(
                                                               "wheel",
                                                               function (e) {
                                                                  e.preventDefault()
                                                               },
                                                               { passive: false }
                                                            )
                                                         }
                                                         // label="Order Value"
                                                         name="order_value"
                                                         disabled
                                                         value={parseFloat(total).toFixed(2)}
                                                      />
                                                   </div>
                                                </Col>
                                                {getCurrentUser().crmpro_permission !== 1 ? (
                                                   <Col md={4}>
                                                      <div className="mb-3">
                                                         <label>Additional charges</label>
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            // label="Order Value"
                                                            name="additional_charges"
                                                            placeholder="Other charges"
                                                            onChange={e => addtionalCharge(e)}
                                                         />
                                                      </div>
                                                   </Col>
                                                ) : (
                                                   ""
                                                )}
                                                <Col
                                                   md={
                                                      getCurrentUser().crmpro_permission !== 1
                                                         ? 4
                                                         : 6
                                                   }
                                                >
                                                   {type === "proforma" ? (
                                                      <div className="mb-3">
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            max={100}
                                                            label="Advance to be paid in(%)"
                                                            name="advance_amount"
                                                            placeholder="Enter number between 0-100"
                                                         />
                                                      </div>
                                                   ) : (
                                                      <div className="mb-3">
                                                         <AvField
                                                            type="number"
                                                            onFocus={e =>
                                                               e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                     e.preventDefault()
                                                                  },
                                                                  { passive: false }
                                                               )
                                                            }
                                                            label="Amount Received"
                                                            name="paymentamount"
                                                            placeholder="Enter amount"
                                                            required
                                                         />
                                                      </div>
                                                   )}
                                                </Col>
                                             </Row>
                                             <div
                                                className="lead_table"
                                                style={{ minHeight: "100px" }}
                                             >
                                                <table style={{ width: "100%" }}>
                                                   <tbody>
                                                      <tr>
                                                         <td>
                                                            <div style={{ width: "120px" }}>
                                                               <label>Select Service</label>
                                                               <Select
                                                                  id="service"
                                                                  name="service"
                                                                  options={options}
                                                                  onChange={e =>
                                                                     serviceDetails(e.value)
                                                                  }
                                                               />
                                                            </div>
                                                         </td>
                                                         {productDetail.service_fields?.map(
                                                            (item, i) => (
                                                               <td key={i}>
                                                                  {item.type == "text" ? (
                                                                     <AvField
                                                                        style={{ width: "120px" }}
                                                                        label={item.field_name}
                                                                        type="text"
                                                                        name={item.field_name}
                                                                        // value={item.value}
                                                                        onChange={e =>
                                                                           handleDetails(e, i)
                                                                        }
                                                                     />
                                                                  ) : (
                                                                     <AvField
                                                                        style={{ width: "120px" }}
                                                                        className="select-arrow"
                                                                        type="select"
                                                                        label={item.field_name}
                                                                        name={item.field_name}
                                                                        onChange={e =>
                                                                           handleDetails(e, i)
                                                                        }
                                                                     >
                                                                        <option value="" hidden>
                                                                           Select
                                                                        </option>
                                                                        {item.value
                                                                           .split(",")
                                                                           .map(data => (
                                                                              <option
                                                                                 key={data}
                                                                                 value={data}
                                                                              >
                                                                                 {data}
                                                                              </option>
                                                                           ))}
                                                                     </AvField>
                                                                  )}
                                                               </td>
                                                            )
                                                         )}
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <AvField
                                                               type="number"
                                                               onFocus={e =>
                                                                  e.target.addEventListener(
                                                                     "wheel",
                                                                     function (e) {
                                                                        e.preventDefault()
                                                                     },
                                                                     { passive: false }
                                                                  )
                                                               }
                                                               min="1"
                                                               style={{ width: "120px" }}
                                                               label="Quantity"
                                                               name="quantity"
                                                               value={quan}
                                                               onChange={e =>
                                                                  setQuan(e.target.value)
                                                               }
                                                            />
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               type="number"
                                                               onFocus={e =>
                                                                  e.target.addEventListener(
                                                                     "wheel",
                                                                     function (e) {
                                                                        e.preventDefault()
                                                                     },
                                                                     { passive: false }
                                                                  )
                                                               }
                                                               min="0"
                                                               style={{ width: "120px" }}
                                                               label="Rate"
                                                               name="rate"
                                                               value={rate}
                                                               onChange={e =>
                                                                  setRate(e.target.value)
                                                               }
                                                            />
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               className="select-arrow"
                                                               label="GST"
                                                               type="select"
                                                               name="gst"
                                                               style={{ width: "120px" }}
                                                               onChange={e => setGST(e.target.value)}
                                                               value={gst}
                                                            >
                                                               <option value="0">0%</option>
                                                               <option value="0.1">0.1%</option>
                                                               <option value="0.25">0.25%</option>
                                                               <option value="5">5%</option>
                                                               <option value="12">12%</option>
                                                               <option value="18">18%</option>
                                                               <option value="28">28%</option>
                                                            </AvField>
                                                         </td>
                                                         <td>
                                                            <button
                                                               type="button"
                                                               className="btn bg-success text-light float-end"
                                                               onClick={() => addToCart2()}
                                                            >
                                                               Add to Cart
                                                            </button>
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                             {showCart ? (
                                                <div
                                                   className="lead_table mb-3"
                                                   style={{
                                                      minHeight: "50px",
                                                      overflowX: "scroll",
                                                   }}
                                                >
                                                   <span
                                                      className=""
                                                      style={{ fontSize: "24px" }}
                                                   >
                                                      Cart
                                                   </span>
                                                   {loader3 !== "" ? (
                                                      loader3
                                                   ) : (
                                                      <table style={{ width: "100%" }}>
                                                         <thead>
                                                            <tr>
                                                               <th>Service</th>
                                                               <th>Quantity</th>
                                                               <th>Rate</th>
                                                               <th>Subtotal</th>
                                                               <th>GST</th>
                                                               <th>Total</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            {cart.products &&
                                                               cart.products
                                                                  .reverse()
                                                                  .map((item, i) => (
                                                                     <ServiceCart
                                                                        key={i}
                                                                        data={item}
                                                                        i={i}
                                                                        removeFormFields={
                                                                           removeFormFields
                                                                        }
                                                                        productDetail={productDetail}
                                                                        setCart={setCart}
                                                                        cart={cart}
                                                                        updateTotal={updateTotal}
                                                                        handleChange={handleChange}
                                                                        handleDynamicValues={
                                                                           handleDynamicValues
                                                                        }
                                                                     />
                                                                  ))}
                                                         </tbody>
                                                      </table>
                                                   )}
                                                </div>
                                             ) : (
                                                <div style={{ height: "50px" }}></div>
                                             )}
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                    </>
                                 )}
                              </>
                           )
                        ) : (
                           <>
                              {followupSec === true ? (
                                 <>
                                    <div className="mb-3 required">
                                       <AvField
                                          className="select-arrow"
                                          label="Followup Stages"
                                          type="select"
                                          name="followup_status_id"
                                          value={props.lead?.followup_status_id}
                                       >
                                          <option value="" hidden>
                                             Select
                                          </option>
                                          {allFollowup &&
                                             allFollowup.map((item, i) => (
                                                <option value={item.id} key={i}>
                                                   {item.name}
                                                </option>
                                             ))}
                                       </AvField>
                                    </div>
                                    <div className="d-flex mt-2">
                                       <div className="mb-3 pe-2 required">
                                          <label className="form-group">
                                             Next Followup Date
                                          </label>
                                          <DatePicker
                                             className="w-100 form-control"
                                             dateFormat="yyyy-MM-dd"
                                             name="next_followup_date"
                                             selected={startDate}
                                             minDate={moment().toDate()}
                                             onChange={date => {
                                                setStartDate(date), getFollowupCount(date)
                                             }}
                                          />
                                          <span className="fst-italic">
                                             {followupMessage?.count !== 0 ? (
                                                <>
                                                   <a
                                                      href={`/leads?type=followup,matured&nextFollowupDate=${formatDate(
                                                         startDate
                                                      )}`}
                                                      target="_blank"
                                                      className="text-info"
                                                   >
                                                      {followupMessage.count} Followup(s){" "}
                                                   </a>
                                                   are scheduled for the day.
                                                </>
                                             ) : (
                                                "No Followup is scheduled for the day."
                                             )}
                                          </span>
                                       </div>
                                       <div className="">
                                          <label className="form-group">
                                             Next Followup Time
                                          </label>
                                          <TimePicker
                                             name="next_followup_time"
                                             // colorPalette="dark"
                                             time={time}
                                             theme="material"
                                             timeMode="12"
                                             onTimeChange={e => handleTimeChange(e)}
                                          />
                                       </div>
                                    </div>
                                 </>
                              ) : (
                                 ""
                              )}
                              {matureSec ? (
                                 <>
                                    <div className="mb-4 required">
                                       <AvField
                                          label="Order Value"
                                          type="number"
                                          onFocus={e =>
                                             e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                   e.preventDefault()
                                                },
                                                { passive: false },
                                                setShowOrderValue(true)
                                             )
                                          }
                                          min="0"
                                          name="order_value"
                                          placeholder="Amount"
                                          required
                                       />
                                       <i>*If you have already recieved a new order.</i>
                                    </div>
                                    <div className="mb-4">
                                       <AvField
                                          label="Payment Recieved"
                                          type="number"
                                          onFocus={e =>
                                             e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                   e.preventDefault()
                                                },
                                                { passive: false }
                                             )
                                          }
                                          min="0"
                                          name="payment_received"
                                          placeholder="Payment Amount"
                                       />
                                       <i>*Payment received against this order.</i>
                                    </div>
                                    {getCurrentUser().crmpro_permission == 1 ||
                                       getCurrentUser().crmpronew_permission == 1 ? (
                                       <>
                                          <div className="mt-2 row">
                                             <div
                                                className={`mb-3 ${reorder ? "col-md-6" : "col-md-12"
                                                   }`}
                                             >
                                                <AvField
                                                   label="Re-order"
                                                   type="checkbox"
                                                   name="reorder"
                                                   onClick={e => setReorder(e.target.checked)}
                                                />
                                             </div>
                                             {reorder ? (
                                                <div className="mb-3 col-md-6">
                                                   <label className="form-group">
                                                      Re-order Date
                                                   </label>
                                                   <DatePicker
                                                      className="w-100 form-control"
                                                      dateFormat="yyyy-MM-dd h:mm aa"
                                                      name="reorder_date"
                                                      selected={reorderDate}
                                                      minDate={moment().toDate()}
                                                      onChange={date => setReorderDate(date)}
                                                      showTimeSelect
                                                   />
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                             <div
                                                className={`mb-3 ${payment ? "col-md-6" : "col-md-12"
                                                   }`}
                                             >
                                                <AvField
                                                   label="Payment Reminder"
                                                   type="checkbox"
                                                   name="payment_reminder"
                                                   onClick={e => setPayment(e.target.checked)}
                                                />
                                             </div>
                                             {payment ? (
                                                <div className="mb-3 col-md-6">
                                                   <label className="form-group">
                                                      Payment reminder Date
                                                   </label>
                                                   <DatePicker
                                                      className="w-100 form-control"
                                                      dateFormat="yyyy-MM-dd h:mm aa"
                                                      name="payment_date"
                                                      selected={paymentDate}
                                                      minDate={moment().toDate()}
                                                      onChange={date => setPaymentDate(date)}
                                                      showTimeSelect
                                                   />
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                          <div className="d-flex mt-2">
                                             <div className="mb-3 pe-2">
                                                <label className="form-group">
                                                   Next Followup Date
                                                </label>
                                                <DatePicker
                                                   className="w-100 form-control"
                                                   dateFormat="yyyy-MM-dd"
                                                   name="next_followup_date"
                                                   selected={startDate}
                                                   onChange={date => {
                                                      setStartDate(date), getFollowupCount(date)
                                                   }}
                                                />
                                                <span className="fst-italic">
                                                   {followupMessage?.count !== 0 ? (
                                                      <>
                                                         <a
                                                            href={`/leads?type=followup,matured&nextFollowupDate=${formatDate(
                                                               startDate
                                                            )}`}
                                                            target="_blank"
                                                            className="text-info"
                                                         >
                                                            {followupMessage.count} Followup(s){" "}
                                                         </a>
                                                         are scheduled for the day.
                                                      </>
                                                   ) : (
                                                      "No Followup is scheduled for the day."
                                                   )}
                                                </span>
                                             </div>
                                             <div className="mb-3">
                                                <label className="form-group">
                                                   Next Followup Time
                                                </label>
                                                <TimePicker
                                                   name="next_followup_time"
                                                   // colorPalette="dark"
                                                   time={time}
                                                   theme="material"
                                                   timeMode="12"
                                                   onTimeChange={e => handleTimeChange(e)}
                                                />
                                             </div>
                                          </div>
                                       </>
                                    ) : (
                                       <div className="d-flex mt-2">
                                          <div className="mb-3 pe-2">
                                             <label className="form-group">
                                                Next Followup Date
                                             </label>
                                             <DatePicker
                                                className="w-100 form-control"
                                                dateFormat="yyyy-MM-dd"
                                                name="next_followup_date"
                                                selected={startDate}
                                                onChange={date => {
                                                   setStartDate(date), getFollowupCount(date)
                                                }}
                                             />
                                             <span className="fst-italic">
                                                {followupMessage?.count !== 0 ? (
                                                   <>
                                                      <a
                                                         href={`/leads?type=followup,matured&nextFollowupDate=${formatDate(
                                                            startDate
                                                         )}`}
                                                         target="_blank"
                                                         className="text-info"
                                                      >
                                                         {followupMessage.count} Followup(s){" "}
                                                      </a>
                                                      are scheduled for the day.
                                                   </>
                                                ) : (
                                                   "No Followup is scheduled for the day."
                                                )}
                                             </span>
                                          </div>
                                          <div className="mb-3">
                                             <label className="form-group">
                                                Next Followup Time
                                             </label>
                                             <TimePicker
                                                name="next_followup_time"
                                                // colorPalette="dark"
                                                time={time}
                                                theme="material"
                                                timeMode="12"
                                                onTimeChange={e => handleTimeChange(e)}
                                             />
                                          </div>
                                       </div>
                                    )}
                                 </>
                              ) : (
                                 ""
                              )}
                              {deadSec === true ? (
                                 <>
                                    <div className="mb-3 required">
                                       <AvField
                                          className="select-arrow"
                                          label="Dead Reason"
                                          type="select"
                                          name="dead_reason_id"
                                       >
                                          <option value="" hidden>
                                             Select
                                          </option>
                                          {allDead &&
                                             allDead.map((item, i) => (
                                                <option value={item.id} key={i}>
                                                   {item.reason}
                                                </option>
                                             ))}
                                       </AvField>
                                    </div>
                                 </>
                              ) : (
                                 ""
                              )}
                              <div className="mb-3 required">
                                 <AvField
                                    label="Message"
                                    type="textarea"
                                    name="message"
                                    placeholder="Message"
                                 />
                              </div>
                              {followupSec === true ? (
                                 <>
                                    <div className="d-flex mt-2">
                                       <div className="mb-3 pe-4">
                                          <AvField
                                             label="Priority"
                                             type="checkbox"
                                             name="priority"
                                          />
                                       </div>
                                       {followupMessage?.follow_event_added === 1 ? (
                                          <div className="mb-3">
                                             <AvField
                                                label="Receive system notification"
                                                type="checkbox"
                                                name="send_followup_reminder"
                                             />
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                       {getCurrentUser().crmpro_permission == 1 ||
                                          getCurrentUser().crmpronew_permission == 1 ? (
                                          <div className="mb-3 ms-4">
                                             <AvField
                                                label="Send Reminder to client"
                                                type="checkbox"
                                                name="client_reminder"
                                                onChange={e =>
                                                   getClientReminder(e.target.checked)
                                                }
                                                style={{
                                                   background: `${getCurrentUser()?.whatsapp_enabled === 1
                                                         ? ""
                                                         : "lightgray"
                                                      }`,
                                                }}
                                                disabled={
                                                   getCurrentUser()?.whatsapp_enabled === 1
                                                      ? false
                                                      : true
                                                }
                                             />
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                       {clientReminder && clientMessages.length ? (
                                          <div className="mb-3 ms-4">
                                             <AvField
                                                className="select-arrow"
                                                type="select"
                                                name="message_title"
                                                onChange={e => handleMessageChange(e)}
                                             >
                                                <option value="">Select Message I </option>
                                                {clientMessages?.map((item, i) => (
                                                   <option value={item.id} key={i}>
                                                      {item.name}
                                                   </option>
                                                ))}
                                             </AvField>
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                    </div>
                                    {clientReminder ? (
                                       <div className="d-flex">
                                          <div className="mb-3">
                                             <label className="form-group">
                                                Select date/time
                                             </label>
                                             <DatePicker
                                                className="w-100 form-control"
                                                dateFormat="yyyy-MM-dd h:mm aa"
                                                name="reminder_date"
                                                selected={reminderDate}
                                                minDate={moment().toDate()}
                                                onChange={date => setReminderDate(date)}
                                                showTimeSelect
                                             />
                                          </div>
                                          <div className="mb-3 required ms-3 w-100">
                                             <label>Whatsapp Message</label>
                                             <MentionsInput
                                                value={state.singleLineValue}
                                                onChange={handleChangeSingle}
                                                markup="@{{__type__||__id__||__display__}}"
                                                placeholder="Type anything, use the @ symbol to select variables."
                                                className="mentions"
                                                required
                                             >
                                                <Mention
                                                   type="user"
                                                   trigger="@"
                                                   data={variableData}
                                                   className="mentions__mention"
                                                />
                                             </MentionsInput>
                                             <small className="fst-italic">{`Use @ to select variables to create message.`}</small>
                                             {/* <AvField
                                                label="Message for client"
                                                type="textarea"
                                                name="message_client"
                                                placeholder="Message"
                                                value={clientMessage}
                                                onChange={(e) => setClientMessage(e.target.value)}
                                             /> */}
                                          </div>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                 </>
                              ) : (
                                 <>
                                    {matureSec ? (
                                       <>
                                          {getCurrentUser()?.crm_permission == "1" ||
                                             getCurrentUser()?.crmpro_permission == "1" ? (
                                             <div className="mb-3">
                                                <AvField
                                                   label="Priority"
                                                   type="checkbox"
                                                   name="priority"
                                                />
                                             </div>
                                          ) : (
                                             ""
                                          )}
                                       </>
                                    ) : (
                                       ""
                                    )}
                                 </>
                              )}
                              <div className="mb-3 col-md-3">
                                 <label>Select Action</label>
                                 <AvRadioGroup
                                    inline
                                    name="action"
                                    defaultValue="call"
                                    required
                                 >
                                    <AvRadio label="Call" value="call" />
                                    <AvRadio label="Meeting" value="meeting" />
                                    <AvRadio label="Update" value="update" />
                                    <AvRadio label="Whatsapp" value="whatsapp" />
                                 </AvRadioGroup>
                              </div>
                              {matureSec ? (
                                 <div className="d-flex">
                                    <div className="mb-3">
                                       <AvField
                                          label="Send Email"
                                          type="checkbox"
                                          name="send_email"
                                          style={{
                                             background: `${getCurrentUser()?.email_enabled === 1
                                                   ? ""
                                                   : "lightgray"
                                                }`,
                                          }}
                                          disabled={
                                             getCurrentUser()?.email_enabled === 1
                                                ? false
                                                : true
                                          }
                                       />
                                    </div>
                                    <div className="mb-3 ms-3">
                                       <AvField
                                          label="Send Whatsapp"
                                          type="checkbox"
                                          name="whatsapp"
                                          style={{
                                             background: `${getCurrentUser()?.whatsapp_enabled === 1
                                                   ? ""
                                                   : "lightgray"
                                                }`,
                                          }}
                                          disabled={
                                             getCurrentUser()?.whatsapp_enabled === 1
                                                ? false
                                                : true
                                          }
                                       />
                                    </div>
                                 </div>
                              ) : (
                                 ""
                              )}
                              {(props.lead && props.lead.leadholder_role == null) ||
                                 (props.lead &&
                                    props.lead.leadholder_role == "Administrator") ? (
                                 ""
                              ) : (
                                 <>
                                    {getCurrentUser() &&
                                       getCurrentUser().role_name == "Administrator" ? (
                                       <div className="mb-3 me-2">
                                          <label>Send Notification to:</label>
                                          <AvCheckboxGroup inline name="senior">
                                             <AvCheckbox
                                                label="Team Leader"
                                                value="team_leader"
                                             ></AvCheckbox>{" "}
                                             {props.lead.leadholder_role == "Team Leader" ? (
                                                ""
                                             ) : (
                                                <AvCheckbox
                                                   label="Executive"
                                                   value="executive"
                                                ></AvCheckbox>
                                             )}
                                          </AvCheckboxGroup>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                    {getCurrentUser() &&
                                       getCurrentUser().role_name == "Team Leader" ? (
                                       <div className="mb-3 me-2">
                                          <label>Send Notification to:</label>
                                          <AvCheckboxGroup inline name="senior">
                                             <AvCheckbox
                                                label="Administrator"
                                                value="administrator"
                                             ></AvCheckbox>{" "}
                                             {props.lead.leadholder_role == "Team Leader" ? (
                                                ""
                                             ) : (
                                                <AvCheckbox
                                                   label="Executive"
                                                   value="executive"
                                                ></AvCheckbox>
                                             )}
                                          </AvCheckboxGroup>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                    {getCurrentUser() &&
                                       getCurrentUser().role_name == "Executive" ? (
                                       <div className="mb-3 me-2">
                                          <label>Send Notification to:</label>
                                          <AvCheckboxGroup inline name="senior">
                                             <AvCheckbox
                                                label="Administrator"
                                                value="administrator"
                                             ></AvCheckbox>{" "}
                                             <AvCheckbox
                                                label="Team Leader"
                                                value="team_leader"
                                             ></AvCheckbox>
                                          </AvCheckboxGroup>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                 </>
                              )}
                           </>
                        )}
                     </>
                  )}
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : getCurrentUser().crmpro_permission == 1 && orderSec ? (
                           <button
                              className="btn btn-primary"
                              type="button"
                              disabled={
                                 orderSec &&
                                    getCurrentUser()?.crmpro_products_enabled === 0 &&
                                    getCurrentUser()?.crmpro_services_enabled === 0
                                    ? true
                                    : false
                              }
                              onClick={() => setNextPopup(true)}
                           >
                              Next
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={
                                 (orderSec &&
                                    getCurrentUser()?.crmpro_products_enabled === 0 &&
                                    getCurrentUser()?.crmpro_services_enabled === 0) ||
                                 !startDate
                              }
                           >
                              Update
                           </button>
                        )}
                     </div>
                  </div>
                  {/* <Modal isOpen={nextPopup} centered={true} size="xl"> */}
                  {nextPopup ? (
                     <Card
                        style={{
                           position: "absolute",
                           top: "0px",
                           left: "0px",
                           // display: "flex",
                           // flexDirection: "column",
                           width: "100%",
                           // color: "var(--bs-modal-color)",
                           pointerEvents: "auto",
                           backgroundColor: "white",
                           backgroundClip: "padding-box",
                           border: "2px solid black",
                           borderRadius: "5px",
                           outline: "0",
                        }}
                     >
                        <CardBody>
                           <div className="modal-header">
                              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                 Order Value: {parseFloat(total).toFixed(2)}
                              </h5>
                              {/* <button
                                 type="button"
                                 className="cross__btn"
                                 onClick={() => setNextPopup(false)}
                                 aria-hidden="true"
                              >
                                 &times;
                              </button> */}
                           </div>
                           <div className="modal-body">
                              {cart.products?.length > 0 ? (
                                 <div
                                    className="p-2 mb-2"
                                    style={{ background: "#8080803d" }}
                                 >
                                    <span
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                          setShowCart2(!showCart2)
                                       }}
                                    >
                                       {" "}
                                       {showCart2 ? (
                                          <i className="fa fa-arrow-up me-2"></i>
                                       ) : (
                                          <i className="fa fa-arrow-down me-2"></i>
                                       )}
                                       You cart has {cart.products?.length} items.
                                    </span>
                                 </div>
                              ) : (
                                 ""
                              )}
                              {showCart2 ? (
                                 orderType === "Service" ? (
                                    <div
                                       className="lead_table mb-3"
                                       style={{
                                          minHeight: "50px",
                                          maxHeight: "200px",
                                          overflow: "auto",
                                       }}
                                    >
                                       {loader3 !== "" ? (
                                          loader3
                                       ) : (
                                          <table style={{ width: "100%" }}>
                                             <thead>
                                                <tr>
                                                   <th>Service</th>
                                                   <th>Quantity</th>
                                                   <th>Rate</th>
                                                   <th>Subtotal</th>
                                                   <th>GST</th>
                                                   <th>Total</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {cart.products &&
                                                   cart.products
                                                      .reverse()
                                                      .map((item, i) => (
                                                         <ServiceCart
                                                            key={i}
                                                            data={item}
                                                            i={i}
                                                            removeFormFields={removeFormFields}
                                                            productDetail={productDetail}
                                                            setCart={setCart}
                                                            cart={cart}
                                                            updateTotal={updateTotal}
                                                            handleChange={handleChange}
                                                            handleDynamicValues={
                                                               handleDynamicValues
                                                            }
                                                         />
                                                      ))}
                                             </tbody>
                                          </table>
                                       )}
                                    </div>
                                 ) : (
                                    <div
                                       className="lead_table mb-3"
                                       style={{
                                          minHeight: "50px",
                                          maxHeight: "200px",
                                          overflow: "auto",
                                       }}
                                    >
                                       {loader3 !== "" ? (
                                          loader3
                                       ) : (
                                          <table style={{ width: "100%" }}>
                                             <thead>
                                                <tr>
                                                   <th>Name</th>
                                                   <th>Quantity</th>
                                                   <th>Rate</th>
                                                   <th>Subtotal</th>
                                                   <th>GST</th>
                                                   <th>Total</th>
                                                   <th>Action</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {cart.products &&
                                                   cart.products
                                                      .reverse()
                                                      .map((item, i) => (
                                                         <ProductCart
                                                            key={i}
                                                            data={item}
                                                            i={i}
                                                            removeFormFields={removeFormFields}
                                                            setCart={setCart}
                                                            cart={cart}
                                                            updateTotal={updateTotal}
                                                         />
                                                      ))}
                                             </tbody>
                                          </table>
                                       )}
                                    </div>
                                 )
                              ) : (
                                 ""
                              )}
                              <Row>
                                 <Col md={4}>
                                    <div className="mb-3">
                                       <label>Bill To</label>
                                       <ReactQuill
                                          theme="snow"
                                          value={billTo}
                                          onChange={e => setBillTo(e)}
                                       />
                                    </div>
                                 </Col>
                                 <Col md={4}>
                                    <div className="mb-3">
                                       <label>Deliver To</label>
                                       <ReactQuill
                                          theme="snow"
                                          value={deliverTo}
                                          onChange={e => setDeliverTo(e)}
                                       />
                                    </div>
                                 </Col>
                                 <Col md={4}>
                                    <div className="mb-3">
                                       <label>Terms & Conditions</label>
                                       <ReactQuill
                                          theme="snow"
                                          value={tandC}
                                          onChange={e => setTandC(e)}
                                       />
                                    </div>
                                 </Col>
                                 <Col md={5}>
                                    <div className="d-flex justify-content-between">
                                       <label>Other Charges/discounts</label>
                                       <button
                                          className="btn btn-primary btn-sm"
                                          type="button"
                                          onClick={() => addOtherFields()}
                                       >
                                          +
                                       </button>
                                    </div>
                                    <div className="">
                                       {otherCharges.fields.length == 0 ? (
                                          <div className="mb-5"></div>
                                       ) : (
                                          <>
                                             {loader4 !== "" ? (
                                                loader4
                                             ) : (
                                                <div className="table-responsive mb-3">
                                                   <table style={{ width: "100%" }}>
                                                      <thead>
                                                         <tr>
                                                            <th>Name</th>
                                                            <th>Action</th>
                                                            <th>Value</th>
                                                            <th>GST</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                         {otherCharges.fields &&
                                                            otherCharges.fields.map(
                                                               (item, index) => (
                                                                  <tr key={index}>
                                                                     <td>
                                                                        <AvField
                                                                           name="name"
                                                                           type="text"
                                                                           placeholder="Enter name"
                                                                           onChange={e =>
                                                                              handleOtherFields(index, e)
                                                                           }
                                                                           value={item.name}
                                                                        />
                                                                     </td>
                                                                     <td>
                                                                        <AvField
                                                                           className="select-arrow"
                                                                           name="action"
                                                                           type="select"
                                                                           onChange={e =>
                                                                              handleOtherFields(index, e)
                                                                           }
                                                                           value={item.action}
                                                                        >
                                                                           <option value="" hidden>
                                                                              Select
                                                                           </option>
                                                                           <option value="1">
                                                                              Add (+)
                                                                           </option>
                                                                           <option value="2">
                                                                              Subtract (-)
                                                                           </option>
                                                                           <option value="3">
                                                                              Percentage (+)
                                                                           </option>
                                                                           <option value="4">
                                                                              Percentage (-)
                                                                           </option>
                                                                        </AvField>
                                                                     </td>
                                                                     <td>
                                                                        <AvField
                                                                           name="value"
                                                                           type="number"
                                                                           onFocus={e =>
                                                                              e.target.addEventListener(
                                                                                 "wheel",
                                                                                 function (e) {
                                                                                    e.preventDefault()
                                                                                 },
                                                                                 { passive: false }
                                                                              )
                                                                           }
                                                                           min="0"
                                                                           placeholder="Enter amount"
                                                                           value={item.value}
                                                                           onChange={e => {
                                                                              handleOtherFields(index, e)
                                                                           }}
                                                                        />
                                                                     </td>
                                                                     <td>
                                                                        <AvField
                                                                           className="select-arrow"
                                                                           type="select"
                                                                           name="gst"
                                                                           style={{ width: "75px" }}
                                                                           onChange={e =>
                                                                              handleOtherFields(index, e)
                                                                           }
                                                                           value={item.gst}
                                                                        // disabled={disGst !== "" ? true : false}
                                                                        >
                                                                           <option value="0">0%</option>
                                                                           <option value="0.25">
                                                                              0.25%
                                                                           </option>
                                                                           <option value="5">5%</option>
                                                                           <option value="12">
                                                                              12%
                                                                           </option>
                                                                           <option value="18">
                                                                              18%
                                                                           </option>
                                                                           <option value="28">
                                                                              28%
                                                                           </option>
                                                                        </AvField>
                                                                     </td>
                                                                     <td>
                                                                        <span
                                                                           className="btn btn-danger"
                                                                           onClick={() =>
                                                                              removeOtherFields(index)
                                                                           }
                                                                        >
                                                                           &times;
                                                                        </span>
                                                                     </td>
                                                                  </tr>
                                                               )
                                                            )}
                                                      </tbody>
                                                   </table>
                                                </div>
                                             )}
                                          </>
                                       )}
                                    </div>
                                 </Col>
                                 <Col md={3}>
                                    <div className="mb-3">
                                       <AvField
                                          type="number"
                                          onFocus={e =>
                                             e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                   e.preventDefault()
                                                },
                                                { passive: false }
                                             )
                                          }
                                          label="Grand Value"
                                          name="amount_after_charges"
                                          value={
                                             afterDiscount !== ""
                                                ? (
                                                   parseFloat(afterDiscount) + otherGst
                                                ).toFixed(2)
                                                : (parseFloat(total) + otherGst).toFixed(2)
                                          }
                                          disabled
                                       />
                                    </div>
                                    <div className="mb-3">
                                       <AvField
                                          type="text"
                                          label="Prefered Transport"
                                          name="preferred_transport"
                                          placeholder="Prefered Transport"
                                       />
                                    </div>
                                    <div className="d-flex">
                                       <div className="mb-3">
                                          <AvField
                                             type="checkbox"
                                             label="Send Email"
                                             name="send_email"
                                             style={{
                                                background: `${getCurrentUser()?.email_enabled === 1
                                                      ? ""
                                                      : "lightgray"
                                                   }`,
                                             }}
                                             disabled={
                                                getCurrentUser()?.email_enabled === 1
                                                   ? false
                                                   : true
                                             }
                                          />
                                       </div>
                                       <div className="mb-3 ms-3">
                                          <AvField
                                             type="checkbox"
                                             label="Send Whatsapp"
                                             name="whatsapp"
                                             style={{
                                                background: `${getCurrentUser()?.whatsapp_enabled === 1
                                                      ? ""
                                                      : "lightgray"
                                                   }`,
                                             }}
                                             disabled={
                                                getCurrentUser()?.whatsapp_enabled === 1
                                                   ? false
                                                   : true
                                             }
                                          />
                                       </div>
                                    </div>
                                 </Col>
                                 <Col md={4}>
                                    {type === "proforma" ? (
                                       <div className="d-flex">
                                          <div className="mb-3 pe-2">
                                             <label className="form-group">
                                                Next Followup Date
                                             </label>
                                             <DatePicker
                                                className="w-100 form-control"
                                                dateFormat="yyyy-MM-dd"
                                                name="next_followup_date"
                                                selected={proformaDate}
                                                minDate={moment().toDate()}
                                                onChange={date => setProformaDate(date)}
                                             />
                                          </div>
                                          <div className="mb-3">
                                             <label type="text" className="form-group">
                                                Next Followup Time
                                             </label>
                                             <DatePicker
                                                className="w-100 form-control"
                                                name="next_followup_time"
                                                selected={endTime}
                                                onChange={date => setEndTime(date)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                             />
                                          </div>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                    <div className="mb-3">
                                       <AvField
                                          type="textarea"
                                          label="Remarks"
                                          name="remarks"
                                          placeholder="Remarks"
                                       />
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                           <div className="modal-footer">
                              <div className="text-center">
                                 <button
                                    className="btn btn-secondary me-2"
                                    type="button"
                                    onClick={() => setNextPopup(false)}
                                 >
                                    Back
                                 </button>
                                 {loading ? (
                                    <button
                                       className="btn btn-primary disabled"
                                       type="button"
                                       disabled
                                    >
                                       Loading
                                    </button>
                                 ) : (
                                    <button
                                       className="btn btn-primary"
                                       type="submit"
                                       disabled={
                                          orderSec &&
                                             getCurrentUser()?.crmpro_products_enabled === 0 &&
                                             getCurrentUser()?.crmpro_services_enabled === 0
                                             ? true
                                             : false
                                       }
                                    // onClick={handleSubmit}
                                    >
                                       Submit
                                    </button>
                                 )}
                              </div>
                           </div>
                        </CardBody>
                     </Card>
                  ) : (
                     ""
                  )}
                  {/* </Modal> */}
               </AvForm>
            </div>
         </div>
         {/* {loader} */}
      </>
   )
}
export default CupdateTable
