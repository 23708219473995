import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useFormik } from "formik"
import csc from "country-state-city"
import Select from "react-select"

let selectedLabelId = []

export default function LocationModal(props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [state, setState] = useState(props.location && props.location.state)
  const [district, setDistrict] = useState(
    props.location && props.location.district
  )
  const [info, setInfo] = useState(props.location && props.location.info)
  const [message, setMessage] = useState("")

  const addressFromik = useFormik({
    initialValues: {
      country: "India",
      state: null,
      city: null,
      info: null,
    },
    onSubmit: values => console.log(JSON.stringify(values)),
  })

  const states = csc.getStatesOfCountry("101")

  const updatedStates = states.map(state => ({
    label: state.name,
    value: state.id,
    ...state,
  }))

  const updatedCities = stateId =>
    csc
      .getCitiesOfState(stateId)
      .map(city => ({ label: city.name, value: city.id, ...city }))

  const { values, handleSubmit, setFieldValue, setValues } = addressFromik

  const getAllStates = e => {
    setState("")
    setDistrict("")
    if (e.length == 6) {
      axios
        .get(`https://api.postalpincode.in/pincode/${e}`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (res) {
          if (res.data[0].Status === "Success") {
            setDistrict(res.data[0].PostOffice[0].District)
            setState(res.data[0].PostOffice[0].State)
            setMessage("")
          } else {
            setMessage(res.data[0].Message)
          }
        })
    }
  }

  const options = []

  if (JSON.parse(localStorage.getItem("AllLabels"))) {
    JSON.parse(localStorage.getItem("AllLabels")).map(item => {
      options.push({
        value: item.id,
        label: item.name,
      })
    })
  }

  const dValues = []

  const getSelectedId = selectedItem => {
    selectedLabelId = []
    selectedItem && selectedItem.map(item => selectedLabelId.push(item.value))
  }

  const submitLocation = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.location !== undefined) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/location/${props.location.id}`,
          {
            state: state,
            district: district,
            city: value.city,
            info: value.info,
            pincode: value.pincode,
            labels: selectedLabelId,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setmodal2(false)
            props.setLocation(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/location`,
          {
            state: state,
            district: district,
            city: value.city,
            info: value.info,
            pincode: value.pincode,
            labels: selectedLabelId,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setmodal2(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  useEffect(() => {
    if (props.location && props.location.lead_label_name !== null) {
      selectedLabelId = []
      props.location &&
        props.location.lead_label_name.map(item => {
          dValues.push({
            value: item.id,
            label: item.name,
          })
          selectedLabelId.push(item.id)
        })
    } else {
      ;("")
    }
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal2(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      <Modal isOpen={props.modal2} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitLocation(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.location !== undefined
                ? "Edit Location"
                : "Create Location"}
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal2(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3 required">
              <label>State</label>
              <Select
                id="state"
                name="state"
                placeholder="Select..."
                options={updatedStates}
                defaultValue={{ label: state }}
                onChange={value => {
                  setState(value.name), setFieldValue("state", value)
                }}
              />
            </div>
            <div className="mb-3 required">
              <label>District</label>
              <Select
                id="city"
                name="district"
                placeholder="Select..."
                options={updatedCities(
                  values.state ? values.state.value : null
                )}
                defaultValue={{ label: district }}
                onChange={value => {
                  setDistrict(value.name), setFieldValue("city", value)
                }}
              />
            </div>
            <div className="mb-3">
              <AvField
                label="City"
                type="alphabet"
                name="city"
                placeholder=""
                value={props.location && props.location.city}
              />
            </div>
            {/* <div className="mb-3">
                     <label>Working Location</label>
                     <Select
                        id="city"
                        name="info"
                        placeholder="Select..."
                        options={updatedCities(values.state ? values.state.value : null)}
                        defaultValue={{ label: district }}
                        onChange={(value) => setInfo(value.name)
                        }
                     />
                  </div> */}
            <div className="mb-3">
              <AvField
                label="Working Location"
                type="textarea"
                name="info"
                placeholder="Add working location"
                value={props.location && props.location.info}
              />
            </div>
            <div className="mb-3">
              <AvField
                label="Pin Code"
                type="number"
                onFocus={e =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault()
                    },
                    { passive: false }
                  )
                }
                max="999999"
                name="pincode"
                placeholder="Enter pin code"
                // onChange={(e) => getAllStates(e.target.value)}
                value={props.location && props.location.pincode}
              />
              {message ? (
                <p className="text-danger fst-italic">Pin code Incorrect*</p>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <label>Select Labels</label>
              <Select
                isMulti
                isClearable="true"
                name="labels"
                options={options}
                defaultValue={dValues}
                onChange={e => getSelectedId(e)}
              />
            </div>
            {/* <div className="mb-3">
                     <AvField
                        label="State"
                        type="text"
                        name="state"
                        placeholder="State"
                        value={state}
                        disabled
                     />
                  </div>
                  <div className="mb-3">
                     <AvField
                        label="District"
                        type="text"
                        name="district"
                        placeholder="District"
                        value={district}
                        disabled
                     />
                  </div> */}

            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal2(false)}
                  >
                    {props.location !== undefined ? "Submit" : "Create"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
