import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import TodayFollowup from "../../components/Followups/Today"

const FollowupToday = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Today's Followup | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <TodayFollowup />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FollowupToday
