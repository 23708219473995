import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import axios from "axios"
import Paginations from "../Pagination"
import Loader from "../Loader/loader.js"
import OccupiedList from "./occupiedList"
import "react-toastify/dist/ReactToastify.css"

const OccupiedTable = () => {
  const [location, setLocation] = useState([])
  const [state, setState] = useState([])
  const [error, setError] = useState("")
  const [error1, setError1] = useState("")
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")

  const getStates = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/location?is_occupied_location_api=1`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError1(res.data.message)
          setState(res.data.data)
        } else {
          setLoader("")
          setError1(res.data.message)
        }
      })
  }

  const getLocations = e => {
    setLocation([])
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/occupied-location?state=${e}&is_occupied_location_api=1`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setLocation(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const getSearchedData = e => {
    setLocation("")
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/search-location?search=${
          e.target.search.value
        }&is_occupied_location_api=1`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          const data = res.data.data
          const formattedData = Array.isArray(data) ? data : Object.values(data)
          setLocation(formattedData)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }
  console.log("location", location)
  const paginate = e => {
    setLocation([])
    setAllPages([])
    setCounter("")
    // setLoader(<Loader />)
    axios
      .get(`${e}&is_occupied_location_api=1`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          // setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setLocation(res.data.data.data)
        } else {
          setError(res.data.message)
          // setLoader("")
        }
      })
  }

  useEffect(() => {
    getStates()
  }, [])

  return (
    <>
      <div className="page-title-box">
        <AvForm
          onValidSubmit={e => {
            getSearchedData(e)
          }}
        >
          <Row>
            <Col md={2}>
              <h6 className="page-title">Occupied Locations</h6>
            </Col>
            <Col md={10}>
              <div className="d-flex justify-content-between">
                <div
                  style={{
                    width: "calc(100% - 100px)",
                  }}
                >
                  <AvField
                    type="search"
                    name="search"
                    placeholder="Search"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <button className="btn btn-primary" type="submit">
                  <i className="mdi mdi-magnify me-2"></i>
                  Search
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </div>
      <Row>
        <Col md={2}>
          <div className="card">
            <div className="card-body">
              <div className="lead_table table-responsive">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr className="static-header">
                      <th>Locations</th>
                    </tr>
                    {state &&
                      state.map((item, i) =>
                        item.state !== "" ? (
                          <tr
                            key={i}
                            className={
                              location[0] && location[0].state === item.state
                                ? "emp-dashboard"
                                : "hov-tab tab colr"
                            }
                            onClick={e =>
                              getLocations(
                                item.state !== null ? item.state : -1
                              )
                            }
                          >
                            <td>
                              {item.state !== null ? item.state : "Unknown"} (
                              {item.location_count})
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                  </tbody>
                </table>
                {error1 ? (
                  <span
                    className="text-danger mt-3 d-table m-auto"
                    role="alert"
                  >
                    {error1}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md={10}>
          <div className="card">
            <div className="card-body">
              <div className="lead_table table table-sm table-bordered table-responsive">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr className="static-header">
                      <th>#</th>
                      <th>State</th>
                      <th>Distt.,City</th>
                      <th>Working Area</th>
                      <th>Pin Code</th>
                      {/* <th>Labels</th> */}
                      <th>Working Customer</th>
                      <th>Summary</th>
                      <th>Action</th>
                    </tr>
                    {Array.isArray(location) &&
                      location.map((item, obj) => (
                        <OccupiedList
                          key={obj}
                          data={item}
                          i={obj}
                          counter={counter}
                        />
                      ))}
                  </tbody>
                </table>
                {location == "" ? (
                  <span
                    className="text-primary mt-3 d-table m-auto"
                    role="alert"
                  >
                    Click on Locations
                  </span>
                ) : (
                  ""
                )}
                {error ? (
                  <span
                    className="text-danger mt-3 d-table m-auto"
                    role="alert"
                  >
                    {error}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {loader}
      {allPages !== [] ? (
        <Paginations data={allPages} paginate={paginate} />
      ) : (
        ""
      )}
    </>
  )
}
export default OccupiedTable
