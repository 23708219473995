import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
  getFilterDate2,
  setFilterDate2,
} from "../../helpers/Utils"
import axios from "axios"
import Paginations from "../Pagination"
import Loader from "../Loader/loader.js"
import OfferList from "./OfferList"
import OfferModal from "./OfferModal"
import MultipleChoice from "../Filter/MultipleChoice"
import InputFilter from "../Filter/SearchFilter"
import DateFilter from "../Filter/DateFilter"

const OfferTable = () => {
  const [modal_offer, setmodal_offer] = useState(false)
  const [id_filter, setid_filter] = useState(false)
  const [name_filter, setname_filter] = useState(false)
  const [status5_filter, setstatus5_filter] = useState(false)
  const [amount_filter, setamount_filter] = useState(false)
  const [date_filter, setdate_filter] = useState(false)
  const [date2_filter, setdate2_filter] = useState(false)
  const [sort, setSort] = useState(false)
  const [sort2, setSort2] = useState(false)
  const [sort3, setSort3] = useState(false)
  const [sort4, setSort4] = useState(false)
  const [sort5, setSort5] = useState(false)
  const [sort6, setSort6] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [offer, setOffer] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  const getOffers = () => {
    setLoader(<Loader />)
    setOffer([])
    const branchQuery = branch !== "" ? `&branch_id=${branch}` : ""
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/offer?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }&${branchQuery}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setOffer(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "id") {
      localStorage.setItem("leadID", e)
    }
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "status5") {
      localStorage.setItem("offerType", e)
    }
    if (type == "amount") {
      localStorage.setItem("leadAmount", e)
    }
    if (type == "date") {
      setFilterDate(e)
    }
    if (type == "date2") {
      setFilterDate2(e)
    }
    if (type == "sort") {
      localStorage.setItem("leadSort", e)
    }
    setSearchedCount("")
    setOffer([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/offer?id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
         &amount=${
           localStorage.getItem("leadAmount") !== null
             ? localStorage.getItem("leadAmount")
             : ""
         }
         &type=${
           localStorage.getItem("offerType") !== null
             ? localStorage.getItem("offerType")
             : ""
         } 
         &name=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &from_start_date=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &to_start_date=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &from_end_date=${
           getFilterDate2() && getFilterDate2().from !== null
             ? getFilterDate2().from
             : ""
         }
         &to_end_date=${
           getFilterDate2() && getFilterDate2().to !== null
             ? getFilterDate2().to
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setOffer(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setid_filter(false)
          setname_filter(false)
          setstatus5_filter(false)
          setleadholder_filter(false)
          setamount_filter(false)
          setdate_filter(false)
          setdate2_filter(false)
          setmessage_filter(false)
        } else {
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setOffer([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(
        `${e}&id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
            &amount=${
              localStorage.getItem("leadAmount") !== null
                ? localStorage.getItem("leadAmount")
                : ""
            }
         &type=${
           localStorage.getItem("offerType") !== null
             ? localStorage.getItem("offerType")
             : ""
         } 
         &name=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &from_start_date=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &to_start_date=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &from_end_date=${
           getFilterDate2() && getFilterDate2().from !== null
             ? getFilterDate2().from
             : ""
         }
         &to_end_date=${
           getFilterDate2() && getFilterDate2().to !== null
             ? getFilterDate2().to
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setOffer(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const exportLead = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/offer-export`,
        {
          ids: "",
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          //  setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-offers-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  useEffect(() => {
    getOffers()
  }, [])

  return (
    <>
      {modal_offer ? (
        <OfferModal
          modal_offer={modal_offer}
          setmodal_offer={setmodal_offer}
          getOffers={getOffers}
        />
      ) : (
        ""
      )}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Offers</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              {getPermission() &&
              getPermission().lead_export.lead_export.view.is_checked ===
                "yes" ? (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    exportLead()
                  }}
                >
                  Export
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setmodal_offer(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Data
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      ID
                      {id_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setid_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadID") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort5 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort5(true),
                                setSort2(false),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort6 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(false),
                                setSort5(true),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="first-filter">
                        <InputFilter
                          id_filter={id_filter}
                          setid_filter={setid_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Name
                      {name_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("name,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort2(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("name,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(615%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort(true),
                                setSort2(false),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("name,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort2(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("name,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(615%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort2 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(false),
                                setSort(true),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("name,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(615%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <InputFilter
                        name_filter={name_filter}
                        setname_filter={setname_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Selected Products</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Type
                      {status5_filter ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setstatus5_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("offerType") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setstatus5_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setstatus5_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        status5_filter={status5_filter}
                        setstatus5_filter={setstatus5_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Amount
                      {amount_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setamount_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadAmount") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setamount_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setamount_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        amount_filter={amount_filter}
                        setamount_filter={setamount_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  {getCurrentUser()?.enable_branch == 1 ? <th>Branch</th> : ""}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Start date
                      {date_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setdate_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {getFilterDate() && getFilterDate().from == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <DateFilter
                        date_filter={date_filter}
                        setdate_filter={setdate_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      End date
                      {date2_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setdate2_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {getFilterDate2() && getFilterDate2().from == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setdate2_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setdate2_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <DateFilter
                        date2_filter={date2_filter}
                        setdate2_filter={setdate2_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Remark</th>
                  <th>Action</th>
                </tr>
                {offer &&
                  offer.map((item, obj) => (
                    <OfferList
                      key={obj}
                      data={item}
                      i={obj}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations data={allPages} paginate={paginate} perPage={getOffers} />
      ) : (
        ""
      )}
    </>
  )
}
export default OfferTable
