import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import OfferTable from "../../components/Offer"

const Offers = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Offers | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <OfferTable />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Offers
