import React, { useState, useEffect } from "react"
import Loader from "../../Loader/loader.js"
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import axios from "axios"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
} from "../../../helpers/Utils"
import { useLocation, useHistory } from "react-router-dom"
import Paginations from "../../Pagination"
import TransferList from "./transferList"
import LeadModal from "../leadmodal"
import classnames from "classnames"
import LeadsFilter from "../../LeadFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SweetAlert from "react-bootstrap-sweetalert"

const TransferLeadsTable = () => {
  const history = useHistory()
  const [modal_standard, setmodal_standard] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [modal, setModal] = useState(false)
  const [blur, setBlur] = useState(false)
  const [allLeads, setAllLeads] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [counter, setCounter] = useState("")
  const [total, setTotal] = useState("")
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert2, setconfirm_alert2] = useState(false)
  const [check, setCheck] = useState(0)
  const [lead_filter, setlead_filter] = useState(false)
  const [customActiveTab, setCustomActiveTab] = useState(1)
  const [leadType, setlLeadType] = useState("others")
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()
  let url1 = query.get("id")
  let url2 = query.get("type")
  let url3 = query.get("followup_status_id")

  const getAllLeads = e => {
    setLoader(<Loader />)
    setAllLeads([])
    const branchQuery = branch !== "" ? `&branch_id=${branch}` : ""
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/transfer-requests?type=${e !== undefined ? e : leadType}&lead_id=${
      url1 !== null ? url1 : ""
    }&per_page=${
      localStorage.getItem("PerPage") !== null
        ? localStorage.getItem("PerPage")
        : ""
    }&page=${localStorage.getItem("current_page")}&${branchQuery}`

    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setTotal(res.data.data.total)
          setAllLeads(res.data.data.data)
          setAllPages(res.data.data)
          setBlur(false)
          setError("")
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
    if (tab == 1) {
      setlLeadType("others")
      getAllLeads("others")
    }
    if (tab == 2) {
      setlLeadType("self")
      getAllLeads("self")
    }
  }

  const paginate = e => {
    setAllLeads([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(
        `${e}type=${leadType}
            &id=${
              localStorage.getItem("leadID") !== null
                ? localStorage.getItem("leadID")
                : ""
            }
            &leadholder_id=${
              localStorage.getItem("leadHolder") !== null
                ? localStorage.getItem("leadHolder")
                : ""
            }
            &contact=${
              localStorage.getItem("leadContact") !== null
                ? localStorage.getItem("leadContact")
                : ""
            } 
            &name=${
              localStorage.getItem("leadName") !== null
                ? localStorage.getItem("leadName")
                : ""
            }
            &lead_label_id=${
              localStorage.getItem("leadLabel") !== null
                ? localStorage.getItem("leadLabel")
                : ""
            }
            &tag_id=${
              localStorage.getItem("leadTag") !== null
                ? localStorage.getItem("leadTag")
                : ""
            }
            &lead_source_id=${
              localStorage.getItem("leadSource") !== null
                ? localStorage.getItem("leadSource")
                : ""
            }
            &followup_status_id=${
              localStorage.getItem("followupStatus") !== null
                ? localStorage.getItem("followupStatus")
                : ""
            }
            &mature_status_id=${
              localStorage.getItem("matureStatus") !== null
                ? localStorage.getItem("matureStatus")
                : ""
            }
            &dead_reason_id=${
              localStorage.getItem("deadReason") !== null
                ? localStorage.getItem("deadReason")
                : ""
            }
            &followup_start=${
              getFilterDate() && getFilterDate().from !== null
                ? getFilterDate().from
                : ""
            }
            &followup_to=${
              getFilterDate() && getFilterDate().to !== null
                ? getFilterDate().to
                : ""
            }
            &lastmessage=${
              localStorage.getItem("leadMessage") !== null
                ? localStorage.getItem("leadMessage")
                : ""
            }
            &sort=${
              localStorage.getItem("leadSort") !== null
                ? localStorage.getItem("leadSort")
                : ""
            }
            &firm_name=${
              localStorage.getItem("leadFirmName") !== null
                ? localStorage.getItem("leadFirmName")
                : ""
            }
             &no_updation_since=${
               localStorage.getItem("leadUpdateSince") !== null
                 ? localStorage.getItem("leadUpdateSince")
                 : ""
             }
             &flag=${
               localStorage.getItem("leadFlag") !== null
                 ? localStorage.getItem("leadFlag")
                 : ""
             }&company_pool=${
          localStorage.getItem("CompanyPool") !== null
            ? localStorage.getItem("CompanyPool")
            : ""
        }&custom_filters=${
          localStorage.getItem("leadfields_filter") !== null
            ? localStorage.getItem("leadfields_filter")
            : ""
        }
            &assigned=${
              localStorage.getItem("leadAssigned") !== null
                ? localStorage.getItem("leadAssigned")
                : ""
            }
             &transfer_request=1
             &from_date=${
               localStorage.getItem("leadCreated_at") !== ""
                 ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
                 : ""
             }
         &to_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
             : ""
         }
            &mature_start_date=${
              localStorage.getItem("leadMatured_at") !== ""
                ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
                : ""
            }
         &mature_end_date=${
           localStorage.getItem("leadMatured_at") !== ""
             ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
             : ""
         }
         &assigned_from_date=${
           localStorage.getItem("leadAssigned_at") !== ""
             ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
             : ""
         }
         &assigned_to_date=${
           localStorage.getItem("leadAssigned_at") !== ""
             ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
             : ""
         }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllLeads(res.data.data.data)
          localStorage.setItem("current_page", res.data.data.current_page)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const acceptRequests = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/transfer-requests`,
        {
          ids: leadsSelectedIds,
          status: "accepted",
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllLeads(leadType)
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const deleteRequests = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-transfer-requests`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllLeads(leadType)
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const exportLead = e => {
    let Ids = []
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/lead-export`
    if (e == "All") {
      Ids = []
    } else if (e == "Selected") {
      Ids = leadsSelectedIds
    } else {
      Ids = []
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/lead-export?type=${
        localStorage.getItem("leadStatus") !== null
          ? localStorage.getItem("leadStatus")
          : ""
      }
   &id=${
     localStorage.getItem("leadID") !== null
       ? localStorage.getItem("leadID")
       : ""
   }
   &leadholder_id=${
     localStorage.getItem("leadHolder") !== null
       ? localStorage.getItem("leadHolder")
       : ""
   }
   &contact=${
     localStorage.getItem("leadContact") !== null
       ? localStorage.getItem("leadContact")
       : ""
   } 
   &name=${
     localStorage.getItem("leadName") !== null
       ? localStorage.getItem("leadName")
       : ""
   }
   &lead_label_id=${
     localStorage.getItem("leadLabel") !== null
       ? localStorage.getItem("leadLabel")
       : ""
   }
   &tag_id=${
     localStorage.getItem("leadTag") !== null
       ? localStorage.getItem("leadTag")
       : ""
   }
   &lead_source_id=${
     localStorage.getItem("leadSource") !== null
       ? localStorage.getItem("leadSource")
       : ""
   }
   &followup_start=${
     getFilterDate() && getFilterDate().from !== null
       ? getFilterDate().from
       : ""
   }
   &followup_to=${
     getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
   }
   &lastmessage=${
     localStorage.getItem("leadMessage") !== null
       ? localStorage.getItem("leadMessage")
       : ""
   }
   &sort=${
     localStorage.getItem("leadSort") !== null
       ? localStorage.getItem("leadSort")
       : ""
   }
   &firm_name=${
     localStorage.getItem("leadFirmName") !== null
       ? localStorage.getItem("leadFirmName")
       : ""
   }
   &no_updation_since=${
     localStorage.getItem("leadUpdateSince") !== null
       ? localStorage.getItem("leadUpdateSince")
       : ""
   }
   &flag=${
     localStorage.getItem("leadFlag") !== null
       ? localStorage.getItem("leadFlag")
       : ""
   }&company_pool=${
        localStorage.getItem("CompanyPool") !== null
          ? localStorage.getItem("CompanyPool")
          : ""
      }&custom_filters=${
        localStorage.getItem("leadfields_filter") !== null
          ? localStorage.getItem("leadfields_filter")
          : ""
      }
            &assigned=${
              localStorage.getItem("leadAssigned") !== null
                ? localStorage.getItem("leadAssigned")
                : ""
            }
   &transfer_request=1
   &followup_status_id=${
     localStorage.getItem("followupStatus") !== null
       ? localStorage.getItem("followupStatus")
       : ""
   }
      &mature_status_id=${
        localStorage.getItem("matureStatus") !== null
          ? localStorage.getItem("matureStatus")
          : ""
      }
      &dead_reason_id=${
        localStorage.getItem("deadReason") !== null
          ? localStorage.getItem("deadReason")
          : ""
      }
   &from_date=${
     localStorage.getItem("leadCreated_at") !== ""
       ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
       : ""
   }
         &to_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
             : ""
         }
            &mature_start_date=${
              localStorage.getItem("leadMatured_at") !== ""
                ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
                : ""
            }
         &mature_end_date=${
           localStorage.getItem("leadMatured_at") !== ""
             ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
             : ""
         }
         &assigned_from_date=${
           localStorage.getItem("leadAssigned_at") !== ""
             ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
             : ""
         }
         &assigned_to_date=${
           localStorage.getItem("leadAssigned_at") !== ""
             ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
             : ""
         }`
    }
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${url}`,
        {
          ids: Ids,
          followup_type: "my_leads",
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const clearLocal = () => {
    localStorage.setItem("leadHolder", "")
    localStorage.setItem("leadLabel", "")
    localStorage.setItem("leadStatus", "")
    localStorage.setItem("leadTag", "")
    localStorage.setItem("leadName", "")
    localStorage.setItem("leadContact", "")
    localStorage.setItem("leadSource", "")
    localStorage.setItem("leadAmount", "")
    setFilterDate({
      from: "",
      to: "",
    })
    localStorage.setItem("leadMessage", "")
    localStorage.setItem("followupStatus", "")
    localStorage.setItem("matureStatus", "")
    localStorage.setItem("deadReason", "")
    localStorage.setItem("deadStatus", "")
    localStorage.setItem("latestMessage", "")
    localStorage.setItem("leadRole", "")
    localStorage.setItem("leadEmail", "")
    localStorage.setItem("leadStatus2", "")
    localStorage.setItem("leadDay", "")
    localStorage.setItem("leadStatus3", "")
    localStorage.setItem("leadmaturedby", "")
    localStorage.setItem("ledgerType", "")
    localStorage.setItem("offerType", "")
    localStorage.setItem("PrductGST", "")
    localStorage.setItem("OrderStatus", "")
    localStorage.setItem("OrderPstatus", "")
    localStorage.setItem("leadSort", "")
    localStorage.setItem("leadID", "")
    localStorage.setItem("leadTransfer", "")
    localStorage.setItem("leadFlag", "")
    localStorage.setItem("leadUpdateSince", "")
    localStorage.setItem("leadOrderSince", "")
    localStorage.setItem("leadFirmName", "")
    localStorage.setItem("leadCreated_at", "")
    localStorage.setItem("leadMatured_at", "")
    localStorage.setItem(
      "leadAssigned_at",
      JSON.stringify({
        startDate: "",
        endDate: "",
      })
    )
    localStorage.setItem("current_page", "")
    localStorage.setItem("leadAssigned", "")
    localStorage.setItem("CompanyPool", "")
  }

  useEffect(() => {
    // history.listen(location => {
    //    if (history.location.search !== "") {
    //       getAllLeads(location.search)
    //    }
    // })
    getAllLeads("others")
  }, [])

  return (
    <>
      {modal_standard ? (
        <LeadModal
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          getAllLeads={getAllLeads}
          type={"All"}
        />
      ) : (
        ""
      )}
      {lead_filter ? (
        <LeadsFilter
          lead_filter={lead_filter}
          setlead_filter={setlead_filter}
          getSearchedLeads={getSearchedLeads}
        />
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            acceptRequests()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-muted">You want to accept all requests?</span>
        </SweetAlert>
      ) : null}
      {confirm_alert2 ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert2(false)
            setsuccess_dlg(true)
            deleteRequests()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert2(false)}
        >
          <span className="text-muted">You want to delete all requests?</span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={2}>
            <h6 className="page-title">
              Transferred Leads
              {total !== "" ? <span> ({total})</span> : ""}
            </h6>
          </Col>
          <Col md={10}>
            <h6 className="page-title float-end">
              {blur ? (
                <div className="btn-group me-2 mb-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        {" "}
                        Batch Accept
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert2(true)
                        }}
                      >
                        Batch Delete
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="btn-group me-2 mb-2">
                  <button type="button" className="btn btn-primary" disabled>
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    disabled
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu"></ul>
                </div>
              )}
              {getPermission().lead.lead.create.is_checked === "yes" ? (
                <button
                  className="btn btn-success mb-2"
                  onClick={() => {
                    setmodal_standard(true)
                  }}
                >
                  Add New
                </button>
              ) : (
                ""
              )}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Leads
                <span> ({searchedCount})</span>
                <button
                  className="btn btn-success ms-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchedCount(""), getAllLeads(), clearLocal()
                  }}
                >
                  <i className="fa me-2" aria-hidden="true">
                    &#xf021;
                  </i>
                  Reset Filters
                </button>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 1 })}
                  onClick={() => {
                    toggleCustom(1)
                  }}
                >
                  {" "}
                  {getCurrentUser()?.role_name === "Executive"
                    ? "Incoming Requests"
                    : "Transfer Requests"}
                </NavLink>
              </NavItem>
              {getCurrentUser()?.role_name === "Executive" ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === 2 })}
                    onClick={() => {
                      toggleCustom(2)
                    }}
                  >
                    {" "}
                    Outgoing Requests
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
            </Nav>
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered  table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>

                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Name
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Contact
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Lead Source
                    </div>
                  </th>
                  {getCurrentUser()?.enable_branch == 1 ? <th>Branch</th> : ""}
                  {getCurrentUser() && getCurrentUser().tag == 1 ? (
                    <th>
                      <div
                        className="position-relative"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Tag
                      </div>
                    </th>
                  ) : (
                    ""
                  )}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Label
                    </div>
                  </th>
                  {getCurrentUser().role_name == "Executive" ? (
                    ""
                  ) : (
                    <th>
                      <div
                        className="position-relative"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Leadholder
                      </div>
                    </th>
                  )}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Status
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Followup
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Last Message
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
                {allLeads &&
                  allLeads.map((item, obj) => (
                    <TransferList
                      key={obj}
                      getAllLeads={getAllLeads}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      data={item}
                      i={obj}
                      counter={counter}
                      leadType={leadType}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllLeads}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default TransferLeadsTable
