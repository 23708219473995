import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import axios from "axios"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

// API function for forgot password
const forgotPasswordAPI = data => {
  return axios.post("/api/auth/forgot-password", data)
}

function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(forgotPasswordAPI, {
      email: user.email,
    })
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "Reset link has been sent to your mailbox, check there first"
        )
      )
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
