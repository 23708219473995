import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import RoleStaff from "../../components/Admin/Role"

const Role = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Roles | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <RoleStaff />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Role
