import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedDays = []

export default function GlobalSecurityModal(props) {
  const [loading, setLoading] = useState(false)
  const [selectedDay, setSelectedDay] = useState("")

  const options = [
    { value: "7", label: "Sunday" },
    { value: "1", label: "Monday" },
    { value: "2", label: "Tuesday" },
    { value: "3", label: "Wednesday" },
    { value: "4", label: "Thursday" },
    { value: "5", label: "Friday" },
    { value: "6", label: "Saturday" },
  ]

  const getSelectedDay = e => {
    selectedDays = []
    e?.map(item => selectedDays.push(item.value))
  }

  const submitGlobalSModal = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.globalSecurity !== undefined) {
      let dayId = "1"
      if (value.day === "Sunday") {
        dayId = "7"
      } else if (value.day === "Monday") {
        dayId = "1"
      } else if (value.day === "Tuesday") {
        dayId = "2"
      } else if (value.day === "Wednesday") {
        dayId = "3"
      } else if (value.day === "Thrusday") {
        dayId = "4"
      } else if (value.day === "Friday") {
        dayId = "5"
      } else if (value.day === "Saturday") {
        dayId = "6"
      }
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/global/security/${props.globalSecurity.id}`,
          {
            day: dayId,
            start_time: e.target.start_time.value,
            end_time: e.target.end_time.value,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setmodal_standard(false)
            props.setGlobalSecurity(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/global/security`,
          {
            day: selectedDays,
            start_time: e.target.start_time.value,
            end_time: e.target.end_time.value,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getAllGlobalSecurity()
            selectedDays = []
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            props.setmodal_standard(false)
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_standard(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      <Modal isOpen={props.modal_standard} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitGlobalSModal(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.globalSecurity !== undefined
                ? "Edit Security"
                : "Create Security"}
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal_standard(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {props.globalSecurity !== undefined ? (
              <div className="mb-3">
                <AvField
                  label="Selected Day"
                  name="day"
                  type="text"
                  disabled
                  value={props.globalSecurity?.day}
                />
              </div>
            ) : (
              <div className="form-group mb-3">
                <label>Select Days</label>
                <Select
                  isMulti
                  isClearable="true"
                  name="day"
                  options={options}
                  onChange={e => getSelectedDay(e)}
                  // defaultValue={dValues}
                />
              </div>
            )}
            <div className="d-flex my-2 ">
              <div className="mb-3 pe-2" style={{ width: "100%" }}>
                <AvField
                  label="Start Time"
                  type="time"
                  name="start_time"
                  value={props.globalSecurity?.start_time}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <AvField
                  label="End Time"
                  type="time"
                  name="end_time"
                  value={props.globalSecurity?.end_time}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal_standard(false)}
                  >
                    {props.globalSecurity !== undefined ? "Submit" : "Create"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
