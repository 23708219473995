import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import SubCateTable from "../../components/Categories/Sub-Category"

const SubCategory = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Sub-Categories | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <SubCateTable />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SubCategory
