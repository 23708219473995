import React, { useState } from "react"
import LongText from "../../MessageField"
import "react-toastify/dist/ReactToastify.css"

const CledgerList = props => {
  const [ledger, setLedger] = useState(props.data)

  if (ledger === "") {
    return ""
  } else
    return (
      <>
        <tr key={props.i}>
          <td>{props.counter + props.i}</td>
          <td>{ledger.id}</td>
          <td>{ledger.order_id}</td>
          <td>{ledger.amount}</td>
          <td>{ledger.transaction_date}</td>
          <td>
            <LongText content={ledger.message} />
          </td>
          <td>
            {ledger.type == "paid" ? (
              <span className="badge bg-success">Credit</span>
            ) : (
              ""
            )}
            {ledger.type == "outstanding" ? (
              <span className="badge bg-danger">Debit</span>
            ) : (
              ""
            )}
          </td>
          <td>{ledger.user_name}</td>
        </tr>
      </>
    )
}
export default CledgerList
