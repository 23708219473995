import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  getCurrentUser,
  getPermission,
  sendWhatsAppMessage,
} from "../../../helpers/Utils"
import axios from "axios"
import classnames from "classnames"
import LeadModal from "../../Lead/leadmodal"
import LeadModal2 from "../../Lead/leadmodal2"
import ReminderModal from "../../Reminder"
import CcomplaintTable from "../../Customer-Profile/C-complaints"
import CschemeTable from "../../Customer-Profile/C-scheme"
import CproductTable from "../../Customer-Profile/C-product"
import CledgerTable from "../../Customer-Profile/C-ledger"
import CorderTable from "../../Customer-Profile/C-order"
import CQuotationsTable from "../../Customer-Profile/C-Quotations"
import CproformaTable from "../../Customer-Profile/C-proforma"
import CupdateTable from "../../Customer-Profile/C-update"
import ChistoryTable from "../../Customer-Profile/C-history"
import CprofileTable from "../../Customer-Profile/C-profile"
import CreateFollowup from "../../Create-Followup"
import LongText from "../../MessageField"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { usePopperTooltip } from "react-popper-tooltip"
import "react-popper-tooltip/dist/styles.css"

let selectedRoleId = []

const FutureList = props => {
  const [lead, setLead] = useState(props.data)
  const [showInfo, setShowInfo] = useState(false)
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_standard2, setmodal_standard2] = useState(false)
  const [modal_reminder, setmodal_reminder] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [modal, setModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [customActiveTab, setCustomActiveTab] = useState(2)
  const [followupActiveTab, setFollowupActiveTab] = useState(2)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [error, setError] = useState("")
  const [typeMature, setTypeMature] = useState(
    lead.type.includes("matured") ? true : false
  )
  const [profileData, setProfileData] = useState([])
  const [modal_xl, setmodal_xl] = useState(false)
  const [updateTab, setUpdateTab] = useState(false)
  const [historyTab, setHistoryTab] = useState(false)
  const [productTab, setProductTab] = useState(false)
  const [proformaTab, setProformaTab] = useState(false)
  const [orderTab, setOrderTab] = useState(false)
  const [quotationsTab, setQuotationsTab] = useState(false)
  const [ledgerTab, setLedgerTab] = useState(false)
  const [schemeTab, setSchemeTab] = useState(false)
  const [complaintTab, setComplaintTab] = useState(false)
  const [historyData, setHistoryData] = useState([])

  const getProfileData = e => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setProfileData(res.data.data)
        } else {
          setError(res.data.message)
        }
        setmodal_xl(true)
        setUpdateTab(true)
      })
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
    if (tab == 2) {
      setUpdateTab(true)
    }
    if (tab == 3) {
      setHistoryTab(true)
    }
    if (tab == 4) {
      setProductTab(true)
    }
    if (tab == 5) {
      setProformaTab(true)
    }
    if (tab == 6) {
      setOrderTab(true)
    }
    if (tab == 7) {
      setQuotationsTab(true)
    }
    if (tab == 8) {
      setLedgerTab(true)
    }
    if (tab == 9) {
      setSchemeTab(true)
    }
    if (tab == 10) {
      setComplaintTab(true)
    }
  }

  useEffect(() => {
    selectedRoleId = []
  }, [])

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e)
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id
      })
      selectedRoleId = uniqueID
      props.setLeadsSelectedIds(selectedRoleId)
    } else {
      selectedRoleId = props.leadsSelectedIds
      var array = selectedRoleId
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1)
      }
      props.setLeadsSelectedIds(selectedRoleId)
    }
    if (selectedRoleId.length == 0) {
      props.setBlur(false)
    } else {
      props.setBlur(true)
    }
  }

  const updateReminder = e => {
    setIsOpen2(true)
    setmodal_reminder(true)
  }

  const deleteFuture = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/${e} `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          props.getFutureFollowup()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editFuture = e => {
    setIsOpen(true)
    setmodal_standard(true)
  }

  const editLead2 = e => {
    setIsOpen(true)
    setmodal_standard2(true)
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        setmodal_xl(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: "top",
  })

  if (lead === "") {
    return ""
  } else
    return (
      <>
        {modal_standard ? (
          <LeadModal
            modal_standard={modal_standard}
            setmodal_standard={setmodal_standard}
            lead={lead}
            setLead={setLead}
            setIsOpen={setIsOpen}
          />
        ) : null}
        {modal_standard2 ? (
          <LeadModal2
            modal_standard2={modal_standard2}
            setmodal_standard2={setmodal_standard2}
            lead={lead}
            setLead={setLead}
            setIsOpen={setIsOpen}
          />
        ) : null}
        {isOpen2 ? (
          <ReminderModal
            modal_reminder={modal_reminder}
            setmodal_reminder={setmodal_reminder}
            lead={lead.id}
            setLead={setLead}
            setIsOpen2={setIsOpen2}
          />
        ) : null}
        {modal ? (
          <CreateFollowup
            modal={modal}
            setModal={setModal}
            lead={lead}
            setLead={setLead}
            customActiveTab={followupActiveTab}
            getAllLeads={props.getFutureFollowup}
          />
        ) : null}
        <tr
          key={props.i}
          className={
            lead.type && lead.type.includes("followup")
              ? "table-warning"
              : "" || (lead.type && lead.type.includes("dead"))
              ? "table-danger"
              : "" || (lead.type && lead.type.includes("matured"))
              ? "table-success"
              : "" || (lead.type && lead.type.includes("new"))
              ? "table-info"
              : ""
          }
        >
          <td>
            <input
              onChange={e => getSelectedId(lead.id, e)}
              name="chk"
              value={lead.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>{props.counter + props.i}</td>
          {/* <td>{lead.id}</td> */}
          <td>
            <div ref={setTriggerRef}>
              {getCurrentUser().role_name == "Team Leader" &&
              getCurrentUser().name !== lead.leadholder_name ? (
                ""
              ) : (
                <>
                  {localStorage.getItem("company_name") !== "medallion" ? (
                    <>
                      {visible && (
                        <div
                          ref={setTooltipRef}
                          {...getTooltipProps({
                            className: "tooltip-container",
                          })}
                        >
                          <span>
                            <i className="fa fa-mobile"></i>{" "}
                            {lead.country_code !== null
                              ? lead.country_code
                              : "+91"}
                            {lead.number}
                          </span>
                          <span>
                            {lead.alternate_number !== null ? (
                              <>
                                {" "}
                                <i className="fa fa-mobile"></i>{" "}
                                {lead.alternate_number}
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                          <span>
                            <i className="fa fa-envelope"></i> {lead.email}
                          </span>
                          <span>
                            <i className="fa fa-map-marker"></i> {lead.city}{" "}
                            {lead.district}
                          </span>
                          <span>{lead.state}</span>
                          <span>{lead.country_name}</span>
                          <span>{lead.pin_code}</span>
                          <div
                            {...getArrowProps({
                              className: "tooltip-arrow",
                            })}
                          />
                        </div>
                      )}
                    </>
                  ) : null}
                </>
              )}
              {lead.firm_name == null || lead.firm_name == "" ? (
                ""
              ) : (
                <span className="">({lead && lead.firm_name})</span>
              )}
              <br />
              <span
                style={{ cursor: "pointer" }}
                onClick={e => {
                  getProfileData(lead.id), setCustomActiveTab(1)
                }}
                className="text-info fs-5"
              >
                {lead && lead.name}
              </span>
              <a
                style={{ cursor: "pointer", color: "#08913b" }}
                target="_blank"
                href={sendWhatsAppMessage(lead.number)}
              >
                <i className="fab fa-whatsapp ms-2 fa-lg"></i>
              </a>
              <br />
            </div>
            {getCurrentUser().crm_permission == 0 &&
            getCurrentUser().crmpro_permission == 0 &&
            lead.type.includes("matured") ? (
              ""
            ) : (
              <span
                className="btn badge bg-success"
                onClick={() => {
                  getProfileData(lead.id), setCustomActiveTab(2)
                }}
              >
                Update Status
              </span>
            )}
            <br />
            {getPermission().today_followup.today_followup.edit.is_checked ===
            "yes" ? (
              lead.type && lead.type.includes("matured") ? (
                getCurrentUser().role_name === "Administrator" ? (
                  <>
                    <span
                      className="btn badge bg-primary"
                      onClick={e => editFuture(lead.id)}
                    >
                      Edit Info
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span
                      className="btn badge bg-primary"
                      onClick={e => editLead2(lead.id)}
                    >
                      Edit Info
                    </span>
                    <br />
                  </>
                )
              ) : (
                <>
                  <span
                    className="btn badge bg-primary"
                    onClick={e => editFuture(lead.id)}
                  >
                    Edit Info
                  </span>
                  <br />
                </>
              )
            ) : (
              ""
            )}
            {localStorage.getItem("company_name") === "demo" ? (
              <>
                <span
                  className="btn badge bg-purple"
                  onClick={e => updateReminder(lead.id)}
                >
                  Set Reminder
                </span>
                <br />
              </>
            ) : (
              ""
            )}
            {lead.flag == "1" ? <i className="fa fa-star"></i> : ""} <br />{" "}
            {lead.created_at} <br />
            <span className="fw-bold">ID- </span>
            {lead.id}
          </td>
          {getCurrentUser().role_name == "Administrator" ? (
            <td>
              <i className="fa fa-mobile"></i>{" "}
              {lead.country_code !== null ? <>{lead.country_code}</> : "+91"}{" "}
              {lead.number}
              <br />
              {lead.email == null || lead.email == "" ? (
                ""
              ) : (
                <>
                  <i className="fa fa-envelope"></i> {lead.email}
                </>
              )}
              <br />
              <i className="fa fa-map-marker"></i> {lead.city}
              {"  "}
              {lead.district == null ? (
                " "
              ) : (
                <>
                  {" "}
                  {lead.district}
                  {", "}{" "}
                </>
              )}
              {lead.state} <br />
              {lead.country_name !== null ? (
                <span>
                  {lead.country_name} <br />
                </span>
              ) : (
                ""
              )}
              {lead.pin_code == null || lead.pin_code == "" ? (
                ""
              ) : (
                <>
                  <i className="fa fa-map-pin"></i> {lead.pin_code}
                </>
              )}
            </td>
          ) : (
            <td>
              <i className="fa fa-mobile"></i>{" "}
              {lead.country_code !== null ? <>{lead.country_code}</> : "+91"}{" "}
              {getCurrentUser().role_name == "Team Leader" &&
              getCurrentUser().name !== lead.leadholder_name ? (
                <>**********</>
              ) : (
                <>******{lead.number.substr(-4)}</>
              )}
              <br />
              {lead.email == null || lead.email == "" ? (
                ""
              ) : (
                <>
                  <i className="fa fa-envelope"></i> ***********.gmail.com
                </>
              )}
              <br />
              <i className="fa fa-map-marker"></i> {lead.city}
              {"  "}
              {lead.district == null ? (
                " "
              ) : (
                <>
                  {" "}
                  {lead.district}
                  {", "}{" "}
                </>
              )}
              {lead.state} <br />
              {lead.country_name !== null ? (
                <span>
                  {lead.country_name} <br />
                </span>
              ) : (
                ""
              )}
              {lead.pin_code == null || lead.pin_code == "" ? (
                ""
              ) : (
                <>
                  <i className="fa fa-map-pin"></i> {lead.pin_code}
                </>
              )}
            </td>
          )}
          <td>{lead.lead_source}</td>
          {getCurrentUser()?.enable_branch == 1 ? (
            <td>{lead.branch?.name}</td>
          ) : (
            ""
          )}
          {getCurrentUser() && getCurrentUser().tag == 1 ? (
            <td>{lead.tag_name}</td>
          ) : (
            ""
          )}
          <td>
            {lead.lead_label &&
              lead.lead_label.map((item, i) => (
                <div key={i}>
                  <span
                    className="badge"
                    style={{ backgroundColor: "#f39c12" }}
                  >
                    {item.name}
                  </span>{" "}
                  <br />
                </div>
              ))}
          </td>
          {getCurrentUser().role_name == "Executive" ? (
            ""
          ) : (
            <td>
              <img
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src={lead.leadholder_photo}
              ></img>
              <br />
              {lead.leadholder_name}
              <br />
            </td>
          )}
          <td>
            {lead.type &&
              lead.type.map((item, i) => (
                <div key={i}>
                  <br />
                  {item === "high priority" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "green" }}
                    >
                      High Priority
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "followup" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "orange" }}
                    >
                      Followup
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "new" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "#58a0c3" }}
                    >
                      Untouched
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "matured" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "green" }}
                    >
                      Matured
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "dead" ? (
                    <span className="badge" style={{ backgroundColor: "red" }}>
                      Dead
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "repeated" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "purple" }}
                    >
                      Repeated
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "transfer request" ? (
                    <span className="badge" style={{ backgroundColor: "pink" }}>
                      Transfer Request
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "dead_request" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "purple" }}
                    >
                      Dead Request
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "company pool" ? (
                    <span className="badge" style={{ backgroundColor: "blue" }}>
                      Company Pool
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "matured_dead" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "#D6BD42" }}
                    >
                      Matured Dead
                    </span>
                  ) : (
                    ""
                  )}
                  {item === "matured_dead_request" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "purple" }}
                    >
                      Matured Dead Request
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            <br />
            <span className="badge" style={{ backgroundColor: "#f39c12" }}>
              {lead.dead_status}
            </span>
            <br />
            <span className="badge" style={{ backgroundColor: "#045fcf" }}>
              {lead.followup_status}
            </span>
            <br />
            <span className="badge" style={{ backgroundColor: "green" }}>
              {lead.mature_status}
            </span>
          </td>
          {localStorage.getItem("ShowImportant") ? (
            <td>{lead?.important_date}</td>
          ) : null}
          <td>
            {lead.next_followup_date}
            <br />
            {lead.next_followup_time}
            {getCurrentUser().role_name == "Executive" ? (
              ""
            ) : (
              <>
                <hr />
                {lead.followup_counter === 0 ? (
                  ""
                ) : (
                  <span>{lead.followup_counter} followup</span>
                )}
              </>
            )}
          </td>
          <td>
            <LongText content={lead.last_message} />
            <br />
            {lead.last_followup_date}
            <br />
            {lead.update_by}
            <br />
            {getCurrentUser().crm_permission == 0 &&
            getCurrentUser().crmpro_permission == 0 &&
            lead.type.includes("matured") ? (
              <span
                className="btn badge bg-primary"
                onClick={e => {
                  getProfileData(lead.id),
                    setCustomActiveTab(3),
                    setHistoryTab(true)
                }}
              >
                Lead History
              </span>
            ) : (
              <span
                className="btn badge bg-primary"
                onClick={e => {
                  getProfileData(lead.id),
                    setCustomActiveTab(3),
                    setHistoryTab(true)
                }}
              >
                Lead History
              </span>
            )}
          </td>
          <td>
            {getPermission().future_followup.future_followup.edit.is_checked ===
            "yes" ? (
              lead.type && lead.type.includes("matured") ? (
                getCurrentUser().role_name === "Administrator" ? (
                  <a
                    className="text-warning"
                    onClick={() => {
                      editFuture(lead.id)
                    }}
                  >
                    <i className="fas fa-pen" />
                  </a>
                ) : (
                  <a
                    className="text-warning"
                    onClick={() => {
                      editLead2(lead.id)
                    }}
                  >
                    <i className="fas fa-pen" />
                  </a>
                )
              ) : (
                <a
                  className="text-warning"
                  onClick={() => {
                    editFuture(lead.id)
                  }}
                >
                  <i className="fas fa-pen" />
                </a>
              )
            ) : (
              ""
            )}
            {getPermission().future_followup.future_followup.delete
              .is_checked === "yes" ? (
              <>
                {lead.type && lead.type.includes("matured") ? (
                  ""
                ) : (
                  <a
                    className="text-danger ms-2"
                    onClick={() => {
                      setconfirm_alert(true)
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </a>
                )}
              </>
            ) : (
              ""
            )}
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteFuture(lead.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
        <Modal isOpen={modal_xl} centered={true} size="xl">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {profileData.name}'s Profile
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => setmodal_xl(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {showInfo ? (
              <Row
                className="m-0 p-3"
                style={{
                  background: "#efefef",
                }}
              >
                <Col md={lead.type && lead.type.includes("matured") ? 4 : 8}>
                  <span>
                    {(lead && lead.firm_name == null) ||
                    lead.firm_name == "" ? (
                      ""
                    ) : (
                      <>
                        <span>
                          <span className="fw-bold">Firm name:</span>{" "}
                          {lead && lead.firm_name}
                        </span>
                        <br />
                      </>
                    )}
                  </span>
                  <span>
                    <span className="fw-bold">Email: </span>
                    <a
                      className="text-info"
                      target="_blank"
                      href={`mailto:${lead.email}`}
                    >
                      {getCurrentUser().role_name == "Team Leader" &&
                      getCurrentUser().name !== lead.leadholder_name ? (
                        <>***********.gmail.com</>
                      ) : (
                        <>{lead.email}</>
                      )}
                    </a>
                  </span>{" "}
                  <br />
                  <span>
                    <span className="fw-bold">Phone:</span>{" "}
                    {lead.country_code !== null ? (
                      <>{lead.country_code}</>
                    ) : (
                      "+91"
                    )}{" "}
                    <a
                      className="text-info"
                      href={`tel://+91${lead.number}`}
                      target="_blank"
                    >
                      {getCurrentUser().role_name == "Team Leader" &&
                      getCurrentUser().name !== lead.leadholder_name ? (
                        <>**********</>
                      ) : (
                        <>{lead.number}</>
                      )}
                    </a>
                  </span>{" "}
                  <a
                    style={{ cursor: "pointer", color: "#08913b" }}
                    target="_blank"
                    href={sendWhatsAppMessage(lead.number)}
                  >
                    <i className="fab fa-whatsapp ms-2 fa-lg"></i>
                  </a>{" "}
                  <br />
                  <span>
                    <span className="fw-bold">Alternate Number:</span>{" "}
                    {lead.alternate_number !== null ? (
                      <>
                        {lead.country_code !== null ? (
                          <> +{lead.country_code}</>
                        ) : (
                          "+91"
                        )}{" "}
                        <a
                          className="text-info"
                          href={`tel://+91${lead.alternate_number}`}
                          target="_blank"
                        >
                          {getCurrentUser().role_name == "Team Leader" &&
                          getCurrentUser().name !== lead.leadholder_name ? (
                            <>**********</>
                          ) : (
                            <>{lead.alternate_number}</>
                          )}
                        </a>
                        <a
                          style={{ cursor: "pointer", color: "#08913b" }}
                          target="_blank"
                          href={sendWhatsAppMessage(lead.alternate_number)}
                        >
                          <i className="fab fa-whatsapp ms-2 fa-lg"></i>
                        </a>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </span>{" "}
                  <br />
                  <span>
                    <span className="fw-bold">Location:</span>{" "}
                    {lead.city !== null ? <>{lead.city},</> : ""}{" "}
                    {lead.district !== null ? <>{lead.district},</> : ""}{" "}
                    {lead.state !== null ? <>{lead.state},</> : ""}{" "}
                    {lead.country}
                  </span>{" "}
                  <br />
                  <span>
                    {lead.pin_code !== null ? (
                      <>
                        <span className="fw-bold">PIN:</span> {lead.pin_code}
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </Col>
                <Col md={lead.type && lead.type.includes("matured") ? 5 : 4}>
                  {lead.matured_date !== null ? (
                    <>
                      <span>
                        <span className="fw-bold">Customer Since:</span>{" "}
                        {lead.matured_date}
                      </span>
                      <br />
                      <span className="fw-bold">Products Ordered:</span>
                      <br />
                      <LongText content={lead?.products} />
                    </>
                  ) : (
                    ""
                  )}{" "}
                  <br />
                  <span>
                    <span className="fw-bold">Last Updated:</span>{" "}
                    {lead.updated_at}
                  </span>
                </Col>
                {lead.type && lead.type.includes("matured") ? (
                  <Col md={3}>
                    <span className="fw-bold d-flex justify-content-center">
                      Business Done/Target
                    </span>
                    <div className="p-2" style={{ border: "gray solid 2px" }}>
                      <span>
                        <span className="fw-bold">Monthly: </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: lead.monthly_bussiness_target,
                          }}
                        />
                      </span>{" "}
                      <br />
                      <span>
                        <span className="fw-bold">Last 3 Months: </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: lead.three_months_bussiness,
                          }}
                        />
                      </span>{" "}
                      <br />
                      <span>
                        <span className="fw-bold">Overall: </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: lead.overall_bussiness_target,
                          }}
                        />
                      </span>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            ) : (
              ""
            )}
            <Nav
              tabs
              style={{
                justifyContent: "left",
                background: "#efefef",
              }}
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowInfo(!showInfo)
                  }}
                >
                  {" "}
                  {showInfo ? (
                    <i className="fa fa-arrow-up me-2"></i>
                  ) : (
                    <i className="fa fa-arrow-down me-2"></i>
                  )}
                  Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 1 })}
                  onClick={() => {
                    toggleCustom(1)
                  }}
                >
                  {" "}
                  Profile
                </NavLink>
              </NavItem>
              {getCurrentUser().crm_permission == 0 &&
              getCurrentUser().crmpro_permission == 0 &&
              lead.type.includes("matured") ? (
                <NavLink
                  style={{ pointerEvents: "none", opacity: "0.25" }}
                  className={classnames({ active: customActiveTab === 2 })}
                  onClick={() => {
                    toggleCustom(2)
                  }}
                >
                  {" "}
                  Update Here
                </NavLink>
              ) : (
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 2 })}
                  onClick={() => {
                    toggleCustom(2)
                  }}
                >
                  {" "}
                  Update Here
                </NavLink>
              )}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 3 })}
                  onClick={() => {
                    toggleCustom(3)
                  }}
                >
                  {" "}
                  Lead History
                </NavLink>
              </NavItem>
              {getCurrentUser().crmpro_products_enabled == 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === 4 })}
                    onClick={() => {
                      toggleCustom(4)
                    }}
                  >
                    {" "}
                    Products
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 5 })}
                  onClick={() => {
                    toggleCustom(5)
                  }}
                >
                  {" "}
                  Proforma
                </NavLink>
              </NavItem>
              {lead.type?.includes("matured") ? (
                <>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === 6,
                      })}
                      onClick={() => {
                        toggleCustom(6)
                      }}
                    >
                      {" "}
                      Order History
                    </NavLink>
                  </NavItem>
                  {/* <NavItem> */}
                  {/* 	<NavLink */}
                  {/*                     style={{ cursor: "pointer" }} */}
                  {/*                     className={classnames({ active: customActiveTab === 7})} */}
                  {/*                     onClick={() => { */}
                  {/*                       toggleCustom(7) */}
                  {/*                     }} */}
                  {/*                   > Quotations */}
                  {/*                   </NavLink> */}
                  {/* </NavItem> */}
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === 8,
                      })}
                      onClick={() => {
                        toggleCustom(8)
                      }}
                    >
                      {" "}
                      Ledger/Payments
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === 9,
                      })}
                      onClick={() => {
                        toggleCustom(9)
                      }}
                    >
                      {" "}
                      Scheme
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === 10,
                      })}
                      onClick={() => {
                        toggleCustom(10)
                      }}
                    >
                      {" "}
                      Complaints
                    </NavLink>
                  </NavItem>
                </>
              ) : (
                ""
              )}
              {getCurrentUser().crmpro_permission == 1 ||
              getCurrentUser().crmpronew_permission == 1 ? (
                <>
                  {/* <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({ active: customActiveTab === 7 })}
                                 onClick={() => {
                                    toggleCustom(7)
                                 }}
                              >
                                 {" "}
                                 Quotations
                              </NavLink>
                           </NavItem> */}
                </>
              ) : (
                <>
                  {/* <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 4 })}
                                 onClick={() => {
                                    toggleCustom(4)
                                 }}
                              >
                                 {" "}
                                 Products
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 5 })}
                                 onClick={() => {
                                    toggleCustom(5)
                                 }}
                              >
                                 {" "}
                                 Proforma
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 6 })}
                                 onClick={() => {
                                    toggleCustom(6)
                                 }}
                              >
                                 {" "}
                                 Order History
                              </NavLink>
                           </NavItem> */}
                  {/* <NavItem>
                              <NavLink
                                 style={{ pointerEvents: 'none', opacity: '0.25' }}
                                 className={classnames({ active: customActiveTab === 7 })}
                                 onClick={() => {
                                    toggleCustom(7)
                                 }}
                              > Quotations
                              </NavLink>
                           </NavItem> */}
                  {/* <NavItem>
                              {lead.type.includes("matured") ?
                                 <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: customActiveTab === 8 })}
                                    onClick={() => {
                                       toggleCustom(8)
                                    }}
                                 >
                                    {" "}
                                    Ledger/Payments
                                 </NavLink>
                                 :
                                 <NavLink
                                    style={{ pointerEvents: "none", opacity: "0.25" }}
                                    className={classnames({ active: customActiveTab === 8 })}
                                    onClick={() => {
                                       toggleCustom(8)
                                    }}
                                 >
                                    {" "}
                                    Ledger/Payments
                                 </NavLink>
                              }
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 9 })}
                                 onClick={() => {
                                    toggleCustom(9)
                                 }}
                              >
                                 {" "}
                                 Scheme
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 10 })}
                                 onClick={() => {
                                    toggleCustom(10)
                                 }}
                              >
                                 {" "}
                                 Complaints
                              </NavLink>
                           </NavItem> */}
                </>
              )}
            </Nav>
            <TabContent activeTab={customActiveTab} className="pt-3">
              <TabPane tabId={1}>
                <>
                  <CprofileTable profileData={profileData} leadId={lead.id} />
                </>
              </TabPane>
              <TabPane tabId={2}>
                <>
                  {updateTab ? (
                    <CupdateTable
                      typeMature={typeMature}
                      setmodal_xl={setmodal_xl}
                      getAllLeads={props.getFutureFollowup}
                      lead={lead}
                    />
                  ) : (
                    ""
                  )}
                </>
              </TabPane>
              <TabPane tabId={3}>
                <>
                  {historyTab ? (
                    <ChistoryTable historyData={historyData} leadId={lead.id} />
                  ) : (
                    ""
                  )}
                </>
              </TabPane>
              <TabPane tabId={4}>
                <>{productTab ? <CproductTable leadId={lead.id} /> : ""}</>
              </TabPane>
              <TabPane tabId={5}>
                <>{proformaTab ? <CproformaTable leadId={lead.id} /> : ""}</>
              </TabPane>
              <TabPane tabId={6}>
                <>{orderTab ? <CorderTable leadId={lead.id} /> : ""}</>
              </TabPane>
              <TabPane tabId={7}>
                <>
                  {quotationsTab ? <CQuotationsTable leadId={lead.id} /> : ""}
                </>
              </TabPane>
              <TabPane tabId={8}>
                <>{ledgerTab ? <CledgerTable leadId={lead.id} /> : ""}</>
              </TabPane>
              <TabPane tabId={9}>
                <>{schemeTab ? <CschemeTable leadId={lead.id} /> : ""}</>
              </TabPane>
              <TabPane tabId={10}>
                <>{complaintTab ? <CcomplaintTable leadId={lead.id} /> : ""}</>
              </TabPane>
            </TabContent>
          </div>
        </Modal>
      </>
    )
}
export default FutureList
