import React, { useState, useEffect } from "react"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToggleSwitch from "../../Toggle-Switch"

let isActive

const CcomplaintList = props => {
  const [complaint, setComplaint] = useState(props.data)
  const [complainSwitch, setComplainSwitch] = useState(
    props.data.resolved === "1" ? true : false
  )
  const [modal_standard, setmodal_standard] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [customer, setCustomer] = useState([])

  const deleteComplaint = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/complaint/${e} `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          props.getAllComplaint()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const updateScheme = e => {
    setComplainSwitch(!complainSwitch)
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
  }

  const editComplaint = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/complaint/${complaint.id}?id=${complaint.id}&
						customer_id=${value.customer_id}&
						complaint=${value.complaint}&
						resolved=${isActive}&
						solution=${value.solution}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoading(false)
          setEditModal(false)
          props.getAllComplaint()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          setLoading(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        setEditModal(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  if (complaint === "") {
    return ""
  } else
    return (
      <>
        <tr key={props.i}>
          <td>{props.counter + props.i}</td>
          <td>{complaint.id}</td>
          <td>{complaint.created_by}</td>
          <td>{complaint.complaint}</td>
          <td>
            {complaint.resolved == "0" ? (
              <span className="badge bg-danger">NO</span>
            ) : (
              <span>
                {complaint.solved_by}
                <br />
                {complaint.solution}
              </span>
            )}
          </td>
          <td>
            <a
              className="text-warning"
              onClick={() => {
                setEditModal(true)
              }}
            >
              <i className="fas fa-pen" />
            </a>
            <a
              className="text-danger ms-2"
              onClick={() => {
                setconfirm_alert(true)
              }}
            >
              <i className="fas fa-trash-alt" />
            </a>
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteComplaint(complaint.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
        <Modal isOpen={editModal} centered={true}>
          <AvForm
            className="modal-title mt-0"
            id="myModalLabel"
            onValidSubmit={(e, v) => {
              editComplaint(e, v)
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Edit Complaint
              </h5>
              <button
                type="button"
                className="cross__btn"
                onClick={() => setEditModal(false)}
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <AvField
                  className="select-arrow"
                  label="Customer Name"
                  type="select"
                  name="customer_id"
                  defaultValue={complaint && complaint.customer_id}
                >
                  <option value="" hidden>
                    Select
                  </option>
                  {customer &&
                    customer.map((item, i) => (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    ))}
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  label="Complaint Message"
                  type="textarea"
                  name="complaint"
                  placeholder="Enter message"
                  value={complaint && complaint.complaint}
                />
              </div>
              <div className="mb-3">
                <AvField
                  label="Solution"
                  type="textarea"
                  name="solution"
                  placeholder="solution"
                  value={complaint && complaint.solution}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="newsletter" className="me-3">
                  Resolved
                </label>
                <ToggleSwitch
                  id={complaint.id.toString()}
                  name="resolved"
                  checked={complainSwitch ? true : false}
                  onChange={e => updateScheme(e)}
                  value={complaint.resolved}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal_standard(false)}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </AvForm>
        </Modal>
      </>
    )
}
export default CcomplaintList
