import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import Loader from "../../Loader/loader.js"
import ServiceCart from "../../serviceCart"
import Select from "react-select"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

let productNames = []
let total = 0
let subTotal
let otherGst = 0

export default function ServiceLineOrder(props) {
  const [loader, setLoader] = useState("")
  const [loader2, setLoader2] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [details, setDetails] = useState("")
  const [hsnCode, setHsnCode] = useState("")
  const [gst, setGST] = useState(0)
  const [quan, setQuan] = useState(1)
  const [nRate, setnRate] = useState(0)
  const [serviceValue, setServiceValue] = useState(0)
  const [afterDiscount, setAfterDiscount] = useState("")
  const [productID, setProductID] = useState("")
  const [productName, setProductName] = useState("")
  const [product, setProduct] = useState([])
  const [productDetail, setProductDetail] = useState([])
  const [proDetail, setProDetail] = useState(false)
  const [showCart, setShowCart] = useState(false)
  const [tandC, setTandC] = useState(
    props.order !== undefined ? props.order.term_condition_message : ""
  )
  const [deliverTo, setDeliverTo] = useState(
    props.order !== undefined ? props.order.deliver_to : ""
  )
  const [billTo, setBillTo] = useState(
    props.order !== undefined ? props.order?.lead?.proforma_address : ""
  )
  const [totalGST, setTotalGST] = useState(0)
  const [grandTot, setGrandTot] = useState("")
  const [cart, setCart] = useState({ products: [] })
  const [otherCharges, setOtherCharges] = useState({ fields: [] })
  const [serviceFields, setServiceFields] = useState({ values: [] })

  const getOrderDetails = e => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/order/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCart({
            products: res.data.data.services?.map(item => ({
              product_id: item.service_id,
              name: item.service_name,
              details: item.details,
              hsn_code: item?.hsn_code,
              product_price: item.service_price,
              quantity: item.quantity,
              total: item.total,
              tax: item.tax,
              subtotal: item.subtotal,
              value: item.value,
            })),
          })
          setOtherCharges({
            fields: res.data.data.other_charges?.map(item => ({
              action: item.action,
              name: item.name,
              value: item.value,
              gst: item.gst,
              gstAmount: item.gstAmount ? item.gstAmount : 0,
            })),
          })
          setShowCart(true)
        }
      })
  }

  const getProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/services?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setProduct(res.data.data.data)
        }
      })
  }

  const getTandC = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/term-condition?type=product_line`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setTandC(res.data.data[0]?.term_condition)
        }
      })
  }

  const getDeliverTo = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/${props.order !== undefined ? props.order.lead_id : props.id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDeliverTo(res.data.data.deliver_to)
          setBillTo(res.data.data.proforma_address)
        }
      })
  }

  const options = []

  if (product !== undefined) {
    product.map(item => {
      options.push({
        value: item.id,
        label: item.name,
      })
    })
  }

  const productDetails = e => {
    setProductDetail([])
    setDetails("")
    setHsnCode("")
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/services/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setProDetail(true)
          setProductDetail(res.data.data)
          setProductID(res.data.data.id)
          setProductName(res.data.data.name)
          setServiceFields({
            values: res.data.data.service_fields?.map(item =>
              item.type == "text"
                ? { field_name: item.field_name, value: item.value }
                : { field_name: item.field_name, value: "" }
            ),
          })
          setGST("0")
          setQuan(1)
          setnRate("")
          setServiceValue(0)
        } else {
        }
      })
  }

  const getNetRate = e => {
    setnRate(e)
    let Dprice = e * quan
    setServiceValue(Dprice)
  }

  const getQuan = e => {
    setQuan(e)
    let Dprice = nRate * e
    setServiceValue(Dprice)
  }

  const handleDetails = (e, i) => {
    let values = serviceFields.values
    values[i]["value"] = e.target.value
    setServiceFields({ values })
  }

  const addToCart = () => {
    if (productID == "") {
      setError("Please select a Product before adding to cart.")
    } else {
      setError("")
      let products = cart.products
      let index = products.findIndex(id => id.product_id === productID)
      // if (index !== -1) {
      //    let subtotal = serviceValue
      //    let netRate = nRate
      //    let qn = quan
      //    let tax = gst
      //    let totalValue = subtotal + (subtotal * tax) / 100
      //    setLoader(<Loader />)
      //    products[index] = {
      //       product_id: productID,
      //       name: productName,
      //       product_price: netRate,
      //       quantity: qn,
      //       subtotal: subtotal,
      //       tax: tax,
      //       total: totalValue,
      //       value: serviceFields.values,
      //    }
      //    setTimeout(() => {
      //       setLoader("")
      //       setCart({ products })
      //       updateTotalAfterDisc()
      //    }, 500)
      // } else {
      setShowCart(true)
      let subtotal = serviceValue
      let netRate = nRate
      let proDetails = details
      let hsnNumber = hsnCode
      let qn = quan
      let tax = gst
      let totalValue = subtotal + (subtotal * tax) / 100
      setCart({
        products: [
          ...cart.products,
          {
            product_id: productID,
            name: productName,
            details: proDetails,
            hsn_code: hsnNumber,
            product_price: netRate,
            quantity: qn,
            subtotal: subtotal,
            tax: tax,
            total: totalValue,
            value: serviceFields.values,
          },
        ],
      })
      setTimeout(() => {
        updateTotalAfterDisc()
      }, 500)
      // }
    }
  }

  const handleChange = (i, e) => {
    let products = cart.products
    products[i][e.target.name] = e.target.value
    setCart({ products })
    setTimeout(() => {
      updateTotal()
    }, 500)
  }

  const handleDynamicValues = (i, index, e) => {
    let products = cart.products
    products[i].value[index]["value"] = e.target.value
    setCart({ products })
    setTimeout(() => {
      updateTotal()
    }, 500)
  }

  const updateTotal = () => {
    total = cart.products.reduce(
      (total, currentValue) => (total = total + currentValue.total),
      0
    )
    subTotal = cart.products.reduce(
      (subTotal, currentValue) => (subTotal = subTotal + currentValue.subtotal),
      0
    )
    setTimeout(() => {
      updateTotalAfterDisc()
    }, 500)
  }

  const updateGrandTotal = e => {
    if (e !== "") {
      setTotalGST(e)
      if (afterDiscount !== "") {
        let gt = (parseInt(afterDiscount) + parseInt(e)).toFixed(2)
        setGrandTot(gt)
      } else {
        let gt2 = (parseInt(total) + parseInt(e)).toFixed(2)
        setGrandTot(gt2)
      }
    } else {
      setTotalGST(0)
      if (afterDiscount !== "") {
        let gt = parseInt(afterDiscount).toFixed(2)
        setGrandTot(gt)
      } else {
        let gt2 = parseInt(total).toFixed(2)
        setGrandTot(gt2)
      }
    }
  }

  useEffect(() => {
    if (afterDiscount !== "") {
      updateGrandTotal(totalGST)
    }
  }, [afterDiscount])

  const updateTotalAfterDisc = () => {
    if (otherCharges.fields.length > 0) {
      total = cart.products.reduce(
        (total, currentValue) => (total = total + currentValue.total),
        0
      )
      let totalValue = total
      let addAmount = 0
      let addPercent = 0
      let addPercentValue = 0
      let subtractAmount = 0
      let subtractPercent = 0
      let subPercentValue = 0
      otherCharges.fields?.map(item => {
        if (item.action == "1") {
          if (item.value != "") {
            addAmount = addAmount + parseInt(item.value)
          } else {
            addAmount = addAmount + 0
          }
        } else if (item.action == "2") {
          if (item.value != "") {
            subtractAmount = subtractAmount + parseInt(item.value)
          } else {
            subtractAmount = subtractAmount + 0
          }
        } else if (item.action == "3") {
          if (item.value != "") {
            addPercent = addPercent + parseInt(item.value)
          } else {
            addPercent = addPercent + 0
          }
          addPercentValue = (totalValue * addPercent) / 100
        } else if (item.action == "4") {
          if (item.value != "") {
            subtractPercent = subtractPercent + parseInt(item.value)
          } else {
            subtractPercent = subtractPercent + 0
          }
          subPercentValue = (totalValue * subtractPercent) / 100
        }
      })
      setAfterDiscount(
        (
          totalValue -
          subtractAmount +
          addAmount +
          addPercentValue -
          subPercentValue
        ).toFixed(2)
      )
    } else {
      setAfterDiscount(total)
    }
  }

  useEffect(() => {
    updateTotalAfterDisc()
  }, [otherCharges])

  const removeFormFields = i => {
    let products = cart.products
    setLoader(<Loader />)
    setCart({ products: [] })
    products.splice(i, 1)
    setTimeout(() => {
      setLoader("")
      setCart({ products })
      updateTotalAfterDisc()
    }, 500)
  }

  total = cart.products.reduce(
    (total, currentValue) => (total = total + currentValue.total),
    0
  )

  subTotal = cart.products.reduce(
    (subTotal, currentValue) => (subTotal = subTotal + currentValue.subtotal),
    0
  )

  otherGst = otherCharges.fields.reduce(
    (otherGst, currentValue) =>
      (otherGst = otherGst + parseFloat(currentValue.gstAmount)),
    0
  )

  const addOtherFields = () => {
    setOtherCharges({
      fields: [
        ...otherCharges.fields,
        { name: "", action: "", value: 0, gst: 0, gstAmount: 0 },
      ],
    })
  }

  const removeOtherFields = i => {
    setLoader2(<Loader />)
    let fields // declare fields variable here
    if (otherCharges) {
      fields = otherCharges.fields
      setOtherCharges({ fields: [] })
    }
    fields.splice(i, 1)
    setTimeout(() => {
      setOtherCharges({ fields })
      setLoader2("")
      let total = cart.products.reduce(
        (total, currentValue) => (total = total + currentValue.total),
        0
      )
      let totalValue = total
      let addAmount = 0
      let addPercent = 0
      let addPercentValue = 0
      let subtractAmount = 0
      let subtractPercent = 0
      let subPercentValue = 0
      let gstValue = 0
      otherCharges.fields.map(item => {
        if (item.action === "1") {
          if (item.value !== "") {
            addAmount = addAmount + parseFloat(item.value)
            let amount = parseFloat(item.value)
            let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
            gstValue = gstAmnt - amount
          } else {
            addAmount = addAmount + 0
          }
        } else if (item.action === "2") {
          if (item.value !== "") {
            subtractAmount = subtractAmount + parseFloat(item.value)
            let amount = parseFloat(item.value)
            let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
            gstValue = gstAmnt - amount
          } else {
            subtractAmount = subtractAmount + 0
          }
        } else if (item.action === "3") {
          if (item.value !== "") {
            addPercent = addPercent + parseInt(item.value)
          } else {
            addPercent = addPercent + 0
          }
          addPercentValue = (totalValue * addPercent) / 100
          let amount = (totalValue * parseFloat(item.value)) / 100
          let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
          gstValue = gstAmnt - amount
        } else if (item.action === "4") {
          if (item.value !== "") {
            subtractPercent = subtractPercent + parseInt(item.value)
          } else {
            subtractPercent = subtractPercent + 0
          }
          subPercentValue = (totalValue * subtractPercent) / 100
          let amount = (totalValue * parseFloat(item.value)) / 100
          let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
          gstValue = gstAmnt - amount
        }
      })
      // fields[i]["gstAmount"] = gstValue
      setOtherCharges({ fields })
      setAfterDiscount(
        (
          totalValue -
          subtractAmount +
          addAmount +
          addPercentValue -
          subPercentValue
        ).toFixed(2)
      )
    }, 500)
  }

  const handleOtherFields = (index, e) => {
    let fields = otherCharges.fields
    fields[index][e.target.name] = e.target.value
    setOtherCharges({ fields })
    total = cart.products.reduce(
      (total, currentValue) => (total = total + currentValue.total),
      0
    )
    let totalValue = total
    if (e.target.name == "name") {
    } else {
      let addAmount = 0
      let addPercent = 0
      let addPercentValue = 0
      let subtractAmount = 0
      let subtractPercent = 0
      let subPercentValue = 0
      let gstValue = 0
      otherCharges.fields.map(item => {
        if (item.action === "1") {
          if (item.value !== "") {
            addAmount = addAmount + parseFloat(item.value)
            let amount = parseFloat(item.value)
            let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
            gstValue = gstAmnt - amount
          } else {
            addAmount = addAmount + 0
          }
        } else if (item.action === "2") {
          if (item.value !== "") {
            subtractAmount = subtractAmount + parseFloat(item.value)
            let amount = parseFloat(item.value)
            let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
            gstValue = gstAmnt - amount
          } else {
            subtractAmount = subtractAmount + 0
          }
        } else if (item.action === "3") {
          if (item.value !== "") {
            addPercent = addPercent + parseInt(item.value)
          } else {
            addPercent = addPercent + 0
          }
          addPercentValue = (totalValue * addPercent) / 100
          let amount = (totalValue * parseFloat(item.value)) / 100
          let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
          gstValue = gstAmnt - amount
        } else if (item.action === "4") {
          if (item.value !== "") {
            subtractPercent = subtractPercent + parseInt(item.value)
          } else {
            subtractPercent = subtractPercent + 0
          }
          subPercentValue = (totalValue * subtractPercent) / 100
          let amount = (totalValue * parseFloat(item.value)) / 100
          let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
          gstValue = gstAmnt - amount
        }
      })
      let fields = otherCharges.fields
      fields[index]["gstAmount"] = gstValue
      setOtherCharges({ fields })
      setAfterDiscount(
        (
          totalValue -
          subtractAmount +
          addAmount +
          addPercentValue -
          subPercentValue
        ).toFixed(2)
      )
    }
  }

  const submitProforma = (e, value) => {
    let emailValue = 0
    if (value.send_email) {
      emailValue = 1
    } else {
      emailValue = 0
    }
    let wasup = 0
    if (value.whatsapp) {
      wasup = 1
    } else {
      wasup = 0
    }
    setLoading(true)
    if (cart.products !== "") {
      productNames = cart.products
    }
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.order !== undefined) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/order/${props.order.id}`,
          {
            amount: total,
            amount_after_charges: value.amount_after_charges,
            deliver_to: deliverTo,
            payment_type: "",
            term_condition: tandC,
            bill_to: billTo,
            remarks: value.remarks,
            other_charges: otherCharges.fields,
            paymentamount: value.paymentamount,
            type: "order_service",
            services: productNames,
            total_gst: value.total_gst,
            preferred_transport: value.preferred_transport,
            // grand_total: value.grand_total
            send_email: emailValue,
            whatsapp: wasup,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setOrder(res.data.data)
            props.setservice_order(false)
            if (props.setservice_order2 != undefined) {
              props.setservice_order2(false)
            }
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setTimeout(() => {
              setCart({ products: [] })
              setOtherCharges({ fields: [] })
              setProDetail(false)
              setShowCart(false)
            }, 500)
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/order`,
          {
            lead_id: props.id,
            branch_id: props.branch_id,
            amount: total,
            amount_after_charges: value.amount_after_charges,
            deliver_to: deliverTo,
            payment_type: "",
            term_condition: tandC,
            bill_to: billTo,
            remarks: value.remarks,
            other_charges: otherCharges.fields,
            paymentamount: value.paymentamount,
            type: "order_service",
            services: productNames,
            send_email: emailValue,
            whatsapp: wasup,
            total_gst: value.total_gst,
            preferred_transport: value.preferred_transport,
            // grand_total: value.grand_total
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getAllOrders("order_service")
            props.setservice_order(false)
            if (props.setmodal_order != undefined) {
              props.setmodal_order(false)
            }
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setTimeout(() => {
              setCart({ products: [] })
              setOtherCharges({ fields: [] })
              setProDetail(false)
              setShowCart(false)
            }, 500)
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  useEffect(() => {
    if (props?.order !== undefined) {
      getOrderDetails(props.order.id)
    }
    getProducts()
    if (props.order !== undefined) {
      if (props.order.term_condition_message !== null) {
      } else {
        getTandC()
      }
    } else {
      getTandC()
    }
    if (props.order !== undefined) {
      if (props.order.deliver_to !== null) {
      } else {
        getDeliverTo()
      }
    } else {
      getDeliverTo()
    }
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setservice_order(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      <Modal
        isOpen={props.service_order}
        centered={true}
        style={{
          maxWidth: "1270px",
          padding: "0 10px",
        }}
      >
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitProforma(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props?.order !== undefined ? "Edit Order" : "Create Order"}
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setservice_order(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {/* <div className="mb-3">
                     <label>Type</label>
                     <AvRadioGroup inline name="payment_type" value={props.order?.payment_type} required>
                        <AvRadio label="Cash" value="cash" />
                        <AvRadio label="Self" value="self" />
                        <AvRadio label="Sample" value="sample" />
                        <AvRadio label="Credit" value="credit" />
                     </AvRadioGroup>
                  </div> */}
            <div className="table-responsive mb-3">
              {error ? (
                <span className="text-danger text-align-center">{error}</span>
              ) : (
                ""
              )}
              <div className="lead_table" style={{ minHeight: "150px" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <div style={{ width: "120px" }}>
                          <label>Type</label>
                          <Select
                            id="product"
                            name="product"
                            options={options}
                            onChange={e => productDetails(e.value)}
                          />
                        </div>
                      </td>
                      {productDetail.service_fields?.map((item, i) => (
                        <td key={i}>
                          {item.type == "text" ? (
                            <AvField
                              style={{ width: "120px" }}
                              label={item.field_name}
                              type="text"
                              name={item.field_name}
                              value={item.value}
                              onChange={e => handleDetails(e, i)}
                            />
                          ) : (
                            <AvField
                              style={{ width: "120px" }}
                              className="select-arrow"
                              type="select"
                              label={item.field_name}
                              name={item.field_name}
                              onChange={e => handleDetails(e, i)}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {item.value.split(",").map(data => (
                                <option key={data} value={data}>
                                  {data}
                                </option>
                              ))}
                            </AvField>
                          )}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td>
                        <AvField
                          label="Details"
                          type="textarea"
                          style={{ width: "120px" }}
                          name="details"
                          value={details}
                          onChange={e => setDetails(e.target.value)}
                        />
                      </td>
                      <td>
                        <AvField
                          label="HSN Code"
                          type="textarea"
                          style={{ width: "120px" }}
                          name="hsn_code"
                          value={hsnCode}
                          onChange={e => setHsnCode(e.target.value)}
                        />
                      </td>
                      <td>
                        <AvField
                          type="number"
                          onFocus={e =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault()
                              },
                              { passive: false }
                            )
                          }
                          min="1"
                          style={{ width: "120px" }}
                          label="Quantity"
                          name="quantity"
                          value={quan}
                          onChange={e => getQuan(e.target.value)}
                        />
                      </td>
                      <td>
                        <AvField
                          type="number"
                          onFocus={e =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault()
                              },
                              { passive: false }
                            )
                          }
                          min="0"
                          style={{ width: "120px" }}
                          label="Rate"
                          name="rate"
                          value={nRate}
                          onChange={e => getNetRate(e.target.value)}
                        />
                      </td>
                      <td>
                        <AvField
                          type="number"
                          onFocus={e =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault()
                              },
                              { passive: false }
                            )
                          }
                          style={{ width: "120px" }}
                          label="Sub-Total"
                          name="subtotal"
                          value={serviceValue}
                          disabled
                        />
                      </td>
                      <td>
                        <AvField
                          className="select-arrow"
                          type="select"
                          label="GST"
                          name="gst"
                          style={{ width: "120px" }}
                          onChange={e => setGST(e.target.value)}
                          value={gst}
                        >
                          <option value="0">0%</option>
                          <option value="0.1">0.1%</option>
                          <option value="0.25">0.25%</option>
                          <option value="5">5%</option>
                          <option value="12">12%</option>
                          <option value="18">18%</option>
                          <option value="28">28%</option>
                        </AvField>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn bg-success text-light float-end"
                          onClick={() => addToCart()}
                        >
                          Add to Cart
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <span className="" style={{ fontSize: '24px' }} >Details</span>
                     {proDetail ?
                        <div className="lead_table"
                           style={{ minHeight: "20px" }}>
                           <table style={{ width: "100%" }}>
                              <tbody>
                                 <tr>
                                    <th>Name</th>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                        :
                        <div style={{ height: '50px' }}></div>
                     } <br /> */}
              <span className="" style={{ fontSize: "24px" }}>
                Cart
              </span>
              {showCart ? (
                <div className="lead_table mb-3">
                  {loader !== "" ? (
                    loader
                  ) : (
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <th>Details</th>
                          <th>HSN Code</th>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>Subtotal</th>
                          <th>GST</th>
                          <th>Total</th>
                        </tr>
                        {cart.products &&
                          cart.products.map((item, i) => (
                            <ServiceCart
                              key={i}
                              data={item}
                              i={i}
                              removeFormFields={removeFormFields}
                              productDetail={productDetail}
                              setCart={setCart}
                              cart={cart}
                              updateTotal={updateTotal}
                              handleChange={handleChange}
                              handleDynamicValues={handleDynamicValues}
                            />
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : (
                <div style={{ height: "50px" }}></div>
              )}
            </div>
            <Row>
              <Col md={5}>
                <div className="mb-3">
                  <AvField
                    type="number"
                    onFocus={e =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault()
                        },
                        { passive: false }
                      )
                    }
                    label="Order Value"
                    name="deliver"
                    disabled
                    value={total.toFixed(2)}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <label>Other Charges/discounts</label>
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    onClick={() => addOtherFields()}
                  >
                    +
                  </button>
                </div>
                <div className="">
                  {otherCharges.fields.length == 0 ? (
                    <div className="mb-5"></div>
                  ) : (
                    <>
                      {loader2 != "" ? (
                        loader2
                      ) : (
                        <div className="table-responsive mb-3">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <th>Action</th>
                                <th>Value</th>
                                <th>GST</th>
                              </tr>
                              {otherCharges.fields &&
                                otherCharges.fields.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <AvField
                                        name="name"
                                        type="text"
                                        placeholder="Enter name"
                                        onChange={e =>
                                          handleOtherFields(index, e)
                                        }
                                        value={item.name}
                                      />
                                    </td>
                                    <td>
                                      <AvField
                                        className="select-arrow"
                                        name="action"
                                        type="select"
                                        onChange={e =>
                                          handleOtherFields(index, e)
                                        }
                                        value={item.action}
                                      >
                                        <option value="" hidden>
                                          Select
                                        </option>
                                        <option value="1">Add (+)</option>
                                        <option value="2">Subtract (-)</option>
                                        <option value="3">
                                          Percentage (+)
                                        </option>
                                        <option value="4">
                                          Percentage (-)
                                        </option>
                                      </AvField>
                                    </td>
                                    <td>
                                      <AvField
                                        name="value"
                                        type="number"
                                        onFocus={e =>
                                          e.target.addEventListener(
                                            "wheel",
                                            function (e) {
                                              e.preventDefault()
                                            },
                                            { passive: false }
                                          )
                                        }
                                        min="1"
                                        placeholder="Enter amount"
                                        value={item.value}
                                        onChange={e =>
                                          handleOtherFields(index, e)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <AvField
                                        className="select-arrow"
                                        type="select"
                                        name="gst"
                                        style={{ width: "75px" }}
                                        onChange={e =>
                                          handleOtherFields(index, e)
                                        }
                                        value={item.gst}
                                        // disabled={disGst !== "" ? true : false}
                                      >
                                        <option value="0">0%</option>
                                        <option value="0.25">0.25%</option>
                                        <option value="5">5%</option>
                                        <option value="12">12%</option>
                                        <option value="18">18%</option>
                                        <option value="28">28%</option>
                                      </AvField>
                                    </td>
                                    <td>
                                      <span
                                        className="btn btn-danger"
                                        onClick={() => removeOtherFields(index)}
                                      >
                                        &times;
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label>Deliver To</label>
                  <ReactQuill
                    theme="snow"
                    value={deliverTo}
                    onChange={e => setDeliverTo(e)}
                  />
                </div>
                <div className="mb-3">
                  <label>Bill To</label>
                  <ReactQuill
                    theme="snow"
                    value={billTo}
                    onChange={e => setBillTo(e)}
                  />
                </div>
                <div className="mb-3">
                  <label>Terms & Conditions</label>
                  <ReactQuill
                    theme="snow"
                    value={tandC}
                    onChange={e => setTandC(e)}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    type="textarea"
                    label="Remarks"
                    name="remarks"
                    placeholder="Remarks"
                    value={props.order && props.order.remarks}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <AvField
                    type="number"
                    onFocus={e =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault()
                        },
                        { passive: false }
                      )
                    }
                    label="Grand Value"
                    name="amount_after_charges"
                    value={
                      afterDiscount !== ""
                        ? (parseFloat(afterDiscount) + otherGst).toFixed(2)
                        : (parseFloat(total) + otherGst).toFixed(2)
                    }
                    disabled
                  />
                </div>
                <div className="mb-3 required">
                  <AvField
                    required
                    type="text"
                    label="GST value (only to display on invoice)"
                    name="total_gst"
                    placeholder="Enter GST"
                    // onChange={(e) => updateGrandTotal(e.target.value)}
                    value={(total - subTotal + otherGst).toFixed(2)}
                  />
                  <span className="text-danger fst-italic">
                    It will not be calculated anywhere
                  </span>
                </div>
                <div className="mb-3">
                  <AvField
                    type="text"
                    label="Amount Received"
                    name="paymentamount"
                    placeholder="Amount Received"
                    value={props.order && props.order.paid_amount}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    type="text"
                    label="Prefered Transport"
                    name="preferred_transport"
                    placeholder="Prefered Transport"
                    value={
                      props.order !== undefined
                        ? props.order.preferred_transport
                        : ""
                    }
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    type="checkbox"
                    label="Send Email"
                    name="send_email"
                    style={{
                      background: `${
                        getCurrentUser()?.email_enabled === 1 ? "" : "lightgray"
                      }`,
                    }}
                    disabled={
                      getCurrentUser()?.email_enabled === 1 ? false : true
                    }
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    type="checkbox"
                    label="Send Whatsapp"
                    name="whatsapp"
                    style={{
                      background: `${
                        getCurrentUser()?.whatsapp_enabled === 1
                          ? ""
                          : "lightgray"
                      }`,
                    }}
                    disabled={
                      getCurrentUser()?.whatsapp_enabled === 1 ? false : true
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <div className="text-center">
              {loading ? (
                <button
                  className="btn btn-primary disabled"
                  type="button"
                  disabled
                >
                  Loading
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="submit"
                  // onClick={() => props.setservice_order(false)}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
