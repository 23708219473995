import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { getCurrentUser } from "../../../helpers/Utils"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import CQuotationsModal from "./CQuotationsModal.js"
import CQuotationsList from "./CQoutationList"

const CQuotationsTable = props => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [quotation, setQuotation] = useState([])

  const getQuotations = () => {
    setQuotation([])
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/quotation?customer_id=${props.leadId}&per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setQuotation(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setQuotation([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(`${e}&customer_id=${props.leadId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setQuotation(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  useEffect(() => {
    getQuotations()
  }, [])

  return (
    <>
      {modal_standard ? (
        <CQuotationsModal
          modal_standard={modal_standard}
          id={props.leadId}
          setmodal_standard={setmodal_standard}
          getQuotations={getQuotations}
        />
      ) : (
        ""
      )}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Recent quotations</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              <button
                className="btn btn-primary submit__button"
                onClick={() => {
                  setmodal_standard(true)
                }}
              >
                Send New Quotations
              </button>
            </h6>
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Products</th>
                  <th>Message</th>
                  <th>Action</th>
                </tr>
                {quotation &&
                  quotation.map((item, obj) => (
                    <CQuotationsList
                      key={obj}
                      data={item}
                      i={obj}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getQuotations}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default CQuotationsTable
