import React, { useState } from "react"
import { AvField } from "availity-reactstrap-validation"

const ProductCart = props => {
  const [item, setItem] = useState(props.data)
  const [editable, setEditable] = useState(false)
  const [min, setMin] = useState(props.data.min_price)
  const [stock, setStock] = useState(props.data.stock_left)
  const [quan, setQuan] = useState(props.data.quantity)
  const [rate, setRate] = useState(props.data.product_price)
  const [GST, setGST] = useState(props.data.tax)
  const [total, setTotal] = useState(props.data.subtotal)
  const [subtotal, setSubTotal] = useState(props.data.total)
  const [minErr, setMinErr] = useState(false)
  const [stockErr, setStockErr] = useState(false)

  const getGST = (i, e) => {
    setGST(e.target.value)
    let price = rate
    let Dprice = price * quan
    setTotal(Dprice)
    let subTot = (Dprice + (Dprice * e.target.value) / 100).toFixed(2)
    setSubTotal(subTot)
    let products = props.cart.products
    products[i][e.target.name] = e.target.value
    products[i]["subtotal"] = parseFloat(Dprice)
    products[i]["total"] = parseFloat(subTot)
    props.setCart({ products })
    setTimeout(() => {
      props.updateTotal()
    }, 500)
  }

  const getNetRate = (i, e) => {
    if (parseInt(e.target.value) > parseInt(stock)) {
      setStockErr(true)
    } else {
      setStockErr(false)
      setQuan(e.target.value)
      let price = rate
      let qn = e.target.value
      let Dprice = price * qn
      setTotal(Dprice)
      let subTot = Dprice + (Dprice * item.tax) / 100
      setSubTotal(subTot)
      let products = props.cart.products
      products[i][e.target.name] = e.target.value
      products[i]["rate"] = parseFloat(price)
      products[i]["subtotal"] = parseFloat(Dprice)
      products[i]["total"] = parseFloat(subTot)
      props.setCart({ products })
      setTimeout(() => {
        props.updateTotal()
      }, 500)
    }
  }

  const getNetRate2 = (i, e) => {
    if (parseInt(e.target.value) < min) {
      setMinErr(true)
    } else {
      setMinErr(false)
    }
    setRate(e.target.value)
    let price = e.target.value
    let qn = quan
    let Dprice = price * qn
    setTotal(Dprice)
    let subTot = Dprice + (Dprice * item.tax) / 100
    setSubTotal(subTot)
    let products = props.cart.products
    products[i][e.target.name] = parseFloat(e.target.value)
    products[i]["subtotal"] = parseFloat(Dprice)
    products[i]["total"] = parseFloat(subTot)
    props.setCart({ products })
    setTimeout(() => {
      props.updateTotal()
    }, 500)
  }

  if (item === "") {
    return ""
  } else
    return (
      <>
        <tr key={props.i}>
          <td>
            <span className="">{item.name}</span>
          </td>
          <td>
            <AvField
              type="number"
              // label="Quantity"
              onFocus={e =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault()
                  },
                  { passive: false }
                )
              }
              min="1"
              name="quantity"
              style={{ width: "75px" }}
              value={quan}
              disabled={editable ? false : true}
              onChange={e => getNetRate(props.i, e)}
            />
            {stockErr ? (
              <span className="text-danger">
                Quantity can not be more than stock left.
              </span>
            ) : (
              ""
            )}
          </td>
          <td>
            <AvField
              type="number"
              // label="Rate"
              onFocus={e =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault()
                  },
                  { passive: false }
                )
              }
              min="0"
              name="product_price"
              style={{ width: "85px" }}
              value={rate}
              disabled={editable ? false : true}
              onChange={e => getNetRate2(props.i, e)}
            />
            {minErr ? (
              <span className="text-danger">
                Net Rate cannot be less than Min Price
              </span>
            ) : (
              ""
            )}
          </td>
          <td>
            <AvField
              type="text"
              // label="Subtotal"
              name="total"
              style={{ width: "85px" }}
              value={total}
              disabled
            />
          </td>
          <td>
            <div className="d-flex align-items-center">
              <span>
                (&#8377;{(props.data.total - props.data.subtotal).toFixed(2)})
              </span>
              <AvField
                className="select-arrow"
                // label="GST"
                type="select"
                name="tax"
                style={{ width: "75px" }}
                onChange={e => getGST(props.i, e)}
                value={GST}
                disabled={editable ? false : true}
                //   disabled
              >
                <option value="0">0%</option>
                <option value="0.1">0.1%</option>
                <option value="0.25">0.25%</option>
                <option value="5">5%</option>
                <option value="12">12%</option>
                <option value="18">18%</option>
                <option value="28">28%</option>
              </AvField>
            </div>
          </td>
          <td>
            <AvField
              type="text"
              // label="Total"
              name="subtotal"
              style={{ width: "85px" }}
              disabled
              value={parseFloat(subtotal).toFixed(2)}
            />
          </td>
          <td>
            {editable ? (
              <a
                className="btn btn-sm btn-success"
                onClick={() => {
                  setEditable(false)
                }}
              >
                Save
              </a>
            ) : (
              <a
                className="btn btn-sm btn-info"
                onClick={() => {
                  setEditable(true)
                }}
              >
                Edit
              </a>
            )}
            <a
              className="btn btn-sm btn-danger ms-2"
              onClick={() => props.removeFormFields(props.i)}
            >
              Delete
            </a>
          </td>
        </tr>
      </>
    )
}
export default ProductCart
