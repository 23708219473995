import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import FetchApiKey from "../../components/Admin/Fetch-From-Api"

const FetchAPI = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Fetch From API | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <FetchApiKey />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FetchAPI
