import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Modal,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import axios from "axios"
import { getCurrentUser, setPermission } from "../../helpers/Utils"
import Paginations from "../../components/Pagination"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import FullCalendarCompo from "../../components/Calendar/index"
import { DateRangePicker } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import Loader from "../../components/Loader/loader.js"
import jsPDF from "jspdf"
import "jspdf-autotable"
//i18n
import { withTranslation } from "react-i18next"
import TodayList from "../../components/DailyReport/Today-Report/todayList.js"

let startdate = ""
let enddate = ""

const Dashboard = props => {
  const isInitialRender = useRef(true)
  const [menu, setMenu] = useState(false)
  const [filterModal, setFilterModal] = useState(false)
  const [bdySoon, setBdySoon] = useState(false)
  const [dayIn, setDayIn] = useState(false)
  const [virtualBoard, setVirtualBoard] = useState(false)
  const [staffLeadsTab, setStaffLeadsTab] = useState(false)
  const [attendance, setAttendance] = useState(false)
  const [assigned, setAssigned] = useState(false)
  const [added, setAdded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [allLeads, setAllLeads] = useState([])
  const [followUps, setFollowUps] = useState([])
  const [matureStages, setMatureStages] = useState([])
  const [leadLabels, setLeadLabels] = useState([])
  const [leadTags, setLeadTags] = useState([])
  const [staffLeads, setStaffLeads] = useState([])
  const [allPages, setAllPages] = useState([])
  const [reminders, setReminders] = useState({})
  const [counter, setCounter] = useState("")
  const [error, setError] = useState("")
  const [matureError, setMatureError] = useState("")
  const [labelError, setLabelError] = useState("")
  const [followupError, setFollowupError] = useState("")
  const [tagError, setTagError] = useState("")
  const [whatsappBalance, setWhatsappBalance] = useState("")
  const [birthdayLeads, setbirthdayLeads] = useState([])
  const [usersBirthday, setUsersBirthday] = useState([])
  const [totalstaffLeads, setTotalStaffLeads] = useState([])
  const [databaseSize, setDatabaseSize] = useState([])
  const [loader, setLoader] = useState("")
  const [user, setUser] = useState("")
  const [user2, setUser2] = useState("")
  const [convertedTime, setConvertedTime] = useState("")
  const [customActiveTab, setCustomActiveTab] = useState(1)
  const [todayReport, setTodayReport] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [firstLogin, setFirstLogin] = useState(0)
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ])
  const [counter2, setCounter2] = useState("")
  const today = new Date()
  const fromDate = new Date(today)
  fromDate.setDate(today.getDate() - 30)

  const toggle = () => {
    setMenu(!menu)
  }

  const formatDate = date => {
    let d = new Date(date)
    let month = (d.getMonth() + 1).toString()
    let day = d.getDate().toString()
    let year = d.getFullYear()
    if (month.length < 2) {
      month = "0" + month
    }
    if (day.length < 2) {
      day = "0" + day
    }
    return [year, month, day].join("-")
  }

  const getTodayReport = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/today-report?per_page=25`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter2(res.data.data.from)
          setTodayReport(res.data.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
    if (tab == 2) {
      setVirtualBoard(true)
    }
    if (tab == 3) {
      setStaffLeadsTab(true)
    }
    if (tab == 4) {
      setAttendance(true)
    }
    if (tab == 5) {
      getBirthdaySoon()
    }
    if (tab == 6) {
      getUserBirthday()
    }
    if (tab == 7) {
      getTodayReport()
    }
  }

  const getFilterData = (e, v) => {
    if (customActiveTab == 1) {
      filterData(e, v)
    }
    if (customActiveTab == 3) {
      getStaffLeads(e, v)
      getLeadTags(e, v)
    }
  }

  const getLeadTags = (e, value) => {
    setLoader(<Loader />)
    let url
    if (e !== undefined) {
      setLoading(true)
      startdate = formatDate(dateFilter[0].startDate)
      enddate = formatDate(dateFilter[0].endDate)
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dashboard-tag?from_date=${formatDate(
        dateFilter[0].startDate
      )}&to_date=${formatDate(dateFilter[0].endDate)}&leadholder_id=${
        value.leadholder_id
      }&assigned=${assigned ? "1" : ""}created_by=${
        value.createdBy_id
      }&is_added=${added ? "1" : ""}`
    } else {
      startdate = ""
      enddate = ""
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dashboard-tag`
    }
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        setLoading(false)
        if (res.data.status) {
          setLeadTags(res.data.data)
          setLoader("")
        } else {
          setTagError(res.data.message)
          setLoader("")
        }
      })
  }

  const getStaffLeads = (e, value) => {
    setLoader(<Loader />)
    let url
    if (e !== undefined) {
      setUser(value.leadholder_id)
      setUser2(value.createdBy_id)
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dashboard-user?from_date=${formatDate(
        dateFilter[0].startDate
      )}&to_date=${formatDate(dateFilter[0].endDate)}&leadholder_id=${
        value.leadholder_id
      }&assigned=${assigned ? "1" : ""}&created_by=${
        value.createdBy_id
      }&is_added=${added ? "1" : ""}`
    } else {
      setUser("")
      setUser2("")
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dashboard-user`
    }
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setFilterModal(false)
          setStaffLeads(res.data.data.data)
          setTotalStaffLeads(res.data.data.total)
          setLoader("")
        } else {
        }
        setLoading(false)
      })
  }

  const filterData = (e, value) => {
    setLoader(<Loader />)
    let url
    if (e !== undefined) {
      startdate = formatDate(dateFilter[0].startDate)
      enddate = formatDate(dateFilter[0].endDate)
      setUser(value.leadholder_id)
      setUser2(value.createdBy_id)
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dashboard-data?from_date=${formatDate(
        dateFilter[0].startDate
      )}&to_date=${formatDate(dateFilter[0].endDate)}&leadholder_id=${
        value.leadholder_id
      }&assigned=${assigned ? "1" : ""}&created_by=${
        value.createdBy_id
      } &is_added=${added ? "1" : ""}`
    } else {
      setUser("")
      setUser2("")
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dashboard-data`
      startdate = ""
      enddate = ""
    }
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        setLoader("")
        setFilterModal(false)
        setAllLeads(res.data.data?.dashboard)
        setMatureStages(res.data.data?.mature_status)
        setFollowUps(res.data.data?.followup_statuses)
        setLeadLabels(res.data.data?.labels)
        setLoading(false)
        const convertTimestampToIST = () => {
          const storedTime = new Date(new Date())
          const currentTime = new Date()

          // Calculate the difference in minutes
          const differenceInMilliseconds = currentTime - storedTime
          const differenceInMinutes = Math.floor(
            differenceInMilliseconds / (1000 * 60)
          )
          setConvertedTime(differenceInMinutes)
        }
        convertTimestampToIST()
      })
  }

  const leadDisplay = (e, value) => {
    setLoader(<Loader />)
    let url
    const branchQuery = branch !== "" ? `&branch_id=${branch}` : ""
    if (e !== undefined) {
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dashboard-data?from_date=${formatDate(
        dateFilter[0].startDate
      )}&to_date=${formatDate(dateFilter[0].endDate)}&leadholder_id=${
        value.leadholder_id
      }&created_by=${value.createdBy_id}?&${branchQuery}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dashboard-data?&${branchQuery}`
      startdate = ""
      enddate = ""
    }
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        setLoader("")
        setAllLeads(res.data.data?.dashboard)
        setReminders(res.data.data?.reminders)
        setMatureStages(res.data.data?.mature_status)
        setFollowUps(res.data.data?.followup_statuses)
        setLeadLabels(res.data.data?.labels)
        setPermission(res.data.data?.permissions)
        setDatabaseSize(res.data.data?.db_usage)
        localStorage.setItem("LastUpdated", new Date().getTime())
        localStorage.setItem("DashboardData", JSON.stringify(res.data.data))
        localStorage.setItem(
          "NavCounters",
          JSON.stringify(res.data.data?.all_counters)
        )
        localStorage.setItem(
          "AllSource",
          JSON.stringify(res.data.data?.sources)
        )
        localStorage.setItem(
          "AllFollowups",
          JSON.stringify(res.data.data?.followup_statuses)
        )
        localStorage.setItem(
          "MatureStages",
          JSON.stringify(res.data.data?.mature_status)
        )
        localStorage.setItem("AllLabels", JSON.stringify(res.data.data?.labels))
        localStorage.setItem("AllTags", JSON.stringify(res.data.data?.tag))
        localStorage.setItem("All-User", JSON.stringify(res.data.data?.users))
        localStorage.setItem(
          "AllUsers",
          JSON.stringify(res.data.data?.lead_holders)
        )
        localStorage.setItem(
          "NextFollowup",
          JSON.stringify(res.data.data?.next_followup)
        )
        localStorage.setItem("Noti-Count", res.data.data?.notification_count)
        setLoading(false)
        const convertTimestampToIST = () => {
          const storedTime = new Date(new Date())
          const currentTime = new Date()

          // Calculate the difference in minutes
          const differenceInMilliseconds = currentTime - storedTime
          const differenceInMinutes = Math.floor(
            differenceInMilliseconds / (1000 * 60)
          )
          setConvertedTime(differenceInMinutes)
        }
        convertTimestampToIST()
      })
  }

  const getDatabaseUsage = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/database-usage`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDatabaseSize(res.data.data)
        } else {
          setDatabaseSize({
            total_size: "- MB",
            used_size: "- GB",
          })
        }
      })
  }

  const getUserBirthday = () => {
    setUsersBirthday([])
    setError("")
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user-birthday?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUsersBirthday(res.data.data.data)
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setLoader("")
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const getBirthdaySoon = () => {
    setbirthdayLeads([])
    setError("")
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/birthday-soon?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setbirthdayLeads(res.data.data.data)
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setLoader("")
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const getBirthday = () => {
    setbirthdayLeads([])
    setError("")
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/birthday?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setbirthdayLeads(res.data.data.data)
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setBdySoon(false)
          setLoader("")
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const paginate = e => {
    setbirthdayLeads([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(`${e}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setbirthdayLeads(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const attendancePunch = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/attendance`,
        {
          type: e,
          user_id: getCurrentUser().id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          console.log("res", res)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const getAllUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/get-lead-holders?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("AllUsers", JSON.stringify(res.data.data.data))
        }
        getAllTag()
      })
      .catch(err => {
        getAllTag()
      })
  }

  const getAllSource = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/source?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("AllSource", JSON.stringify(res.data.data.data))
        }
        getAllUser()
      })
      .catch(err => {
        getAllUser()
      })
  }

  const getAllUser = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("All-User", JSON.stringify(res.data.data.data))
        } else {
        }
      })
  }

  const getAllTag = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/tag?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("AllTags", JSON.stringify(res.data.data.data))
        }
        getAllLabel()
      })
      .catch(err => {
        getAllLabel()
      })
  }

  const getAllLabel = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/label?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("AllLabels", JSON.stringify(res.data.data.data))
        }
        getAllSource()
      })
      .catch(err => {
        getAllSource()
      })
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = "Staff Leads"
    const headers = [
      [
        "#",
        "Name",
        "Total",
        "Old",
        "Today",
        "Due",
        "Updated",
        "Updation",
        "New",
        "Assigned",
        "Matured",
        "Dead",
        "Request",
        "Business",
      ],
    ]

    const staticData = [
      [
        "Total",
        "",
        totalstaffLeads.all_total,
        totalstaffLeads.old_total,
        totalstaffLeads.today_total,
        totalstaffLeads.due_total,
        totalstaffLeads.done_total,
        totalstaffLeads.total_updation,
        totalstaffLeads.new_total,
        totalstaffLeads.assigned_total,
        totalstaffLeads.matured_total,
        totalstaffLeads.dead_total,
        totalstaffLeads.dead_request_total,
        totalstaffLeads.business_total,
      ],
    ]

    const data =
      staffLeads && staffLeads.length > 0
        ? staffLeads
            .map((item, i) => [
              i + 1,
              item.name,
              item.total,
              item.old,
              item.today,
              item.due,
              item.done,
              item.updation,
              item.new,
              item.assigned,
              item.matured,
              item.dead,
              item.dead_request,
              item.business,
            ])
            .concat(staticData)
        : "No data Found"

    let content = {
      startY: 50,
      head: headers,
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save("Staffleads.pdf")
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        setFilterModal(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    setStaffLeadsTab(false)
    const storedData = localStorage.getItem("DashboardData")
    const storedTimestamp = localStorage.getItem("LastUpdated")
      ? localStorage.getItem("LastUpdated")
      : 0
    const currentTime = new Date()
    const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60)
    if (storedData !== "" && timeDiff < 30) {
      setAllLeads(JSON.parse(localStorage.getItem("DashboardData")).dashboard)
      setMatureStages(
        JSON.parse(localStorage.getItem("DashboardData")).mature_status
      )
      setFollowUps(
        JSON.parse(localStorage.getItem("DashboardData")).followup_statuses
      )
      setLeadLabels(JSON.parse(localStorage.getItem("DashboardData")).labels)
      setDatabaseSize(
        JSON.parse(localStorage.getItem("DashboardData")).db_usage
      )
      setReminders(JSON.parse(localStorage.getItem("DashboardData")).reminders)
      setConvertedTime(Math.round(timeDiff))
    } else {
      leadDisplay()
    }
  }, [])

  useEffect(() => {
    // This block will run on every render
    if (isInitialRender.current) {
      // Code for the initial render (skipping function calls)
      isInitialRender.current = false
    } else {
      // Code for subsequent renders (where you want to call the functions)
      getLeadTags()
      getStaffLeads()
    }
  }, [staffLeadsTab])

  const showFirstLogin = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/update-first-login`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
          params: {
            is_first_login: 0,
          },
        }
      )
      .then(res => {
        if (res.data.data) {
          setFirstLogin(res.data.data?.is_first_login)
        }
      })
  }

  const handleExploreClick = () => {
    setShowPopup(false)
    showFirstLogin()
    window.location.href = "/host-domain" // Redirects to settings page
  }

  const handleNotInterestedClick = () => {
    setShowPopup(false)
    showFirstLogin()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <div
            className="page-title-box"
            style={{ marginBottom: "0px !important" }}
          >
            {getCurrentUser()?.user_schemes.length > 0 ? (
              <div className="">
                {getCurrentUser()?.user_schemes?.map(item => (
                  <>
                    <span className="text-white fs-5 bg-warning p-2">
                      <span style={{ fontWeight: "500" }}>{item.message}</span>.
                      - Expiring on
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        {item.end_date}
                      </span>
                    </span>
                    <br />
                    <br />
                  </>
                ))}
              </div>
            ) : (
              ""
            )}
            <Row className="align-items-center">
              <Col md={3}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to KlikCRM Dashboard
                  </li>
                </ol>
              </Col>
              <Col md={9}>
                <h6 className="card-title  float-end">
                  {getCurrentUser()?.role_name == "Administrator" ? (
                    <span
                      className="fw-bold btn btn-secondary me-2"
                      style={{ pointerEvents: "none" }}
                    >
                      Database Usage {databaseSize?.used_size}/
                      {databaseSize?.total_size}
                    </span>
                  ) : (
                    <span
                      className="fw-bold btn btn-secondary me-2"
                      style={{ pointerEvents: "none" }}
                    >
                      Monthly Target:{" "}
                      {getCurrentUser()?.monthly_business_target}
                    </span>
                  )}
                  {customActiveTab == "3" ? (
                    <button
                      className="btn btn-primary submit__button me-2"
                      type="button"
                      onClick={() => exportPDF()}
                    >
                      Export
                    </button>
                  ) : (
                    ""
                  )}
                  {customActiveTab == "1" || customActiveTab == "3" ? (
                    <>
                      {customActiveTab == "3" ? (
                        <>
                          {startdate !== "" ? (
                            <span className="me-2 badge bg-secondary text-dark fs-6 fw-bold">
                              From: {startdate} - To: {enddate}
                            </span>
                          ) : (
                            ""
                          )}
                          <button
                            className="btn btn-primary submit__button me-2"
                            type="button"
                            onClick={() => {
                              setFilterModal(true)
                            }}
                          >
                            <i className="fas fa-filter me-2"></i>
                            Filter
                          </button>
                          {startdate !== "" ? (
                            <button
                              className="btn btn-primary submit__button"
                              type="button"
                              onClick={() => window.location.reload()}
                            >
                              <i className="fa me-2" aria-hidden="true">
                                &#xf021;
                              </i>
                              Reset Filters
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <span
                            className="fw-bold btn btn-secondary me-2"
                            style={{ pointerEvents: "none" }}
                          >
                            Last Updated {convertedTime} mins ago
                          </span>
                          <button
                            className="btn btn-primary submit__button"
                            type="button"
                            onClick={() => leadDisplay()}
                          >
                            <i className="fa" aria-hidden="true">
                              &#xf021;
                            </i>
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {/* {customActiveTab == "4" ?
                              <>
                                 <button
                                    className="btn btn-primary submit__button me-2"
                                    type="button"
                                    onClick={() => {
                                       attendancePunch("day_in")
                                    }}
                                 // disabled={dayIn ? true : false}
                                 >
                                    <i className="fas fa-sign-in-alt me-2"></i>
                                    Day-In
                                 </button>
                                 <button
                                    className="btn btn-primary submit__button"
                                    type="button"
                                    onClick={() =>
                                       attendancePunch("day_out")
                                    }
                                 // disabled={dayIn ? false : true}
                                 >
                                    <i className="fas fa-sign-out-alt me-2"></i>
                                    Day-Out
                                 </button>
                              </>
                              : ""} */}

                  {customActiveTab == "5" ? (
                    <>
                      <button
                        className="btn btn-primary submit__button me-2"
                        type="button"
                        onClick={() => getBirthday()}
                      >
                        Birthday
                      </button>
                      {/* <button
                                    className="btn btn-primary submit__button"
                                    type="button"
                                    onClick={() => {
                                       setBdySoon(true), getBirthdaySoon()
                                    }}
                                 >
                                    Birthday Soon
                                 </button> */}
                    </>
                  ) : (
                    ""
                  )}
                </h6>
              </Col>
            </Row>
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === 1 })}
                onClick={() => {
                  toggleCustom(1)
                }}
              >
                {" "}
                Analytics
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === 3 })}
                onClick={() => {
                  toggleCustom(3)
                }}
              >
                {" "}
                Virtual Board
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === 7 })}
                onClick={() => {
                  toggleCustom(7)
                }}
              >
                {""}
                Live Report
              </NavLink>
            </NavItem>
            {/* <NavItem>
                     <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: customActiveTab === 2 })}
                        onClick={() => {
                           toggleCustom(2)
                        }}
                     >
                        {" "}
                        Graph
                     </NavLink>
                  </NavItem> */}
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === 4 })}
                onClick={() => {
                  toggleCustom(4)
                }}
              >
                {" "}
                Attendance
              </NavLink>
            </NavItem>
            {/* <NavItem>
                     <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: customActiveTab === 5 })}
                        onClick={() => {
                           toggleCustom(5)
                        }}
                     > Lead Events
                     </NavLink>
                  </NavItem> */}
            {/* <NavItem>
                     <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: customActiveTab === 6 })}
                        onClick={() => {
                           toggleCustom(6)
                        }}
                     > User Events
                     </NavLink>
                  </NavItem> */}
          </Nav>
          <TabContent activeTab={customActiveTab} className="pt-3">
            <TabPane tabId={1}>
              {loader ? (
                loader
              ) : (
                <>
                  <Row className="mb-4">
                    <Col md={8}>
                      <Card className="h-100">
                        <CardBody>
                          <h2 className="card-title mb-4 border-bottom pb-2">
                            Leads Counter
                            <button
                              className="btn btn-primary submit__button ms-2"
                              type="button"
                              onClick={() => {
                                setFilterModal(true)
                              }}
                            >
                              <i className="fas fa-filter me-2"></i>
                              Filter
                            </button>
                            {startdate !== "" ? (
                              <>
                                <button
                                  className="ms-2 btn btn-primary submit__button"
                                  type="button"
                                  onClick={() => window.location.reload()}
                                >
                                  <i className="fa me-2" aria-hidden="true">
                                    &#xf021;
                                  </i>
                                  Reset Filters
                                </button>
                                <span className="ms-2 badge bg-secondary text-dark fs-6 fw-bold">
                                  From: {startdate} - To: {enddate}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </h2>
                          <Row>
                            <Col xl={3} md={4} sm={6}>
                              <Link
                                to={`/leads?id=&from=${startdate}&to=${enddate}&leadholder_id=${user}&createdBy_id=${user2}&assigned2=${
                                  assigned ? "1" : ""
                                }&isadded=${added ? "1" : ""}`}
                              >
                                <Card className="mini-stat bg-primary text-white">
                                  <CardBody className="text-center">
                                    <h2 className="fw-medium fs-1">
                                      {allLeads && allLeads.all_leads}{" "}
                                    </h2>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                      All Leads
                                    </h5>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            {/* <Col xl={3} md={4} sm={6}>
                                             <Link to={`/leads?type=followup&from=${startdate}&to=${enddate}&leadholder_id=${user}&assigned2=${assigned ? '1' : '0'}`}>
                                                <Card className="mini-stat bg-primary text-white">
                                                   <CardBody className="text-center">
                                                      <h2 className="fw-medium fs-1">
                                                         {allLeads && allLeads.followup}{" "}
                                                      </h2>
                                                      <h5 className="font-size-16 mt-0 text-uppercase text-white-50">
                                                         FollowUps
                                                      </h5>
                                                   </CardBody>
                                                </Card>
                                             </Link>
                                          </Col> */}
                            <Col xl={3} md={4} sm={6}>
                              <Link
                                to={`/leads?type=dead&from=${startdate}&to=${enddate}&leadholder_id=${user}&createdBy_id=${user2}&assigned2=${
                                  assigned ? "1" : ""
                                }&isadded=${added ? "1" : ""}`}
                              >
                                <Card className="mini-stat bg-primary text-white">
                                  <CardBody className="text-center">
                                    <h2 className="fw-medium fs-1">
                                      {allLeads && allLeads.dead}{" "}
                                    </h2>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                      Dead
                                    </h5>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col xl={3} md={4} sm={6}>
                              <Link
                                to={`/leads?type=dead_request&from=${startdate}&to=${enddate}&leadholder_id=${user}&createdBy_id=${user2}&assigned2=${
                                  assigned ? "1" : ""
                                }&isadded=${added ? "1" : ""}`}
                              >
                                <Card className="mini-stat bg-primary text-white">
                                  <CardBody className="text-center">
                                    <h2 className="fw-medium fs-1">
                                      {allLeads && allLeads.dead_request}{" "}
                                    </h2>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                      Dead Request
                                    </h5>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col xl={3} md={4} sm={6}>
                              <Link
                                to={`/leads?type=matured&from=${startdate}&to=${enddate}&leadholder_id=${user}&createdBy_id=${user2}&assigned2=${
                                  assigned ? "1" : ""
                                }&isadded=${added ? "1" : ""}`}
                              >
                                <Card className="mini-stat bg-primary text-white">
                                  <CardBody className="text-center">
                                    <h2 className="fw-medium fs-1">
                                      {allLeads && allLeads.matured}{" "}
                                    </h2>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                      {getCurrentUser()?.crm_permission ==
                                        "1" ||
                                      getCurrentUser()?.crmpro_permission ==
                                        "1" ||
                                      getCurrentUser()?.crmpronew_permission ==
                                        "1"
                                        ? "Customers"
                                        : "Matured Leads"}
                                    </h5>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col xl={3} md={4} sm={6}>
                              <Link
                                to={`/transfer-request?from=${startdate}&to=${enddate}&leadholder_id=${user}&createdBy_id=${user2}&assigned2=${
                                  assigned ? "1" : ""
                                }&isadded=${added ? "1" : ""}`}
                              >
                                <Card className="mini-stat bg-primary text-white">
                                  <CardBody className="text-center">
                                    <h2 className="fw-medium fs-1">
                                      {allLeads && allLeads.transfer_request}{" "}
                                    </h2>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                      Transfer Request
                                    </h5>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col xl={3} md={4} sm={6}>
                              <Link
                                to={`/leads?type=new&from=${startdate}&to=${enddate}&leadholder_id=${user}&createdBy_id=${user2}&assigned2=${
                                  assigned ? "1" : ""
                                }&isadded=${added ? "1" : ""}`}
                              >
                                <Card className="mini-stat bg-primary text-white">
                                  <CardBody className="text-center">
                                    <h2 className="fw-medium fs-1">
                                      {allLeads && allLeads.new}{" "}
                                    </h2>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                      Untouched
                                    </h5>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col xl={3} md={4} sm={6}>
                              <Link
                                to={`/leads?no_updation=1&from_date=${formatDate(
                                  fromDate
                                )}&leadholder_id=${user}&createdBy_id=${user2}&assigned2=${
                                  assigned ? "1" : ""
                                }&isadded=${added ? "1" : ""}`}
                              >
                                <Card className="mini-stat bg-primary text-white">
                                  <CardBody className="text-center">
                                    <h2 className="fw-medium fs-1">
                                      {allLeads && allLeads.no_updations}{" "}
                                    </h2>
                                    <h5 className="font-size-16 mt-0 text-uppercase text-white-50">
                                      No Updation{" "}
                                      <span className="text-normal">
                                        (Since 30 days)
                                      </span>
                                    </h5>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col xl={3} md={4} sm={6}>
                              <Link
                                to={`/customers?no_order_since=${formatDate(
                                  fromDate
                                )}&leadholder_id=${user}&createdBy_id=${user2}&assigned2=${
                                  assigned ? "1" : ""
                                }&isadded=${added ? "1" : ""}`}
                              >
                                <Card className="mini-stat bg-primary text-white">
                                  <CardBody className="text-center">
                                    <h2 className="fw-medium fs-1">
                                      {allLeads && allLeads.no_order_since}{" "}
                                    </h2>
                                    <h5 className="font-size-16 mt-0 text-uppercase text-white-50">
                                      No Order{" "}
                                      <span className="text-normal">
                                        (Since 30 days)
                                      </span>
                                    </h5>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            {loader}
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={4}>
                      <Card className="">
                        <CardBody>
                          <div className="fs-h3">
                            <span className="fw-bold fs-5">
                              Total Business:{" "}
                            </span>
                            <span className="fs-5">
                              {allLeads?.total_revenue}
                            </span>
                            {loader}
                          </div>
                        </CardBody>
                      </Card>
                      <Card className="">
                        <CardBody>
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            {getCurrentUser()?.crm_permission === 1 ||
                            getCurrentUser()?.crmpro_permission === 1 ||
                            getCurrentUser()?.crmpronew_permission === 1 ? (
                              <>
                                <div className="accordion-item">
                                  <h2 className="accordion-header">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#flush-collapseOne"
                                      aria-expanded="false"
                                      aria-controls="flush-collapseOne"
                                    >
                                      Reminders
                                    </button>
                                  </h2>
                                  <div
                                    id="flush-collapseOne"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body">
                                      <h3 className="mx-2">
                                        <Link
                                          to={`/leads?type=matured&mature_status_options=reorder&from=${startdate}&to=${enddate}&leadholder_id=${user}&assigned2=${
                                            assigned ? "1" : ""
                                          }`}
                                          className="pt-2 p-2 badge bg-primary"
                                          // style={{ backgroundColor: "orange" }}
                                        >
                                          Re Order - {reminders?.reorder}
                                        </Link>
                                      </h3>
                                      <h3 className="mx-2">
                                        <Link
                                          to={`/leads?type=matured&mature_status_options=payment_reminder&from=${startdate}&to=${enddate}&leadholder_id=${user}&assigned2=${
                                            assigned ? "1" : ""
                                          }`}
                                          className="pt-2 p-2 badge bg-primary"
                                          // style={{ backgroundColor: "orange" }}
                                        >
                                          Payment Reminder -{" "}
                                          {reminders?.payment_reminder}
                                        </Link>
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwo"
                                >
                                  Followup Stages
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwo"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <h3 className="mx-2">
                                    <Link
                                      to={`/leads?type=followup&from=${startdate}&to=${enddate}&leadholder_id=${user}&assigned2=${
                                        assigned ? "1" : ""
                                      }`}
                                      className="pt-2 p-2 badge bg-primary"
                                      // style={{ backgroundColor: "orange" }}
                                    >
                                      All FollowUps -{" "}
                                      {allLeads && allLeads.followup}
                                    </Link>
                                  </h3>
                                  {followUps &&
                                    followUps.map((item, i) => (
                                      <h3 className="mx-2" key={i}>
                                        <Link
                                          to={`/leads?followup_status_id=${
                                            item.id
                                          }&from=${startdate}&to=${enddate}&leadholder_id=${user}&assigned2=${
                                            assigned ? "1" : ""
                                          }`}
                                          className="pt-2 p-2 badge"
                                          style={{ backgroundColor: "orange" }}
                                        >
                                          {`${item.name} - ${item.count}`}
                                        </Link>
                                      </h3>
                                    ))}
                                  {followupError ? (
                                    <span
                                      className="text-danger mt-3 d-table m-auto"
                                      role="alert"
                                    >
                                      {followupError}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            {getCurrentUser()?.crm_permission === 1 ||
                            getCurrentUser()?.crmpro_permission === 1 ||
                            getCurrentUser()?.crmpronew_permission === 1 ? (
                              <>
                                <div className="accordion-item">
                                  <h2 className="accordion-header">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#flush-collapseThree"
                                      aria-expanded="false"
                                      aria-controls="flush-collapseThree"
                                    >
                                      Mature Stages
                                    </button>
                                  </h2>
                                  <div
                                    id="flush-collapseThree"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body">
                                      <h3 className="mx-2">
                                        <Link
                                          to={`/leads?type=matured&from=${startdate}&to=${enddate}&leadholder_id=${user}&assigned2=${
                                            assigned ? "1" : ""
                                          }`}
                                          className="pt-2 p-2 badge bg-primary"
                                          // style={{ backgroundColor: "orange" }}
                                        >
                                          All Customers -{" "}
                                          {allLeads && allLeads.matured}
                                        </Link>
                                      </h3>
                                      {matureStages &&
                                        matureStages.map((item, i) => (
                                          <h3 className="mx-2" key={i}>
                                            <Link
                                              to={`/leads?mature_status_id=${
                                                item.id
                                              }&from=${startdate}&to=${enddate}&leadholder_id=${user}&assigned2=${
                                                assigned ? "1" : ""
                                              }`}
                                              className="pt-2 p-2 badge"
                                              style={{
                                                backgroundColor: "orange",
                                              }}
                                            >
                                              {item.name} - {item.lead_count}
                                            </Link>
                                          </h3>
                                        ))}
                                      {matureError ? (
                                        <span
                                          className="text-danger mt-3 d-table m-auto"
                                          role="alert"
                                        >
                                          {matureError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFour"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFour"
                                >
                                  Lead Labels
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFour"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  {leadLabels &&
                                    leadLabels.map((item, i) => (
                                      <h3 className="mx-2" key={i}>
                                        <Link
                                          to={`/leads?lead_label_id=${
                                            item.id
                                          }&from=${startdate}&to=${enddate}&leadholder_id=${user}&assigned=${
                                            assigned ? "1" : ""
                                          }`}
                                          className="pt-2 p-2 badge"
                                          style={{ backgroundColor: "orange" }}
                                        >
                                          {item.name} - {item.total}
                                        </Link>
                                      </h3>
                                    ))}
                                  {labelError ? (
                                    <span
                                      className="text-danger mt-3 d-table m-auto"
                                      role="alert"
                                    >
                                      {labelError}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </TabPane>
            <TabPane tabId={2}>
              <Row className="mb-4">
                <Col xl={9}>
                  <Card className="h-100">
                    <CardBody>
                      <h4 className="card-title mb-4">Monthly Leads</h4>
                      <Row>
                        <Col lg={12}>
                          <div>{virtualBoard ? <LineAreaChart /> : ""}</div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={3}>
                  <Card className="h-100">
                    <CardBody>
                      <h4 className="card-title mb-4">Followup Stages</h4>
                      <div className="cleafix">
                        <p className="float-start">Detailed Report</p>
                        <h5 className="font-18 text-end">$4230</h5>
                      </div>
                      <div id="ct-donut" className="ct-chart wid pt-5">
                        {virtualBoard ? <Salesdonut /> : ""}
                      </div>
                      <div className="mt-4">
                        <table className="table mb-0">
                          <tbody>
                            <tr>
                              <td>
                                <span className="badge bg-primary">Desk</span>
                              </td>
                              <td>Desktop</td>
                              <td className="text-end">54.5%</td>
                            </tr>
                            <tr>
                              <td>
                                <span className="badge bg-success">Mob</span>
                              </td>
                              <td>Mobile</td>
                              <td className="text-end">28.0%</td>
                            </tr>
                            <tr>
                              <td>
                                <span className="badge bg-warning">Tab</span>
                              </td>
                              <td>Tablets</td>
                              <td className="text-end">17.5%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={3}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Staff Leads</h4>
                      <div
                        style={{
                          marginTop: "-47px",
                          position: "relative",
                        }}
                        className="table-responsive"
                      >
                        <table className="table table-centered table-nowrap mb-0 dash-table">
                          <thead>
                            <tr>
                              <th colSpan="3" className="bg-transparent"></th>
                              <th
                                colSpan="4"
                                style={{ border: "1px solid" }}
                                className="text-center"
                              >
                                Followups
                              </th>
                              {/* <th colSpan ></th> */}
                            </tr>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Live</th>
                              <th scope="col">Name</th>
                              <th
                                scope="col"
                                style={{
                                  border: "1px solid",
                                  borderRight: "none",
                                }}
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                style={{
                                  border: "1px solid",
                                  borderLeft: "none",
                                  borderRight: "none",
                                }}
                              >
                                Old
                              </th>
                              <th
                                scope="col"
                                style={{
                                  border: "1px solid",
                                  borderLeft: "none",
                                  borderRight: "none",
                                }}
                              >
                                Today
                              </th>
                              <th
                                scope="col"
                                style={{
                                  border: "1px solid",
                                  borderLeft: "none",
                                }}
                              >
                                Due
                              </th>
                              <th scope="col">Updated</th>
                              <th scope="col">Updation</th>
                              <th scope="col">New</th>
                              <th scope="col">Assigned</th>
                              <th scope="col">Matured</th>
                              <th scope="col">Dead</th>
                              <th scope="col">Request</th>
                              <th scope="col">Business</th>
                            </tr>
                          </thead>
                          <tbody>
                            {staffLeads &&
                              staffLeads.map((item, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>
                                    {item.live === "yes" ||
                                    getCurrentUser()?.id == item.id ? (
                                      <span
                                        className="text-success"
                                        style={{
                                          fontSize: "40px",
                                          padding: "0px 0px 0px 6px",
                                        }}
                                      >
                                        •
                                      </span>
                                    ) : (
                                      <span
                                        className="text-danger"
                                        style={{
                                          fontSize: "40px",
                                          padding: "0px 0px 0px 6px",
                                        }}
                                      >
                                        •
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      className="text-info"
                                      to={`/leads?leadholder_id=${item.id}`}
                                    >
                                      {item.name}
                                    </Link>{" "}
                                    <br />
                                    {item.role !== null ? (
                                      <>
                                        <span className="text-muted">
                                          ({item.role})
                                        </span>{" "}
                                        <br />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <span className="text-muted">
                                      {item.last_seen_at}
                                    </span>
                                  </td>
                                  <td style={{ background: "silver" }}>
                                    {item.total}
                                  </td>
                                  <td style={{ background: "white" }}>
                                    {item.old}
                                  </td>
                                  <td style={{ background: "silver" }}>
                                    {item.today}
                                  </td>
                                  <td style={{ background: "white" }}>
                                    {item.due}
                                  </td>
                                  <td style={{ background: "silver" }}>
                                    {item.done}
                                  </td>
                                  <td style={{ background: "white" }}>
                                    {item.updation}
                                  </td>
                                  <td style={{ background: "cyan" }}>
                                    <Link
                                      to={`/leads?lead_type=new&leadholder_id=${
                                        item.id
                                      }&from=${formatDate(
                                        dateFilter[0].startDate
                                      )}&to=${formatDate(
                                        dateFilter[0].endDate
                                      )}`}
                                    >
                                      {item.new}
                                    </Link>
                                  </td>
                                  <td
                                    style={{
                                      background: "#ff9c00",
                                      color: "white",
                                    }}
                                  >
                                    <Link
                                      to={`/leads?assigned=1&leadholder_id=${
                                        item.id
                                      }&assigned_from=${formatDate(
                                        dateFilter[0].startDate
                                      )}&assigned_to=${formatDate(
                                        dateFilter[0].endDate
                                      )}`}
                                    >
                                      {item.assigned}
                                    </Link>
                                  </td>
                                  <td
                                    style={{
                                      background: "green",
                                      color: "white",
                                    }}
                                  >
                                    <Link
                                      to={`/leads?type=matured&leadholder_id=${
                                        item.id
                                      }&from=${formatDate(
                                        dateFilter[0].startDate
                                      )}&to=${formatDate(
                                        dateFilter[0].endDate
                                      )}`}
                                      className="text-light"
                                    >
                                      {item.matured}
                                    </Link>
                                  </td>
                                  <td
                                    style={{
                                      background: "#f14747",
                                      color: "white",
                                    }}
                                    className="text-light"
                                  >
                                    {/* {item.role == "Executive" ? "-" :
                                                      <> */}
                                    <Link
                                      to={`/leads?type=dead&leadholder_id=${
                                        item.id
                                      }&from=${formatDate(
                                        dateFilter[0].startDate
                                      )}&to=${formatDate(
                                        dateFilter[0].endDate
                                      )}`}
                                      className="text-light"
                                    >
                                      {item.dead}
                                    </Link>
                                    {/* </>
                                                   } */}
                                  </td>
                                  <td
                                    style={{
                                      background: "purple",
                                      color: "white",
                                    }}
                                  >
                                    {/* {item.role != "Executive" ? "-" :
                                                      <> */}
                                    <Link
                                      to={`/leads?type=dead_request&leadholder_id=${
                                        item.id
                                      }&from=${formatDate(
                                        dateFilter[0].startDate
                                      )}&to=${formatDate(
                                        dateFilter[0].endDate
                                      )}`}
                                      className="text-light"
                                    >
                                      {item.dead_request}
                                    </Link>
                                    {/* </>
                                                   } */}
                                  </td>
                                  <td
                                    style={{
                                      background: "gray",
                                      color: "white",
                                    }}
                                  >
                                    &#8377; {item.business}
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td className="fw-bolder">Total</td>
                              <td>{totalstaffLeads.live}</td>
                              <td>{totalstaffLeads.name}</td>
                              <td>{totalstaffLeads.all_total}</td>
                              <td>{totalstaffLeads.old_total}</td>
                              <td>{totalstaffLeads.today_total}</td>
                              <td>{totalstaffLeads.due_total}</td>
                              <td>{totalstaffLeads.done_total}</td>
                              <td>{totalstaffLeads.total_updation}</td>
                              <td>{totalstaffLeads.new_total}</td>
                              <td>{totalstaffLeads.assigned_total}</td>
                              <td>{totalstaffLeads.matured_total}</td>
                              <td>{totalstaffLeads.dead_total}</td>
                              <td>{totalstaffLeads.dead_request_total}</td>
                              <td>&#8377; {totalstaffLeads.business_total}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {loader}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {getCurrentUser() && getCurrentUser().tag == 1 ? (
                <Row>
                  <Col xl={12}>
                    <Card>
                      <CardBody>
                        <h4 className="card-title mb-4">Lead Tags</h4>
                        <div className="table-responsive">
                          <table className="table table-centered table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>TAG Name</th>
                                <th>Total</th>
                                <th>Matured</th>
                                <th>Matured Amount</th>
                                <th>New</th>
                                <th>Followup</th>
                                <th>Dead</th>
                                <th>Dead Request</th>
                              </tr>
                            </thead>
                            <tbody>
                              {leadTags &&
                                leadTags.map((item, i) => (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td style={{ background: "silver" }}>
                                      {item.total}
                                    </td>
                                    <td
                                      style={{
                                        background: "green",
                                        color: "white",
                                      }}
                                    >
                                      {item.matured}
                                    </td>
                                    <td style={{ background: "white" }}>
                                      &#8377; {item.matured_amount}
                                    </td>
                                    <td style={{ background: "cyan" }}>
                                      {item.new}
                                    </td>
                                    <td
                                      style={{
                                        background: "#ff9c00",
                                        color: "white",
                                      }}
                                    >
                                      {item.followup}
                                    </td>
                                    <td
                                      style={{
                                        background: "#f14747",
                                        color: "white",
                                      }}
                                    >
                                      {item.dead}
                                    </td>
                                    <td
                                      style={{
                                        background: "purple",
                                        color: "white",
                                      }}
                                    >
                                      {item.dead_request}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {tagError ? (
                            <span
                              className="text-danger mt-3 d-table m-auto"
                              role="alert"
                            >
                              {tagError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        {loader}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </TabPane>
            <TabPane tabId={4}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      {attendance ? <FullCalendarCompo /> : ""}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={5}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Birthday</h4>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead>
                            <tr className="static-header">
                              <th>#</th>
                              <th>Name</th>
                              <th>Contact</th>
                              <th>DOB</th>
                              <th>Anniversary</th>
                            </tr>
                          </thead>
                          <tbody>
                            {birthdayLeads &&
                              birthdayLeads.map((item, i) => (
                                <tr key={i}>
                                  <td>{counter + i}</td>
                                  <td>
                                    <Link
                                      className="text-info"
                                      to={`/leads?id=${item.id}`}
                                    >
                                      {item.name}
                                    </Link>
                                  </td>
                                  <td>{item.number}</td>
                                  <td>{item.dob}</td>
                                  <td>{item.anniversary}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {error ? (
                          <span
                            className="text-danger mt-3 d-table m-auto"
                            role="alert"
                          >
                            {error}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {loader}
                    </CardBody>
                  </Card>
                  {allPages ? (
                    <Paginations
                      data={allPages}
                      paginate={paginate}
                      perPage={bdySoon ? getBirthdaySoon : getBirthday}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={6}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Birthday</h4>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead>
                            <tr className="static-header">
                              <th>#</th>
                              <th>Name</th>
                              <th>Contact</th>
                              <th>DOB</th>
                              <th>Anniversary</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usersBirthday &&
                              usersBirthday.map((item, i) => (
                                <tr key={i}>
                                  <td>{counter + i}</td>
                                  <td>{item.name}</td>
                                  <td>{item.phone}</td>
                                  <td>{item.dob}</td>
                                  <td>{item.anniversary}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {error ? (
                          <span
                            className="text-danger mt-3 d-table m-auto"
                            role="alert"
                          >
                            {error}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {loader}
                    </CardBody>
                  </Card>
                  {allPages ? (
                    <Paginations
                      data={allPages}
                      paginate={paginate}
                      perPage={getUserBirthday}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={7}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Today's Reports</h4>
                      <div className="lead_table table table-sm table-bordered table-responsive">
                        <table
                          className="table table-centered table-nowrap mb-0 dash-table"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Customer</th>
                              <th scope="col">Message Updated</th>
                              <th scope="col">Previous Message</th>
                              <th scope="col">Updated By</th>
                              <th scope="col">Leadholder</th>
                              <th scope="col">Label</th>
                              <th scope="col">Lead Source</th>
                              <th scope="col">Action</th>
                              <th scope="col">Lead Status</th>
                              <th scope="col">Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {todayReport &&
                              todayReport.map((item, obj) => (
                                <TodayList
                                  check={false}
                                  key={obj}
                                  data={item}
                                  i={obj}
                                  counter={counter2}
                                  getTodayReport={getTodayReport}
                                />
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {loader}
                      <div className="d-flex justify-content-center">
                        <Link
                          to={`today-report`}
                          className="btn btn-primary text-center"
                        >
                          More Reports
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <Modal
            isOpen={filterModal}
            centered={true}
            // size="xl"
            style={{ maxWidth: "600px" }}
          >
            <AvForm
              className="modal-title mt-0"
              id="myModalLabel"
              onValidSubmit={(e, v) => getFilterData(e, v)}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  Filter
                </h5>
                <button
                  type="button"
                  className="cross__btn"
                  onClick={() => setFilterModal(false)}
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3 col-8">
                    <AvField
                      className="select-arrow"
                      label="Lead Holder"
                      type="select"
                      name="leadholder_id"
                    >
                      <option value="" hidden>
                        Select Leadholder
                      </option>
                      {JSON.parse(localStorage.getItem("AllUsers")) &&
                        JSON.parse(localStorage.getItem("AllUsers")).map(
                          (option, i) => (
                            <option value={option.id} key={i}>
                              {option.name}
                            </option>
                          )
                        )}
                    </AvField>
                  </div>
                  <div className="mb-3 col-8">
                    <AvField
                      className="select-arrow"
                      label="Created By"
                      type="select"
                      name="createdBy_id"
                    >
                      <option value="" hidden>
                        Created By
                      </option>
                      {JSON.parse(localStorage.getItem("AllUsers")) &&
                        JSON.parse(localStorage.getItem("AllUsers")).map(
                          (option, i) => (
                            <option value={option.id} key={i}>
                              {option.name}
                            </option>
                          )
                        )}
                    </AvField>
                  </div>
                  <div className="mb-3 col-4">
                    <AvField
                      className=""
                      label="Assigned"
                      type="checkbox"
                      name="assigned"
                      checked={assigned}
                      onChange={() => setAssigned(!assigned)}
                    />
                    <AvField
                      className=""
                      label="Added"
                      type="checkbox"
                      name="added"
                      checked={added}
                      onChange={() => setAdded(!added)}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <DateRangePicker
                    onChange={item => setDateFilter([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    RangeFormatObject="skeleton"
                    ranges={dateFilter}
                    direction="horizontal"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <div className="text-center d-flex">
                  {loading ? (
                    <button
                      className="btn btn-primary disabled me-2"
                      type="button"
                      disabled
                    >
                      Loading
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      type="submit"
                      // onClick={() => props.setmodal_standard(false)}
                    >
                      Filter
                    </button>
                  )}
                </div>
              </div>
            </AvForm>
          </Modal>
        </Container>
      </div>
      {getCurrentUser()?.role_name === "Administrator" ||
      getCurrentUser()?.role_name === "Sub Administrator" ? (
        <Modal isOpen={showPopup} toggle={() => setShowPopup(false)}>
          <div className="modal-header">
            <h5 className="modal-title"></h5>
            <button
              type="button"
              className="close"
              onClick={handleNotInterestedClick}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Now host this CRM on your domain <strong>FOR FREE</strong>!!!!
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn bg-success text-white"
              onClick={handleExploreClick}
            >
              Explore
            </button>
            <button
              type="submit"
              className="btn bg-danger text-white"
              onClick={handleNotInterestedClick}
            >
              Not Interested
            </button>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
