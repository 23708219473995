import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import CustomerTable from "../../components/Customers"

const CusLeads = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customers | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <CustomerTable />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CusLeads
