import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { getCurrentUser } from "../../../helpers/Utils"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import CproductList from "./CproductList"
import "react-toastify/dist/ReactToastify.css"

const CproductTable = props => {
  const [product, setProduct] = useState([])
  const [error, setError] = useState("")
  const [searched, setSearched] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [columns, setColumns] = useState([])

  const getAllProduct = e => {
    setProduct([])
    setColumns([])
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/product?name=${searched}&customer_id=${props.leadId}&per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.values.from)
          setColumns(res.data.data.keys)
          setProduct(res.data.data.values.data)
          setAllPages(res.data.data.values)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setProduct([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(`${e}&customer_id=${props.leadId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setError(res.data.message)
          setCounter(res.data.data.values.from)
          setColumns(res.data.data.keys)
          setProduct(res.data.data.values.data)
          setAllPages(res.data.data.values)
          setLoader("")
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  useEffect(() => {
    getAllProduct()
  }, [])

  return (
    <>
      <div className="page-title-box">
        <Row className="align-items-end">
          <Col md={12}>
            <div>
              <input
                className="me-2 ps-2"
                style={{ width: "calc(100% - 100px)" }}
                type="search"
                name="name"
                onChange={e => setSearched(e.target.value)}
                placeholder="Search Product"
              />
              <button
                className="btn btn-primary submit__button"
                type="button"
                onClick={() => {
                  getAllProduct()
                }}
              >
                Search
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div
            className="lead_table table-responsive"
            style={{ overflowX: "scroll" }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>#</th>
                  {/* <th>Total Orders</th>
                  <th>Last Order</th> */}
                  {columns?.map((item, i) => (
                    <th key={i}>{item}</th>
                  ))}
                  <th>Total Orders</th>
                  <th>Stock Left</th>
                  <th>Last Order</th>
                  <th>Last Rate</th>
                  <th>Offer Price</th>
                  <th>Total Quantity</th>
                  <th>DP</th>
                </tr>
                {product &&
                  product.map((item, obj) => (
                    <CproductList
                      key={obj}
                      data={item}
                      i={obj}
                      counter={counter}
                      getAllProduct={getAllProduct}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllProduct}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default CproductTable
