import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import CampaignTable from "../../components/Admin/Campaign-table"

const Campaigns = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Campaigns | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <CampaignTable />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Campaigns
