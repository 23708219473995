import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import DeletedLeadsTable from "../../../components/Lead/deletedLeads/index.jsx"

const DeletedLeads = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Deleted Leads | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <DeletedLeadsTable />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default DeletedLeads
