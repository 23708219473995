import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import KlikDrivePage from "../../components/Admin/KlikDrive"

const KlikDrive = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>KlikDrive | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <KlikDrivePage />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default KlikDrive
