import React, { useEffect, useState } from "react"
import FullCalendar from "@fullcalendar/react" // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"
import { Row, Col } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import axios from "axios"
import moment from "moment"
import { Tooltip } from "bootstrap"
import Loader from "../Loader/loader"

let tooltipInstance = null
let userID = getCurrentUser() && getCurrentUser().id

function FullCalendarCompo() {
  const [data, setData] = useState([])
  // const [userID, setUserID] = useState('')
  const [allUsers, setAllUsers] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)

  const fetchCalendarData = e => {
    setIsDisabled(true)
    let startDay = ""
    let endDay = ""
    if (e !== undefined) {
      startDay = moment(e.start).format("YYYY-MM-DD")
      endDay = moment(e.end).format("YYYY-MM-DD")
    } else {
      startDay = moment().clone().startOf("month").format("YYYY-MM-DD")
      endDay = moment().format("YYYY-MM-DD")
    }
    setData("")
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/dashboard-report?start_date=${startDay}&end_date=${endDay}&user_id=${
          userID == undefined ? "" : userID
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setIsDisabled(false)
          setData(res.data.data)
        } else {
        }
      })
  }

  const getAllUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/get-lead-holders?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllUsers(res.data.data.data)
        } else {
        }
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  // actions
  const handleMouseEnter = info => {
    if (info.event.extendedProps.description) {
      tooltipInstance = new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body",
      })

      tooltipInstance.show()
    }
  }

  const getID = e => {
    userID = e
    fetchCalendarData()
  }
  const handleMouseLeave = () => {
    if (tooltipInstance) {
      tooltipInstance.dispose()
      tooltipInstance = null
    }
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  return (
    <div className="position-relative">
      <Row>
        <h6 className="card-title">Attendance Report</h6>
        {getCurrentUser().role_name == "Executive" ? (
          ""
        ) : (
          <Col md={4}>
            <div
              className="lead_table"
              style={{
                height: "534px",
                overflow: "auto",
                marginTop: "38px",
                cursor: "pointer",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers &&
                    allUsers.map((item, i) =>
                      item.role_name != "Administrator" ? (
                        <tr
                          onClick={e => getID(item.id)}
                          key={i}
                          className={
                            userID === item.id
                              ? "emp-dashboard"
                              : "hov-tab tab colr"
                          }
                        >
                          <td className="tab">{item.name}</td>
                          <td className="tab">{item.role_name}</td>
                        </tr>
                      ) : null
                    )}
                </tbody>
              </table>
            </div>
          </Col>
        )}
        <Col md={getCurrentUser().role_name == "Executive" ? 12 : 8}>
          <div className="d-flex justify-content-center">
            <div className="d-flex">
              <div className="login"></div>
              <span className="ms-2">First Login</span>
            </div>
            <div className="d-flex ms-3">
              <div className="logout"></div>
              <span className="ms-2">Last Logout</span>
            </div>
          </div>
          <div className={`container py-5 ${isDisabled ? "fullcalendar" : ""}`}>
            {isDisabled ? (
              <div className="fullcalendar_loader">
                <Loader />
              </div>
            ) : (
              ""
            )}
            <FullCalendar
              plugins={[dayGridPlugin]}
              headerToolbar={{
                left: "prev",
                center: "title",
                right: "next",
              }}
              initialView="dayGridMonth"
              events={data}
              datesSet={e => fetchCalendarData(e)}
              eventOrder="duration"
              eventMouseEnter={handleMouseEnter}
              eventMouseLeave={handleMouseLeave}
              contentHeight="auto"
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default FullCalendarCompo
