import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import axios from "axios"

// Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

// API function for registration
const registerAPI = data => {
  return axios.post("/api/auth/register", data)
}

function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(registerAPI, user)
    yield put(registerUserSuccessful(response.data))
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
