import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import DatePicker from "react-datepicker"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"

export default function CledgerModal(props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [show, setShow] = useState(false)
  const [ledger, setLedger] = useState("")
  const [order, setOrder] = useState([])
  const [customer, setCustomer] = useState([])
  const [transactionDate, setTransactionDate] = useState(new Date())
  const [branches, setBranches] = useState([])
  const [error2, setError2] = useState("")
  const [selectedBranches, setSelectedBranches] = useState()
  const [newmodal_element, setnewModal_element] = useState(false)
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )
  useEffect(() => {
    const newBranch = localStorage.getItem("showBranch")
    if (newBranch) {
      const parsedBranches = JSON.parse(newBranch)
      const formattedBranches = parsedBranches.map(item => ({
        value: item.id,
        label: item.name,
      }))

      setBranches(formattedBranches)
    }
  }, [])

  const getAllCustomers = () => {
    setShow(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/customers?sort=name,asc&per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCustomer(res.data.data.data)
        } else {
        }
        setShow(false)
      })
  }

  const getAllOrders = id => {
    setError(false)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/order?type=order,order_service&customer_id=${id}&payment_status=p_paid,unpaid&per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setOrder(res.data.data.data)
        } else {
          setError(true)
        }
      })
  }

  const submitLedger = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/ledger`,
        {
          customer_id: value.customer_id,
          order_id: value.order_id,
          amount: value.amount,
          transaction_date: e.target.transaction_date.value,
          message: value.message,
          type: value.type,
          branch_id: selectedBranches,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoading(false)
          props.getAllLedgers()
          props.setmodal_standard(false)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          setLoading(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const handleSelectChange = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setSelectedBranches(selectedValues)
  }
  useEffect(() => {
    getAllCustomers()
    // getAllOrders()
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_standard(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      <Modal isOpen={props.modal_standard} centered={true}>
        <AvForm
          className="modal-title mt-0 required"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitLedger(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Create Ledger
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal_standard(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <AvField
                className="select-arrow"
                label="Select Customer"
                type="select"
                name="customer_id"
                defaultValue={props.ledger && props.ledger.customer_id}
                onChange={e => getAllOrders(e.target.value)}
              >
                <option value="" hidden>
                  Select
                </option>
                {customer &&
                  customer.map(item => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </AvField>
              {show ? (
                <span className="text-success">
                  Fetching Customers Please wait...
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <AvField
                className="select-arrow"
                label="Select Order"
                type="select"
                name="order_id"
                defaultValue={props.ledger && props.ledger.order_id}
              >
                <option value="" hidden>
                  Select
                </option>
                {order &&
                  order.map(item => (
                    <option value={item.id}>Order id: KLK0000{item.id}</option>
                  ))}
              </AvField>
              {error ? (
                <span className="text-danger fst-italic">
                  No data found please select another customer
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <AvField
                label="Amount"
                type="text"
                name="amount"
                placeholder="Enter Amount"
                defaultValue={props.ledger && props.ledger.amount}
              />
            </div>
            <div className="mb-3">
              <label className="form-group">Transaction Date</label>
              <DatePicker
                className="w-100 form-control"
                dateFormat="yyyy-MM-dd"
                name="transaction_date"
                selected={transactionDate}
                onChange={date => setTransactionDate(date)}
                value={props.ledger && props.ledger.transaction_date}
              />
            </div>
            {getCurrentUser()?.enable_branch == 1 ? (
              <div className="mb-3">
                <label htmlFor="branch-select" className="form-label">
                  Branch
                </label>
                <Select
                  isMulti
                  options={branches}
                  onChange={handleSelectChange}
                  placeholder="Select Branches"
                />
              </div>
            ) : (
              ""
            )}
            <div className="mb-3">
              <AvField
                label="Message"
                type="textarea"
                name="message"
                placeholder="Enter message"
                defaultValue={props.ledger && props.ledger.message}
              />
            </div>
            <div className="modal-footer">
              <div className="text-center">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal_standard(false)}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
