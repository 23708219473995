import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import TableReminder from "../../components/Admin/Reminder"

const Reminder = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reminder | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <TableReminder />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Reminder
