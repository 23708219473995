import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import HostDomain from "../../components/Admin/profileComps/HostDomain"
const hostDomain = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Host on your Domain | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <HostDomain />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default hostDomain
