import React, { useState, useEffect } from "react"
import { Modal, Button } from "reactstrap"
import axios from "axios"
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import moment from "moment"
import { addDays } from "date-fns"
import DatePicker from "react-datepicker"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { MentionsInput, Mention } from "react-mentions"
import _escapeRegExp from "lodash/escapeRegExp"

const AttachmentModal = props => {
  const [loading, setLoading] = useState(false)
  const [showDate, setShowDate] = useState("1")
  const [messages, setMessages] = useState([])
  const [whatsappNumber, setWhatsappNumber] = useState("primary")
  const [selectedMessage, setSelectedMessage] = useState("")
  const [file, setFile] = useState("")
  const [startDate, setStartDate] = useState(addDays(new Date(), 1))
  const [allData, setAllData] = useState([])
  const [state, setState] = useState({
    name: "React",
    value: "",
    singleLineValue: "",
    mentionData: null,
    variables: [
      {
        _id: "{company_name}",
        name: "{company_name}",
      },
      {
        _id: "{user_name}",
        name: "{user_name}",
      },
      {
        _id: "{user_number}",
        name: "{user_number}",
      },
      {
        _id: "{order_id}",
        name: "{order_id}",
      },
      {
        _id: "{proforma_id}",
        name: "{proforma_id}",
      },
      {
        _id: "{lead_name}",
        name: "{lead_name}",
      },
      {
        _id: "{customer_care_number}",
        name: "{customer_care_number}",
      },
      {
        _id: "{brand_name}",
        name: "{brand_name}",
      },
      {
        _id: "{balance_payment}",
        name: "{balance_payment}",
      },
    ],
  })

  const getAllDrive = () => {
    setAllData([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/klikdrive?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllData(res.data.data.data)
        } else {
        }
      })
  }

  const getMessages = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/message-templates?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setMessages(res.data.data.data)
        } else {
        }
      })
  }

  const handleChangeSingle = (e, newValue) => {
    setState({
      ...state,
      singleLineValue: newValue,
    })
  }

  const variableData1 = state.variables.map(variable => ({
    id: variable._id,
    display: `${variable.name}`,
  }))

  const variableData2 = allData?.map(link => ({
    id: link?.link,
    display: `${link?.title}`,
  }))

  const variableData = [...variableData1, ...variableData2]

  function swapTags(text) {
    let displayText = text
    const tagsWithBraces = text.match(/@\[\{[^\]]+\}\]\([^\)]+\)/gi) || []
    const tagsWithoutBraces = text.match(/@\[[^\]]+\]\([^\)]+\)/gi) || []

    // Handle tags with curly braces
    tagsWithBraces.forEach(tag => {
      const tagData = tag.slice(3, -1) // Extract data between [{...}]({...})
      const tagDisplayValue = tagData.split("](")[0] // Get the content before ']('
      const tagReplacement = "{" + tagDisplayValue // Ensure { and } are retained
      displayText = displayText.replace(
        new RegExp(_escapeRegExp(tag), "gi"),
        tagReplacement
      )
    })

    // Handle tags without curly braces
    tagsWithoutBraces.forEach(tag => {
      const tagData = tag.slice(2, -1) // Extract data between [...](...)
      const tagDisplayValue = tagData.split("](")[0] // Get the content before ']('
      const tagReplacement = tagDisplayValue + ": " + tagData.split("](")[1] // No need to add curly braces
      displayText = displayText.replace(
        new RegExp(_escapeRegExp(tag), "gi"),
        tagReplacement
      )
    })

    return displayText
  }

  const handleSelectChange = event => {
    const newSelectedId = parseInt(event.target.value)
    const selectedObject = messages?.find(item => item.id === newSelectedId)
    const message = selectedObject ? selectedObject.message : ""
    setSelectedMessage(message)
    setState({
      ...state,
      singleLineValue: message,
    })
  }

  const getFile = e => {
    setFile(e.target.files[0])
  }

  const sendAttachment = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    let Email = 0
    if (value.send_email) {
      Email = 1
    } else {
      Email = 0
    }
    let Whatsapp = 0
    if (value.whatsapp) {
      Whatsapp = 1
    } else {
      Whatsapp = 0
    }
    const formData = new FormData()
    formData.append("lead_ids", JSON.stringify(props.leadsSelectedIds))
    formData.append("email", Email)
    formData.append("whatsapp", Whatsapp)
    formData.append("message", swapTags(state.singleLineValue))
    formData.append("delay", value.delay)
    formData.append("batch_size", value.batch_size)
    formData.append("whatsapp_number", value.whatsapp_number)
    formData.append("send_now", value.send_now)
    // formData.append("attachment", file)
    formData.append(
      "scheduled_date_time",
      e.target.scheduled_date_time !== undefined
        ? e.target.scheduled_date_time.value
        : ""
    )
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/send-attachments`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // props.getAllLeads()
          // props.setLeadsSelectedIds([])
          props.setattachment_model(false)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setLoading(false)
        } else {
          setLoading(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    // getMessages()
    getAllDrive()
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setattachment_model(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <div>
      <Modal isOpen={props.attachment_model} centered={true} size="lg">
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            sendAttachment(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Send Attachment
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setattachment_model(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {props.leadsSelectedIds?.length > 300 ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                You can't send attachments to more than 300 leads.
              </span>
            ) : (
              ""
            )}
            {/* <div className="mb-3">
                     <AvField
                        type="text"
                        placeholder="Enter campaign name"
                        name="name"
                        label="Campaign Name"
                     />
                  </div>
                  <div className="mb-3">
                     <AvField
                        type="select"
                        className="select-arrow"
                        name="template_id"
                        label="Select Message"
                        onChange={(e) => handleSelectChange(e)}
                     >
                        <option value="">Select</option>
                        {messages?.map((item) =>
                           <option value={item.id}>{item.name}</option>
                        )}
                     </AvField>
                  </div>
                  <div className="mb-3">
                     <label>Select Attachment</label>
                     <AvField
                        type="file"
                        name="attachment"
                        accept=".xls,.xlsx,.pdf,.png,.jpg,.jpeg,.txt,.csv,.docx"
                        onChange={e => getFile(e)}
                     />
                  </div> */}
            <div className="mb-3">
              {/* <AvField
                        type="textarea"
                        placeholder="Enter message"
                        name="message"
                        label="Message"
                        value={selectedMessage}
                     /> */}
              <label>Message</label>
              <MentionsInput
                value={state.singleLineValue}
                onChange={handleChangeSingle}
                markup="@{{__type__||__id__||__display__}}"
                placeholder="Type anything, use the @ symbol to select variables."
                className="mentions"
              >
                <Mention
                  type="user"
                  trigger="@"
                  data={variableData}
                  className="mentions__mention"
                />
              </MentionsInput>
              <small className="fst-italic">{`Use @ to select variables and links from klikdrive to create message.`}</small>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label>Send</label>
                <div className="d-flex">
                  <div className="mb-3 me-2">
                    <AvField
                      label="Email"
                      type="checkbox"
                      name="send_email"
                      style={{
                        background: `${
                          getCurrentUser()?.email_enabled === 1
                            ? ""
                            : "lightgray"
                        }`,
                      }}
                      disabled={
                        getCurrentUser()?.email_enabled === 1 ? false : true
                      }
                    />
                  </div>
                  <div className="mb-3 ms-4">
                    <AvField
                      label="Whatsapp"
                      type="checkbox"
                      name="whatsapp"
                      style={{
                        background: `${
                          getCurrentUser()?.whatsapp_enabled === 1
                            ? ""
                            : "lightgray"
                        }`,
                      }}
                      disabled={
                        getCurrentUser()?.whatsapp_enabled === 1 ? false : true
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label>From which number you want to send message</label>
                <AvRadioGroup
                  inline
                  name="whatsapp_number"
                  onChange={e => setWhatsappNumber(e.target.value)}
                  required
                  defaultValue={whatsappNumber}
                >
                  {getCurrentUser().admin_whatsapp_token !== null &&
                  getCurrentUser().admin_whatsapp_username !== null ? (
                    <AvRadio label="Primary Number" value="primary" />
                  ) : (
                    <AvRadio label="Primary Number" value="primary" disabled />
                  )}
                  <AvRadio label="Leadholder's Number" value="leadholder" />
                </AvRadioGroup>
              </div>
            </div>
            <div className="d-flex">
              <div className="mb-3 me-2">
                <AvField
                  label="Set delay after every message (in seconds)"
                  type="number"
                  name="delay"
                  min={30}
                  max={120}
                  defaultValue={30}
                />
                <small>Enter value between 30-120.</small>
              </div>
              <div className="mb-3 ms-4">
                <AvField
                  label="Set batch size of messages"
                  type="number"
                  name="batch_size"
                  min={5}
                  max={300}
                  defaultValue="5"
                />
                <small>Enter value between 5-300.</small>
              </div>
            </div>
            <div className="mb-3">
              <AvRadioGroup
                inline
                name="send_now"
                defaultValue="1"
                onChange={e => setShowDate(e.target.value)}
              >
                <AvRadio label="Schedule Message" value="0" />
                <AvRadio label="Send Now" value="1" />
              </AvRadioGroup>
              {showDate === "0" ? (
                <DatePicker
                  className="w-100 form-control"
                  showTimeSelect
                  dateFormat="yyyy-MM-dd h:mm aa"
                  name="scheduled_date_time"
                  selected={startDate}
                  minDate={moment().toDate()}
                  onChange={date => setStartDate(date)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="modal-footer">
            <div className="text-center">
              {loading ? (
                <button
                  className="btn btn-primary disabled"
                  type="button"
                  disabled
                >
                  Loading
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="submit"
                  // onClick={() => setModal2(false)}
                  disabled={props.leadsSelectedIds?.length > 300 ? true : false}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </div>
  )
}

export default AttachmentModal
