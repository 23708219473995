import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import DatePicker from "react-datepicker"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import moment from "moment"
import Select from "react-select"

let selectedProductId = []
let dValues = []

export default function OfferModal(props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [product, setProduct] = useState([])
  const [branches, setBranches] = useState([])
  const [error2, setError2] = useState("")
  const [selectedBranches, setSelectedBranches] = useState()
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )
  useEffect(() => {
    const newBranch = localStorage.getItem("showBranch")
    if (newBranch) {
      const parsedBranches = JSON.parse(newBranch)
      const formattedBranches = parsedBranches.map(item => ({
        value: item.id,
        label: item.name,
      }))

      setBranches(formattedBranches)
    }
  }, [])

  const getProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/product?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setProduct(res.data.data.values.data)
          setError("")
        } else {
          setError(res.data.message)
        }
      })
  }

  const options = []

  if (product !== undefined) {
    product.map(item => {
      options.push({
        value: item.id,
        label: item.name,
      })
    })
  }

  const getSelectedId = e => {
    selectedProductId = []
    e?.map(item => selectedProductId.push(item.value))
  }

  const submitOffer = (e, value) => {
    let chk = "0"
    if (e.target.select_products.checked) {
      chk = "1"
    }
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.offer !== undefined) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/offer/${props.offer.id}`,
          {
            product_id: selectedProductId,
            name: value.name,
            type: value.type,
            amount: value.amount,
            start_date: e.target.start_date.value,
            end_date: e.target.end_date.value,
            remark: value.remark,
            select_products: chk,
            branch_id: selectedBranches,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setmodal_offer(false)
            props.setOffer(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/offer`,
          {
            product_id: selectedProductId,
            name: value.name,
            type: value.type,
            amount: value.amount,
            start_date: e.target.start_date.value,
            end_date: e.target.end_date.value,
            remark: value.remark,
            select_products: chk,
            branch_id: selectedBranches,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getOffers()
            props.setmodal_offer(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  const handleSelectChange = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setSelectedBranches(selectedValues)
  }
  const handleSelectChanges = e => {
    const selectedValue = parseInt(e.target.selectedOptions[0]?.value, 10)
    setSelectedBranches([selectedValue])
  }

  useEffect(() => {
    getProducts()
    dValues = []
    if (props.offer !== undefined) {
      setStartDate(moment(props.offer?.start_date).toDate())
      setEndDate(moment(props.offer?.end_date).toDate())
      if (props.offer.product_name !== null) {
        selectedProductId = []
        props.offer.product_name.map(item => {
          dValues.push({
            value: item.id,
            label: item.name,
          })
          selectedProductId.push(item.id)
        })
      } else {
        ;("")
      }
    }
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_offer(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      <Modal isOpen={props.modal_offer} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitOffer(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.offer !== undefined ? "Edit Offer" : "Create Offer"}
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal_offer(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3 required">
              <AvField
                label="Name"
                type="text"
                name="name"
                placeholder="Offer Name"
                value={props.offer && props.offer.name}
              />
            </div>
            <div className="mb-3">
              <AvField
                label="All Products"
                type="checkbox"
                name="select_products"
                checked={props.offer?.select_products == "1" ? true : false}
              />
            </div>
            <div className="form-group mb-3">
              <label>Select Products</label>
              <Select
                isMulti
                isClearable="true"
                name="product_id"
                options={options}
                onChange={e => getSelectedId(e)}
                defaultValue={dValues}
              />
              {error ? (
                <div className="mb-3">
                  <Alert color="danger">{error}</Alert>
                </div>
              ) : null}
            </div>
            <div className="mb-3 required">
              <AvField
                label="Type"
                type="select"
                className="select-arrow"
                name="type"
                value={props.offer && props.offer.type}
              >
                <option value="" hidden>
                  Select
                </option>
                <option value="fixed">Fixed</option>
                <option value="percentage">Percentage</option>
              </AvField>
            </div>
            <div className="mb-3 required">
              <AvField
                label="Amount"
                type="number"
                onFocus={e =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault()
                    },
                    { passive: false }
                  )
                }
                name="amount"
                placeholder="Enter Amount"
                value={props.offer && props.offer.amount}
              />
            </div>
            {getCurrentUser()?.enable_branch == 1 ? (
              <div className="mb-3">
                <label htmlFor="branch-select" className="form-label">
                  Branch
                </label>
                <Select
                  isMulti
                  options={branches}
                  onChange={handleSelectChange}
                  placeholder="Select Branches"
                />
              </div>
            ) : (
              ""
            )}
            <div className="d-flex mt-2 mb-3 required">
              <div className="mb-3 pe-2">
                <label className="form-group">Start Date</label>
                <DatePicker
                  className="w-100 form-control"
                  dateFormat="yyyy-MM-dd"
                  name="start_date"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                />
              </div>
              <div className="mb-3 pe-2">
                <label type="text" className="form-group">
                  End Date
                </label>
                <DatePicker
                  className="w-100 form-control"
                  dateFormat="yyyy-MM-dd"
                  name="end_date"
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                />
              </div>
            </div>
            <div className="mb-3">
              <AvField
                label="Remark"
                type="text"
                name="remark"
                placeholder="Remark"
                value={props.offer && props.offer.remark}
              />
            </div>
            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal_offer(false)}
                  >
                    {props.offer !== undefined ? "Submit" : "Create"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
