import React, { useState, useEffect } from "react"
import Loader from "../../Loader/loader.js"
import { Row, Col } from "reactstrap"
import axios from "axios"
import Paginations from "../../Pagination"
import {
  getCurrentUser,
  getFilterDate,
  setFilterDate,
} from "../../../helpers/Utils"
import ReminderList from "./reminderlist"
import ReminderModal from "./remindermodal"
import MultipleChoice from "../../Filter/MultipleChoice"
import DateFilter from "../../Filter/DateFilter"
import InputFilter from "../../Filter/SearchFilter"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TableReminder = props => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [allReminder, setAllReminder] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [allUsers, setAllUsers] = useState([])
  const [leadholder_filter, setleadholder_filter] = useState(false)
  const [name_filter, setname_filter] = useState(false)
  const [latestmessage_filter, setlatestmessage_filter] = useState(false)
  const [date_filter, setdate_filter] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [blur, setBlur] = useState(false)
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)

  const getAllReminder = () => {
    setLoader(<Loader />)
    setAllReminder([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/reminder?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setAllReminder(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setAllReminder([])
    setCounter("")
    setAllPages([])
    setLoader(<Loader />)
    axios
      .get(
        `${e}&userid=${
          localStorage.getItem("leadHolder") !== null
            ? localStorage.getItem("leadHolder")
            : ""
        }
            &leadname=${
              localStorage.getItem("leadName") !== null
                ? localStorage.getItem("leadName")
                : ""
            }
            &from_date=${
              getFilterDate() && getFilterDate().from !== null
                ? getFilterDate().from
                : ""
            }
            &to_date=${
              getFilterDate() && getFilterDate().to !== null
                ? getFilterDate().to
                : ""
            }
            &message=${
              localStorage.getItem("latestMessage") !== null
                ? localStorage.getItem("latestMessage")
                : ""
            }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllReminder(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "latMsg") {
      localStorage.setItem("latestMessage", e)
    }
    if (type == "leadholder") {
      localStorage.setItem("leadHolder", e)
    }
    if (type == "date") {
      setFilterDate(e)
    }
    setSearchedCount("")
    setAllReminder([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/reminder?userid=${
          localStorage.getItem("leadHolder") !== null
            ? localStorage.getItem("leadHolder")
            : ""
        }
         &leadname=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &from_date=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &to_date=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &message=${
           localStorage.getItem("latestMessage") !== null
             ? localStorage.getItem("latestMessage")
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllReminder(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setname_filter(false)
          setdate_filter(false)
          setlatestmessage_filter(false)
          setleadholder_filter(false)
        } else {
          setError(res.data.message)
        }
      })
  }

  const getAllUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllUsers(res.data.data.data)
        } else {
        }
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const deleteLeads = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-reminder-deleted`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllReminder()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    getAllReminder()
    getAllUsers()
  }, [])

  return (
    <>
      {modal_standard ? (
        <ReminderModal
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          getAllReminder={getAllReminder}
        />
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">You won't be able to revert this!</span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Reminder</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              {blur ? (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary" disabled>
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    disabled
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              <button
                className="btn btn-success"
                onClick={() => {
                  setmodal_standard(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Results
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      User Name
                      {leadholder_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setleadholder_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadHolder") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setleadholder_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setleadholder_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        leadholder_filter={leadholder_filter}
                        setleadholder_filter={setleadholder_filter}
                        data={allUsers}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Lead Name
                      {name_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        name_filter={name_filter}
                        setname_filter={setname_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Message
                      {latestmessage_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setlatestmessage_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("latestMessage") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setlatestmessage_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setlatestmessage_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        latestmessage_filter={latestmessage_filter}
                        setlatestmessage_filter={setlatestmessage_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Date & Time
                      {date_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setdate_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {getFilterDate() && getFilterDate().from == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <DateFilter
                        date_filter={date_filter}
                        setdate_filter={setdate_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
                {allReminder &&
                  allReminder.map((item, obj) => (
                    <ReminderList
                      key={obj}
                      data={item}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      i={obj}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllReminder}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default TableReminder
