import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { MentionsInput, Mention } from "react-mentions"
import _escapeRegExp from "lodash/escapeRegExp"

export default function MessageModal(props) {
  const [loading, setLoading] = useState(false)
  const [allData, setAllData] = useState([])
  const [state, setState] = useState({
    name: "React",
    value: props.message?.message ? props.message?.message : "",
    singleLineValue: props.message?.message ? props.message?.message : "",
    mentionData: null,
    variables: [
      {
        _id: "{company_name}",
        name: "{company_name}",
      },
      {
        _id: "{user_name}",
        name: "{user_name}",
      },
      {
        _id: "{user_number}",
        name: "{user_number}",
      },
      {
        _id: "{order_id}",
        name: "{order_id}",
      },
      {
        _id: "{proforma_id}",
        name: "{proforma_id}",
      },
      {
        _id: "{lead_name}",
        name: "{lead_name}",
      },
      {
        _id: "{customer_care_number}",
        name: "{customer_care_number}",
      },
      {
        _id: "{brand_name}",
        name: "{brand_name}",
      },
      {
        _id: "{balance_payment}",
        name: "{balance_payment}",
      },
    ],
  })

  const getAllDrive = () => {
    setAllData([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/klikdrive?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllData(res.data.data.data)
        } else {
        }
      })
  }

  const handleChangeSingle = (e, newValue) => {
    setState({
      ...state,
      singleLineValue: newValue,
    })
  }

  const variableData1 = state.variables.map(variable => ({
    id: variable._id,
    display: `${variable.name}`,
  }))

  const variableData2 = allData?.map(link => ({
    id: link?.link,
    display: `${link?.title}`,
  }))

  const variableData = [...variableData1, ...variableData2]

  function swapTags(text) {
    let displayText = text
    const tagsWithBraces = text.match(/@\[\{[^\]]+\}\]\([^\)]+\)/gi) || []
    const tagsWithoutBraces = text.match(/@\[[^\]]+\]\([^\)]+\)/gi) || []

    // Handle tags with curly braces
    tagsWithBraces.forEach(tag => {
      const tagData = tag.slice(3, -1) // Extract data between [{...}]({...})
      const tagDisplayValue = tagData.split("](")[0] // Get the content before ']('
      const tagReplacement = "{" + tagDisplayValue // Ensure { and } are retained
      displayText = displayText.replace(
        new RegExp(_escapeRegExp(tag), "gi"),
        tagReplacement
      )
    })

    // Handle tags without curly braces
    tagsWithoutBraces.forEach(tag => {
      const tagData = tag.slice(2, -1) // Extract data between [...](...)
      const tagDisplayValue = tagData.split("](")[0] // Get the content before ']('
      const tagReplacement = tagDisplayValue + ": " + tagData.split("](")[1] // No need to add curly braces
      displayText = displayText.replace(
        new RegExp(_escapeRegExp(tag), "gi"),
        tagReplacement
      )
    })

    return displayText
  }

  const submitMessage = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.message !== undefined) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/message-templates/${props.message.id}`,
          {
            name: value.name,
            events: value.events,
            message: swapTags(state.singleLineValue),
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setIsOpen(false)
            props.setmodal_messages(false)
            props.setMessage(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/message-templates`,
          {
            name: value.name,
            events: value.events,
            message: swapTags(state.singleLineValue),
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getAllMessages()
            props.setmodal_messages(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  useEffect(() => {
    getAllDrive()
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_messages(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])
  return (
    <>
      <Modal isOpen={props.modal_messages} centered={true} size="lg">
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitMessage(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.message !== undefined ? "Edit Message" : "Create Message"}
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal_messages(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3 required">
              <AvField
                label="Subject"
                placeholder="Enter subject"
                type="text"
                name="name"
                defaultValue={props.message && props.message.name}
              />
            </div>
            <div className="mb-3">
              <AvField
                className="select-arrow"
                label="Select Event (Optional)"
                type="select"
                name="events"
                defaultValue={props.message && props.message.events}
              >
                <option value="">Select</option>
                {/* <option value="lead_create">Lead Create</option> */}
                {/* <option value="lead_matured">Lead Matured</option> */}
                <option value="followup_reminder">Followup Reminder</option>
                {/* <option value="new_proforma">New Proforma</option> */}
                {/* <option value="new_order">New Order</option> */}
                {/* <option value="update_proforma">Update Proforma</option> */}
                {/* <option value="update_order">Update Order</option> */}
                {/* <option value="payment_update">Payment Update</option> */}
                {/* <option value="payment_request">Payment Request</option> */}
                <option value="dispatch_details">Dispatch Details</option>
                <option value="new_products/services">
                  New Products/Services
                </option>
                <option value="product_purchase_reminder">
                  Product Purchase Reminder
                </option>
                <option value="purchase_order">Purchase Order</option>
              </AvField>
            </div>
            <div className="mb-3 required">
              <label>Message</label>
              <MentionsInput
                value={state.singleLineValue}
                onChange={handleChangeSingle}
                markup="@{{__type__||__id__||__display__}}"
                placeholder="Type anything, use the @ symbol to select variables."
                className="mentions"
              >
                <Mention
                  type="user"
                  trigger="@"
                  data={variableData}
                  className="mentions__mention"
                />
              </MentionsInput>
              <small className="fst-italic">{`Use @ to select variables to create message.`}</small>
            </div>
          </div>
          <div className="modal-footer">
            <div className="text-center">
              {loading ? (
                <button
                  className="btn btn-primary disabled"
                  type="button"
                  disabled
                >
                  Loading
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="submit"
                  // onClick={() => props.setmodal_messages(false)}
                >
                  {props.message !== undefined ? "Submit" : "Create"}
                </button>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
