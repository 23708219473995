import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import axios from "axios"
import ProductLineModal from "./productLineModal"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const ProductLineList = props => {
  const [service, setService] = useState(props.data)
  const [modal_standard, setmodal_standard] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const deleteService = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/services/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setService("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editService = e => {
    setIsOpen(true)
    setmodal_standard(true)
  }

  useEffect(() => {
    selectedRoleId = []
  }, [])

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e)
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id
      })
      selectedRoleId = uniqueID
      props.setLeadsSelectedIds(selectedRoleId)
    } else {
      selectedRoleId = props.leadsSelectedIds
      var array = selectedRoleId
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1)
      }
      props.setLeadsSelectedIds(selectedRoleId)
    }
    if (selectedRoleId.length == 0) {
      props.setBlur(false)
    } else {
      props.setBlur(true)
    }
  }

  if (service === "") {
    return ""
  } else
    return (
      <>
        {isOpen ? (
          <ProductLineModal
            modal_standard={modal_standard}
            setmodal_standard={setmodal_standard}
            service={service}
            setService={setService}
            setIsOpen={setIsOpen}
          />
        ) : null}
        <tr key={props.i}>
          <td>
            <input
              onChange={e => getSelectedId(service.id, e)}
              name="chk"
              value={service.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>{props.counter + props.i}</td>
          <td>{service.id}</td>
          <td className="d-flex">
            <div
              className="pe-2 d-flex align-items-center"
              style={{ borderRight: "1px solid gray" }}
            >
              <span
                className="text-info"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  editService(service.id)
                }}
              >
                {service.name}
              </span>
            </div>
            <div className="ps-2">
              {service.service_fields?.length !== 0 ? (
                <>
                  <tr>
                    <th>Field Name</th>
                    <th>Type</th>
                    <th>Value</th>
                  </tr>
                  {service.service_fields?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.field_name}</td>
                      <td>{item.type}</td>
                      <td>{item.value}</td>
                      <br />
                    </tr>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </td>
          {getCurrentUser()?.enable_branch == 1 ? (
            <td>{service.branch?.name}</td>
          ) : (
            ""
          )}
          <td>
            <a
              className="text-warning"
              onClick={() => {
                editService(service.id)
              }}
            >
              <i className="fas fa-pen" />
            </a>
            {getPermission().product.product.delete.is_checked === "yes" ? (
              <a
                className="text-danger ms-2"
                onClick={() => {
                  setconfirm_alert(true)
                }}
              >
                <i className="fas fa-trash-alt" />
              </a>
            ) : (
              ""
            )}
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteService(service.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
      </>
    )
}
export default ProductLineList
