import PropTypes from "prop-types"
import axios from "axios"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Modal,
  Label,
  FormGroup,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { editProfile, resetProfileFlag } from "../../store/actions"
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const UserProfile = props => {
  // const navigate = useNavigate();
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_xxlarge, setmodal_xxlarge] = useState(false)
  const [modal_standard, setmodal_standard] = useState(false)
  const [passField1, setPassField1] = useState(false)
  const [passField2, setPassField2] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [error, setError] = useState("")
  const [platform, setPlatform] = useState(
    getCurrentUser().whatsapp_platform
      ? getCurrentUser().whatsapp_platform
      : "webjaddu"
  )
  const [image, setImage] = useState(getCurrentUser().photo)
  const [imagePreviewProfile, setImagePreviewProfile] = useState(
    getCurrentUser().photo
  )
  const [portalLogo, setPortalLogo] = useState(getCurrentUser()?.logo_formatted)
  const [confirm_alert, setconfirm_alert] = useState(false)

  const toggle = () => {
    setPassField1(!passField1)
  }

  const toggle2 = () => {
    setPassField2(!passField2)
  }

  const handleValidSubmit = (e, value) => {
    setLoading(true)
    // let bodyFormData = new FormData();
    // bodyFormData.append('name', e.target.name.value);
    // bodyFormData.append('email', e.target.email.value);
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/update-profile`,
        {
          name: e.target.name.value,
          email: e.target.email.value,
          company_name: e.target.company_name.value,
        },
        {
          headers: {
            // 'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoading(false)
          setCurrentUser(res.data.data)
          setmodal_xlarge(false)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          window.history.go(-1)
        } else {
          setLoading(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const submitPassword = (e, value) => {
    setLoading(true)
    // let bodyFormData = new FormData();
    // bodyFormData.append('password', e.target.password.value);
    // bodyFormData.append('confirm_password', e.target.confirm_password.value);
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/change-password`,
        {
          // old_password: e.target.old_password.value,
          password: e.target.password.value,
          confirm_password: e.target.confirm_password.value,
          logout_others: e.target.logout_others.checked ? 1 : 0,
        },
        {
          headers: {
            // 'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoading(false)
          setmodal_xlarge(false)
          setCurrentUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          window.history.go(-1)
        } else {
          setLoading(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const imagePreview = () => {
    if (image === getCurrentUser().photo) {
      setmodal_xxlarge(false)
    } else {
      var formData = new FormData()
      formData.append("profile_pic", image)
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/profile-pic`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setImage(res.data.data.photo)
            setLoading(false)
            setCurrentUser(res.data.data)
            setmodal_xxlarge(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            window.history.go(-1)
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  const whatsappToken = (e, value) => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute
    setLoading3(true)
    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    let whatsToken = ""
    if (platform === "chatway") {
      whatsToken = value.whatsapp_credit_balance_token
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/whatsapp-token`,
        {
          whatsapp_platform: "webjaddu",
          whatsapp_username: value.whatsapp_username,
          whatsapp_token: value.whatsapp_token,
          whatsapp_credit_balance_token: whatsToken,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoading3(false)
          setCurrentUser(res.data.data)
          if (platform === "chatway") {
            getWhatsAppCredits(value.whatsapp_username, value.whatsapp_token)
          } else {
            toast.success(
              "Whatsapp Integration details successfully submitted.",
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            )
            window.history.go(-1)
          }
        } else {
          setLoading3(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const handleLogoSubmit = (e, value) => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })
    const timeout = 120000 // 2 minute
    setLoading2(true)

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    var formData = new FormData()
    formData.append("logo", portalLogo)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/update-logo`,
        formData,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoading2(false)
          setCurrentUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          window.history.go(-1)
        } else {
          setLoading2(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const getWhatsAppCredits = (e, f) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/get-whatsapp-api-response?whatsapp_username=${e}&whatsapp_token=${f}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.data.status !== "error") {
          toast.success(
            "Whatsapp Integration details successfully submitted.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
          window.history.go(-1)
        } else {
          toast.error("Invalid token!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const feedAttach = e => {
    setImage(e.target.files[0])
    setImagePreviewProfile(URL.createObjectURL(e.target.files[0]))
  }

  const getLogo = e => {
    setPortalLogo(e.target.files[0])
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        setmodal_standard(false)
        setmodal_xlarge(false)
        setmodal_xxlarge(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="KlikCRM" breadcrumbItem="Profile" /> */}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={6}>
                <h4 className="page-title">Profile</h4>
              </Col>
              <Col md={6}>
                <h6 className="page-title float-end"></h6>
              </Col>
            </Row>
          </div>
          <Row>
            <Col lg="8">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <div className="d-flex align-items-center profile-mobile">
                        <div className="">
                          <Label
                            className="profile_update update-prof"
                            onClick={() => {
                              setmodal_xxlarge(true)
                            }}
                          >
                            <img
                              className="mw-100 mb-1 profile_update update-img"
                              src={image}
                              alt=""
                              style={{ cursor: "pointer" }}
                            />
                            <span className="edit-picture">Edit Picture</span>
                            <FormGroup className="text-center">
                              Profile Picture
                            </FormGroup>
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col md={8}>
                      <AvForm
                        className="modal-body mt-0"
                        id="myModalLabel"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <AvField
                                name="name"
                                label="Name"
                                defaultValue={getCurrentUser().name}
                                placeholder="Enter Name"
                                type="text"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Email"
                                defaultValue={getCurrentUser().email}
                                style={{ backgroundColor: "#edebeb" }}
                                placeholder="Enter E-mail"
                                type="email"
                                disabled
                              />
                            </div>
                          </Col>
                          {/* <Col md={5}>
                                          <div className="mb-3">
                                             <AvField
                                                name="dob"
                                                label="DOB"
                                                value={getCurrentUser().dob}
                                                type="date"
                                             />
                                          </div>
                                          <div className="mb-3">
                                             <AvField
                                                name="anniversary"
                                                label="Anniversary"
                                                value={getCurrentUser().dob}
                                                type="date"
                                             />
                                          </div>
                                       </Col> */}
                        </Row>
                        <div className="mb-3">
                          <AvField
                            name="number"
                            style={{ backgroundColor: "#edebeb" }}
                            label="Phone Number"
                            defaultValue={getCurrentUser().phone}
                            type="text"
                            disabled
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="company_name"
                            style={{
                              backgroundColor: `${
                                getCurrentUser()?.role_name === "Administrator"
                                  ? "#FFF"
                                  : "#edebeb"
                              }`,
                            }}
                            label="Company Name"
                            defaultValue={getCurrentUser().company_name}
                            type="text"
                            disabled={
                              getCurrentUser()?.role_name === "Administrator"
                                ? false
                                : true
                            }
                          />
                        </div>

                        <div className="modal-footer">
                          <div className="text-end">
                            {loading ? (
                              <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                              >
                                Loading
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  <AvForm
                    className="modal-title mt-0"
                    id="myModalLabel"
                    onValidSubmit={(e, v) => {
                      submitPassword(e, v)
                    }}
                  >
                    <h4 className="card-title mb-4">Change Password</h4>
                    <hr />
                    {/* <div className="mb-3">
                                 <AvField
                                    label="Old Password"
                                    type="password"
                                    name="old_password"
                                 />
                              </div> */}
                    <div className="mb-3">
                      <AvField
                        style={{ paddingRight: "35px" }}
                        label="Password"
                        type={passField1 ? "text" : "password"}
                        name="password"
                        value={getCurrentUser().password}
                      />
                      {passField1 ? (
                        <span
                          className="float-end"
                          style={{ marginTop: "-27px", marginRight: "10px" }}
                        >
                          <i
                            className="fa fa-eye-slash"
                            style={{ cursor: "pointer" }}
                            onClick={() => toggle()}
                          ></i>
                        </span>
                      ) : (
                        <span
                          className="float-end"
                          style={{ marginTop: "-27px", marginRight: "10px" }}
                        >
                          <i
                            className="fa fa-eye"
                            style={{ cursor: "pointer" }}
                            onClick={() => toggle()}
                          ></i>
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <AvField
                        label="Password Confirmation"
                        style={{ paddingRight: "35px" }}
                        type={passField2 ? "text" : "password"}
                        name="confirm_password"
                        value={getCurrentUser().confirm_password}
                      />
                      {passField2 ? (
                        <span
                          className="float-end"
                          style={{ marginTop: "-27px", marginRight: "10px" }}
                        >
                          <i
                            className="fa fa-eye-slash"
                            style={{ cursor: "pointer" }}
                            onClick={() => toggle2()}
                          ></i>
                        </span>
                      ) : (
                        <span
                          className="float-end"
                          style={{ marginTop: "-27px", marginRight: "10px" }}
                        >
                          <i
                            className="fa fa-eye"
                            style={{ cursor: "pointer" }}
                            onClick={() => toggle2()}
                          ></i>
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <AvField
                        type="checkbox"
                        name="logout_others"
                        label="Logout All Active Sessions"
                      />
                    </div>
                    <hr />
                    <div className="text-center">
                      {loading ? (
                        <button
                          className="btn btn-primary disabled"
                          type="button"
                          disabled
                        >
                          Loading
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          type="submit"
                          // onClick={() => props.setmodal_dead_reason(false)}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col
              lg={getCurrentUser()?.role_name === "Administrator" ? "8" : "12"}
            >
              <Card>
                <CardBody>
                  <AvForm
                    className="card-title mt-0"
                    onValidSubmit={(e, v) => whatsappToken(e, v)}
                  >
                    <h4 className="card-title mb-4">Whatsapp Token Details</h4>
                    <hr />
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <AvField
                            className="select-arrow"
                            label="Select Platform"
                            type="select"
                            name="whatsapp_platform"
                            defaultValue={platform}
                            onChange={e => setPlatform(e.target.value)}
                          >
                            {/* <option value="chatway">Chatway</option> */}
                            <option value="webjaddu">waKonnect</option>
                          </AvField>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <AvField
                            label="Instance ID"
                            type="text"
                            name="whatsapp_username"
                            placeholder="Enter your username"
                            defaultValue={getCurrentUser()?.whatsapp_username}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <AvField
                            label="Token"
                            type="text"
                            name="whatsapp_token"
                            placeholder="Enter your token"
                            defaultValue={getCurrentUser()?.whatsapp_token}
                          />
                        </div>
                      </Col>
                      {/* {platform === "webjaddu" ? (
                                    ""
                                 ) : (
                                    <Col md={6}>
                                       <div className="mb-3">
                                          <AvField
                                             label="Whatsapp Credit Balance Token"
                                             type="text"
                                             name="whatsapp_credit_balance_token"
                                             placeholder="Enter your token"
                                             defaultValue={
                                                getCurrentUser()?.whatsapp_credit_balance_token
                                             }
                                          />
                                       </div>
                                    </Col>
                                 )} */}
                      <hr />
                    </Row>
                    <div className="text-center">
                      {loading3 ? (
                        <button
                          className="btn btn-primary disabled"
                          type="button"
                          disabled
                        >
                          Loading
                        </button>
                      ) : (
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      )}
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            {getCurrentUser()?.role_name === "Administrator" && (
              <Col lg="4">
                <Card>
                  <CardBody>
                    <AvForm
                      className="card-title mt-0"
                      onValidSubmit={(e, v) => handleLogoSubmit(e, v)}
                    >
                      <h4 className="card-title mb-4">Logo</h4>
                      <hr />
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <div>
                              <AvField
                                // label="Invoice Logo"
                                type="file"
                                name="portal_logo"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={e => getLogo(e)}
                              />
                            </div>
                          </div>
                          <div>
                            {!getCurrentUser()?.logo_formatted ? (
                              ""
                            ) : (
                              <img
                                width={100}
                                src={getCurrentUser()?.logo_formatted}
                                alt="portal logo"
                              />
                            )}
                          </div>
                        </Col>
                        <hr />
                      </Row>
                      <div className="text-center">
                        {loading2 ? (
                          <button
                            className="btn btn-primary disabled"
                            type="button"
                            disabled
                          >
                            Loading
                          </button>
                        ) : (
                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        )}
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            )}
            <Modal isOpen={modal_xxlarge} centered={true}>
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  Change Profile Photo
                </h5>
                <button
                  type="button"
                  className="cross__btn"
                  onClick={() => setmodal_xxlarge(false)}
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <AvForm
                className="modal-body mt-0 pad-footer"
                id="myModalLabel"
                onValidSubmit={(e, v) => {
                  imagePreview(e, v)
                }}
              >
                <div className="form-group mt-4">
                  <div className="upload_opt">
                    <input
                      id="attached"
                      accept="image/png, image/gif, image/jpeg"
                      className="d-none"
                      type="file"
                      name="profile_pic"
                      onChange={e => feedAttach(e)}
                    />
                    <label htmlFor="attached" className="pl-3">
                      {imagePreviewProfile ? (
                        <>
                          <img
                            alt="dd"
                            className="fa-camer uploaded__img p-0"
                            src={imagePreviewProfile}
                          />
                          <br />
                          <div className="">
                            <span className="cam-img">Edit Profile Image</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <i className="fa fa-camer">{image}</i>{" "}
                          <span className="cam-img">Change Profile Image</span>
                        </>
                      )}
                    </label>

                    <br />
                  </div>
                </div>

                {/* <div className="mb-3"> */}
                {/*   <AvField */}
                {/*     name="name" */}
                {/*     label="Name" */}
                {/*     defaultValue={getCurrentUser().name} */}
                {/*     placeholder="Enter Name" */}
                {/*     type="text" */}
                {/*     required */}
                {/*   /> */}
                {/* </div> */}

                <div className="modal-footer">
                  <div className="text-end">
                    {loading ? (
                      <button
                        className="btn btn-primary disabled"
                        type="button"
                        disabled
                      >
                        Loading
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </AvForm>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
)
