import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../helpers/Utils"
import axios from "axios"
import OfferModal from "./OfferModal"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const OfferList = props => {
  const [offer, setOffer] = useState(props.data)
  const [modal_offer, setmodal_offer] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const deleteOffer = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/offer/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setOffer("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editOffer = e => {
    setIsOpen(true)
    setmodal_offer(true)
  }

  if (offer === "") {
    return ""
  } else
    return (
      <>
        {modal_offer ? (
          <OfferModal
            modal_offer={modal_offer}
            setmodal_offer={setmodal_offer}
            offer={offer}
            setOffer={setOffer}
          />
        ) : null}
        <tr key={props.i}>
          <td>{props.counter + props.i}</td>
          <td>{offer.id}</td>
          <td>
            <span
              className="text-info"
              style={{ cursor: "pointer" }}
              onClick={() => {
                editOffer(offer.id)
              }}
            >
              {offer.name}
            </span>
            <br />
            {offer.is_expired == "Yes" ? (
              <span className="text-danger">Expired</span>
            ) : (
              ""
            )}
          </td>
          <td>
            {offer.select_products == "1" ? (
              <span className="badge" style={{ backgroundColor: "orange" }}>
                All Products
              </span>
            ) : (
              <>
                {offer.product_name?.map((item, i) => (
                  <div key={i}>
                    <span className="badge" style={{ backgroundColor: "gray" }}>
                      {item.name}
                    </span>{" "}
                  </div>
                ))}
              </>
            )}
          </td>
          <td>{offer.type}</td>
          <td>{offer.amount}</td>
          {getCurrentUser()?.enable_branch == 1 ? (
            <td>{offer.branch?.name}</td>
          ) : (
            ""
          )}
          <td>{offer.start_date}</td>
          <td>{offer.end_date}</td>
          <td>{offer.remark}</td>
          <td>
            <a
              className="text-warning"
              onClick={() => {
                editOffer(offer.id)
              }}
            >
              <i className="fas fa-pen" />
            </a>
            {getPermission().offer.offer.delete.is_checked === "yes" ? (
              <a
                className="text-danger ms-2"
                onClick={() => {
                  setconfirm_alert(true)
                }}
              >
                <i className="fas fa-trash-alt" />
              </a>
            ) : (
              ""
            )}
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteOffer(offer.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
      </>
    )
}
export default OfferList
