import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

// API function for login
const loginAPI = data => {
  return axios.post("/api/auth/login", data)
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(loginAPI, {
      email: user.email,
      password: user.password,
    })
    localStorage.setItem("authUser", JSON.stringify(response.data))
    yield put(loginSuccess(response.data))
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    yield put(logoutUserSuccess())
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
