import React, { useState } from "react"
import axios from "axios"
import { Row, Col, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { getCurrentUser } from "../../../helpers/Utils"

const CreateBranch = ({ modal_standard, setmodal_standard, user, props }) => {
  const [loading, setLoading] = useState(false)
  const [formValid, setFormValid] = useState(false) // State to track form validity

  const handleInputChange = e => {
    const form = e.target.form
    setFormValid(form.checkValidity())
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const formData = {
      name: e.target.name.value,
      address: e.target.address.value,
    }

    if (user) {
      // Update branch
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/branch/${user.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token ?? ""}`,
            },
          }
        )
        .then(res => {
          setLoading(false)
          if (res.data.status) {
            setmodal_standard(false)
            props.setUser(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
            })
          } else {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          toast.error(error.response?.data?.message)
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/branch`,
          { ...formData, branch_manager: 1 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCurrentUser().token ?? ""}`,
            },
          }
        )
        .then(res => {
          setLoading(false)
          if (res.data.status) {
            toast.success(res.data.message)
            setmodal_standard(false)
          } else {
            toast.error(res.data.message)
          }
        })
        .catch(error => {
          setLoading(false)
          toast.error(error.response?.data?.message)
        })
    }
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  }

  return (
    <Modal isOpen={modal_standard} centered size="lg">
      <AvForm
        onSubmit={handleSubmit}
        onChange={handleInputChange} // Trigger validity check on change
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {user ? "Edit Branch" : "Create Branch"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setmodal_standard(false)}
          ></button>
        </div>
        <div className="modal-body">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="required">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    value={(user && user.name) || ""}
                    onChange={handleInputChange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Name is required",
                      },
                    }}
                  />
                </div>
                <div className="mb-none mt-3 required">
                  <AvField
                    name="address"
                    label="Address"
                    type="textarea"
                    value={(user && user.address) || ""}
                    onChange={handleInputChange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Address is required",
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setmodal_standard(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!formValid || loading} // Disable button if form is invalid
          >
            {loading ? "Saving..." : user ? "Update" : "Create"}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

export default CreateBranch
