import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import DeadStaff from "../../components/Admin/Dead"

const Dead = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dead Statuses | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <DeadStaff />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dead
