import React from "react"
import MetaTags from "react-meta-tags"
import { getCurrentUser } from "../../helpers/Utils"
import PricingTable from "../../components/plans"
import Header from "../../components/HorizontalLayout/Header"
import Navbar from "../../components/HorizontalLayout/Navbar"

const Pricingtable = () => {
  console.log(getCurrentUser()?.is_active)
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        {getCurrentUser()?.is_active == "1" ? <Navbar /> : ""}
        <div className="page-content">
          <MetaTags>
            <title>Leads | KlikCRM</title>
          </MetaTags>
          <PricingTable />
        </div>
      </div>
    </React.Fragment>
  )
}
export default Pricingtable
