import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import SettingMenu from "../../components/Admin/settings"

const Settings = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin settings | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <SettingMenu />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Settings
