import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, Modal, Spinner } from "reactstrap"
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { useFormik } from "formik"
import csc from "country-state-city"
import Select from "react-select"
import moment from "moment"
import DatePicker from "react-datepicker"
import CreateFollowup from "../Create-Followup"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

let selectedUserId = []
let phoneNumber = ""
let altNumber = ""
let countryCode = ""
let altCountryCode = ""
let updatedStates = []
let fieldValues = []
let fieldCheckbox = []
let fieldText = []
let fieldTextArea = []
let fieldSelect = []
let fieldRadio = []
let fieldFlipButton = []

export default function LeadModal4(props) {
  const [lead, setLead] = useState([])
  const [loading, setLoading] = useState(false)
  const [loader1, setLoader1] = useState(false)
  const [bigModal, setBigModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [error, setError] = useState("")
  // const [file, setFile] = useState('')
  const [newsletter, setNewsletter] = useState(false)
  const [flip_button, setFlip_button] = useState(false)
  const [indiaOnly, setIndiaOnly] = useState(true)
  const [indiaOnlyalt, setIndiaOnlyalt] = useState(true)
  const [allFields, setAllFields] = useState([])
  const [timer, setTimer] = useState(null)
  const [districtMessage, setDistrictMessage] = useState("")
  const [emailMessage, setEmailMessage] = useState("")
  const [NumberMessage, setNumberMessage] = useState("")
  const [alternateNumber, setAlternateNumber] = useState("")
  const [duplicate, setDuplicate] = useState(0)
  const [duplicate2, setDuplicate2] = useState(0)
  const [duplicate3, setDuplicate3] = useState(1)
  const [sortName, setSortName] = useState("in")
  const [altsortName, setAltSortName] = useState("in")
  const [country, setCountry] = useState(props.lead && props.lead.country_name)
  const [state, setState] = useState(props.lead && props.lead.state)
  const [district, setDistrict] = useState(props.lead && props.lead.district)
  const [area, setArea] = useState(props.lead && props.lead.working_area)
  const [message, setMessage] = useState("")
  const [proformaValue, setProformaValue] = useState(
    props.lead && props.lead.proforma_address
  )
  const [deliverTo, setDeliverTo] = useState(
    props.lead && props.lead.deliver_to
  )
  const [dobDate, setDobDate] = useState()
  const [anniDate, setAnniDate] = useState()
  const [importantDate, setImportantDate] = useState()
  const [branches, setBranches] = useState([])
  const [selectedBranches, setSelectedBranches] = useState(
    props.lead?.branch ? [props.lead.branch.name] : []
  )

  const [error2, setError2] = useState("")
  const [dashboardData, setDashboardData] = useState(null)
  const [filteredLeadHolders, setFilteredLeadHolders] = useState([])
  const [branchManagers, setBranchManagers] = useState([])

  useEffect(() => {
    const storedData = localStorage.getItem("DashboardData")
    if (storedData) {
      const parsedData = JSON.parse(storedData)
      setDashboardData(parsedData)
      setBranchManagers(parsedData.branch_managers || [])
    }
  }, [])
  useEffect(() => {
    const newBranch = localStorage.getItem("showBranch")
    if (newBranch) {
      const parsedBranches = JSON.parse(newBranch)
      const formattedBranches = parsedBranches.map(item => ({
        value: item.id,
        label: item.name,
      }))

      setBranches(formattedBranches)
    }
  }, [])

  const addressFromik = useFormik({
    initialValues: {
      country: "India",
      state: null,
      city: null,
      area: null,
    },
    onSubmit: values => console.log(JSON.stringify(values)),
  })

  const countries = csc.getAllCountries()

  const updatedCountries = countries.map(country => ({
    label: country.name,
    value: country.id,
    ...country,
  }))

  const updatedState = e => {
    if (e !== undefined) {
      updatedStates = csc
        .getStatesOfCountry(e)
        .map(state => ({ label: state.name, value: state.id, ...state }))
    } else {
      updatedStates = csc
        .getStatesOfCountry("101")
        .map(state => ({ label: state.name, value: state.id, ...state }))
    }
  }

  const updatedCities = stateId =>
    csc
      .getCitiesOfState(stateId)
      .map(city => ({ label: city.name, value: city.id, ...city }))

  const { values, handleSubmit, setFieldValue, setValues } = addressFromik

  useEffect(() => {}, [values])

  const getAllFields = () => {
    fieldValues = []
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead-field${
          props.lead !== undefined ? `?lead_id=${props.lead.id}` : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError(res.data.message)
          setAllFields(res.data.data)
          res.data.data?.map(item => {
            fieldValues.push({
              lead_field_id: item.id,
              value: item.value !== null ? item.value : "",
            })
          })
        } else {
          setError(res.data.message)
        }
        props.setLoader3(false)
      })
  }

  const dialCode = e => {
    let str = e
    let str2 = str.toLowerCase()
    setSortName(str2)
    if (e == "IN") {
      setIndiaOnly(true)
    } else {
      setIndiaOnly(false)
    }
  }
  const altdialCode = e => {
    let str = e
    let str2 = str.toLowerCase()
    setAltSortName(str2)
    if (e == "IN") {
      setIndiaOnlyalt(true)
    } else {
      setIndiaOnlyalt(false)
    }
  }

  const getAllStates = e => {
    setState("")
    setDistrict("")
    if (e.length == 6) {
      setLoader1(true)
      axios
        .get(`https://api.postalpincode.in/pincode/${e}`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (res) {
          if (res.data[0].Status === "Success") {
            setDistrict(res.data[0].PostOffice[0].District)
            setState(res.data[0].PostOffice[0].State)
            setMessage("")
            setValues(
              {
                country: res.data[0].PostOffice[0].Country,
                state: res.data[0].PostOffice[0].State,
                city: res.data[0].PostOffice[0].District,
              },
              false
            )
            setFieldValue("state", res.data[0].PostOffice[0].State)
            districtExist(res.data[0].PostOffice[0].District)
            setLoader1(false)
          } else {
            setMessage(res.data[0].Message)
            setLoader1(false)
          }
        })
    }
  }

  const getLeadEmail = e => {
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      emailExist(e)
    }, 1000)
    setTimer(newTimer)
  }

  const emailExist = e => {
    setDuplicate(0)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead-exist?type=email&value=${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setEmailMessage(res.data.message)
          if (res.data == "") {
            setDuplicate(1)
            setEmailMessage("")
          } else {
            setDuplicate(res.data.duplicate)
          }
        } else {
          setEmailMessage("")
        }
      })
  }

  const numberExist = e => {
    if (e.length > 10) {
      setDuplicate2(0)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/lead-exist?type=number&value=${e}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setNumberMessage(res.data.message)
            setDuplicate2(res.data.duplicate)
          } else {
            setDuplicate2(1)
            setNumberMessage("")
          }
        })
    }
  }

  const alternateNumberExist = e => {
    if (e.length > 9) {
      setDuplicate3(1)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/lead-exist?type=alternate_number&value=${e}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setAlternateNumber(res.data.message)
            setDuplicate3(res.data.duplicate)
          } else {
            setDuplicate3(1)
            setAlternateNumber("")
          }
        })
    }
  }

  const getphonee = (a, b, c, d) => {
    countryCode = b.dialCode
    if (b.dialCode != "91") {
      setDuplicate2(1)
      setIndiaOnly(false)
    } else {
      setIndiaOnly(true)
    }
    let str = d
    const after = str.slice(str.indexOf(" ") + 1)
    phoneNumber = after
    setTimeout(() => {
      numberExist(after)
    }, 500)
  }
  const getalterphonee = (a, b, c, d) => {
    altCountryCode = b.dialCode
    if (b.dialCode != "91") {
      setDuplicate3(1)
      setIndiaOnlyalt(false)
    } else {
      setIndiaOnlyalt(true)
    }
    let str = d
    const after = str.slice(str.indexOf(" ") + 1)
    altNumber = after
    setTimeout(() => {
      alternateNumberExist(after)
    }, 500)
  }

  const districtExist = e => {
    setDistrictMessage("")
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/district-exist?district=${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDistrictMessage(res.data.data)
        } else {
          setDistrictMessage("")
        }
      })
  }

  const checkImportantDate = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/check-important-date-field`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem(
            "ShowImportant",
            res.data.data?.show_important_date
              ? res.data.data?.show_important_date
              : ""
          )
        } else {
          localStorage.setItem("ShowImportant", "")
        }
      })
  }

  const onNewsletterChange = checked => {
    if (checked) {
      getAllFields()
      setNewsletter(true)
      setBigModal(true)
    } else {
      setNewsletter(false)
      setBigModal(false)
    }
  }

  const setAllFieldValues = (e, id) => {
    if (e.target && e.target.type !== undefined) {
      if (e.target.type === "checkbox" && e.target.role === null) {
        if (e.target.checked === true) {
          let index = fieldCheckbox.findIndex(
            fieldCheckbox => fieldCheckbox.lead_field_id === id
          )
          if (index !== -1) {
            var oldValue = fieldCheckbox[index].value
            fieldCheckbox[index].value = oldValue
              ? oldValue + "," + e.target.value
              : e.target.value
          } else {
            fieldCheckbox.push({ lead_field_id: id, value: e.target.value })
          }
        } else {
          let index = fieldCheckbox.findIndex(
            fieldCheckbox => fieldCheckbox.lead_field_id === id
          )
          if (index !== -1) {
            var oldValue = fieldCheckbox[index].value
            oldValue = oldValue.split(",")
            let index2 = oldValue.indexOf(e.target.value)
            if (index2 !== -1) {
              oldValue.splice(index2, 1)
            }
            oldValue = oldValue.join()
            fieldCheckbox[index].value = oldValue
          }
        }
      } else if (e.target.type === "text") {
        let index = fieldText.findIndex(
          fieldText => fieldText.lead_field_id === id
        )
        if (index !== -1) {
          fieldText[index].value = e.target.value
        } else {
          fieldText.push({ lead_field_id: id, value: e.target.value })
        }
      } else if (e.target.type === "textarea") {
        let index = fieldTextArea.findIndex(
          fieldTextArea => fieldTextArea.lead_field_id === id
        )
        if (index !== -1) {
          fieldTextArea[index].value = e.target.value
        } else {
          fieldTextArea.push({ lead_field_id: id, value: e.target.value })
        }
      } else if (e.target.type === "radio") {
        let index = fieldRadio.findIndex(
          fieldRadio => fieldRadio.lead_field_id === id
        )
        if (index !== -1) {
          fieldRadio[index].value = e.target.value
        } else {
          fieldRadio.push({ lead_field_id: id, value: e.target.value })
        }
      }
      // else if (e.target.type === "file") {
      // 	fieldValues.push({lead_field_id: id, value: e.target.files[0]})
      // }
      else if (e.target.type === "select-one") {
        let index = fieldSelect.findIndex(
          fieldSelect => fieldSelect.lead_field_id === id
        )
        if (index !== -1) {
          fieldSelect[index].value = e.target.value
        } else {
          fieldSelect.push({ lead_field_id: id, value: e.target.value })
        }
      } else if (e.target.role === "switch") {
        let index = fieldFlipButton.findIndex(
          fieldFlipButton => fieldFlipButton.lead_field_id === id
        )
        if (index !== -1) {
          fieldFlipButton[index].value = e.target.checked ? "on" : "off"
        } else {
          fieldFlipButton.push({
            lead_field_id: id,
            value: e.target.checked ? "on" : "off",
          })
        }
      }
    } else {
    }
  }

  const options = []

  if (JSON.parse(localStorage.getItem("AllLabels"))) {
    JSON.parse(localStorage.getItem("AllLabels")).map(item => {
      options.push({
        value: item.id,
        label: item.name,
      })
    })
  }

  const dValues = []

  const handleSelectChange = selectedOptions => {
    const selectedIds = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setSelectedBranches(selectedIds)

    const filteredManagers =
      selectedIds.length > 0
        ? dashboardData?.branch_managers.filter(manager =>
            selectedIds.includes(manager.branch_id)
          )
        : []

    setFilteredLeadHolders(filteredManagers || []) // Use managers directly
  }

  const handleSelectChanges = e => {
    const selectedValue = parseInt(e.target.selectedOptions[0]?.value, 10)
    setSelectedBranches(selectedValue)
  }

  const getSelectedId = selectedItem => {
    selectedUserId = []
    selectedItem && selectedItem.map(item => selectedUserId.push(item.value))
  }

  const submitLead = (e, value) => {
    let flagValue = "0"
    let whatsapp = 0
    let sendEmail = 0
    if (e.target.flag.checked) {
      flagValue = "1"
    } else {
      flagValue = "0"
    }
    fieldCheckbox.map(item => {
      let index = fieldValues.findIndex(
        fieldValues => fieldValues.lead_field_id === item.lead_field_id
      )
      if (index !== -1) {
        fieldValues[index].value = item.value
      } else {
        fieldValues.push(item)
      }
    })
    fieldText.map(item => {
      let index = fieldValues.findIndex(
        fieldValues => fieldValues.lead_field_id === item.lead_field_id
      )
      if (index !== -1) {
        fieldValues[index].value = item.value
      } else {
        fieldValues.push(item)
      }
    })
    fieldTextArea.map(item => {
      let index = fieldValues.findIndex(
        fieldValues => fieldValues.lead_field_id === item.lead_field_id
      )
      if (index !== -1) {
        fieldValues[index].value = item.value
      } else {
        fieldValues.push(item)
      }
    })
    fieldRadio.map(item => {
      let index = fieldValues.findIndex(
        fieldValues => fieldValues.lead_field_id === item.lead_field_id
      )
      if (index !== -1) {
        fieldValues[index].value = item.value
      } else {
        fieldValues.push(item)
      }
    })
    fieldSelect.map(item => {
      let index = fieldValues.findIndex(
        fieldValues => fieldValues.lead_field_id === item.lead_field_id
      )
      if (index !== -1) {
        fieldValues[index].value = item.value
      } else {
        fieldValues.push(item)
      }
    })
    fieldFlipButton.map(item => {
      let index = fieldValues.findIndex(
        fieldValues => fieldValues.lead_field_id === item.lead_field_id
      )
      if (index !== -1) {
        fieldValues[index].value = item.value
      } else {
        fieldValues.push(item)
      }
    })
    let tagID = ""
    if (e.target.tag_id !== undefined) {
      tagID = e.target.tag_id.value
    }
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)

    if (props.lead !== undefined) {
      if (phoneNumber == "") {
        phoneNumber = props.lead && props.lead.number
      }
      if (altNumber == "") {
        altNumber = props.lead && props.lead.alternate_number
      }
      if (countryCode == "") {
        countryCode = props.lead?.country_code ? props.lead?.country_code : ""
      } else {
        if (countryCode?.includes("+")) {
        } else {
          countryCode = `+ ${countryCode}`
        }
      }
      if (altCountryCode == "") {
        altCountryCode = props.lead?.alternate_country_code
          ? props.lead?.alternate_country_code
          : ""
      } else {
        if (altCountryCode?.includes("+")) {
        } else {
          altCountryCode = `+ ${altCountryCode}`
        }
      }
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/lead/${props.lead.id}`,
          {
            name: e.target.name.value,
            email: e.target.email.value,
            firm_name: e.target.firm_name.value,
            number: phoneNumber,
            alternate_number: altNumber,
            alternate_country_code: altCountryCode,
            leadholder_id: e.target.leadholder_id.value,
            lead_source_id: e.target.lead_source_id.value,
            country_name: country,
            state: state,
            district: district,
            working_area: area,
            country_code: countryCode,
            dob: e.target.dob.value,
            best_time: e.target.best_time.value,
            anniversary: e.target.anniversary.value,
            important_date: e.target.important_date.value,
            city: e.target.city.value,
            pin_code: e.target.pin_code.value,
            monthly_target:
              e.target.monthly_target !== undefined
                ? e.target.monthly_target.value
                : "",
            tag_id: tagID,
            flag: flagValue,
            lead_label_id: selectedUserId,
            proforma_address: proformaValue,
            deliver_to: deliverTo,
            fieldvalues: fieldValues,
            branch_id: selectedBranches,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            fieldValues = []
            props.setIsOpen(false)
            props.setLead(res.data.data)
            props.setmodal_standard(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setDistrictMessage("")
            setEmailMessage("")
            setNumberMessage("")
            setAlternateNumber("")
            if (!localStorage.getItem("ShowImportant")) {
              checkImportantDate()
            }
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setDistrictMessage("")
            setEmailMessage("")
            setNumberMessage("")
            setAlternateNumber("")
          }
        })
    } else {
      if (e.target.whatsapp.checked) {
        whatsapp = 1
      } else {
        whatsapp = 0
      }
      if (e.target.send_email.checked) {
        sendEmail = 1
      } else {
        sendEmail = 0
      }
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/lead`,
          {
            // action: e.target.action.value,
            name: e.target.name.value,
            email: e.target.email.value,
            firm_name: e.target.firm_name.value,
            number: phoneNumber,
            alternate_number: altNumber,
            alternate_country_code: altCountryCode,
            type: "new",
            leadholder_id: e.target.leadholder_id.value,
            lead_source_id: e.target.lead_source_id.value,
            country_name: country,
            state: state,
            district: district,
            working_area: area,
            country_code: `+ ${countryCode}`,
            dob: e.target.dob.value,
            best_time: e.target.best_time.value,
            anniversary: e.target.anniversary.value,
            important_date: e.target.important_date.value,
            city: e.target.city.value,
            pin_code: e.target.pin_code.value,
            tag_id: tagID,
            flag: flagValue,
            whatsapp: whatsapp,
            send_email: sendEmail,
            message: e.target.message.value,
            lead_label_id: selectedUserId,
            proforma_address: proformaValue,
            deliver_to: deliverTo,
            fieldvalues: fieldValues,
            branch_id: selectedBranches,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            setLead(res.data.data)
            if (props.getAllLeads !== undefined) {
              if (props.local !== undefined) {
                props.getAllLeads(undefined, "refresh")
              } else {
                props.getAllLeads()
              }
            }
            // props.setmodal_standard(false)
            fieldValues = []
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setDistrictMessage("")
            setEmailMessage("")
            setNumberMessage("")
            setAlternateNumber("")
            if (!localStorage.getItem("ShowImportant")) {
              checkImportantDate()
            }
            setTimeout(() => {
              setModal(true)
            }, 1500)
          } else {
            setLoading(false)
            // props.setmodal_standard(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setDistrictMessage("")
            setEmailMessage("")
            setNumberMessage("")
            setAlternateNumber("")
          }
        })
    }
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_standard(false)
        setDistrictMessage("")
        setEmailMessage("")
        setNumberMessage("")
        setAlternateNumber("")
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    updatedState()
    getAllFields()
    fieldValues = []
    phoneNumber = ""
    countryCode = ""
    if (props.lead !== undefined) {
      const momentDate = moment(props.lead.dob, "DD-MM-YYYY")
      const annDate = moment(props.lead.anniversary, "DD-MM-YYYY")
      const impDate = moment(props.lead?.important_date, "DD-MM-YYYY")
      if (momentDate.isValid()) {
        setDobDate(momentDate.toDate())
      }
      if (annDate.isValid()) {
        setAnniDate(annDate.toDate())
      }
      if (impDate.isValid()) {
        setImportantDate(impDate.toDate())
      }
    }
    if (props.lead && props.lead.lead_label !== null) {
      selectedUserId = []
      props.lead &&
        props.lead.lead_label.map(item => {
          dValues.push({
            value: item.id,
            label: item.name,
          })
          selectedUserId.push(item.id)
        })
    } else {
      ;("")
    }
  }, [])

  return (
    <>
      {modal ? (
        <CreateFollowup
          modal={modal}
          setModal={setModal}
          lead={lead}
          customActiveTab={2}
          getAllLeads={props.getAllLeads}
          local={props.local}
          setmodal_standard={props.setmodal_standard}
        />
      ) : null}
{props.loader3 ? "" :       <Modal isOpen={props.modal_standard} centered={true} size="xl">
        <AvForm
          className="mt-0"
          id="myModalLabel"
          onSubmit={(e, v) => submitLead(e, v)}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.lead !== undefined ? "Edit lead" : "Create lead"}
            </h5>
            <button
              className="cross__btn"
              onClick={() => {
                props.setmodal_standard(false)
                setDistrictMessage(""),
                  setEmailMessage(""),
                  setNumberMessage(""),
                  setAlternateNumber("")
              }}
              aria-hidden="true"
              type="button"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {emailMessage ? (
              <p
                className="alert alert-message d-table text-center w-100"
                dangerouslySetInnerHTML={{ __html: emailMessage }}
              />
            ) : (
              ""
            )}
            {NumberMessage ? (
              <p
                className="alert alert-message d-table text-center w-100"
                dangerouslySetInnerHTML={{ __html: NumberMessage }}
              />
            ) : (
              ""
            )}
            {alternateNumber ? (
              <p
                className="alert alert-message d-table text-center w-100"
                dangerouslySetInnerHTML={{ __html: alternateNumber }}
              />
            ) : (
              ""
            )}
            {districtMessage ? (
              <p
                className="alert alert-message d-table text-center w-100"
                dangerouslySetInnerHTML={{ __html: districtMessage }}
              />
            ) : (
              ""
            )}
            <Row>
              <Col md={3}>
                <div className="mb-3 required">
                  <AvField
                    label="Lead Name"
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={props.lead && props.lead.name}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <AvField
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    onChange={e => getLeadEmail(e.target.value)}
                    value={props.lead && props.lead.email}
                    readOnly={
                      props.lead !== undefined &&
                      localStorage.getItem("company_name") === "defence" &&
                      getCurrentUser()?.role_name !== "Administrator"
                        ? true
                        : false
                    }
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3 required">
                  <label className="">Phone/Whatsapp Number</label>
                  <PhoneInput
                    country={sortName}
                    onChange={(s, d, f, g) => getphonee(s, d, f, g)}
                    placeholder="Enter number"
                    value={`${
                      props.lead && props.lead.country_code !== null
                        ? props.lead.country_code
                        : "91"
                    } ${props.lead && props.lead.number}`}
                    name="number"
                    inputStyle={{ width: "100%" }}
                    disabled={
                      props.lead !== undefined &&
                      localStorage.getItem("company_name") === "defence" &&
                      getCurrentUser()?.role_name !== "Administrator"
                        ? true
                        : false
                    }
                  />
                  {indiaOnly ? (
                    <p className="text-normal fst-italic">
                      (Only 10 digits required)
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <label className="">Alternate Number</label>
                  <PhoneInput
                    country={altsortName}
                    onChange={(s, d, f, g) => getalterphonee(s, d, f, g)}
                    placeholder="Enter number"
                    value={`${
                      props.lead && props.lead.alternate_country_code !== null
                        ? props.lead.alternate_country_code
                        : "91"
                    } ${props.lead && props.lead.alternate_number}`}
                    name="alternate_number"
                    inputStyle={{ width: "100%" }}
                    onFocus={e =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault()
                        },
                        { passive: false }
                      )
                    }
                    disabled={
                      props.lead !== undefined &&
                      localStorage.getItem("company_name") === "defence" &&
                      getCurrentUser()?.role_name !== "Administrator"
                        ? true
                        : false
                    }
                  />
                  {indiaOnlyalt ? (
                    <p className="text-normal fst-italic">
                      (Only 10 digits required)
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <label>Country</label>
                  <Select
                    id="country"
                    name="country"
                    placeholder="Select..."
                    options={updatedCountries}
                    defaultValue={{ label: "India", value: "India" }}
                    onChange={value => {
                      updatedState(value.id),
                        dialCode(value.sortname),
                        setCountry(value.name),
                        setValues(
                          { country: value, state: null, city: null },
                          false
                        )
                    }}
                  />
                </div>
              </Col>
              <Col md={3}>
                {loader1 ? (
                  <div className="mb-3 required">
                    <label>State</label> <br />
                    <Spinner />
                  </div>
                ) : (
                  <div className="mb-3">
                    <label>State</label>
                    <Select
                      id="state"
                      name="state"
                      placeholder="Select..."
                      options={updatedStates}
                      defaultValue={{ label: state }}
                      onChange={value => {
                        setState(value.name), setFieldValue("state", value)
                      }}
                    />
                  </div>
                )}
              </Col>
              <Col md={3}>
                {loader1 ? (
                  <div className="mb-3">
                    <label>District</label> <br />
                    <Spinner />
                  </div>
                ) : (
                  <div className="mb-3">
                    <label>District</label>
                    <Select
                      id="city"
                      name="district"
                      placeholder="Select..."
                      options={updatedCities(
                        values.state ? values.state.value : null
                      )}
                      defaultValue={{ label: district }}
                      onChange={value => {
                        setDistrict(value.name),
                          districtExist(value.name),
                          setFieldValue("city", value)
                      }}
                    />
                  </div>
                )}
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <AvField
                    label="Pin Code"
                    type="number"
                    onFocus={e =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault()
                        },
                        { passive: false }
                      )
                    }
                    max="999999"
                    name="pin_code"
                    placeholder="Enter pin code"
                    onChange={e => getAllStates(e.target.value)}
                    value={props.lead && props.lead.pincode}
                  />
                  {message ? (
                    <p className="text-danger fst-italic">
                      Pin code Incorrect*
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <AvField
                    label="City"
                    type="text"
                    name="city"
                    placeholder="Enter city"
                    value={props.lead && props.lead.city}
                  />
                </div>
              </Col>
              {props.lead !== undefined &&
              props.lead?.type.includes("matured") ? (
                <Col md={3}>
                  <div className="mb-3">
                    <label>Working Area</label>
                    <AvField
                      type="text"
                      name="working_area"
                      value={props.lead && props.lead.working_area}
                      // defaultValue={{ label: district }}
                      onChange={e => setArea(e.target.value)}
                    />
                  </div>
                </Col>
              ) : (
                ""
              )}
              <Col md={3}>
                <div className="mb-3">
                  <AvField
                    label="Firm name"
                    type="text"
                    name="firm_name"
                    placeholder="Enter firm name"
                    value={props.lead && props.lead.firm_name}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <AvField
                    label="Best Time to Talk"
                    type="time"
                    name="best_time"
                    value={props.lead?.best_time}
                  />
                </div>
              </Col>
              {getCurrentUser().enable_branch === 1 ? (
                props.lead?.id !== undefined ? (
                  <Col md={3}>
                    <div className="mb-3">
                      <label htmlFor="branch-select" className="form-label">
                        Branch
                      </label>
                      <AvField
                        type="select"
                        name="branch"
                        id="branch-select"
                        onChange={handleSelectChanges}
                      >
                        <option>Select Branch</option>
                        {branches.map((branch, index) => (
                          <option key={index} value={branch.value}>
                            {branch.label}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                ) : (
                  <Col md={3}>
                    <div className="mb-3 required">
                      <label htmlFor="branch-select" className="form-label">
                        Branch
                      </label>
                      <Select
                        id="branch-select"
                        isMulti
                        options={branches} // Options to display
                        value={branches.filter(branch =>
                          selectedBranches.includes(branch.value)
                        )} // Filter to get selected options
                        onChange={handleSelectChange} // Handle selection
                        placeholder="Select Branch"
                        className="select-arrow"
                      />
                    </div>
                  </Col>
                )
              ) : (
                ""
              )}
              <Col md={3}>
                {getCurrentUser() &&
                getCurrentUser().role_name === "Executive" ? (
                  <div className="">
                    <AvField
                      className="select-arrow d-none"
                      type="select"
                      name="leadholder_id"
                      value={getCurrentUser().id}
                    >
                      <option value="" hidden>
                        Select Leadholder
                      </option>
                      {JSON.parse(localStorage.getItem("AllUsers"))?.map(
                        (option, i) => (
                          <option value={option.id} key={i}>
                            {option.name}
                          </option>
                        )
                      )}
                    </AvField>
                  </div>
                ) : getCurrentUser() &&
                  getCurrentUser().role_name === "Team Leader" ? (
                  <div className="mb-3 required">
                    <AvField
                      className="select-arrow"
                      label="Lead Holder"
                      type="select"
                      name="leadholder_id"
                      value={props.lead?.leadholder_id || ""}
                      required
                    >
                      <option value="" hidden>
                        Select Leadholder
                      </option>
                      {JSON.parse(localStorage.getItem("AllUsers"))?.map(
                        (option, i) => (
                          <option value={option.id} key={i}>
                            {option.name}
                          </option>
                        )
                      )}
                    </AvField>
                  </div>
                ) : (
                  <div className="mb-3 required">
                    <AvField
                      className="select-arrow"
                      label="Lead Holder"
                      type="select"
                      name="leadholder_id"
                      value={props.lead?.leadholder_id || ""}
                      required
                    >
                      <option value="" hidden>
                        Select Leadholder
                      </option>
                      {selectedBranches.length > 0
                        ? filteredLeadHolders.map((manager, i) => (
                            <option value={manager.id} key={i}>
                              {manager.name}
                            </option>
                          ))
                        : JSON.parse(localStorage.getItem("AllUsers"))?.map(
                            (option, i) => (
                              <option value={option.id} key={i}>
                                {option.name}
                              </option>
                            )
                          )}
                    </AvField>
                  </div>
                )}
              </Col>

              <Col md={3}>
                <div className="mb-3 required2">
                  <AvField
                    className="select-arrow"
                    label="Lead Source"
                    type="select"
                    name="lead_source_id"
                    value={
                      props.lead && props.lead.lead_source_id !== undefined
                        ? props.lead.lead_source_id
                        : 0
                    }
                  >
                    <option value="" hidden>
                      Select Lead Source
                    </option>
                    {JSON.parse(localStorage.getItem("AllSource")) &&
                      JSON.parse(localStorage.getItem("AllSource")).map(
                        (option, i) => (
                          <option value={option.id} key={i}>
                            {option.name}
                          </option>
                        )
                      )}
                  </AvField>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3 required2">
                  <label>Lead Label</label>
                  <Select
                    isMulti
                    isClearable="true"
                    name="lead_label_id"
                    options={options}
                    defaultValue={dValues}
                    onChange={e => getSelectedId(e)}
                    isDisabled={
                      props.lead !== undefined &&
                      props.lead?.type.includes("matured") &&
                      getCurrentUser()?.role_name !== "Administrator"
                        ? true
                        : false
                    }
                  />
                </div>
              </Col>
              {getCurrentUser() && getCurrentUser().tag == 1 ? (
                <Col md={3}>
                  <div className="mb-3 required2">
                    <AvField
                      className="select-arrow"
                      label="Lead Tags"
                      type="select"
                      name="tag_id"
                      value={props.lead && props.lead.tag_id}
                    >
                      <option value="" hidden>
                        Select Tag
                      </option>
                      {JSON.parse(localStorage.getItem("AllTags")) &&
                        JSON.parse(localStorage.getItem("AllTags")).map(
                          (option, i) => (
                            <option value={option.id} key={i}>
                              {option.name}
                            </option>
                          )
                        )}
                    </AvField>
                  </div>
                </Col>
              ) : (
                ""
              )}
              <Col md={3}>
                <div className="mb-3">
                  <label className="form-group" htmlFor="dob">
                    DOB
                  </label>
                  <DatePicker
                    className="w-100 form-control"
                    dateFormat="yyyy-MM-dd"
                    id="dob"
                    name="dob"
                    selected={dobDate}
                    onChange={date => setDobDate(date)}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <label className="form-group" htmlFor="anniversary">
                    Anniversary
                  </label>
                  <DatePicker
                    className="w-100 form-control"
                    dateFormat="yyyy-MM-dd"
                    id="anniversary"
                    name="anniversary"
                    selected={anniDate}
                    onChange={date => setAnniDate(date)}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <label className="form-group" htmlFor="important_date">
                    Important Date
                  </label>
                  <DatePicker
                    className="w-100 form-control"
                    dateFormat="yyyy-MM-dd"
                    id="important_date"
                    name="important_date"
                    selected={importantDate}
                    onChange={date => setImportantDate(date)}
                  />
                </div>
              </Col>
              {getCurrentUser()?.crmpro_permission === 1 ||
              getCurrentUser()?.crmpronew_permission === 1 ? (
                <>
                  <Col md={3}>
                    <div className="mb-3">
                      <label>Proforma Invoice(bill to)</label>
                      <ReactQuill
                        theme="snow"
                        value={proformaValue}
                        onChange={e => setProformaValue(e)}
                        readOnly={
                          props.lead !== undefined &&
                          props.lead?.type.includes("matured") &&
                          getCurrentUser()?.role_name !== "Administrator"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <label>Shipping address</label>
                      <ReactQuill
                        theme="snow"
                        value={deliverTo}
                        onChange={e => setDeliverTo(e)}
                        readOnly={
                          props.lead !== undefined &&
                          props.lead?.type.includes("matured") &&
                          getCurrentUser()?.role_name !== "Administrator"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
              {/* {loader1 ?
                           <div className="mb-3">
                              <label>Working Area</label> <br />
                              <Spinner />
                           </div>
                           :
                           <div className="mb-3">
                              <label>Working Area</label>
                              <Select
                                 id="city"
                                 name="working_area"
                                 placeholder="Select..."
                                 options={updatedCities(values.state ? values.state.value : null)}
                                 defaultValue={{ label: district }}
                                 onChange={(value) => setArea(value.name)
                                 }
                              />
                           </div>
                        } */}
              {props.lead !== undefined ? (
                ""
              ) : (
                <>
                  {/* <div className="mb-3">
                                 <AvField
                                    // className="select-arrow"
                                    disabled
                                    type="select"
                                    name="type"
                                    label="Lead Type"
                                    value={props.lead && props.lead.type}
                                 >
                                    {/* <option value="" hidden> */}
                  {/* 	Select Type */}
                  {/* </option> */}
                  {/* <option value="new">New</option> */}
                  {/* <option value="followup">Followup</option> */}
                  {/* <option value="matured">Mature</option> */}
                  {/* <option value="dead_request">Dead Request */}
                  {/* </option> */}
                  {/* <option value="dead">Dead</option> */}

                  {/* </AvField> */}
                  {/* </div>  */}
                  <Col md={3}>
                    <div className="mb-3">
                      <AvField
                        label="Notes"
                        type="textarea"
                        name="message"
                        placeholder="Enter notes"
                        value={"New lead added."}
                      />
                    </div>
                  </Col>
                </>
              )}
              {
                props.lead !== undefined ? "" : ""
                // <div className="mb-3">
                //    <label>Action</label>
                //    <AvRadioGroup inline name="action" defaultValue="update" required>
                //       <AvRadio label="Call" value="call" />
                //       <AvRadio label="Meeting" value="meeting" />
                //       <AvRadio label="Update" value="update" />
                //    </AvRadioGroup>
                // </div>
              }
              {props.lead !== undefined &&
              props.lead?.type.includes("matured") ? (
                <Col md={3}>
                  <div className="mb-3">
                    <AvField
                      label="Monthly Target"
                      type="number"
                      onFocus={e =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault()
                          },
                          { passive: false }
                        )
                      }
                      name="monthly_target"
                      value={props.lead && props.lead.monthly_target}
                    />
                  </div>
                </Col>
              ) : (
                ""
              )}
              <Col md={3}>
                <div className="mb-3">
                  <label>Star</label>
                  <AvField
                    // label="Star"
                    checked={props.lead?.flag == "1" ? true : false}
                    type="checkbox"
                    name="flag"
                  />
                </div>
              </Col>
              {props.lead !== undefined ? (
                ""
              ) : (
                <>
                  <Col md={3}>
                    <div className="mb-3">
                      <label>Send Email</label>
                      <AvField
                        // label="Send Email"
                        type="checkbox"
                        name="send_email"
                        style={{
                          background: `${
                            getCurrentUser()?.email_enabled === 1
                              ? ""
                              : "lightgray"
                          }`,
                        }}
                        disabled={
                          getCurrentUser()?.email_enabled === 1 ? false : true
                        }
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <label>Send Whatsapp</label>
                      <AvField
                        // label="Send Whatsapp"
                        type="checkbox"
                        name="whatsapp"
                        style={{
                          background: `${
                            getCurrentUser()?.whatsapp_enabled === 1
                              ? ""
                              : "lightgray"
                          }`,
                        }}
                        disabled={
                          getCurrentUser()?.whatsapp_enabled === 1
                            ? false
                            : true
                        }
                      />
                    </div>
                  </Col>
                </>
              )}
              {allFields.length > 0 ? (
                <>
                  {allFields &&
                    allFields.map((item, i) => (
                      <Col md={3} className="mb-3" key={i}>
                        {item.type === "Input" ? (
                          <div
                            className={`${
                              item.is_required === 1 ? "required" : ""
                            }`}
                          >
                            <AvField
                              label={item.label}
                              type="text"
                              name={"fieldvalues-" + item.id}
                              placeholder="Enter"
                              onChange={e => setAllFieldValues(e, item.id)}
                              value={item.value}
                              required={item.is_required === 1 ? true : false}
                              // readOnly={props.lead !== undefined && localStorage.getItem("company_name") === "defence" && getCurrentUser()?.role_name !== "Administrator" ? true : false}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {item.type === "Dropdown" ? (
                          <div
                            className={`${
                              item.is_required === 1 ? "required" : ""
                            }`}
                          >
                            <AvField
                              className="select-arrow"
                              label={item.label}
                              type="select"
                              name={"fieldvalues-" + item.id}
                              onChange={e => setAllFieldValues(e, item.id)}
                              required={item.is_required === 1 ? true : false}
                              value={item.value}
                              // readOnly={props.lead !== undefined && localStorage.getItem("company_name") === "defence" && getCurrentUser()?.role_name !== "Administrator" ? true : false}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {item.fieldvalues.map((option, i) => (
                                <option value={option.id} key={i}>
                                  {option.value}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        ) : (
                          ""
                        )}
                        {item.type === "Radio" ? (
                          <>
                            <div
                              className={`${
                                item.is_required === 1 ? "required" : ""
                              }`}
                            >
                              <label>{item.label}</label>
                            </div>
                            <AvRadioGroup
                              inline
                              onClick={e => setAllFieldValues(e, item.id)}
                              name={"fieldvalues-" + item.id}
                              defaultValue={item.value}
                              required={item.is_required === 1 ? true : false}
                            >
                              {item.fieldvalues.map((radio, i) => (
                                <AvRadio
                                  key={i}
                                  label={radio.value}
                                  value={radio.id.toString()}
                                  // disabled={props.lead !== undefined && localStorage.getItem("company_name") === "defence" && getCurrentUser()?.role_name !== "Administrator" ? true : false}
                                />
                              ))}
                            </AvRadioGroup>
                          </>
                        ) : (
                          ""
                        )}
                        {item.type === "Checkbox" ? (
                          <>
                            <label>{item.label}</label>
                            <AvCheckboxGroup
                              inline
                              onClick={e => setAllFieldValues(e, item.id)}
                              name={"fieldvalues-" + item.id}
                              value={
                                item.value !== null ? item.value.split(",") : []
                              }
                            >
                              {item.fieldvalues.map((check, i) => (
                                <AvCheckbox
                                  key={i}
                                  label={check.value}
                                  value={check.id.toString()}
                                  // disabled={props.lead !== undefined && localStorage.getItem("company_name") === "defence" && getCurrentUser()?.role_name !== "Administrator" ? true : false}
                                />
                              ))}
                            </AvCheckboxGroup>
                          </>
                        ) : (
                          ""
                        )}
                        {item.type === "Text Area" ? (
                          <div
                            className={`${
                              item.is_required === 1 ? "required" : ""
                            }`}
                          >
                            <AvField
                              label={item.label}
                              type="textarea"
                              name={"fieldvalues-" + item.id}
                              placeholder="Enter"
                              value={item.value}
                              onChange={e => setAllFieldValues(e, item.id)}
                              required={item.is_required === 1 ? true : false}
                              // readOnly={props.lead !== undefined && localStorage.getItem("company_name") === "defence" && getCurrentUser()?.role_name !== "Administrator" ? true : false}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {item.type === "Flip Button" ? (
                          <>
                            <div className="mb-3">
                              <label>
                                <span>{item.label}</span>
                                <br />
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    name={"fieldvalues-" + item.id}
                                    onChange={e =>
                                      setAllFieldValues(e, item.id)
                                    }
                                    defaultChecked={
                                      item.value == "on" ? true : false
                                    }
                                    // readOnly={props.lead !== undefined && localStorage.getItem("company_name") === "defence" && getCurrentUser()?.role_name !== "Administrator" ? true : false}
                                  />
                                </div>
                                {/* <Switch
                                                   className="mt-2"
                                                   name="flip_button"
                                                   onChange={(e) => { setAllFieldValues(e, item.id), setFlip_button(!flip_button) }}
                                                   checked={flip_button}
                                                /> */}
                              </label>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    ))}
                </>
              ) : (
                ""
              )}
            </Row>
          </div>
          <div className="modal-footer">
            <div className="text-end">
              {loading ? (
                <button
                  className="btn btn-primary disabled"
                  type="button"
                  disabled
                >
                  Loading
                </button>
              ) : (
                <>
                  {props.lead !== undefined ? (
                    <>
                      {duplicate2 == 0 && duplicate3 == 0 ? (
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled
                        >
                          Submit
                        </button>
                      ) : (
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {duplicate2 == 1 && duplicate3 == 1 ? (
                        <button className="btn btn-primary" type="submit">
                          Create
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled
                        >
                          Create
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>}
    </>
  )
}
