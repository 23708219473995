import React, { useState, useEffect } from "react"
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import axios from "axios"
import classnames from "classnames"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import CproformaList from "./CproformaList"
import CproformaModal from "./CproformaModal"
import ServiceLineProforma from "./serviceLineProforma"

const CproformaTable = props => {
  const [modal_proforma, setmodal_proforma] = useState(false)
  const [service_proforma, setservice_proforma] = useState(false)
  const [proforma, setProforma] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [customActiveTab, setCustomActiveTab] = useState(3)
  const [orderType, setOrderType] = useState("proforma,proforma_service")

  const getAllProformas = e => {
    setLoader(<Loader />)
    setProforma([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/order?type=${e}&lead_id=${props.leadId}&per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setProforma(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setProforma([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(`${e}&type=${orderType}&lead_id=${props.leadId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setProforma(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
    if (tab == 3) {
      getAllProformas("proforma,proforma_service")
      setOrderType("proforma,proforma_service")
    }
    if (tab == 1) {
      getAllProformas("proforma")
      setOrderType("proforma")
    }
    if (tab == 2) {
      getAllProformas("proforma_service")
      setOrderType("proforma_service")
    }
  }

  useEffect(() => {
    getAllProformas("proforma,proforma_service")
  }, [])

  return (
    <>
      {modal_proforma ? (
        <CproformaModal
          modal_proforma={modal_proforma}
          id={props.leadId}
          setmodal_proforma={setmodal_proforma}
          getAllProformas={getAllProformas}
        />
      ) : (
        ""
      )}
      {service_proforma ? (
        <ServiceLineProforma
          service_proforma={service_proforma}
          id={props.leadId}
          setservice_proforma={setservice_proforma}
          getAllProformas={getAllProformas}
        />
      ) : (
        ""
      )}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={3}>
            <h6 className="page-title">
              {getCurrentUser()?.crm_proforma_title
                ? getCurrentUser()?.crm_proforma_title
                : "Proformas"}
            </h6>
          </Col>
          <Col md={9}>
            <h6 className="page-title float-end">
              <span className="text-white fs-6 bg-danger me-2">
                {getCurrentUser()?.crm_proforma_title
                  ? getCurrentUser()?.crm_proforma_title
                  : "Proforma"}{" "}
                will be automatically deleted after 30 days of creation.
              </span>
              {getCurrentUser().crmpro_permission == 1 ||
              getCurrentUser().crmpronew_permission == 1 ? (
                getPermission().proforma.proforma.create.is_checked ===
                "yes" ? (
                  <>
                    {getCurrentUser().crmpro_services_enabled == 1 ? (
                      <button
                        className="btn btn-primary submit__button me-2"
                        type="button"
                        onClick={() => {
                          setservice_proforma(true)
                        }}
                      >
                        Add Service Line{" "}
                        {getCurrentUser()?.crm_proforma_title
                          ? getCurrentUser()?.crm_proforma_title
                          : "Proforma"}
                      </button>
                    ) : (
                      ""
                    )}
                    {getCurrentUser().crmpro_products_enabled == 1 ? (
                      <button
                        className="btn btn-primary submit__button"
                        type="button"
                        onClick={() => {
                          setmodal_proforma(true)
                        }}
                      >
                        Add Product List New
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 3 })}
                  onClick={() => {
                    toggleCustom(3)
                  }}
                >
                  {" "}
                  All{" "}
                  {getCurrentUser()?.crm_proforma_title
                    ? getCurrentUser()?.crm_proforma_title
                    : "Proformas"}
                </NavLink>
              </NavItem>
              {getCurrentUser().crmpro_products_enabled == 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === 1 })}
                    onClick={() => {
                      toggleCustom(1)
                    }}
                  >
                    {" "}
                    Product List{" "}
                    {getCurrentUser()?.crm_proforma_title
                      ? getCurrentUser()?.crm_proforma_title
                      : "Proformas"}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {getCurrentUser().crmpro_services_enabled == 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === 2 })}
                    onClick={() => {
                      toggleCustom(2)
                    }}
                  >
                    {" "}
                    Service Line{" "}
                    {getCurrentUser()?.crm_proforma_title
                      ? getCurrentUser()?.crm_proforma_title
                      : "Proformas"}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
            </Nav>
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>Proforma ID</th>
                  <th>Payment Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Payment Status</th>
                  <th>Invoice</th>
                </tr>
                {proforma &&
                  proforma.map((item, obj) => (
                    <CproformaList
                      key={obj}
                      data={item}
                      i={obj}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllProformas}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default CproformaTable
