import React, { useState } from "react"
import axios from "axios"
import { Row, Col, Card, CardBody } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser, setCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const WhatsappApi = () => {
  const [platform, setPlatform] = useState(
    getCurrentUser().whatsapp_platform
      ? getCurrentUser().whatsapp_platform
      : "webjaadu"
  )
  const [loading, setLoading] = useState(false)

  const whatsappToken = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    let whatsToken = ""
    if (platform === "chatway") {
      whatsToken = value.whatsapp_credit_balance_token
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/whatsapp-token`,
        {
          whatsapp_platform: "webjaddu",
          whatsapp_username: value.whatsapp_username,
          whatsapp_token: value.whatsapp_token,
          whatsapp_credit_balance_token: whatsToken,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoading(false)
          setCurrentUser(res.data.data)
          if (platform === "chatway") {
            getWhatsAppCredits(value.whatsapp_username, value.whatsapp_token)
          } else {
            toast.success(
              "Whatsapp Integration details successfully submitted.",
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            )
            window.history.go(-1)
          }
        } else {
          setLoading(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const getWhatsAppCredits = (e, f) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/get-whatsapp-api-response?whatsapp_username=${e}&whatsapp_token=${f}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.data.status !== "error") {
          toast.success(
            "Whatsapp Integration details successfully submitted.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
          window.history.go(-1)
        } else {
          toast.error("Invalid token!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  return (
    <>
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h4 className="page-title">Connect Whatsapp</h4>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end"></h6>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm
                className="card-title mt-0"
                onValidSubmit={(e, v) => whatsappToken(e, v)}
              >
                <h4 className="card-title mb-4">Whatsapp Token Details</h4>
                <hr />
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <AvField
                        className="select-arrow"
                        label="Select Platform"
                        type="select"
                        name="whatsapp_platform"
                        defaultValue={platform}
                        onChange={e => setPlatform(e.target.value)}
                      >
                        {/* <option value="chatway">Chatway</option> */}
                        <option value="webjaddu">waKonnect</option>
                      </AvField>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <AvField
                        label="Instance ID"
                        type="text"
                        name="whatsapp_username"
                        placeholder="Enter your username"
                        defaultValue={getCurrentUser()?.whatsapp_username}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <AvField
                        label="Token"
                        type="text"
                        name="whatsapp_token"
                        placeholder="Enter your token"
                        defaultValue={getCurrentUser()?.whatsapp_token}
                      />
                    </div>
                  </Col>
                  {/* {platform === "webjaddu" ? (
                    ""
                  ) : (
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          label="Whatsapp Credit Balance Token"
                          type="text"
                          name="whatsapp_credit_balance_token"
                          placeholder="Enter your token"
                          defaultValue={
                            getCurrentUser()?.whatsapp_credit_balance_token
                          }
                        />
                      </div>
                    </Col>
                  )} */}
                  <hr />
                </Row>
                <div className="text-center">
                  {loading ? (
                    <button
                      className="btn btn-primary disabled"
                      type="button"
                      disabled
                    >
                      Loading
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  )}
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default WhatsappApi
