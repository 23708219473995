import React, { useState } from "react"
import { getCurrentUser } from "../../../helpers/Utils"
import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const CproductList = props => {
  const [product, setProduct] = useState(props.data)
  const [productValues, setProductValues] = useState(Object.values(props.data))
  const [timer, setTimer] = useState(null)

  const getDP = e => {
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      updateDP(e)
    }, 1000)
    setTimer(newTimer)
  }

  const updateDP = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/update-dealer-price`,
        {
          product_id: product.id,
          dp: e,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          props.getAllProduct()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  if (product === "") {
    return ""
  } else
    return (
      <>
        <tr key={props.i}>
          <td>{props.counter + props.i}</td>
          {productValues?.map((item, i) => (
            <td key={i}>
              <p
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
              />
            </td>
          ))}
          <td>
            <input
              type="text"
              defaultValue={product.selling_price}
              style={{ width: "100px" }}
              onChange={e => getDP(e.target.value)}
              // disabled
            />
          </td>
        </tr>
      </>
    )
}
export default CproductList
