import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import bg from "../../assets/images/bg.jpg"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { AvForm, AvField } from "availity-reactstrap-validation"
import axios from "axios"
import logoSm from "../../assets/images/klikcrm-logo.png"
import { setCurrentUser, setPermission } from "../../helpers/Utils"
import { useHistory } from "react-router-dom"

let subDomain

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [login, setLogin] = useState(false)
  const history = useHistory()
  const [companyName, setCompanyName] = useState("")
  const [otpToken, setOtpToken] = useState("")
  const [isMounted, setIsMounted] = useState(true)
  const [otp, setOtp] = useState(false)
  const [seconds, setSeconds] = useState("")
  const [isActive, setIsActive] = useState(false)
  const [passField1, setPassField1] = useState(false)
  const toggle = () => {
    setPassField1(!passField1)
  }

  useEffect(() => {
    subDomain = window.location.host.split(".")[0]
    if (subDomain !== "login") {
      setCompanyName(subDomain)
      setLogin(false)
    } else {
      setLogin(true)
    }
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    localStorage.clear()
  }, [])

  const handleValidSubmit = async (event, value) => {
    setOtp(false)
    let loginCompany = "0"
    if (login) {
      loginCompany = "1"
    }
    localStorage.setItem("company_name", companyName)
    if (companyName !== "") {
      setLoading(true)
      axios
        .post(`${process.env.REACT_APP_API_URL}/${companyName}/auth/login`, {
          email: value.email,
          password: value.password,
          company_type: loginCompany,
        })
        .then(res => {
          if (isMounted) {
            if (res.data.status) {
              if (res.data?.required_otp) {
                setOtp(true)
                setLoading(false)
                setOtpToken(res.data?.token)
                handleStartTimer(40)
              } else {
                if (login) {
                  window.location.replace(
                    `http://${companyName}.klikcrm.com?token=${res.data.data.login_token}`
                  )
                  // setCurrentUser(res.data.data)
                  // setPermission(res.data.data.permissions)
                } else {
                  setCurrentUser(res.data.data)
                  setPermission(res.data.data.permissions)
                  localStorage.setItem(
                    "ShowImportant",
                    res.data.data?.show_important_date
                      ? res.data.data?.show_important_date
                      : ""
                  )
                  if (res.data.data.role_name === "Administrator") {
                    setTimeout(() => {
                      window.location.href = "/dashboard"
                    }, 500)
                  } else if (res.data.data.role_name === "Accountant") {
                    setTimeout(() => {
                      history.push("/accounts")
                    }, 500)
                  } else {
                    setTimeout(() => {
                      window.location.href = "/followups-today"
                    }, 500)
                  }
                  toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                  setLoading(false)
                }
              }
            } else {
              setLoading(false)
              toast
                .error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
                .catch(err => {
                  // console.log("err", err)
                })
            }
          }
        })
        .catch(err => {
          // console.log("err", err)
        })
    } else {
      toast.error("Enter a valid domain name", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const handleOtpSubmit = async (event, value) => {
    setLoading(true)
    let loginCompany = "0"
    if (login) {
      loginCompany = "1"
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${companyName}/verify-otp`,
        {
          otp: value.otp,
          company_type: loginCompany,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${otpToken}`,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          if (login) {
            window.location.replace(
              `http://${companyName}.klikcrm.com?token=${res.data.data.login_token}`
            )
          } else {
            setCurrentUser(res.data.data)
            setPermission(res.data.data.permissions)
            localStorage.setItem(
              "ShowImportant",
              res.data.data?.show_important_date
                ? res.data.data?.show_important_date
                : ""
            )
            if (res.data.data.role_name === "Administrator") {
              setTimeout(() => {
                history.push("/dashboard")
              }, 500)
            } else if (res.data.data.role_name === "Accountant") {
              setTimeout(() => {
                history.push("/accounts")
              }, 500)
            } else {
              setTimeout(() => {
                history.push("/followups-today")
              }, 500)
            }
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setLoading(false)
          }
        } else {
          setLoading(false)
          toast
            .error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            .catch(err => {
              // console.log("err", err)
            })
        }
      })
      .catch(err => {
        // console.log("err", err)
      })
  }

  const getOtp = () => {
    if (seconds) return null
    axios
      .get(`${process.env.REACT_APP_API_URL}/${companyName}/resend-otp`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${otpToken}`,
        },
      })
      .then(res => {
        if (res.data.status) {
          handleStartTimer(30)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast
            .error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            .catch(err => {
              // console.log("err", err)
            })
        }
      })
      .catch(err => {
        // console.log("err", err)
      })
  }

  const handleStartTimer = e => {
    setIsActive(true)
    setSeconds(e)
  }

  useEffect(() => {
    let interval

    if (isActive) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds === 0) {
            setIsActive(false)
            return
          } else {
            return prevSeconds - 1
          }
        })
      }, 1000)
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [isActive])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | KlikCRM</title>
      </MetaTags>
      <div
        className="accountbg"
        style={{
          background: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={10} xl={8}>
              <Row className="justify-content-center m-0">
                <Col md={6} className="p-0">
                  <Card className="overflow-hidden rounded-0 h-100 bg-secondary">
                    <div className="promotional-wrapper h-100">
                      <div className="text-center mt-2">
                        <img src={logoSm} height="42" alt="logo" />
                      </div>
                      <h2 className="heading">Why You will Love KlikCRM!</h2>
                      <ul className="advantages">
                        <li>
                          <div>
                            <i className="fa fa-star"></i>
                          </div>
                          <div>
                            <h2>Easy To Use</h2>
                            <p>
                              KlikCRM is easy to use, even for CRM beginners.
                              Just sign up and get going!
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <i className="fa fa-user-tie"></i>
                          </div>
                          <div>
                            <h2>Expert Help</h2>
                            <p>
                              KlikCRM comes with support that will set you up,
                              help you make the most of the platform and address
                              any issues you may have- all for free!
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <i className="fas fa-shield-alt"></i>
                          </div>
                          <div>
                            <h2>Secure Technology</h2>
                            <p>
                              KlikCRM is built on a solid technology stack, so
                              your data is in good hands - safe, available at
                              all times and almost impossible to steal.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Card>
                </Col>
                <Col md={6} className="p-0">
                  <Card className="overflow-hidden rounded-0 h-100">
                    <div className="text-center mt-4">
                      <h2 className="font-size-38 mt-4 text-center">
                        Welcome Back !
                      </h2>
                      <p className="font-size-14 text-muted text-center">
                        {otp
                          ? "Enter OTP from your email."
                          : "Sign in to continue to KlikCRM."}
                      </p>
                    </div>
                    <CardBody className="p-4">
                      <div className="p-3">
                        {otp ? (
                          <AvForm
                            className="form-horizontal mt-2"
                            onValidSubmit={(e, v) => {
                              handleOtpSubmit(e, v)
                            }}
                          >
                            <div className="mb-3">
                              <AvField
                                name="otp"
                                label="OTP"
                                className="form-control"
                                placeholder="Enter OTP"
                                type="number"
                                required
                                autoCompletes="one-time-code"
                              />
                            </div>
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="col-12 mt-4">
                                  <span
                                    className={`${seconds ? "" : "text-info"}`}
                                    style={{
                                      cursor: `${
                                        seconds ? "not-allowed" : "pointer"
                                      }`,
                                    }}
                                    onClick={() => getOtp()}
                                  >
                                    <i className="fa" aria-hidden="true">
                                      &#xf021;
                                    </i>{" "}
                                    Resend OTP {seconds ? `in ${seconds}` : ""}
                                  </span>
                                </div>
                              </Col>
                              <Col sm={6} className="text-end">
                                {loading ? (
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="button"
                                    disabled
                                  >
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                              </Col>
                            </Row>
                          </AvForm>
                        ) : (
                          <AvForm
                            className="form-horizontal mt-2"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(e, v)
                            }}
                          >
                            {subDomain == "login" ? (
                              <div className="mb-3">
                                <label>Domain Name</label>
                                <div className="input-group mb-3">
                                  <input
                                    onChange={e =>
                                      setCompanyName(e.target.value.trim())
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Domain Name"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    required
                                  />
                                  <span
                                    className="input-group-text"
                                    id="basic-addon2"
                                  >
                                    .klikcrm.com
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                required
                                autoComplete="email"
                              />
                            </div>

                            <div className="mb-3">
                              <AvField
                                name="password"
                                label="Password"
                                type={passField1 ? "text" : "password"}
                                required
                                placeholder="Enter Password"
                                autoComplete="current-password"
                              />
                              {passField1 ? (
                                <span
                                  className="float-end"
                                  style={{
                                    marginTop: "-27px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <i
                                    className="fa fa-eye-slash"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => toggle()}
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="float-end"
                                  style={{
                                    marginTop: "-27px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <i
                                    className="fa fa-eye"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => toggle()}
                                  ></i>
                                </span>
                              )}
                            </div>

                            {/* <div className="mb-3">
                            <ReCAPTCHA
                              ref={captchaRef}
                              sitekey={site_key}
                              size="invisible"
                              // className={recaptchaClass}
                            />
                          </div> */}

                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customControlInline"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customControlInline"
                                  >
                                    Remember me
                                  </label>
                                </div>
                              </Col>
                              <Col sm={6} className="text-end">
                                {loading ? (
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="button"
                                    disabled
                                  >
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    Log In
                                  </button>
                                )}
                              </Col>
                            </Row>
                            <Row className="mt-2 mb-0 row">
                              <div className="col-12 mt-4">
                                <Link to="/forgot-password">
                                  <i className="mdi mdi-lock"></i> Forgot your
                                  password?
                                </Link>
                                <p>
                                  Don't have an account ?{" "}
                                  <Link
                                    to="/register"
                                    className="fw-medium text-primary"
                                  >
                                    {" "}
                                    Signup now{" "}
                                  </Link>{" "}
                                </p>
                              </div>
                            </Row>
                          </AvForm>
                        )}
                        <div className="mt-3 pt-3 d-flex justify-content-between">
                          <div>
                            <label className="">Sales & Support</label> <br />
                            <i className="fas fa-users fa-lg"></i>
                            <a
                              className="text-info ms-2"
                              href="tel://+919781239781"
                            >
                              +91-9781 23 9781
                            </a>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="mt-5 text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} KlikCRM.com, all rights reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
