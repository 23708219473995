import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import AllNotifications from "../../components/Notification"

const Notifications = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Notifications | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <AllNotifications />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Notifications
