import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Logout from "../pages/Authentication/Logout"
import ResetPasswordPage from "../pages/AuthenticationInner/Reset-password"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Pages
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Custom Pages
import OccupiedLocations from "../pages/OccupiedLocations"
import AllLeads from "../pages/Lead"
import RequestLeads from "../pages/DeadLeads/Request"
import AllDeadLeads from "../pages/DeadLeads/Dead"
import CompanyPoolLeads from "../pages/Lead/CompanyPool"
import AllStatsLeads from "../pages/Lead/AllStats"
import MyLeads from "../pages/Lead/MyLeads"
import DeletedLeads from "../pages/Lead/DeletedLeads"
import RepeatedLeads from "../pages/Lead/ReapeatedLeads"
import DuplicatedLeads from "../pages/Lead/DuplicatedLeads"
import TransferLeads from "../pages/Lead/TranferRequest"
import WinBackLeads from "../pages/Lead/WinbackPool"
import Pricingtable from "../pages/plans"
import Staff from "../pages/Admin"
import Settings from "../pages/Admin/settings"
import Workflow from "../pages/workflow"
import KlikDrive from "../pages/klik-drive"
import Role from "../pages/Role"
import Messages from "../pages/CreateMessage"
import Campaigns from "../pages/Campaign"
import Dead from "../pages/Dead"
import FollowupStatus from "../pages/Followup-Status"
import Scheme from "../pages/Scheme"
import Mstatus from "../pages/Mature-Status"
import Loc from "../pages/Location"
import LoginLogs from "../pages/Authentication/LoginLogs"
import LdTag from "../pages/LeadTag"
import LdField from "../pages/LeadField"
import LdSource from "../pages/LeadSource"
import LdLabel from "../pages/LeadLabel"
import GlobaliP from "../pages/GlobalIP"
import GlobalSecurity from "../pages/Global-Security"
import Reminder from "../pages/Reminder"
import Fetch from "../pages/Fetch-Email"
import FetchAPI from "../pages/Fetch-Api"
import FollowupToday from "../pages/Followups/Today"
import FollowupFuture from "../pages/Followups/Future"
import ReportToday from "../pages/Daily-report/Today"
import ReportPast from "../pages/Daily-report/Past"
import ActivityLogs from "../pages/Daily-report/ActivityLog"
import CusLeads from "../pages/Customers"
import Orders from "../pages/Order"
import Ledgers from "../pages/Ledger"
import Proformas from "../pages/Proforma"
import Complaints from "../pages/Complaint"
import CustomerSchemes from "../pages/Customer-scheme"
import Offers from "../pages/Offer"
import Notifications from "../pages/Notification"
import Products from "../pages/Products/allProducts"
import ProductLine from "../pages/Products/productLine"
import ProductFields from "../pages/Products/productFields"
import AllCategory from "../pages/Categories/All-categories"
import SubCategory from "../pages/Categories/Sub-categories"
import AllPacking from "../pages/Categories/All-packing"
import PrivacyPolicy from "../pages/Privacy-policy/privacy"
import TermsConditions from "../pages/Privacy-policy/terms-condition"
import ReturnRefundPolicyPage from "../pages/Privacy-policy/refund-policy"
import AccountantPage from "../pages/Accountant"
import EmailPage from "../pages/Admin/emailSmtp"
import WhatsappAPI from "../pages/Admin/whatsappAPI"
import ProformaSetting from "../pages/Admin/proformaSetting"
import WebhookApikey from "../pages/Admin/webhookApikey"
import hostDomain from "../pages/Admin/hostDomain"
import Facebook from "../components/Admin/profileComps/facebook"
import BranchesAll from "../pages/Admin/Branchesall"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  // Custom
  { path: "/occupied-locations", component: OccupiedLocations },
  { path: "/leads", component: AllLeads },
  { path: "/statistics", component: AllStatsLeads },
  { path: "/dead-requests", component: RequestLeads },
  { path: "/deleted-leads", component: DeletedLeads },
  { path: "/dead-leads", component: AllDeadLeads },
  { path: "/company-leads", component: CompanyPoolLeads },
  { path: "/my-leads", component: MyLeads },
  { path: "/repeated-leads", component: RepeatedLeads },
  { path: "/duplicated-leads", component: DuplicatedLeads },
  { path: "/transfer-request", component: TransferLeads },
  { path: "/winback-leads", component: WinBackLeads },
  { path: "/plans", component: Pricingtable },
  { path: "/settings", component: Settings },
  { path: "/workflows", component: Workflow },
  { path: "/staff", component: Staff },
  { path: "/role", component: Role },
  { path: "/messages", component: Messages },
  { path: "/campaigns", component: Campaigns },
  { path: "/dead", component: Dead },
  { path: "/followup-status", component: FollowupStatus },
  { path: "/scheme", component: Scheme },
  { path: "/mature-status", component: Mstatus },
  { path: "/location", component: Loc },
  { path: "/lead-tag", component: LdTag },
  { path: "/lead-fields", component: LdField },
  { path: "/login-logs", component: LoginLogs },
  { path: "/lead-source", component: LdSource },
  { path: "/lead-label", component: LdLabel },
  { path: "/klik-drive", component: KlikDrive },
  { path: "/global-ip", component: GlobaliP },
  { path: "/global-security", component: GlobalSecurity },
  { path: "/reminder", component: Reminder },
  { path: "/fetch-from-email", component: Fetch },
  { path: "/fetch-enquiries", component: FetchAPI },
  { path: "/followups-today", component: FollowupToday },
  { path: "/followups-future", component: FollowupFuture },
  { path: "/today-report", component: ReportToday },
  { path: "/past-report", component: ReportPast },
  { path: "/activity-logs", component: ActivityLogs },
  { path: "/customers", component: CusLeads },
  { path: "/orders", component: Orders },
  { path: "/ledgers", component: Ledgers },
  { path: "/proformas", component: Proformas },
  { path: "/complaints", component: Complaints },
  { path: "/schemes", component: CustomerSchemes },
  { path: "/offers", component: Offers },
  { path: "/notifications", component: Notifications },
  { path: "/products", component: Products },
  { path: "/product-line", component: ProductLine },
  { path: "/product-fields", component: ProductFields },
  { path: "/all-packing", component: AllPacking },
  { path: "/sub-category", component: SubCategory },
  { path: "/all-category", component: AllCategory },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-conditions", component: TermsConditions },
  { path: "/return-policy", component: ReturnRefundPolicyPage },
  { path: "/accounts", component: AccountantPage },
  { path: "/connect-email", component: EmailPage },
  { path: "/connect-whatsapp", component: WhatsappAPI },
  { path: "/proforma-settings", component: ProformaSetting },
  { path: "/webhook-keys", component: WebhookApikey },
  { path: "/host-domain", component: hostDomain },
  { path: "/connect-meta", component: Facebook },
  { path: "/branches", component: BranchesAll },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login1 },
  { path: "/forgot-password", component: Recoverpw },
  { path: "/reset-password", component: ResetPasswordPage },
  { path: "/register", component: Register1 },
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
  { path: "/plans", component: Pricingtable },
]

export { userRoutes, authRoutes }
