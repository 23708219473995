import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  CardTitle,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser, setCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loader from "../../Loader/loader"

const HostDomain = () => {
  const [invoiceLogo, setInvoiceLogo] = useState("")
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(<Loader />)
  const [currentIp, setCurrentIp] = useState("")
  const [message, setMessage] = useState("")
  const getFile = e => {
    setInvoiceLogo(e.target.files[0])
  }
  const getProformaDetail = (e, value) => {
    var formData = new FormData()

    formData.append("header_logo", invoiceLogo)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/domain-details`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoading(false)
          setCurrentUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          window.history.go(-1)
        } else {
          setLoading(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(err => {
        // console.log("err", err)
      })
  }
  const getCurrentIp = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/current-ip`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setMessage(res.data.message)
          setCurrentIp(res.data.data)
        } else {
          setLoader("")
          setMessage(res.data.message)
        }
      })
  }
  useEffect(() => {
    getCurrentIp()
  }, [])
  return (
    <>
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h4 className="page-title">
              Host this CRM on your domain FOR FREE
            </h4>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end"></h6>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg="12">
          {getCurrentUser().role_name === "Administrator" ? (
            <Card>
              <CardBody>
                <AvForm
                  className="card-title mt-0"
                  onValidSubmit={(e, v) => {
                    getProformaDetail(e, v)
                  }}
                >
                  <h4 className="card-title mb-4">Host on your domain</h4>
                  <hr />
                  <Row>
                    <Col md={4}>
                      <div className="d-flex justify-content-between mb-2">
                        <div>
                          <label>Invoice Logo</label>
                          <AvField
                            // label="Invoice Logo"
                            type="file"
                            name="invoice_logo"
                            accept="image/jpg, image/png, image/gif, image/jpeg"
                            onChange={e => getFile(e)}
                          />
                        </div>
                      </div>
                      <small>
                        <b>
                          <i>
                            Note: Make sure the dimensions of your logo is in
                            300×50
                          </i>
                        </b>
                      </small>
                      <div>
                        {getCurrentUser().header_logo_formatted ? (
                          <>
                            <div className="navbar-brand-box">
                              <span className="logo-lg logo-img">
                                <img
                                  src={
                                    getCurrentUser()?.header_logo_formatted
                                      ? getCurrentUser()?.header_logo_formatted
                                      : logolightImg
                                  }
                                  alt=""
                                  height={
                                    getCurrentUser()?.header_logo_formatted
                                      ? "50"
                                      : "30"
                                  }
                                />
                              </span>
                            </div>
                          </>
                        ) : (
                          <img
                            width={100}
                            src={getCurrentUser().header_logo_formatted}
                            alt="uploaded logo"
                          />
                        )}
                      </div>
                    </Col>
                    <hr />
                  </Row>
                  <div className="text-center">
                    {loading ? (
                      <button
                        className="btn btn-primary disabled"
                        type="button"
                        disabled
                      >
                        Loading
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="submit"
                        // onClick={() => props.setmodal_dead_reason(false)}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Card className="mt-4">
        <CardBody>
          <CardTitle tag="h5">DNS Setup Instructions</CardTitle>

          <ListGroup className="mt-3">
            <ListGroupItem>
              <strong>1. Sign in to your domain's account:</strong>
              <br />
              Log in to your account on your domain hosting provider's website.
            </ListGroupItem>

            <ListGroupItem>
              <strong>2. Locate DNS Settings:</strong>
              <br />
              Navigate to the page or section where you can update DNS records
              for your domain.
            </ListGroupItem>

            <ListGroupItem>
              <strong>3. Modify or Create A Records:</strong>
              <br />
              Find or create new <strong>A records</strong> for your domain. In
              the "Points to" or "Value" field, enter the IP address of our
              server: Server IP: <strong>165.232.179.103</strong>
            </ListGroupItem>

            <ListGroupItem>
              <strong>4. Save Changes:</strong>
              <br />
              Save the changes to your DNS records.
            </ListGroupItem>

            <ListGroupItem>
              <strong>5. Allow Time for DNS Propagation:</strong>
              <br />
              Wait for the DNS changes to propagate. This can take a few minutes
              to a few hours.
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </>
  )
}

export default HostDomain
