import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import Loader from "../Loader/loader"
import axios from "axios"

const PricingTable = () => {
  const [plans, setPlans] = useState([])
  const [loader, setLoader] = useState("")
  const [subDetails, setSubDetails] = useState("")

  const getPlans = () => {
    setPlans([])
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/get-plans`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setLoader("")
        if (res.data.status) {
          setPlans(res.data.data)
        } else {
        }
      })
  }

  const getSub = () => {
    setSubDetails("")
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/get-subscription-by-id`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.data?.status == "created") {
          window.open(res.data.data.short_url, "_blank")
        }
        if (
          res.data.data?.status == "active" ||
          res.data.data?.status == "authenticated"
        ) {
          setSubDetails(res.data.data)
        }
      })
  }

  useEffect(() => {
    getPlans()
    getSub()
  }, [])

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h1 className="text-center py-4">
            {subDetails.quantity !== undefined
              ? `Your current subscription has ${subDetails.quantity} users.`
              : "Choose the plan that's right for you"}
          </h1>
        </Col>
      </Row>
      <Row>
        {plans?.map(plan => (
          <Col md={4}>
            <PlanList data={plan} />
          </Col>
        ))}
        {loader}
      </Row>
    </Container>
  )
}

export default PricingTable
