import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import PastReport from "../../components/DailyReport/Past-Report"

const ReportPast = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Past Report | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <PastReport />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportPast
