import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import RequestTable from "../../../components/deadLeads/request"

const RequestLeads = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dead Requests | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <RequestTable />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default RequestLeads
