import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import axios from "axios"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

// API function for profile update
const updateProfileAPI = data => {
  return axios.post("/api/users/profile", data)
}

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(updateProfileAPI, {
      username: user.username,
      idx: user.idx,
    })
    yield put(profileSuccess(response.data))
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
