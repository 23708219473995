import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import { getCurrentUser } from "../../../helpers/Utils"
import axios from "axios"
import classnames from "classnames"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import CorderList from "./CorderList"
import CorderModal from "./CorderModal"
import ServiceLineOrder from "./serviceLineOrder"

const CorderTable = props => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [service_order, setservice_order] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [order, setOrder] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [customActiveTab, setCustomActiveTab] = useState(3)
  const [orderType, setOrderType] = useState("order,order_service")
  const [allData, setAllData] = useState([])

  const getAllOrders = e => {
    setOrder([])
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/order?type=${e}&lead_id=${props.leadId}&per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setOrder(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const leadDisplay = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/order-counts?customer_id=${props.leadId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setAllData(res.data.data)
      })
  }

  const paginate = e => {
    setOrder([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(`${e}&type=${orderType}&lead_id=${props.leadId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setOrder(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
    if (tab == 3) {
      getAllOrders("order,order_service")
      setOrderType("order,order_service")
    }
    if (tab == 1) {
      getAllOrders("order")
      setOrderType("order")
    }
    if (tab == 2) {
      getAllOrders("order_service")
      setOrderType("order_service")
    }
  }

  useEffect(() => {
    getAllOrders("order,order_service")
  }, [])

  return (
    <>
      {modal_standard ? (
        <CorderModal
          modal_standard={modal_standard}
          id={props.leadId}
          setmodal_standard={setmodal_standard}
          getAllOrders={getAllOrders}
        />
      ) : (
        ""
      )}
      {service_order ? (
        <ServiceLineOrder
          service_order={service_order}
          id={props.leadId}
          setservice_order={setservice_order}
          getAllOrders={getAllOrders}
        />
      ) : (
        ""
      )}
      {modal3 ? (
        <div style={{ marginTop: "25px" }}>
          <Row>
            <Col>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Total
                    </h5>
                    <h4 className="fw-medium font-size-18">
                      {allData.all_orders_with_amount}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Amount Received
                    </h5>
                    <h4 className="fw-medium font-size-18">
                      {allData.received}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Outstanding
                    </h5>
                    <h4 className="fw-medium font-size-18">
                      {allData.outstanding}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Pending
                    </h5>
                    <h4 className="fw-medium font-size-18">
                      {allData.pending}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Unpaid
                    </h5>
                    <h4 className="fw-medium font-size-18">
                      {allData.unpaid}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Partially Paid
                    </h5>
                    <h4 className="fw-medium font-size-18">
                      {allData.p_paid}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={3}>
            <h6 className="page-title">
              Recent{" "}
              {getCurrentUser()?.crm_order_title
                ? getCurrentUser()?.crm_order_title
                : "Orders"}
            </h6>
          </Col>
          <Col md={9}>
            <h6 className="page-title float-end">
              {modal3 ? (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    setModal3(false)
                  }}
                >
                  {getCurrentUser()?.crm_order_title
                    ? getCurrentUser()?.crm_order_title
                    : "Orders"}{" "}
                  Counter
                  <i className="fa fa-arrow-up ms-2"></i>
                </button>
              ) : (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    setModal3(true), leadDisplay()
                  }}
                >
                  {getCurrentUser()?.crm_order_title
                    ? getCurrentUser()?.crm_order_title
                    : "Orders"}{" "}
                  Counter
                  <i className="fa fa-arrow-down ms-2"></i>
                </button>
              )}
              {getCurrentUser().crmpro_permission == 1 ||
              getCurrentUser().crmpronew_permission == 1 ? (
                <>
                  {getCurrentUser().crmpro_services_enabled == 1 ? (
                    <button
                      className="btn btn-primary submit__button me-2"
                      type="button"
                      onClick={() => {
                        setservice_order(true)
                      }}
                    >
                      Add Service Line{" "}
                      {getCurrentUser()?.crm_order_title
                        ? getCurrentUser()?.crm_order_title
                        : "Order"}
                    </button>
                  ) : (
                    ""
                  )}
                  {getCurrentUser().crmpro_products_enabled == 1 ? (
                    <button
                      className="btn btn-primary submit__button"
                      type="button"
                      onClick={() => {
                        setmodal_standard(true)
                      }}
                    >
                      Add Product List New
                    </button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 3 })}
                  onClick={() => {
                    toggleCustom(3)
                  }}
                >
                  {" "}
                  All{" "}
                  {getCurrentUser()?.crm_order_title
                    ? getCurrentUser()?.crm_order_title
                    : "Orders"}
                </NavLink>
              </NavItem>
              {getCurrentUser().crmpro_products_enabled == 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === 1 })}
                    onClick={() => {
                      toggleCustom(1)
                    }}
                  >
                    {" "}
                    Product List{" "}
                    {getCurrentUser()?.crm_order_title
                      ? getCurrentUser()?.crm_order_title
                      : "Orders"}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {getCurrentUser().crmpro_services_enabled == 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === 2 })}
                    onClick={() => {
                      toggleCustom(2)
                    }}
                  >
                    {" "}
                    Service Line{" "}
                    {getCurrentUser()?.crm_order_title
                      ? getCurrentUser()?.crm_order_title
                      : "Orders"}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
            </Nav>
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>Order ID</th>
                  <th>Payment Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Order Balance</th>
                  <th>Status</th>
                  <th>Payment Status</th>
                  <th>Action</th>
                </tr>
                {order &&
                  order.map((item, obj) => (
                    <CorderList
                      key={obj}
                      data={item}
                      i={obj}
                      counter={counter}
                      leadDisplay={leadDisplay}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllOrders}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default CorderTable
