import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import bg from "../../assets/images/bg.jpg"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { AvForm, AvField } from "availity-reactstrap-validation"
import axios from "axios"
import logoSm from "../../assets/images/klikcrm-logo.png"
import { useHistory } from "react-router-dom"

const Register = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const handleValidSubmit = async (event, value) => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        name: value.name,
        email: value.email,
        phone: value.phone,
        company: value.company,
        designation: value.designation,
        city: value.city,
      })
      .then(res => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setLoading(false)
          history.push("/login")
        } else {
          setLoading(false)
          toast
            .error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            .catch(err => {
              // console.log("err", err)
            })
        }
      })
      .catch(err => {
        // console.log("err", err)
      })
  }

  useEffect(() => {
    localStorage.clear()
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | KlikCRM</title>
      </MetaTags>
      <div
        className="accountbg"
        style={{
          background: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      {/* <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
               <i className="fas fa-home h2"></i>
            </Link>
         </div> */}
      <div className="account-pages mb-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={10} xl={8}>
              <Row className="justify-content-center m-0">
                <Col md={6} className="p-0">
                  <Card className="overflow-hidden rounded-0 h-100 bg-secondary">
                    <div className="promotional-wrapper h-100">
                      <div className="text-center mt-2">
                        <img src={logoSm} height="42" alt="logo" />
                      </div>
                      <h2 className="heading">Why You will Love KlikCRM!</h2>
                      <ul className="advantages">
                        <li>
                          <div>
                            <i className="fa fa-star"></i>
                          </div>
                          <div>
                            <h2>Easy To Use</h2>
                            <p>
                              KlikCRM is easy to use, even for CRM beginners.
                              Just sign up and get going!
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <i className="fa fa-user-tie"></i>
                          </div>
                          <div>
                            <h2>Expert Help</h2>
                            <p>
                              KlikCRM comes with support that will set you up,
                              help you make the most of the platform and address
                              any issues you may have- all for free!
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <i className="fas fa-shield-alt"></i>
                          </div>
                          <div>
                            <h2>Secure Technology</h2>
                            <p>
                              KlikCRM is built on a solid technology stack, so
                              your data is in good hands - safe, available at
                              all times and almost impossible to steal.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Card>
                </Col>
                <Col md={6} className="p-0">
                  <Card className="overflow-hidden  rounded-0 h-100">
                    <div className="text-center mt-4">
                      <h2 className="font-size-38 mt-4 text-center">
                        Welcome to KlikCRM !
                      </h2>
                      <p className="font-size-14 text-muted text-center">
                        Fill details for demo request.
                      </p>
                    </div>
                    <CardBody className="p-4 pt-0">
                      <div className="p-3 pt-0">
                        <AvForm
                          className=""
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                        >
                          <div className="mb-3 required">
                            <AvField
                              name="name"
                              label="Name"
                              type="text"
                              required
                              placeholder="Enter your name"
                            />
                          </div>
                          <div className="mb-3 required">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>

                          <div className="mb-3 required">
                            <AvField
                              name="phone"
                              label="Phone Number"
                              type="number"
                              required
                              placeholder="Enter phone number"
                            />
                          </div>
                          <div className="mb-3 required">
                            <AvField
                              name="company"
                              label="Company Name"
                              type="text"
                              required
                              placeholder="Enter company name"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="designation"
                              label="Designation"
                              type="text"
                              placeholder="Enter your designation"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="city"
                              label="City"
                              type="text"
                              placeholder="Enter your city"
                            />
                          </div>

                          <div className="mb-3 row">
                            <div className="col-12 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Request for Demo
                              </button>
                            </div>
                          </div>

                          <Row className="mt-2 mb-0 row">
                            <div className="col-12 mt-4">
                              {/* <Link to="/forgot-password">
                                                <i className="mdi mdi-lock"></i> Forgot your
                                                password?
                                             </Link> */}
                              <p>
                                Already have an account ?{" "}
                                <Link
                                  to="/login"
                                  className="fw-medium text-primary"
                                >
                                  {" "}
                                  Login
                                </Link>{" "}
                              </p>
                            </div>
                          </Row>
                        </AvForm>
                        {/* <div className="mt-3 pt-3 d-flex justify-content-between">
                                       <div>
                                          <label className="">Support</label> <br />
                                          <i className="fas fa-headset fa-lg"></i>
                                          <a
                                             className="text-info ms-2"
                                             href="tel://+918054805705"
                                          >
                                             +91-805 4805 705
                                          </a>
                                       </div>
                                       <div>
                                          <label className="">Sales & Support</label> <br />
                                          <i className="fas fa-users fa-lg"></i>
                                          <a
                                             className="text-info ms-2"
                                             href="tel://+919781239781"
                                          >
                                             +91-9781 23 9781
                                          </a>
                                       </div>
                                    </div> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {/* <div className="mt-5 text-center">
                        <p>
                           Already have an account ?{" "}
                           <Link
                              to="/login"
                              className="fw-medium text-primary"
                           >
                              {" "}
                              Login
                           </Link>{" "}
                        </p>
                        <p>
                           © {new Date().getFullYear()} KlikCRM.com,{" "}
                           all rights reserved.
                        </p>
                     </div> */}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
