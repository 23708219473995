import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import io from "socket.io-client"
import axios from "axios"
import CustomSwitch from "./CustomSwitch"
import Loader from "./components/Loader/loader"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { userRoutes, authRoutes } from "./routes/allRoutes"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { getCurrentUser, setCurrentUser, setPermission } from "./helpers/Utils"
import { useHistory } from "react-router-dom"
import "./assets/scss/theme.scss"
import notificationSound from "./assets/sounds/notification-sound.wav"

const App = props => {
  const history = useHistory()
  const [audio] = useState(new Audio(notificationSound))

  function getLayout() {
    return HorizontalLayout
  }

  const Layout = getLayout()

  useEffect(() => {
    if (getCurrentUser()?.id) {
      const socket = io("https://sockets.klikcrm.com/")
      socket.on(
        `${localStorage.getItem("company_name")}-${getCurrentUser().id}`,
        (type, data) => {
          if (type === "new_message") {
            audio.play()
            toast.success(
              `${data.message_user.name}: ${
                data.body !== null
                  ? data.body
                  : data.image
                  ? "sent you an image"
                  : data.video
                  ? "sent you a video"
                  : ""
              }`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            )
            // localStorage.setItem('unreadMessages', data.unread_message_count)
          } else if (type === "notification") {
            audio.play()
            toast.success(`${data}`, {
              position: "top-right",
              autoClose: 300000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else if (type == "refresh") {
            window.reload()
          } else if (type == "logout") {
            setCurrentUser("")
            localStorage.setItem("DashboardData", "")
            localStorage.setItem("LastUpdated", "")
            localStorage.setItem("NavCounters", "")
            history.push("/login")
          }
        }
      )
      return () => socket.disconnect()
    }
  }, [])

  let isToken = window.location.href.split("token=")[1]

  if (isToken !== undefined) {
    let subDomain = window.location.host.split(".")[0]
    localStorage.setItem("company_name", subDomain)
    setCurrentUser("")
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${subDomain}/auth/login-as-token?token=${isToken}`
      )
      .then(res => {
        if (res.data.status) {
          setCurrentUser(res.data.data)
          setPermission(res.data.data.permissions)
          localStorage.setItem(
            "ShowImportant",
            res.data.data?.show_important_date
          )
          if (res.data.data.role_name == "Administrator") {
            window.location.href = "/dashboard"
          } else {
            window.location.href = "/followups-today"
          }
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(err => {
        // console.log("err", err)
      })
    return (
      <>
        <Loader />
      </>
    )
  } else {
    if (
      getCurrentUser() === null &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/register" &&
      window.location.pathname !== "/forgot-password" &&
      window.location.pathname !== "/reset-password"
    ) {
      // history.push('/login')
      window.location.href = "/login"
    } else {
      return (
        <React.Fragment>
          <ToastContainer />
          {/* <IdleTime /> */}
          <Router>
            <CustomSwitch>
              {authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}
              {userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
              <Redirect to="/404" />
              <Redirect to="/500" />
            </CustomSwitch>
          </Router>
        </React.Fragment>
      )
    }
  }
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
