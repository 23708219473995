import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
  getFilterDate2,
  setFilterDate2,
} from "../../../helpers/Utils"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import ProductsList from "./ProductsList"
import ProductsModal from "./ProductsModal"
import BatchModal from "./BatchModal"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const ProductsTable = () => {
  const [modal_product, setmodal_product] = useState(false)
  const [modal_batch, setmodal_batch] = useState(false)
  const [modal, setModal] = useState(false)
  const [name_filter, setname_filter] = useState(false)
  const [date_filter, setdate_filter] = useState(false)
  const [date2_filter, setdate2_filter] = useState(false)
  const [status6_filter, setstatus6_filter] = useState(false)
  const [product, setProduct] = useState([])
  const [searchedCount, setSearchedCount] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [file, setFile] = useState("")
  const [counter, setCounter] = useState("")
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert2, setconfirm_alert2] = useState(false)
  const [confirm_alert3, setconfirm_alert3] = useState(false)
  const [blur, setBlur] = useState(false)
  const [columns, setColumns] = useState([])
  const [searched, setSearched] = useState("")

  const getProducts = e => {
    setProduct([])
    setColumns([])
    setLoader(<Loader />)
    let url = ""
    if (getCurrentUser()?.role_name == "Administrator") {
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/product?name=${e === "clear" ? "" : searched}&per_page=${
        localStorage.getItem("PerPage") !== null
          ? localStorage.getItem("PerPage")
          : ""
      }`
    } else {
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/product?permission=enable&per_page=${
        localStorage.getItem("PerPage") !== null
          ? localStorage.getItem("PerPage")
          : ""
      }`
    }
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.values.from)
          setColumns(res.data.data.keys)
          if (res.data.data.values.data.length > 0) {
            setProduct(res.data.data.values.data)
            setError("")
          } else {
            setError("No data found!")
          }
          setAllPages(res.data.data.values)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const clearSearch = () => {
    setSearched("")
    getProducts("clear")
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "status6") {
      localStorage.setItem("PrductGST", e)
    }
    if (type == "date") {
      setFilterDate(e)
    }
    if (type == "date2") {
      setFilterDate2(e)
    }
    if (type == "sort") {
      localStorage.setItem("leadSort", e)
    }
    setSearchedCount("")
    setProduct([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/product?gst=${
          localStorage.getItem("PrductGST") !== null
            ? localStorage.getItem("PrductGST")
            : ""
        } 
         &name=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &from_date=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &to_date=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &expiry_from_date=${
           getFilterDate2() && getFilterDate2().from !== null
             ? getFilterDate2().from
             : ""
         }
         &expiry_to_date=${
           getFilterDate2() && getFilterDate2().to !== null
             ? getFilterDate2().to
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }
         &permission=${
           getCurrentUser()?.role_name == "Administrator" ? "" : "enable"
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setProduct(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setname_filter(false)
          setstatus6_filter(false)
          setdate_filter(false)
          setdate2_filter(false)
        } else {
          setError(res.data.message)
        }
      })
  }

  const getImportFormat = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/product-import-format`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setLoader("")
        window.location.href = res.data.file_url
      })
  }

  const paginate = e => {
    setProduct([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(
        `${e}&permission=${
          getCurrentUser()?.role_name == "Administrator" ? "" : "enable"
        }&gst=${
          localStorage.getItem("PrductGST") !== null
            ? localStorage.getItem("PrductGST")
            : ""
        } 
            &name=${searched}
            &from_date=${
              getFilterDate() && getFilterDate().from !== null
                ? getFilterDate().from
                : ""
            }
            &to_date=${
              getFilterDate() && getFilterDate().to !== null
                ? getFilterDate().to
                : ""
            }
            &expiry_from_date=${
              getFilterDate2() && getFilterDate2().from !== null
                ? getFilterDate2().from
                : ""
            }
            &expiry_to_date=${
              getFilterDate2() && getFilterDate2().to !== null
                ? getFilterDate2().to
                : ""
            }
            &sort=${
              localStorage.getItem("leadSort") !== null
                ? localStorage.getItem("leadSort")
                : ""
            }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.values.from)
          setColumns(res.data.data.keys)
          setProduct(res.data.data.values.data)
          setAllPages(res.data.data.values)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const exportProduct = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/product-export`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 2000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-productfile`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const getFile = e => {
    setFile(e.target.files[0])
  }

  const importProducts = (e, value) => {
    setLoading(true)
    let margProduct = "0"
    if (value.is_marg_product) {
      margProduct = "1"
    } else {
      margProduct = "0"
    }
    const formData = new FormData()
    formData.append("upload", file)
    formData.append("is_marg_product", margProduct)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/product-import`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setLoading(false)
        if (res.data.status) {
          setModal(false)
          getProducts()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const deleteLeads = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-product-deleted`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getProducts()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const productPermission = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/product-permission`,
        {
          ids: leadsSelectedIds,
          permission: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getProducts()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    getProducts()
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        setModal(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      {modal_product ? (
        <ProductsModal
          modal_product={modal_product}
          setmodal_product={setmodal_product}
          getProducts={getProducts}
        />
      ) : (
        ""
      )}
      {modal_batch ? (
        <BatchModal
          modal_batch={modal_batch}
          setmodal_batch={setmodal_batch}
          getProducts={getProducts}
          product={product}
        />
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">You won't be able to revert this!</span>
        </SweetAlert>
      ) : null}
      {confirm_alert2 ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert2(false)
            setsuccess_dlg(true)
            productPermission("disable")
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert2(false)}
        >
          <span className="text-danger">
            You want to disable selected Products?
          </span>
        </SweetAlert>
      ) : null}
      {confirm_alert3 ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert3(false)
            setsuccess_dlg(true)
            productPermission("enable")
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert3(false)}
        >
          <span className="text-danger">
            You want to Enable selected Products?
          </span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={2}>
            <h6 className="page-title">Products</h6>
          </Col>
          <Col md={5}>
            <div>
              <input
                className="me-2 ps-2"
                style={{ width: "calc(100% - 225px)" }}
                type="search"
                name="name"
                value={searched}
                onChange={e => setSearched(e.target.value)}
                placeholder="Search Product"
              />
              <button
                className="btn btn-primary submit__button"
                type="button"
                onClick={() => {
                  getProducts()
                }}
              >
                Search
              </button>
              <button
                className="btn btn-primary submit__button ms-2"
                type="button"
                onClick={() => clearSearch()}
              >
                Clear
              </button>
            </div>
          </Col>
          <Col md={5}>
            <h6 className="page-title float-end">
              {blur ? (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    {getPermission().product.product.delete.is_checked ===
                    "yes" ? (
                      <li>
                        <span
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setconfirm_alert(true)
                          }}
                        >
                          Batch delete
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {getCurrentUser()?.role_name == "Administrator" ? (
                      <>
                        <li>
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setconfirm_alert2(true)
                            }}
                          >
                            Disable Product
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setconfirm_alert3(true)
                            }}
                          >
                            Enable Product
                          </span>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              ) : (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary" disabled>
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    disabled
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                    {getCurrentUser()?.role_name == "Administrator" ? (
                      <>
                        <li>
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setconfirm_alert2(true)
                            }}
                          >
                            Disable Product
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setconfirm_alert3(true)
                            }}
                          >
                            Enable Product
                          </span>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              )}
              <button
                className="btn btn-primary submit__button me-2"
                onClick={() => {
                  setModal(true)
                }}
              >
                Import Products
              </button>
              <button
                className="btn btn-primary submit__button me-2"
                onClick={() => {
                  exportProduct()
                }}
              >
                Export Products
              </button>
              {/* {blur ? (
                <button
                  className="btn btn-danger me-2"
                  onClick={() => {
                    setconfirm_alert(true)
                  }}
                >
                  Delete Products
                </button>
              ) : (
                <button className="btn btn-danger me-2" disabled>
                  Delete Products
                </button>
              )} */}
              {/* <button
                className="btn btn-success me-2"
                type="button"
                onClick={() => {
                  setmodal_batch(true)
                }}
              >
                Add New Batch
              </button> */}
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setmodal_product(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Data
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={modal}
        centered={true}
        // size="sm"
      >
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            importProducts(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Import Products
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => setModal(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label>Download Sample Format</label>
              <a
                className="btn bg-secondary text-dark float-end"
                onClick={() => getImportFormat()}
              >
                <i className="fas fa-download me-2" />
                Download
              </a>
            </div>
            <div className="mb-3">
              <label>Select (xlxs) file</label>
              <AvField
                type="file"
                name="upload"
                accept=".xls,.xlsx"
                onChange={e => getFile(e)}
              />
            </div>
            <div className="mb-3">
              <AvField
                label="Marg ERP Products Import"
                type="checkbox"
                name="is_marg_product"
                onChange={e => getFile(e)}
              />
            </div>
            <div className="modal-footer">
              <div className="text-center">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
      {/* <div className="card">
        <div className="card-body"> */}
      <div
        className="lead_table table table-sm table-bordered table-responsive"
        // style={{ overflowX: "scroll" }}
      >
        <table style={{ width: "100%", background: "#fff" }}>
          <tbody>
            <tr className="static-header">
              <th>
                <input
                  onClick={e => selectAllCheck(e.target)}
                  className="p-0 d-inline-block"
                  type="checkbox"
                />
              </th>
              <th>#</th>
              {columns?.map((item, i) => (
                <th key={i}>{item}</th>
              ))}
              <th>Total Orders</th>
              <th>Stock Left</th>
              <th>Last Rate</th>
              <th>Offer Price</th>
              <th>Total Quantity</th>
              <th>Action</th>
            </tr>
            {product &&
              product.map((item, obj) => (
                <ProductsList
                  key={obj}
                  data={item}
                  i={obj}
                  setBlur={setBlur}
                  getProducts={getProducts}
                  setLeadsSelectedIds={setLeadsSelectedIds}
                  leadsSelectedIds={leadsSelectedIds}
                  counter={counter}
                />
              ))}
          </tbody>
        </table>
        {error ? (
          <span className="text-danger mt-3 d-table m-auto" role="alert">
            {error}
          </span>
        ) : (
          ""
        )}
      </div>
      {/* </div>
      </div> */}
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getProducts}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default ProductsTable
