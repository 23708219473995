import React from "react"
import MetaTags from "react-meta-tags"
import ReturnRefundPolicy from "../../components/Privacy-termsCondition/refund-policy"

const ReturnRefundPolicyPage = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Return & Refund Policy | KlikCRM</title>
      </MetaTags>
      <ReturnRefundPolicy />
    </React.Fragment>
  )
}
export default ReturnRefundPolicyPage
