import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToggleSwitch from "../../Toggle-Switch"
import CreateBranch from "./createBranch"

const BranchList = props => {
  const [user, setUser] = useState(props.data)
  const [modal_standard, setmodal_standard] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const deleteUser = id => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch/${id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUser("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          props.getAllBranch()
        } else {
          setdynamic_description(res.data.message)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editRole = e => {
    setIsOpen(true)
    setmodal_standard(true)
  }
  return (
    <>
      {isOpen ? (
        <CreateBranch
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          user={user}
          setUser={setUser}
          setIsOpen={setIsOpen}
        />
      ) : null}
      <tr key={props.i}>
        <td>{props.i + 1}</td>
        <td>{user.name}</td>
        <td>{user.address}</td>
        <td>
          {user.role_name === "Administrator" ? (
            ""
          ) : (
            <>
              <a
                className="text-warning"
                onClick={() => {
                  editRole(user.id)
                }}
              >
                <i className="fas fa-pen" />
              </a>
              <a
                className="text-danger ms-2"
                onClick={() => {
                  setconfirm_alert(true)
                }}
              >
                <i className="fas fa-trash-alt" />
              </a>
              {confirm_alert ? (
                <SweetAlert
                  title="Are you sure?"
                  warning
                  showCancel
                  confirmButtonText="Yes, delete it!"
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setconfirm_alert(false)
                    setsuccess_dlg(true)
                    deleteUser(user.id)
                    setdynamic_title("Deleted")
                    setdynamic_description("Your file has been deleted.")
                  }}
                  onCancel={() => setconfirm_alert(false)}
                >
                  You won't be able to revert this!
                </SweetAlert>
              ) : null}
            </>
          )}
        </td>
      </tr>
    </>
  )
}
export default BranchList
