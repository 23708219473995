import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import FetchList from "./fetch-list"
import FetchWebsite from "./websiteList"
import FetchModal from "./FetchModal"
import MultipleChoice from "../../Filter/MultipleChoice"
import InputFilter from "../../Filter/SearchFilter"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import FetchWeb from "./fetch-webhook.js"
import FetchWlist from "./fetch-wlist.js"
import FetchEList from "./fetch-elist.js"
import FetcheModal from "./FetcheModal.js"

const FetchApiKey = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [nwemodal_standard, setnewModal_standard] = useState(false)
  const [allFetch, setAllFetch] = useState([])
  const [webData, setWebData] = useState([])
  const [webhook, setWebhook] = useState([])
  const [error, setError] = useState("")
  const [error2, setError2] = useState("")
  const [error3, setError3] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [loader2, setLoader2] = useState(<Loader />)
  const [loader3, setLoader3] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [counter2, setCounter2] = useState("")
  const [counter3, setCounter3] = useState("")
  const [allSource, setAllSource] = useState([])
  const [name_filter, setname_filter] = useState(false)
  const [message_filter, setmessage_filter] = useState(false)
  const [leadsource_filter, setleadsource_filter] = useState(false)
  const [status3_filter, setstatus3_filter] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [blur, setBlur] = useState(false)
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [modal_standard1, setmodal_standard1] = useState(false)
  const [allFetch1, setAllFetch1] = useState([])
  const [error4, setError4] = useState("")
  const [loader4, setLoader4] = useState(<Loader />)
  const [allPages1, setAllPages1] = useState([])
  const [counter4, setCounter4] = useState("")
  const [allSource4, setAllSource4] = useState([])
  const [name_filter4, setname_filter4] = useState(false)
  const [message_filter4, setmessage_filter4] = useState(false)
  const [leadsource_filter4, setleadsource_filter4] = useState(false)
  const [status3_filter4, setstatus3_filter4] = useState(false)
  const [searchedCount4, setSearchedCount4] = useState("")
  const [blur4, setBlur4] = useState(false)
  const [leadsSelectedIds4, setLeadsSelectedIds4] = useState([])
  const [success_dlg4, setsuccess_dlg4] = useState(false)
  const [dynamic_title4, setdynamic_title4] = useState("")
  const [dynamic_description4, setdynamic_description4] = useState("")
  const [confirm_alert1, setconfirm_alert1] = useState(false)
  const [dashboardData, setDashboardData] = useState(null)

  useEffect(() => {
    const storedData = localStorage.getItem("DashboardData")
    if (storedData) {
      setDashboardData(JSON.parse(storedData))
    }
  }, [])

  const getAllFetch = () => {
    setLoader(<Loader />)
    setAllFetch([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/fetch-from-email?type=api&per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setAllFetch(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const getWebApi = () => {
    setLoader3(<Loader />)
    setWebhook([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/get-webhooks`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader3("")
          setCounter3(res.data.message)
          setWebhook(res.data.data.data)
        } else {
          setLoader3("")
          setError3(res.data.message)
        }
      })
  }

  const getWebsiteApi = () => {
    //  setLoader2(<Loader />)
    //  setWebData([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/source?fetched_from_website_source=1&per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          //  setLoader2("")
          //  setError2(res.data.message)
          //  setCounter2(res.data.data.from)
          //  setWebData(res.data.data.data)
        } else {
          //  setLoader2("")
          //  setError2(res.data.message)
        }
      })
  }

  const paginate = e => {
    setAllFetch([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(
        `${e}&type=api&latest_leads_only=${
          localStorage.getItem("leadStatus3") !== null
            ? localStorage.getItem("leadStatus3")
            : ""
        }
            &api_key=${
              localStorage.getItem("leadName") !== null
                ? localStorage.getItem("leadName")
                : ""
            }
            &lead_source_id=${
              localStorage.getItem("leadSource") !== null
                ? localStorage.getItem("leadSource")
                : ""
            }
            &platform=${
              localStorage.getItem("leadMessage") !== null
                ? localStorage.getItem("leadMessage")
                : ""
            }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllFetch(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "leadsource") {
      localStorage.setItem("leadSource", e)
    }
    if (type == "status3") {
      localStorage.setItem("leadStatus3", e)
    }
    if (type == "message") {
      localStorage.setItem("leadMessage", e)
    }
    setSearchedCount("")
    setAllFetch([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/fetch-from-email?type=api&latest_leads_only=${
          localStorage.getItem("leadStatus3") !== null
            ? localStorage.getItem("leadStatus3")
            : ""
        }
         &api_key=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &lead_source_id=${
           localStorage.getItem("leadSource") !== null
             ? localStorage.getItem("leadSource")
             : ""
         }
         &platform=${
           localStorage.getItem("leadMessage") !== null
             ? localStorage.getItem("leadMessage")
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllFetch(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setname_filter(false)
          setleadsource_filter(false)
          setmessage_filter(false)
          setstatus3_filter(false)
        } else {
          setError(res.data.message)
        }
      })
  }

  const getAllSource = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/source?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllSource(res.data.data.data)
        } else {
        }
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const exportLead = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/fetch-from-email-export`,
        {
          ids: leadsSelectedIds,
          type: "api",
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-fetch-from-email-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const deleteLeads = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-fetch-email-deleted`,
        {
          ids: leadsSelectedIds,
          type: "api",
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllFetch()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const getAllFetch1 = () => {
    setLoader4(<Loader />)
    setAllFetch1([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/fetch-from-email?type=email&per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader4("")
          setError4(res.data.message)
          setCounter4(res.data.data.from)
          setAllFetch1(res.data.data.data)
          setAllPages1(res.data.data)
        } else {
          setLoader4("")
          setError4(res.data.message)
        }
      })
  }

  const paginate1 = e => {
    setAllFetch1([])
    setAllPages1([])
    setCounter4("")
    setLoader4(<Loader />)
    axios
      .get(
        `${e}&type=email&latest_leads_only=${
          localStorage.getItem("leadStatus3") !== null
            ? localStorage.getItem("leadStatus3")
            : ""
        }
           &host=${
             localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
           }
           &lead_source_id=${
             localStorage.getItem("leadSource") !== null
               ? localStorage.getItem("leadSource")
               : ""
           }
           &platform=${
             localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
           }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader4("")
          setCounter4(res.data.data.from)
          setAllPages1(res.data.data)
          setAllFetch1(res.data.data.data)
        } else {
          setError4(res.data.message)
          setLoader4("")
        }
      })
  }

  const getSearchedLeads1 = (e, type) => {
    setError4("")
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "leadsource") {
      localStorage.setItem("leadSource", e)
    }
    if (type == "status3") {
      localStorage.setItem("leadStatus3", e)
    }
    if (type == "message") {
      localStorage.setItem("leadMessage", e)
    }
    setSearchedCount4("")
    setAllFetch1([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/fetch-from-email?type=email&latest_leads_only=${
          localStorage.getItem("leadStatus3") !== null
            ? localStorage.getItem("leadStatus3")
            : ""
        }
        &host=${
          localStorage.getItem("leadName") !== null
            ? localStorage.getItem("leadName")
            : ""
        }
        &lead_source_id=${
          localStorage.getItem("leadSource") !== null
            ? localStorage.getItem("leadSource")
            : ""
        }
        &platform=${
          localStorage.getItem("leadMessage") !== null
            ? localStorage.getItem("leadMessage")
            : ""
        }
        &per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }
        `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader4("")
          setCounter4(res.data.data.from)
          setAllFetch1(res.data.data.data)
          setAllPages1(res.data.data)
          setSearchedCount4(res.data.data.total)
          setname_filter4(false)
          setleadsource_filter4(false)
          setmessage_filter4(false)
          setstatus3_filter4(false)
        } else {
          setError4(res.data.message)
        }
      })
  }

  const getAllSource1 = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/source?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllSource4(res.data.data.data)
        } else {
        }
      })
  }

  const selectAllCheck1 = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds4.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds4
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds4.length === 0) {
      setBlur4(false)
    } else {
      setBlur4(true)
    }
  }

  const exportLead1 = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/fetch-from-email-export`,
        {
          ids: leadsSelectedIds4,
          type: "email",
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds4([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName1(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName1 = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-fetch-from-email-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const deleteLeads1 = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-fetch-email-deleted`,
        {
          ids: leadsSelectedIds4,
          type: "email",
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds4([])
          getAllFetch1()
          setdynamic_description4(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    getAllFetch1()
    getAllSource1()
    getAllFetch()
    getWebApi()
    getAllSource()
    getWebsiteApi()
  }, [])

  return (
    <>
      {modal_standard ? (
        <FetchModal
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          getAllFetch={getAllFetch}
        />
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">You won't be able to revert this!</span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">
              <span className="text-warning">
                Allowed Number of Tokens (Total limit is{" "}
                {dashboardData?.fetch_enquries?.fetch_enquries_limit_total},
                Used {dashboardData?.fetch_enquries?.fetch_enquries_limit_used})
              </span>
            </h6>
          </Col>
        </Row>
      </div>
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Fetch From Webhook</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setnewModal_standard(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Results
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Webhook URL
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Platform
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Lead Source
                    </div>
                  </th>
                  <th>Auto Assign To</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Latest Lead Only
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
                {webhook &&
                  webhook.map((item, obj) => (
                    <FetchWlist
                      key={obj}
                      data={item}
                      setBlur={setBlur}
                      i={obj + 1}
                      counter3={counter3}
                    />
                  ))}
              </tbody>
            </table>
            {error3 ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error3}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Fetch From API</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              {blur ? (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary" disabled>
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    disabled
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              {getPermission() &&
              getPermission().lead_export.lead_export.view.is_checked ===
                "yes" ? (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    exportLead()
                  }}
                >
                  Export
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setmodal_standard(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Results
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      API Key
                      {name_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        name_filter={name_filter}
                        setname_filter={setname_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Platform
                      {message_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setmessage_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadMessage") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setmessage_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setmessage_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        message_filter={message_filter}
                        setmessage_filter={setmessage_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Lead Source
                      {leadsource_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setleadsource_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadSource") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setleadsource_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setleadsource_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        leadsource_filter={leadsource_filter}
                        setleadsource_filter={setleadsource_filter}
                        data={allSource}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Auto Assign To</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Latest Lead Only
                      {status3_filter ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setstatus3_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadStatus3") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setstatus3_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setstatus3_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        status3_filter={status3_filter}
                        setstatus3_filter={setstatus3_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
                {allFetch &&
                  allFetch.map((item, obj) => (
                    <FetchList
                      key={obj}
                      data={item}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      i={obj}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllFetch}
        />
      ) : (
        ""
      )}
      {nwemodal_standard ? (
        <FetchWeb
          nwemodal_standard={nwemodal_standard}
          setnewModal_standard={setnewModal_standard}
          getWebApi={getWebApi}
        />
      ) : (
        ""
      )}

      {modal_standard1 ? (
        <FetcheModal
          modal_standard1={modal_standard1}
          setmodal_standard1={setmodal_standard1}
          getAllFetch1={getAllFetch1}
        />
      ) : (
        ""
      )}
      {confirm_alert1 ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert1(false)
            setsuccess_dlg4(true)
            deleteLeads1()
            setdynamic_title4("Deleted")
            setdynamic_description4("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert1(false)}
        >
          <span className="text-danger">You won't be able to revert this!</span>
        </SweetAlert>
      ) : null}

      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Fetch From Email</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              {blur4 ? (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert1(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary" disabled>
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    disabled
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert1(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              {getPermission() &&
              getPermission().lead_export.lead_export.view.is_checked ===
                "yes" ? (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    exportLead1()
                  }}
                >
                  Export
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setmodal_standard1(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount4 !== "" ? (
              <h6 className="">
                Searched Results
                <span> ({searchedCount4})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck1(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Host
                      {name_filter4 ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter4(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter4(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter4(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        name_filter={name_filter4}
                        setname_filter={setname_filter4}
                        getSearchedLeads={getSearchedLeads1}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Platform
                      {message_filter4 ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setmessage_filter4(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadMessage") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setmessage_filter4(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setmessage_filter4(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        message_filter={message_filter4}
                        setmessage_filter={setmessage_filter4}
                        getSearchedLeads={getSearchedLeads1}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Lead Source
                      {leadsource_filter4 ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setleadsource_filter4(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadSource") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setleadsource_filter4(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setleadsource_filter4(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        leadsource_filter={leadsource_filter4}
                        setleadsource_filter={setleadsource_filter4}
                        data={allSource4}
                        getSearchedLeads={getSearchedLeads1}
                      />
                    </div>
                  </th>
                  <th>Auto Assign To</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Latest Lead Only
                      {status3_filter4 ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setstatus3_filter4(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadStatus3") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setstatus3_filter4(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setstatus3_filter4(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        status3_filter={status3_filter4}
                        setstatus3_filter={setstatus3_filter4}
                        getSearchedLeads={getSearchedLeads1}
                      />
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
                {allFetch1 &&
                  allFetch1.map((item, obj) => (
                    <FetchEList
                      key={obj}
                      data={item}
                      setBlur={setBlur4}
                      setLeadsSelectedIds={setLeadsSelectedIds4}
                      leadsSelectedIds={leadsSelectedIds4}
                      i={obj}
                      counter={counter4}
                    />
                  ))}
              </tbody>
            </table>
            {error3 ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error3}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader4}
      {allPages1 !== [] ? (
        <Paginations
          data={allPages1}
          paginate={paginate1}
          perPage={getAllFetch1}
        />
      ) : (
        ""
      )}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Fetch From Website</h6>
          </Col>
          <Col md={6}></Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>#</th>
                  <th>Name</th>
                  <th>Total Leads</th>
                  <th>Assigned to</th>
                  <th>Action</th>
                </tr>
                {webData &&
                  webData.map((item, obj) => (
                    <FetchWebsite
                      key={obj}
                      data={item}
                      i={obj}
                      counter={counter2}
                    />
                  ))}
              </tbody>
            </table>
            {error2 ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error2}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default FetchApiKey
