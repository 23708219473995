import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, FormGroup, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { getCurrentUser, setCurrentUser } from "../../../helpers/Utils"
import axios from "axios"

const Facebook = () => {
  const callbackURL = "https://staging.klikcrm.com/webhook-data"
  const verifyToken = "Please Enter your Facebook Verify Token"

  // State variables for inputs
  const [loading, setLoading] = useState(false)
  const [FACEBOOK_APP_ID, setFACEBOOK_APP_ID] = useState("")
  const [app_secret, setApp_secret] = useState("")
  const [access_token, setAccess_Token] = useState("")
  const [verify_token, setVerify_token] = useState("")

  // Fetch initial values from localStorage using getCurrentUser
  const loadInitialValues = () => {
    const user = getCurrentUser()
    setFACEBOOK_APP_ID(user?.FACEBOOK_APP_ID || "")
    setApp_secret(user?.FACEBOOK_APP_SECRET || "")
    setAccess_Token(user?.FACEBOOK_ACCESS_TOKEN || "")
    setVerify_token(user?.FACEBOOK_VERIFY_TOKEN || "")
  }

  useEffect(() => {
    loadInitialValues()
  }, [])

  // Handle form submission
  const handleSubmit = (e, value) => {
    e.preventDefault()
    setLoading(true)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/connect-facebook`,
        {
          FACEBOOK_APP_ID: value.app_id,
          FACEBOOK_APP_SECRET: value.app_secret,
          FACEBOOK_ACCESS_TOKEN: value.access_token,
          FACEBOOK_VERIFY_TOKEN: value.verify_token,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(res => {
        setLoading(false)

        if (res.data.data) {
          // Update local state and localStorage
          const updatedData = {
            FACEBOOK_APP_ID: res.data.data.FACEBOOK_APP_ID,
            FACEBOOK_APP_SECRET: res.data.data.FACEBOOK_APP_SECRET,
            FACEBOOK_ACCESS_TOKEN: res.data.data.FACEBOOK_ACCESS_TOKEN,
            FACEBOOK_VERIFY_TOKEN: res.data.data.FACEBOOK_VERIFY_TOKEN,
          }

          setFACEBOOK_APP_ID(updatedData.FACEBOOK_APP_ID)
          setApp_secret(updatedData.FACEBOOK_APP_SECRET)
          setAccess_Token(updatedData.FACEBOOK_ACCESS_TOKEN)
          setVerify_token(updatedData.FACEBOOK_VERIFY_TOKEN)

          // Update user in localStorage
          const user = getCurrentUser()
          setCurrentUser({ ...user, ...updatedData })

          // Optional: Reload values from localStorage
          loadInitialValues()

          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
          })
          window.history.go(-1)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
          })
        }
      })
      .catch(() => {
        setLoading(false)
        toast.error("An error occurred. Please try again.", {
          position: "top-center",
          autoClose: 5000,
        })
      })
  }

  return (
    <div className="container mt-5">
      <Card>
        <CardBody>
          <h2 className="mb-4 text-center">Facebook Webhooks Integration</h2>
          <AvForm
            onValidSubmit={(e, v) => {
              handleSubmit(e, v)
            }}
          >
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Facebook App ID</Label>
                  <AvField
                    name="app_id"
                    placeholder="Enter Facebook App ID"
                    type="text"
                    value={FACEBOOK_APP_ID}
                    onChange={e => setFACEBOOK_APP_ID(e.target.value)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "This field is required",
                      },
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Facebook App Secret</Label>
                  <AvField
                    name="app_secret"
                    placeholder="Enter Facebook App Secret"
                    type="text"
                    value={app_secret}
                    onChange={e => setApp_secret(e.target.value)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "This field is required",
                      },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Facebook Access Token</Label>
                  <AvField
                    name="access_token"
                    placeholder="Enter Facebook Access Token"
                    type="text"
                    value={access_token}
                    onChange={e => setAccess_Token(e.target.value)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "This field is required",
                      },
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Facebook Verify Token</Label>
                  <AvField
                    name="verify_token"
                    placeholder="Enter Facebook Verify Token"
                    type="text"
                    value={verify_token}
                    onChange={e => setVerify_token(e.target.value)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "This field is required",
                      },
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center mt-2 d-flex justify-content-end">
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </AvForm>
        </CardBody>
        <div className="mb-3">
          <h5>
            <mark style={{ backgroundColor: "lightgreen" }}>
              Your changes have been saved successfully! Please follow the
              instructions below to complete the setup.
            </mark>
          </h5>
        </div>

        <div className="mb-4">
          <h4>Step 1: Add Webhooks Product</h4>
          <ul>
            <li className="mb-2">
              In the app dashboard, go to Products + Add Product.
            </li>
            <li className="mb-2">
              Find the "Webhooks" product and click "Set Up".
            </li>
            <li className="mb-2">
              Click "Add Callback URL" for the desired object (e.g., Page).
            </li>
            <ul>
              <li className="mb-2">
                <strong>Callback URL:</strong> {callbackURL}
              </li>
              <li className="mb-2">
                <strong>Verify Token:</strong> {verifyToken}
              </li>
            </ul>
            <li className="mb-2">Click "Verify and Save".</li>
          </ul>
        </div>

        <div className="mb-4">
          <h4>Step 2: Add Necessary Permissions</h4>
          <ul>
            <li className="mb-2">
              In the App Review section, request the following permissions:
            </li>
            <ul>
              <li className="mb-2">
                <strong>manage_pages:</strong> To manage pages.
              </li>
              <li className="mb-2">
                <strong>leads_retrieval:</strong> To fetch lead data from forms.
              </li>
            </ul>
            <li className="mb-2">
              Complete the App Review process by submitting your app for review
              with proper documentation and screencasts.
            </li>
          </ul>
        </div>

        <div>
          <h4>Step 3: Configure Page Subscription</h4>
          <ul>
            <li className="mb-2">Go to Webhooks in your app dashboard</li>
            <li className="mb-2">Select Page under the Webhooks section.</li>
            <li className="mb-2">
              Subscribe to the leadgen field to receive notifications for lead
              generation.
            </li>
            <li className="mb-2">
              Use the Page Access Token to authorize your app.
            </li>
          </ul>
        </div>
      </Card>
    </div>
  )
}

export default Facebook
