import React from "react"
import MetaTags from "react-meta-tags"
import PrivacyPage from "../../components/Privacy-termsCondition/privacy"

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Privacy policy | KlikCRM</title>
      </MetaTags>
      <PrivacyPage />
    </React.Fragment>
  )
}
export default PrivacyPolicy
