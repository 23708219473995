import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import axios from "axios"
import { AvForm } from "availity-reactstrap-validation"
import { getCurrentUser, getPermission } from "../../helpers/Utils"
import { Col, Row, Container } from "reactstrap"
import Loader from "../../components/Loader/loader.js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Paginations from "../../components/Pagination"
import InputFilter from "../../components/Filter/SearchFilter/index.jsx"
import DateFilter from "../../components/Filter/DateFilter/index.jsx"

let name = ""

const LoginLogs = () => {
  const [allLogs, setAllLogs] = useState([])
  const [log, setLog] = useState("")
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [dynamic_description, setdynamic_description] = useState("")
  const [name_filter, setname_filter] = useState(false)
  const [important_date_filter, setimportant_date_filter] = useState(false)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")

  const getAllLogs = (e, type) => {
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "important_date") {
      localStorage.setItem(
        "leadImportant_date",
        JSON.stringify({
          from: e.from,
          to: e.to,
        })
      )
    }
    setAllLogs([])

    setLoader(<Loader />)
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/login-log?per_page=${
      localStorage.getItem("PerPage") !== null
        ? localStorage.getItem("PerPage")
        : ""
    }&search=${
      localStorage.getItem("leadName") !== null
        ? localStorage.getItem("leadName")
        : ""
    }&from_date=${
      JSON.parse(localStorage.getItem("leadImportant_date"))
        ? JSON.parse(localStorage.getItem("leadImportant_date")).from
        : ""
    }
         &to_date=${
           JSON.parse(localStorage.getItem("leadImportant_date"))
             ? JSON.parse(localStorage.getItem("leadImportant_date")).to
             : ""
         }`

    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setAllLogs(res.data.data.data)
          setAllPages(res.data.data)
          setname_filter(false)
          setimportant_date_filter(false)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const deleteLog = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/login-log/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLog("")
          setdynamic_description(res.data.message)
        } else {
        }
      })
  }

  const paginate = e => {
    setAllLogs([])
    setCounter("")
    setAllPages([])
    setLoader(<Loader />)
    axios
      .get(
        `${e}&search=${
          localStorage.getItem("leadName") !== null
            ? localStorage.getItem("leadName")
            : ""
        }&from_date=${
          JSON.parse(localStorage.getItem("leadImportant_date"))
            ? JSON.parse(localStorage.getItem("leadImportant_date")).from
            : ""
        }
         &to_date=${
           JSON.parse(localStorage.getItem("leadImportant_date"))
             ? JSON.parse(localStorage.getItem("leadImportant_date")).to
             : ""
         }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllLogs(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const clear = () => {
    getAllLogs("", "")
  }

  const exportLead = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/loginlogs-export`,
        {
          ids: "",
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-loginlogs-export-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  useEffect(() => {
    getAllLogs()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Login Logs | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <AvForm
              onValidSubmit={e => {
                getAllLogs(e)
              }}
            >
              <Row>
                <Col md={6}>
                  <h6 className="page-title">Login Logs</h6>
                </Col>
                <Col md={6}>
                  <div className="d-flex justify-content-end">
                    {getPermission() &&
                    getPermission().lead_export.lead_export.view.is_checked ===
                      "yes" ? (
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => {
                          exportLead()
                        }}
                      >
                        Export
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="lead_table table table-sm table-bordered table-responsive">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr className="static-header">
                      <th>#</th>
                      <th>
                        <div
                          className="position-relative"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          User Name{" "}
                          {name_filter ? (
                            <>
                              <span
                                style={{ cursor: "pointer", color: "orange" }}
                                onClick={() => setname_filter(false)}
                              >
                                <i className="fa fa-times fa-lg ms-2"></i>
                              </span>
                            </>
                          ) : (
                            <>
                              {localStorage.getItem("leadName") == "" ? (
                                <span
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setname_filter(true)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              ) : (
                                <span
                                  className=""
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => setname_filter(true)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              )}
                            </>
                          )}
                          <InputFilter
                            name_filter={name_filter}
                            setname_filter={setname_filter}
                            getSearchedLeads={getAllLogs}
                          />
                        </div>
                      </th>
                      <th>Activity</th>
                      <th>IP</th>
                      <th>
                        <div
                          className="position-relative"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Date & Time{" "}
                          {important_date_filter ? (
                            <>
                              <span
                                style={{ cursor: "pointer", color: "orange" }}
                                onClick={() => setimportant_date_filter(false)}
                              >
                                <i className="fa fa-times fa-lg ms-2"></i>
                              </span>
                            </>
                          ) : (
                            <>
                              {JSON.parse(
                                localStorage.getItem("leadImportant_date")
                              )?.from !== undefined &&
                              JSON.parse(
                                localStorage.getItem("leadImportant_date")
                              )?.from !== "" ? (
                                <span
                                  className=""
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => setimportant_date_filter(true)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              ) : (
                                <span
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setimportant_date_filter(true)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              )}
                            </>
                          )}
                          <DateFilter
                            important_date_filter={important_date_filter}
                            setimportant_date_filter={setimportant_date_filter}
                            getSearchedLeads={getAllLogs}
                          />
                        </div>
                      </th>
                      {/* <th className="text-end">Action</th> */}
                    </tr>
                    {allLogs &&
                      allLogs.map((item, i) => (
                        <tr key={i}>
                          <td>{counter + i}</td>
                          <td>{item.user_name}</td>
                          <td>
                            {item.activity !== "login" ? (
                              <span className="badge bg-danger">
                                {item.activity}
                              </span>
                            ) : (
                              <span className="badge bg-success">
                                {item.activity}
                              </span>
                            )}
                          </td>
                          <td>{item.ip}</td>
                          <td>{item.created_at}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {error ? (
                  <span
                    className="text-danger mt-3 d-table m-auto"
                    role="alert"
                  >
                    <h1>{error}</h1>
                  </span>
                ) : (
                  ""
                )}
              </div>
              {loader}
              {allPages ? (
                <Paginations
                  data={allPages}
                  paginate={paginate}
                  perPage={getAllLogs}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LoginLogs
