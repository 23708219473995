import React, { useState } from "react"
import { DateRange } from "react-date-range"

const DateFilter = props => {
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ])

  const formatDate = date => {
    let d = new Date(date)
    let month = (d.getMonth() + 1).toString()
    let day = d.getDate().toString()
    let year = d.getFullYear()
    if (month.length < 2) {
      month = "0" + month
    }
    if (day.length < 2) {
      day = "0" + day
    }
    return [year, month, day].join("-")
  }

  return (
    <div>
      {props.date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af2_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af3_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af4_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af5_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.important_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af_important_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af2_important_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af3_important_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af4_important_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af5_important_date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "important_date"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.date2_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={item => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date2"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "date2"
              )
            }
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default DateFilter
