import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import ProductFieldsTable from "../../../components/Product/Product-fields"

const ProductFields = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Fields | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <ProductFieldsTable />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default ProductFields
