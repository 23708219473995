import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import LongText from "../MessageField"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const LedgerList = props => {
  const [ledger, setLedger] = useState(props.data)
  const [modal_standard, setmodal_standard] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const deleteLedger = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/ledger/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLedger("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    selectedRoleId = []
  }, [])

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e)
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id
      })
      selectedRoleId = uniqueID
      props.setLeadsSelectedIds(selectedRoleId)
    } else {
      selectedRoleId = props.leadsSelectedIds
      var array = selectedRoleId
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1)
      }
      props.setLeadsSelectedIds(selectedRoleId)
    }
    if (selectedRoleId.length == 0) {
      props.setBlur(false)
    } else {
      props.setBlur(true)
    }
  }

  if (ledger === "") {
    return ""
  } else
    return (
      <>
        <tr key={props.i}>
          <td>
            <input
              onChange={e => getSelectedId(ledger.id, e)}
              name="chk"
              value={ledger.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>{props.counter + props.i}</td>
          <td>{ledger.id}</td>
          <td>
            <Link
              to={`/customers?id=${ledger.customer_id}`}
              className="text-info"
            >
              {ledger.customer_name}
            </Link>
          </td>
          <td>{ledger.order_id}</td>
          <td>&#8377; {ledger.amount}</td>
          <td>
            <LongText content={ledger.message} />
          </td>
          {getCurrentUser()?.enable_branch == 1 ? (
            <td>{ledger.branch?.name}</td>
          ) : (
            ""
          )}
          <td>
            {ledger.type == "paid" ? (
              <span className="badge bg-success">Credit</span>
            ) : null}
            {ledger.type == "outstanding" ? (
              <span className="badge bg-danger">Debit</span>
            ) : null}
          </td>
          <td>{ledger.user_name}</td>
          <td>{ledger.transaction_date}</td>
          <td>
            {getPermission().ledger.ledger.delete.is_checked === "yes" ? (
              <a
                className="text-danger ms-2"
                onClick={() => {
                  setconfirm_alert(true)
                }}
              >
                <i className="fas fa-trash-alt" />
              </a>
            ) : (
              ""
            )}
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteLedger(ledger.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
      </>
    )
}
export default LedgerList
