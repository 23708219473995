import React from "react"
import MetaTags from "react-meta-tags"
import ConditionsPage from "../../components/Privacy-termsCondition/conditions"

const TermsConditions = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Terms & Conditions | KlikCRM</title>
      </MetaTags>
      <ConditionsPage />
    </React.Fragment>
  )
}
export default TermsConditions
